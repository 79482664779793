import actions from '../enums/actions';

export const setField = field => ({
    type: actions.FIELD_SET_ID,
    payload: {
        field
    }
});

export const setPeopleGroup = peopleGroup => ({
    type: actions.FIELD_SET_PEOPLE_GROUP,
    payload: {
        peopleGroup
    }
});

export const setActive = active => ({
    type: actions.FIELD_SET_ACTIVE,
    payload: {
        active
    }
});

export const setFieldToConsultants = fieldToConsultants => ({
    type: actions.FIELD_SET_FIELD_TO_CONSULTANTS,
    payload: {
        fieldToConsultants
    }
});
