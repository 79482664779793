import escapeRegExp from 'lodash/escapeRegExp';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import swal from 'sweetalert';

import { ternary } from '../../constants';

const generateQueryParams = (location, obj) => {
    const newQuery = {
        ...location.query,
        ...obj
    };
    return Object
        .keys(newQuery)
        .map(key => `${key}=${encodeURI(newQuery[key])}`)
        .join('&');
};

const Training = ({
                      trainings,
                      localizedLists,
                      location,
                      history,
                      user,
                      canCreateUpdateTrainings,
                      setCanCreateUpdateTrainings,
                  }) => {
    const { _id: userId } = user;

    useEffect(() => {
        const computeCanCreate = async () => {
            const { errors, data } = await gql.transaction(
                'query',
                'getCreateUpdateTrainingPermissions',
                { userId },
            );
            if (errors) throw errors[0];
            const allowed = data.getCreateUpdateTrainingPermissions;
            console.log('new val', ternary.fromBoolean(allowed), allowed)
            setCanCreateUpdateTrainings(ternary.fromBoolean(allowed));
        };

        if (userId && canCreateUpdateTrainings === ternary.UNKNOWN) {
            computeCanCreate().then();
        }
    }, [userId, canCreateUpdateTrainings, setCanCreateUpdateTrainings]);

    const onMainFilterChange = e => {
        e.preventDefault();
        history.push(`/training?${generateQueryParams(location, {filter: e.target.value})}`);
    };

    const showCannotCreateMsg = async e => {
        e.preventDefault();
        await swal(Localize.text('YourUserAccountDoesNotHaveAuthorizationToAddANewTrainingSessionCRLFCRLFPleaseContactImoGlobalPartnersForHelp', 'Training', { CRLF: '\n' }));
    };

    const mainFilter = location.query.filter || '';
    const qty = location.query.q ? Number(location.query.q) : 100;

    const { listManager } = window;
    const listItems = listManager.getItems(localizedLists);

    const generatePatt = str => new RegExp(`(?:^|\\W)${escapeRegExp(str.trim())}`, 'i');
    const patts = mainFilter.trim()
        .split(/\s+/g)
        .map(str => generatePatt(str));
    const testByPatterns = str => patts.every(p => p.test(str));

    const { pathname } = location;
    const { sort = 'date', direction = 'des' } = location.query;
    const sortDirection = direction === 'des' ? -1 : 1;

    const filteredTrainings = trainings
        .filter(t => !!t.date)
        .map(t => Object.assign({}, t, {
            date: moment(t.date, 'MM-YYYY').toISOString(),
            normalName: listItems.has(t.trainingName) ? listItems.get(t.trainingName).name : '',
            instructors: t.instructors.map(i => ({...i, fullName: `${i.firstName || ''} ${i.lastName || ''}`}))
        }))
        .filter(t => {
            if(patts.length === 0) return true;
            return testByPatterns(t.normalName) ||
                testByPatterns(t.location) ||
                testByPatterns(t.instructors.map(i => i.fullName).join(' ')) ||
                testByPatterns(t.note) ||
                testByPatterns(t.date);
        })
        .sort((a, b) => {
            if(sort === 'name') {
                const nameA = a.normalName || '';
                const nameB = b.normalName || '';
                return nameA.localeCompare(nameB) * sortDirection;
            } else {
                const dateA = a.date || '';
                const dateB = b.date || '';
                return dateA.localeCompare(dateB) * sortDirection;
            }
        });

    const addNewButtonText = <><i className={'fa fa-plus'} /> {Localize.text('AddNewTrainingSession', 'Training')}</>;

    return (
        <div className={'container-fluid'}>
            <div className={'row'}>
                <div className={'col'}>
                    <h1>{Localize.text('TrainingSessions', 'Training')}</h1>
                </div>
            </div>
            <div className={'row'}>
                <div className={'col form-group'}>
                    {canCreateUpdateTrainings === ternary.TRUE ?
                        <Link to={'/training/new'} className={'btn btn-outline-dark'}>{addNewButtonText}</Link>
                        :
                        <button className="btn btn-outline-dark" disabled={canCreateUpdateTrainings === ternary.UNKNOWN} onClick={showCannotCreateMsg}>{addNewButtonText}</button>
                    }
                </div>
            </div>

            <div className={'row'}>
                <div className={'col-4'}>
                    <div className={'form-group'}>
                        <label>Filter:</label>
                        <input type={'text'} className={'form-control'} value={mainFilter} onChange={onMainFilterChange} />
                    </div>
                </div>
                {/*<div className={'col-2'}>*/}
                    {/*<div className={'form-group'}>*/}
                        {/*<label>Sort By:</label>*/}
                        {/*<select className={'form-control'}>*/}
                            {/*<option value={'lastName'}>Date</option>*/}
                            {/*<option value={'firstName'}>Name</option>*/}
                        {/*</select>*/}
                    {/*</div>*/}
                {/*</div>*/}
            </div>

            <div className={'row'}>
                <div className={'col-12'}>
                    <table className={'table table-bordered table-hover table-sm'}>
                        <thead>
                        <tr>
                            <th><Link className={'text-dark'} to={`${pathname}?sort=date&direction=${sort !== 'date' ? 'des' : direction === 'des' ? 'asc' : 'des'}`}>Date {sort === 'date' ? <i className={'fa fa-sort'} /> : <span />}</Link></th>
                            <th><Link className={'text-dark'} to={`${pathname}?sort=name&direction=${sort !== 'name' ? 'asc' : direction === 'des' ? 'asc' : 'des'}`}>Name {sort === 'name' ? <i className={'fa fa-sort'} /> : <span />}</Link></th>
                            <th>Location</th>
                            <th>Instructors</th>
                            <th>Note</th>
                        </tr>
                        </thead>
                        <tbody>
                        {filteredTrainings
                            .slice(0, qty)
                            .map(t => {
                                return (
                                    <tr key={t._id}>
                                        <td>{moment(t.date).format('YYYY-MM')}</td>
                                        <td><Link to={`/training/${t._id}`}>{t.normalName ? t.normalName : Localize.text('Unknown', 'UniversalForms')}</Link></td>
                                        <td>{t.location}</td>
                                        <td>{t.instructors.reduce((arr, i, idx) => {
                                            if(idx === t.instructors.length - 1) {
                                                return [
                                                    ...arr,
                                                    <Link key={i._id} to={`/personnel/${i._id}`}>{i.fullName}</Link>
                                                ];
                                            } else {
                                                return [
                                                    ...arr,
                                                    <Link key={i._id} to={`/personnel/${i._id}`}>{i.fullName}</Link>,
                                                    <span key={'spacer-' + idx}>{', '}</span>
                                                ];
                                            }
                                        }, [])}</td>
                                        <td>{t.note}</td>
                                    </tr>
                                );
                        })}
                        </tbody>
                    </table>
                </div>
            </div>

            <div className={'row'}>
                <div className={'col-12'}>
                    <div className={'form-group d-flex justify-content-center'}>
                        {qty < filteredTrainings.length ? <Link to={`/training?${generateQueryParams(location, {q: qty + 100})}`} className={'btn btn-primary'}>{'Load More'}</Link> : <div />}
                    </div>
                </div>
            </div>

        </div>
    );
};
Training.propTypes = {
    trainings: PropTypes.arrayOf(PropTypes.object),
    localizedLists: PropTypes.arrayOf(PropTypes.object),
    location: PropTypes.object,
    history: PropTypes.object,
    user: PropTypes.object,
    canCreateUpdateTrainings: PropTypes.string,
    setCanCreateUpdateTrainings: PropTypes.func,
};

export default Training;
