import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import * as _ from 'lodash';
import { Map } from 'immutable';
import ModalTextarea from '../../../shared/modal-textarea';
import RadarGraph from '../../../shared/radar-graph';
import LineChart from '../../../shared/line-chart';
import { TwoColumnLayout, TwoColumnRow } from '../../../shared/two-column-layout';
import * as dateUtils from '../../../../util/date-utils';
import { dbGuids, modalStatuses } from '../../../../constants';
import { setAppDisableNav, setAppLocations, setAppModalStatus } from '../../../../actions/app-actions';
import shareWithServer from '../../../../constants/share-with-server';
const { swapMMdashYYYY } = shareWithServer;
const { pgServiceStatusActive, pgServiceStatusInactive } = shareWithServer.localizableLists;


const yesNo = bool => {
    if(bool) {
        return <span>{Localize.text('Yes', 'Universal')}</span>;
    } else {
        return <span>{Localize.text('No', 'Universal')}</span>;
    }
};

class Summary extends React.Component {

    constructor(props) {
        super(props);
        const { peopleGroup } = props;
        this.state = this.getInitialState(peopleGroup);
        _.bindAll(this, [
            'openGeneralModal',
            'onGeneralSave',
            'onGeneralCancel',
            'onAlternateNameChange',
            'onPublicationNameChange',
            'onWorkStatusChange',
            'openLocationsModal',
            'onLocationsCancel',
            'openLinkModal',
            'onLinkChange',
            'onLinkCancel',
            'onLinkSave',
            'onInputChange',
            'saveNote',
            'consultantsChanged',
            'getInitialState'
        ]);
    }

    getInitialState(peopleGroup) {
        return {
            alternateName: peopleGroup.alternateName,
            publicationName: peopleGroup.publicationName,
            workStatus: peopleGroup.workStatus,
            ethnologueCode: peopleGroup.ethnologueCode,
            languageFamily: peopleGroup.languageFamily,
            population: peopleGroup.population,

            dateStartedPartnering: peopleGroup.dateStartedPartnering,
            literacyRateVernacular: peopleGroup.literacyRateVernacular,
            literacyRateLWC: peopleGroup.literacyRateLWC,
            note: peopleGroup.note,

            joshuaProjectLink: peopleGroup.joshuaProjectLink,
            peopleGroupsOrgLink: peopleGroup.peopleGroupsOrgLink
        };
    }

    componentWillReceiveProps(newProps) {
        if(newProps.peopleGroup !== this.props.peopleGroup) {
            this.setState(this.getInitialState(newProps.peopleGroup));
        }
    }

    componentDidUpdate() {
        if (this.props.modalStatus === modalStatuses.CLOSE_MODAL) {
            if (($(this.generalModalNode).data('bs.modal') || {})._isShown) this.onGeneralCancel();
            if (($(this.linkModalNode).data('bs.modal') || {})._isShown) this.onLinkCancel();
            if (($(this.locationsModalNode).data('bs.modal') || {})._isShown) this.onLocationsCancel();
        }
    }

    onAlternateNameChange(e) {
        e.preventDefault();
        this.setState({
            ...this.state,
            alternateName: e.target.value
        });
    }

    onPublicationNameChange(e) {
        e.preventDefault();
        this.setState({
            ...this.state,
            publicationName: e.target.value
        });
    }

    onWorkStatusChange(e) {
        e.preventDefault();
        this.setState({
            ...this.state,
            workStatus: e.target.value
        });
    }

    openGeneralModal(e) {
        e.preventDefault();
        $(this.generalModalNode).modal({
            backdrop: 'static',
            keyboard: false,
            show: true
        });
        setTimeout(() => {
            $(this.generalModalNode).find('input')[0].focus();
        }, 0);
        this.props.disableNav();
        this.props.setModalStatus(modalStatuses.SHOWING_MODAL);
    }

    onGeneralCancel(e) {
        if (e) e.preventDefault();
        const {
            alternateName,
            publicationName,
            workStatus,
            ethnologueCode,
            languageFamily,
            population,
            dateStartedPartnering,
            literacyRateVernacular,
            literacyRateLWC,
        } = this.props.peopleGroup;
        this.setState({
            ...this.state,
            alternateName,
            publicationName,
            workStatus,
            ethnologueCode,
            languageFamily,
            population,
            dateStartedPartnering,
            literacyRateVernacular,
            literacyRateLWC,
        });
        $(this.generalModalNode).modal('hide');
        this.props.setModalStatus(modalStatuses.NO_MODAL);
        this.props.enableNav();
    }

    async onGeneralSave(e) {
        e.preventDefault();
        let {
            alternateName,
            publicationName,
            workStatus,
            ethnologueCode,
            languageFamily,
            population = 0,
            dateStartedPartnering,
            literacyRateVernacular = 0,
            literacyRateLWC = 0,
        } = this.state;

        alternateName = alternateName.trim();
        publicationName = publicationName.trim();
        workStatus = workStatus.trim();
        ethnologueCode = ethnologueCode.trim();
        languageFamily = languageFamily.trim();
        dateStartedPartnering = dateStartedPartnering.trim();
        population = Number(population);
        literacyRateVernacular = Number(literacyRateVernacular);
        literacyRateLWC = Number(literacyRateLWC);

        if(literacyRateVernacular && (literacyRateVernacular < 0 || literacyRateVernacular > 100)) {
            return swal({
                icon: 'error',
                text: Localize.text('LiteracyRateVernacularMustBeANumberBetween0And100', 'PeopleGroupDetail'),
                button: Localize.text('OK', 'Universal')
            });
        }
        if(literacyRateLWC && (literacyRateLWC < 0 || literacyRateLWC > 100)) {
            return swal({
                icon: 'error',
                text: Localize.text('LiteracyRateLWCMustBeANumberBetween0And100', 'PeopleGroupDetail'),
                button: Localize.text('OK', 'Universal')
            });
        }

        for(const date of [dateStartedPartnering]) {
            if(!date) continue;
            const valid = await dateUtils.isValidDate(date, true);
            if(!valid) return;
        }

        dateStartedPartnering = dateStartedPartnering ? dateUtils.formatDate(dateStartedPartnering) : '';

        this.props.save({
            alternateName,
            publicationName,
            workStatus,
            ethnologueCode,
            languageFamily,
            dateStartedPartnering,
            population,
            literacyRateVernacular,
            literacyRateLWC
        });

        $(this.generalModalNode).modal('hide');
        this.props.setModalStatus(modalStatuses.NO_MODAL);
        this.props.enableNav();
    }

    openLocationsModal(e) {
        e.preventDefault();

        $(this.locationsModalNode).modal({
            backdrop: 'static',
            keyboard: false,
            show: true
        });
        this.props.disableNav();
        this.props.setModalStatus(modalStatuses.SHOWING_MODAL);
    }

    onLocationsCancel(e) {
        if (e) e.preventDefault();
        $(this.locationsModalNode).modal('hide');
        this.props.setModalStatus(modalStatuses.NO_MODAL);
        this.props.enableNav();
    }

    openLinkModal(e) {
        e.preventDefault();
        $(this.linkModalNode).modal({
            backdrop: 'static',
            keyboard: false,
            show: true
        });
        setTimeout(() => {
            $(this.linkModalNode).find('input')[0].focus();
        }, 0);
        this.props.disableNav();
        this.props.setModalStatus(modalStatuses.SHOWING_MODAL);
    }

    onLinkChange(e, key) {
        const { value } = e.target;
        this.setState({
            ...this.state,
            [key]: value
        });
    }

    onLinkCancel(e) {
        if (e) e.preventDefault();
        $(this.linkModalNode).modal('hide');
        const { joshuaProjectLink, peopleGroupsOrgLink } = this.props.peopleGroup;
        this.setState({
            ...this.state,
            joshuaProjectLink,
            peopleGroupsOrgLink
        });
        this.props.setModalStatus(modalStatuses.NO_MODAL);
        this.props.enableNav();
    }

    async onLinkSave(e) {
        e.preventDefault();
        const linkPatt = /https?:\/\/.+\..+/;
        let { joshuaProjectLink, peopleGroupsOrgLink } = this.state;
        joshuaProjectLink = joshuaProjectLink.trim();
        peopleGroupsOrgLink = peopleGroupsOrgLink.trim();
        if(joshuaProjectLink && !linkPatt.test(joshuaProjectLink)) {
            await swal(Localize.text('Oops', 'Universal'), Localize.text('JoshuaProjectLinkMustBeAValidWebAddress', 'PeopleGroupDetail'), 'warning');
            return;
        } else if (peopleGroupsOrgLink && !linkPatt.test(peopleGroupsOrgLink)) {
            await swal(Localize.text('Oops', 'Universal'), Localize.text('PeopleGroupsOrgLinkMustBeAValidWebAddress', 'PeopleGroupDetail'), 'warning');
            return;
        }
        this.props.save({ joshuaProjectLink, peopleGroupsOrgLink });
        $(this.linkModalNode).modal('hide');
        this.props.setModalStatus(modalStatuses.NO_MODAL);
        this.props.enableNav();
    }

    onInputChange(e, key) {
        e.preventDefault();
        this.setState({
            ...this.state,
            [key]: e.target.value
        });
    }

    saveNote(note) {
        note = note.trim();
        this.setState({
            ...this.state,
            note
        });
        this.props.save({ note });
    }

    consultantsChanged(data) {
        console.log(data);
    }

    render() {
        const { peopleGroup, locations, localizedListItems, windowHeight, localizedLists, statuses, consultants, people, readonly, isAdmin, fieldId, peopleId, setLocations } = this.props;
        const listItems = localizedListItems;
        const { state } = this;

        // console.log('peopleGroup', peopleGroup);

        const styles = {
            leftPadding: {
                // paddingLeft: 10,
                // minHeight: 24,
                // minWidth: 50
            }
        };

        const countryNames = _.chain(locations)
            .map(l => l.country ? l.country.name : '')
            .filter(l => l ? true : false)
            .uniq()
            .value();

        const churchesWithElders = locations
            .filter(l => l.numberOfElders > 0)
            .length;

        const churchesWithoutElders = locations
            .filter(loc => [dbGuids.CHURCH_STAGE_GROWTH_AND_MATURITY, dbGuids.CHURCH_STAGE_EQUIPPED_TO_PARTNER].includes(loc.churchStage) && loc.numberOfElders === 0)
            .length;

        const believers = locations
            .reduce((num, l) => num + l.numberOfBelievers, 0);

        const peopleGroupLocations = locations
            .filter(l => l.peopleGroup._id === peopleGroup._id);

        const getEarliestLocationDateField = dateField => {
            let earliest = '';

            for (const loc of locations) {
                const dateVal = loc[dateField];
                if (dateVal && (!earliest || swapMMdashYYYY(dateVal) < swapMMdashYYYY(earliest))) {
                    earliest = dateVal;
                }
            }

            return earliest;
        };

        const targetDateEntry = getEarliestLocationDateField('targetDateEntry');
        const dateEntered = getEarliestLocationDateField('dateEntered');
        const targetDateGospelPresented = getEarliestLocationDateField('targetDateGospelPresented');
        const dateGospelPresented = getEarliestLocationDateField('dateGospelPresented');

        const getAverage = obj => {
            const keys = Object
                .keys(obj)
                .filter(key => key !== 'mChurchDate');
            const total = keys
                .reduce((sum, key) => {
                    return sum + obj[key];
                }, 0);
            return total / keys.length;
        };

        let highestNum = 0, statusId;
        let discipleshipValue = 0, wordValue = 0, lifeValue = 0, identityValue = 0;
        for(const { _id, maturingChurchStatuses } of peopleGroupLocations) {
            if(maturingChurchStatuses.length === 0) continue;
            const latest = maturingChurchStatuses[maturingChurchStatuses.length - 1];

            const discipleshipStatusKeys = Object.keys(latest)
                .filter(key => /^mChurchDiscipleship/.test(key));
            const discipleshipAvg = discipleshipStatusKeys
                .reduce((sum, key) => sum + latest[key], 0) / discipleshipStatusKeys.length;
            if(discipleshipAvg > discipleshipValue) discipleshipValue = discipleshipAvg;

            const wordStatusKeys = Object.keys(latest)
                .filter(key => /^mChurchGodsWord/.test(key));
            const wordAvg = wordStatusKeys
                .reduce((sum, key) => sum + latest[key], 0) / wordStatusKeys.length;
            if(wordAvg > wordValue) wordValue = wordAvg;

            const identityStatusKeys = Object.keys(latest)
                .filter(key => /^mChurchIdentity/.test(key));
            const identityAvg = identityStatusKeys
                .reduce((sum, key) => sum + latest[key], 0) / identityStatusKeys.length;
            if(identityAvg > identityValue) identityValue = identityAvg;

            const lifeStatusKeys = Object.keys(latest)
                .filter(key => /^mChurchLife/.test(key));
            const lifeAvg = lifeStatusKeys
                .reduce((sum, key) => sum + latest[key], 0) / lifeStatusKeys.length;
            if(lifeAvg > lifeValue) lifeValue = lifeAvg;

            const avg = (discipleshipAvg + wordAvg + identityAvg + lifeAvg) / 4;

            if(avg > highestNum) {
                highestNum = avg;
                statusId = _id;
            }
        }

        let maturingChurchStatuses;
        if(highestNum && statusId) {
            const sortedStatuses = locations
                .reduce((arr, l) => {
                    return arr.concat(l.maturingChurchStatuses || []);
                }, [])
                .sort((a, b) => {
                    let [ monthA, yearA ] = a.mChurchDate.split('-');
                    monthA = Number(monthA);
                    yearA = Number(yearA);
                    let [ monthB, yearB ] = b.mChurchDate.split('-');
                    monthB = Number(monthB);
                    yearB = Number(yearB);
                    if(yearA === yearB) {
                        // return monthA === monthB ? 0 :
                        if(monthA === monthB) {
                            const avgA = getAverage(a);
                            const avgB = getAverage(b);
                            return avgA === avgB ? 0 : avgA > avgB ? 1 : -1;
                        } else {
                            return monthA > monthB ? 1 : -1;
                        }
                    } else {
                        return yearA > yearB ? 1 : -1;
                    }
                });
            let statusMap = Map();
            for(const status of sortedStatuses) {
                statusMap = statusMap.set(status.mChurchDate, status);
            }
            maturingChurchStatuses = [...statusMap.values()];
        } else {
            maturingChurchStatuses = [];
        }

        const preppedStatuses = statuses
            .reduce((map, s) => {
                if(map.has(s.person)) {
                    const arr = map.get(s.person);
                    return map.set(s.person, [...arr, { status: s.serviceStatus, location: s.location }]);
                } else {
                    return map.set(s.person, [{ status: s.serviceStatus, location: s.location }]);
                }
            }, Map());

        const preppedConsultants = consultants
            .reduce((map, s) => {
                const consultant = { personId: s.person, startDate: s.startDate, endDate: s.endDate };
                if(map.has(s.consultantType)) {
                    const arr = map.get(s.consultantType);
                    return map.set(s.consultantType, _.uniq([...arr, consultant]));
                } else {
                    return map.set(s.consultantType, [consultant]);
                }
            }, Map());

        const activeId = pgServiceStatusActive;
        const inactiveId = pgServiceStatusInactive;

        const consultantDates = p => {
            const start = p.startDate ? `${Localize.text('Start', 'PeopleGroupDetail')} ${p.startDate}` : null;
            const end = p.endDate ? `${Localize.text('End', 'PeopleGroupDetail')} ${p.endDate}` : null;

            return <span>{start} {end}</span>;
        };

        const onDeleteLocation = async e => {
            e.preventDefault();
            const locationId = e.currentTarget.value;
            const location = locations.find(loc => loc._id === locationId);

            const confirmed = await swal({
                title: Localize.text('AreYouSureYouWantToDeleteThisLocation', 'PeopleGroupDetail'),
                text: `${location.name} (${location.country.name})`,
                icon: 'warning',
                buttons: {
                    cancel: { text: Localize.text('DoNOTDelete', 'PeopleGroupDetail'), visible: true },
                    confirm: { text: Localize.text('YesDelete', 'PeopleGroupDetail'), closeModal: false },
                },
            });
            if (!confirmed) {
                return;
            }

            const { errors: locationErrors, data: locationData } = await gql.transaction(
                'mutation',
                'deleteLocation',
                { _id: locationId }
            );
            if (locationErrors) throw new Error(locationErrors[0].message);
            const result = JSON.parse(locationData.deleteLocation);
            if (Array.isArray(result)) {
                const msg = `${Localize.text('ThisLocationCannotBeDeletedBecauseItIsReferencedElsewhereInTheDatabase', 'PeopleGroupDetail')}\n\n${result.join('\n')}`;
                await swal({
                    icon: 'error',
                    title: Localize.text('CannotDelete', 'Universal'),
                    text: msg,
                });
                return;
            }


            const { errors, data } = await gql.transaction(
                'query',
                'getLocations',
                {},
                ['_id', 'name', 'peopleGroup { _id, name }', 'country { _id, name }', 'field']
            );
            if(errors) throw new Error(errors[0].message);
            const freshLocations = data.getLocations;
            setLocations(freshLocations);

            swal.close();
        };

        return (
            <div className={'container-fluid'}>
                <div className={'row'}>
                    <div className={'col-6'}>

                        <div className={'row'}>
                            <div className={'col-12 form-group'}>
                                <div className={'card'}>
                                    <div className={'card-header'}>
                                        <div className={'pull-right'}>
                                            <a href={'#'} onClick={this.openGeneralModal} style={readonly ? {display: 'none'} : {}}><i className={'fa fa-pencil'} /></a>
                                        </div>
                                        <h4 className={'no-bottom-margin'}>{Localize.text('GeneralDetails', 'PeopleGroupDetail')}</h4>
                                    </div>
                                    <div className={'card-body'}>
                                        <TwoColumnLayout>
                                            <TwoColumnRow>
                                                <div>{Localize.text('AlternateName', 'PeopleGroupDetail')}:</div>
                                                <div style={styles.leftPadding}>{peopleGroup.alternateName}</div>
                                            </TwoColumnRow>
                                            <TwoColumnRow>
                                                <div>{Localize.text('PublicationName', 'PeopleGroupDetail')}:</div>
                                                <div style={styles.leftPadding}>{peopleGroup.publicationName}</div>
                                            </TwoColumnRow>
                                            <TwoColumnRow>
                                                <div>{Localize.text('WorkStatus', 'PeopleGroupDetail')}:</div>
                                                <div style={styles.leftPadding}>{listItems.has(peopleGroup.workStatus) ? listItems.get(peopleGroup.workStatus).name : ''}</div>
                                            </TwoColumnRow>
                                            <TwoColumnRow>
                                                <div>{Localize.text('Countries', 'PeopleGroupDetail')}:</div>
                                                <div style={styles.leftPadding}>{countryNames.join(', ')}</div>
                                            </TwoColumnRow>
                                            <TwoColumnRow>
                                                <div>{Localize.text('EthnologueCode', 'PeopleGroupDetail')}:</div>
                                                <div style={styles.leftPadding}>{peopleGroup.ethnologueCode}</div>
                                            </TwoColumnRow>
                                            <TwoColumnRow>
                                                <div>{Localize.text('LanguageFamily', 'PeopleGroupDetail')}:</div>
                                                <div style={styles.leftPadding}>{peopleGroup.languageFamily}</div>
                                            </TwoColumnRow>
                                            <TwoColumnRow>
                                                <div>{Localize.text('Population', 'PeopleGroupDetail')}:</div>
                                                <div style={styles.leftPadding}>{peopleGroup.population}</div>
                                            </TwoColumnRow>
                                            <TwoColumnRow>
                                                <div>{Localize.text('TargetDateEntry', 'PeopleGroupDetail')}:</div>
                                                <div style={styles.leftPadding}>{targetDateEntry}</div>
                                            </TwoColumnRow>
                                            <TwoColumnRow>
                                                <div>{Localize.text('DateEntered', 'PeopleGroupDetail')}:</div>
                                                <div style={styles.leftPadding}>{dateEntered}</div>
                                            </TwoColumnRow>
                                            <TwoColumnRow>
                                                <div>{Localize.text('TargetDateGospelPresented', 'PeopleGroupDetail')}:</div>
                                                <div style={styles.leftPadding}>{targetDateGospelPresented}</div>
                                            </TwoColumnRow>
                                            <TwoColumnRow>
                                                <div>{Localize.text('DateGospelPresented', 'PeopleGroupDetail')}:</div>
                                                <div style={styles.leftPadding}>{dateGospelPresented}</div>
                                            </TwoColumnRow>
                                            <TwoColumnRow>
                                                <div>{Localize.text('DateStartedPartnering', 'PeopleGroupDetail')}:</div>
                                                <div style={styles.leftPadding}>{peopleGroup.dateStartedPartnering}</div>
                                            </TwoColumnRow>
                                            <TwoColumnRow>
                                                <div>{Localize.text('LiteracyRateVernacular', 'PeopleGroupDetail')}:</div>
                                                <div style={styles.leftPadding}>{peopleGroup.literacyRateVernacular || 0}%</div>
                                            </TwoColumnRow>
                                            <TwoColumnRow>
                                                <div>{Localize.text('LiteracyRateLWC', 'PeopleGroupDetail')}:</div>
                                                <div style={styles.leftPadding}>{peopleGroup.literacyRateLWC || 0}%</div>
                                            </TwoColumnRow>
                                        </TwoColumnLayout>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={'modal'} ref={node => this.generalModalNode = node}>
                            <div className={'modal-dialog modal-lg'}>
                                <div className={'modal-content'}>
                                    <div className={'modal-header'}>
                                        <h5 className="modal-title">{Localize.text('GeneralDetails', 'PeopleGroupDetail')}</h5>
                                        <button type="button" className="close" onClick={this.onGeneralCancel}>
                                            <span>&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body" style={{height: windowHeight - 300, overflowY: 'auto'}}>

                                        <div className={'form-group row'}>
                                            <label className={'col-lg-3'}>{Localize.text('AlternateName', 'PeopleGroupDetail') + ':'}</label>
                                            <div className={'col-lg-6'}>
                                                <input type={'text'} className={'form-control form-control-sm'} value={state.alternateName} onChange={this.onAlternateNameChange} />
                                            </div>
                                        </div>

                                        <div className={'form-group row'}>
                                            <label className={'col-lg-3'}>{Localize.text('PublicationName', 'PeopleGroupDetail') + ':'}</label>
                                            <div className={'col-lg-6'}>
                                                <input type={'text'} className={'form-control form-control-sm'} value={state.publicationName} onChange={this.onPublicationNameChange} />
                                            </div>
                                        </div>

                                        <div className={'form-group row'}>
                                            <label className={'col-lg-3'}>{Localize.text('WorkStatus', 'PeopleGroupDetail') + ':'}</label>
                                            <div className={'col-lg-6'}>
                                                <select className={'form-control form-control-sm'} value={state.workStatus} onChange={this.onWorkStatusChange}>
                                                    <option value={''}>{Localize.text('SelectOne', 'UniversalForms')}</option>
                                                    {
                                                        listManager
                                                            .get(localizedLists, 'Work Status')
                                                            .map(d => <option key={d._id} value={d._id} title={d.values.get('description') || ''}>{d.values.get('name')}</option>)
                                                    }
                                                </select>
                                            </div>
                                        </div>

                                        <div className={'form-group row'}>
                                            <label className={'col-lg-3'}>{Localize.text('EthnologueCode', 'PeopleGroupDetail') + ':'}</label>
                                            <div className={'col-lg-6'}>
                                                <input type={'text'} className={'form-control form-control-sm'} value={state.ethnologueCode} onChange={e => this.onInputChange(e, 'ethnologueCode')} />
                                            </div>
                                        </div>

                                        <div className={'form-group row'}>
                                            <label className={'col-lg-3'}>{Localize.text('LanguageFamily', 'PeopleGroupDetail') + ':'}</label>
                                            <div className={'col-lg-6'}>
                                                <input type={'text'} className={'form-control form-control-sm'} value={state.languageFamily} onChange={e => this.onInputChange(e, 'languageFamily')} />
                                            </div>
                                        </div>

                                        <div className={'form-group row'}>
                                            <label className={'col-lg-3'}>{Localize.text('Population', 'PeopleGroupDetail') + ':'}</label>
                                            <div className={'col-lg-6'}>
                                                <input type={'number'} className={'form-control form-control-sm'} value={state.population} onChange={e => this.onInputChange(e, 'population')} />
                                            </div>
                                        </div>

                                        <div className={'form-group row'}>
                                            <label className={'col-lg-3'}>{Localize.text('DateStartedPartnering', 'PeopleGroupDetail') + ':'}</label>
                                            <div className={'col-lg-6'}>
                                                <input type={'text'} className={'form-control form-control-sm'} value={state.dateStartedPartnering} onChange={e => this.onInputChange(e, 'dateStartedPartnering')} />
                                            </div>
                                        </div>

                                        <div className={'form-group row'}>
                                            <label className={'col-lg-3'}>{Localize.text('LiteracyRateVernacular', 'PeopleGroupDetail') + ':'}</label>
                                            <div className={'col-lg-6'}>
                                                <input type={'number'} className={'form-control form-control-sm'} value={state.literacyRateVernacular} max={100} min={0} onChange={e => this.onInputChange(e, 'literacyRateVernacular')} />
                                            </div>
                                        </div>

                                        <div className={'form-group row'}>
                                            <label className={'col-lg-3'}>{Localize.text('LiteracyRateLWC', 'PeopleGroupDetail') + ':'}</label>
                                            <div className={'col-lg-6'}>
                                                <input type={'number'} className={'form-control form-control-sm'} value={state.literacyRateLWC} max={100} min={0} onChange={e => this.onInputChange(e, 'literacyRateLWC')} />
                                            </div>
                                        </div>

                                    </div>
                                    <div className="modal-footer">
                                        <button type={'button'} className={'btn btn-secondary'} onClick={this.onGeneralCancel}>{Localize.text('Cancel', 'Universal')}</button>
                                        <button type={'button'} className={'btn btn-primary'} onClick={this.onGeneralSave}>{Localize.text('Save', 'Universal')}</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={'row'}>
                            <div className={'col-12 form-group'}>
                                <div className={'card'}>
                                    <div className={'card-header'}>
                                        <ModalTextarea
                                            readonly={readonly}
                                            value={state.note}
                                            onSave={this.saveNote}
                                            title={Localize.text('PeopleGroupNotes', 'PeopleGroupDetail')} />
                                        <h4 className={'no-bottom-margin'}>{Localize.text('PeopleGroupNotes', 'PeopleGroupDetail')}</h4>
                                    </div>
                                    <div style={{whiteSpace: 'pre-line'}} className={'card-body'}>
                                        {peopleGroup.note}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={'row'}>
                            <div className={'col-12 form-group'}>
                                <div className={'card'}>
                                    <div className={'card-header'}>
                                        <h4 className={'no-bottom-margin'}>{Localize.text('MinistrySummary', 'PeopleGroupDetail')}</h4>
                                    </div>
                                    <div className={'card-body'}>
                                        <TwoColumnLayout>
                                            <TwoColumnRow>
                                                <div>{Localize.text('NumberOfChurchesWithElders', 'PeopleGroupDetail')}:</div>
                                                <div style={styles.leftPadding}>{churchesWithElders}</div>
                                            </TwoColumnRow>
                                            <TwoColumnRow>
                                                <div>{Localize.text('NumberOfChurchesWithoutElders', 'PeopleGroupDetail')}:</div>
                                                <div style={styles.leftPadding}>{churchesWithoutElders}</div>
                                            </TwoColumnRow>
                                            <TwoColumnRow>
                                                <div>{Localize.text('NumberOfBelievers', 'PeopleGroupDetail')}:</div>
                                                <div style={styles.leftPadding}>{believers}</div>
                                            </TwoColumnRow>
                                            <TwoColumnRow>
                                                <div>{Localize.text('LiteracyDeveloped', 'PeopleGroupDetail')}:</div>
                                                <div style={styles.leftPadding}>{yesNo(peopleGroup.literacyProgramDeveloped)}</div>
                                            </TwoColumnRow>
                                            <TwoColumnRow>
                                                <div>{Localize.text('OldTestamentTranslated', 'PeopleGroupDetail')}:</div>
                                                <div style={styles.leftPadding}>{peopleGroup.oldTestamentPercentage || 0}%</div>
                                            </TwoColumnRow>
                                            <TwoColumnRow>
                                                <div>{Localize.text('NewTestamentTranslated', 'PeopleGroupDetail')}:</div>
                                                <div style={styles.leftPadding}>{peopleGroup.newTestamentPercentage || 0}%</div>
                                            </TwoColumnRow>
                                        </TwoColumnLayout>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={'row'}>
                            <div className={'col-12 form-group'}>
                                <div className={'card'}>
                                    <div className={'card-header'}>
                                        <div className={'pull-right'}>
                                            <Link to={`/field/${fieldId}/people/${peopleId}/edit-personnel`} style={readonly ? {display: 'none'} : {}}><i className={'fa fa-pencil'} /></Link>
                                        </div>
                                        <h4 className={'no-bottom-margin'}>{Localize.text('Personnel', 'PeopleGroupDetail')}</h4>
                                    </div>
                                    <div className={'card-body'}>
                                        <TwoColumnLayout>
                                            {[...preppedStatuses.entries()]
                                                .sort((a, b) => {
                                                    const aStatuses = a[1].map(s => s.status);
                                                    const aActive = aStatuses.includes(activeId);
                                                    const aFormer = aStatuses.includes(inactiveId);
                                                    const bStatuses = b[1].map(s => s.status);
                                                    const bActive = bStatuses.includes(activeId);
                                                    const bFormer = bStatuses.includes(inactiveId);
                                                    if((aActive && bActive) || (aFormer && bFormer) || (!aActive && !aFormer && !bActive && !bFormer)) {
                                                        return 0;
                                                    } else if(aActive && !bActive) {
                                                        return -1;
                                                    } else if(!aActive && bActive) {
                                                        return 1;
                                                    } else if(aFormer && !bFormer) {
                                                        return 1;
                                                    } else if(!aFormer && bFormer) {
                                                        return -1;
                                                    } else {
                                                        return 0;
                                                    }
                                                })
                                                .map(([ personId, statusArr ]) => {
                                                    const { firstName = '', lastName = '' } = people.get(personId) || {};
                                                    const loc = locations.find(loc2 => loc2._id === statusArr[0].location);
                                                    const name = loc ? loc.name : '';
                                                    const personStatuses = statusArr
                                                        .map(status => {
                                                            // t367, t369 status.status can be '' if admin deletes the status from LocalizedLists
                                                            const statusObj = localizedListItems.get(status.status);
                                                            if (statusObj) {
                                                                return statusObj.name;
                                                            } else {
                                                                return Localize.text('Unknown', 'UniversalForms');
                                                            }
                                                        })
                                                        .join(', ');
                                                    console.log(statusArr, personStatuses);
                                                    return (
                                                        <TwoColumnRow key={personId}>
                                                            <div><Link to={`/personnel/${personId}`}>{`${firstName} ${lastName}`}</Link>:</div>
                                                            <div>{personStatuses} <span className="m-3">{name}</span></div>
                                                        </TwoColumnRow>
                                                    );
                                                })
                                            }
                                        </TwoColumnLayout>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={'row'}>
                            <div className={'col-12 form-group'}>
                                <div className={'card'}>
                                    <div className={'card-header'}>
                                        <div className={'pull-right'}>
                                            <a href={'#'} onClick={this.openLocationsModal} style={isAdmin ? {} : {display: 'none'}}><i className={'fa fa-pencil'} /></a>
                                        </div>
                                        <h4 className={'no-bottom-margin'}>{Localize.text('Locations', 'PeopleGroupDetail')}</h4>
                                    </div>
                                    <div className={'card-body'}>
                                        <ul>
                                            {locations
                                                .map(l => <li key={l._id}>{`${l.name} (${l.country.name})`}</li>)
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={'modal'} ref={node => this.locationsModalNode = node}>
                            <div className={'modal-dialog'}>
                                <div className={'modal-content'}>
                                    <div className={'modal-header'}>
                                        <h5 className="modal-title">{Localize.text('Locations', 'PeopleGroupDetail')}</h5>
                                        <button type="button" className="close" onClick={this.onLocationsCancel}>
                                            <span>&times;</span>
                                        </button>
                                    </div>
                                    <div className="list-group">
                                        {locations
                                            .map(l =>
                                                <div key={l._id} className="list-group-item">
                                                    <div className="d-flex w-100 justify-content-between">
                                                        <span>{`${l.name} (${l.country.name})`}</span>
                                                        <button type="button" className="btn btn-sm btn-outline-danger" value={l._id} onClick={onDeleteLocation}><i className="fa fa-times" /></button>
                                                    </div>
                                                </div>)
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={'row'}>
                            <div className={'col-12 form-group'}>
                                <div className={'card'}>
                                    <div className={'card-header'}>
                                        <div className={'pull-right'}>
                                            <Link to={`/field/${fieldId}/people/${peopleId}/edit-consultants`} style={readonly ? {display: 'none'} : {}}><i className={'fa fa-pencil'} /></Link>
                                        </div>
                                        <h4 className={'no-bottom-margin'}>{Localize.text('Consultants', 'PeopleGroupDetail')}</h4>
                                    </div>
                                    <div className={'card-body'}>
                                        <TwoColumnLayout>
                                            {[...preppedConsultants.entries()]
                                                .map(([ consultantType, peopleArr ]) => {
                                                    consultantType = localizedListItems.has(consultantType) ? localizedListItems.get(consultantType).name : '';
                                                    peopleArr = peopleArr.map(consultant => {
                                                        const { personId, startDate, endDate } = consultant;
                                                        const { firstName = '', lastName = '' } = people.get(personId) || {};
                                                        const fullName =  firstName + ' ' + lastName;
                                                        return { fullName, personId, startDate, endDate };
                                                    });
                                                    return (
                                                        <TwoColumnRow key={consultantType}>
                                                            <div>{consultantType}:</div>
                                                            <div>{
                                                                peopleArr.map(p => <div key={consultantType + p.personId}><Link to={`/personnel/${p.personId}`}>{p.fullName}</Link> {consultantDates(p)}</div>)
                                                            }</div>
                                                        </TwoColumnRow>
                                                    );
                                                })
                                            }
                                        </TwoColumnLayout>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={'row'}>
                            <div className={'col-12 form-group'}>
                                <div className={'card'}>
                                    <div className={'card-header'}>
                                        <div className={'pull-right'}>
                                            <a href={'#'} onClick={this.openLinkModal} style={readonly ? {display: 'none'} : {}}><i className={'fa fa-pencil'} /></a>
                                        </div>
                                        <h4 className={'no-bottom-margin'}>{Localize.text('Links', 'PeopleGroupDetail')}</h4>
                                    </div>
                                    <div className={'card-body'}>
                                        <TwoColumnLayout>
                                            <TwoColumnRow col1Width={200}>
                                                <div>{Localize.text('JoshuaProjectLink', 'PeopleGroupDetail')}:</div>
                                                <div style={styles.leftPadding}>{peopleGroup.joshuaProjectLink ? <a href={peopleGroup.joshuaProjectLink}>{peopleGroup.joshuaProjectLink}</a> : ''}</div>
                                            </TwoColumnRow>
                                            <TwoColumnRow col1Width={200}>
                                                <div>{Localize.text('PeopleGroupsOrgLink', 'PeopleGroupDetail')}:</div>
                                                <div style={styles.leftPadding}>{peopleGroup.peopleGroupsOrgLink ? <a href={peopleGroup.peopleGroupsOrgLink}>{peopleGroup.peopleGroupsOrgLink}</a> : ''}</div>
                                            </TwoColumnRow>
                                        </TwoColumnLayout>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={'modal'} ref={node => this.linkModalNode = node}>
                            <div className={'modal-dialog modal-lg'}>
                                <div className={'modal-content'}>
                                    <div className={'modal-header'}>
                                        <h5 className="modal-title">{Localize.text('Links', 'PeopleGroupDetail')}</h5>
                                        <button type="button" className="close" onClick={this.onLinkCancel}>
                                            <span>&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body" style={{height: windowHeight - 800, overflowY: 'auto'}}>

                                        <div className={'form-group row'}>
                                            <label className={'col-lg-4'}>{Localize.text('JoshuaProjectLink', 'PeopleGroupDetail') + ':'}</label>
                                            <div className={'col-lg-6'}>
                                                <input type={'text'} className={'form-control form-control-sm'} value={state.joshuaProjectLink} onChange={e => this.onLinkChange(e, 'joshuaProjectLink')} />
                                            </div>
                                        </div>

                                        <div className={'form-group row'}>
                                            <label className={'col-lg-4'}>{Localize.text('PeopleGroupsOrgLink', 'PeopleGroupDetail') + ':'}</label>
                                            <div className={'col-lg-6'}>
                                                <input type={'text'} className={'form-control form-control-sm'} value={state.peopleGroupsOrgLink} onChange={e => this.onLinkChange(e, 'peopleGroupsOrgLink')} />
                                            </div>
                                        </div>

                                    </div>
                                    <div className="modal-footer">
                                        <button type={'button'} className={'btn btn-secondary'} onClick={this.onLinkCancel}>{Localize.text('Cancel', 'Universal')}</button>
                                        <button type={'button'} className={'btn btn-primary'} onClick={this.onLinkSave}>{Localize.text('Save', 'Universal')}</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className={'col-6'}>

                        <div className={'row'}>
                            <div className={'col-12'}>
                                <div style={{float: 'right', padding: 4, borderRadius: 0}} className={'alert alert-secondary'}>{Localize.text('FromLocationsData', 'PeopleGroupDetail')}</div>
                                <h2 className={'text-center'}>{Localize.text('ChurchMaturity', 'PeopleGroupDetail')}</h2>
                                <div style={{marginLeft: 'auto', marginRight: 'auto', width: 420}}>
                                    <RadarGraph width={420} divisions={6} data={[
                                        {
                                            label: 'Discipleship In The Church',
                                            color: '#ffa',
                                            value: discipleshipValue
                                        },
                                        {
                                            label: 'God\'s Word For The Church',
                                            color: '#faa',
                                            value: wordValue
                                        },
                                        {
                                            label: 'Life Of The Church',
                                            color: '#afa',
                                            value: lifeValue
                                        },
                                        {
                                            label: 'Identity Of The Church',
                                            color: '#aaf',
                                            value: identityValue
                                        }
                                    ]} />
                                </div>
                            </div>
                        </div>

                        <div className={'row'}>
                            <div className={'col-12'}>
                                <h2 className={'text-center'}>{Localize.text('MaturingChurch', 'PeopleGroupDetail')}</h2>
                                {maturingChurchStatuses.length > 0 ?
                                    <LineChart data={{
                                        labels: maturingChurchStatuses
                                            .map(s => s.mChurchDate),
                                        datasets: [
                                            {
                                                label: Localize.text('DiscipleshipInTheChurch', 'PeopleGroupDetail'),
                                                borderColor: '#cc0',
                                                backgroundColor: '#fff',
                                                fill: false,
                                                data: maturingChurchStatuses
                                                    .map(s => {
                                                        // debugger;
                                                        return (s.mChurchDiscipleship0 + s.mChurchDiscipleship1 + s.mChurchDiscipleship2 + s.mChurchDiscipleship3 + s.mChurchDiscipleship4) / 5;
                                                    })
                                            },
                                            {
                                                label: Localize.text('GodsWordForTheChurch', 'PeopleGroupDetail'),
                                                borderColor: '#f00',
                                                backgroundColor: '#fff',
                                                fill: false,
                                                data: maturingChurchStatuses
                                                    .map(s => {
                                                        return (s.mChurchGodsWord0 + s.mChurchGodsWord1 + s.mChurchGodsWord2 + s.mChurchGodsWord3 + s.mChurchGodsWord4) / 5;
                                                    })
                                            },
                                            {
                                                label: Localize.text('LifeOfTheChurch', 'PeopleGroupDetail'),
                                                borderColor: '#0b0',
                                                backgroundColor: '#fff',
                                                fill: false,
                                                data: maturingChurchStatuses
                                                    .map(s => {
                                                        return (s.mChurchLife0 + s.mChurchLife1 + s.mChurchLife2 + s.mChurchLife3 + s.mChurchLife4) / 5;
                                                    })
                                            },
                                            {
                                                label: Localize.text('IdentityOfTheChurch', 'PeopleGroupDetail'),
                                                borderColor: '#00f',
                                                backgroundColor: '#fff',
                                                fill: false,
                                                data: maturingChurchStatuses
                                                    .map(s => {
                                                        return (s.mChurchIdentity0 + s.mChurchIdentity1 + s.mChurchIdentity2 + s.mChurchIdentity3 + s.mChurchIdentity4) / 5;
                                                    })
                                            }
                                        ]
                                    }} />
                                    :
                                    <div></div>
                                }
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }

}
Summary.propTypes = {
    peopleId: PropTypes.string,
    fieldId: PropTypes.string,
    localizedListItems: PropTypes.instanceOf(Map),
    people: PropTypes.instanceOf(Map),
    peopleGroup: PropTypes.object,
    statuses: PropTypes.arrayOf(PropTypes.object),
    consultants: PropTypes.arrayOf(PropTypes.object),
    locations: PropTypes.arrayOf(PropTypes.object),
    localizedLists: PropTypes.arrayOf(PropTypes.object),
    windowHeight: PropTypes.number,
    readonly: PropTypes.bool,
    isAdmin: PropTypes.bool,
    save: PropTypes.func,
    modalStatus: PropTypes.string,
    disableNav: PropTypes.func,
    enableNav: PropTypes.func,
    setModalStatus: PropTypes.func,
    setLocations: PropTypes.func,
};

const SummaryContainer = connect(
    ({ appState }) => ({
        people: appState.people,
        modalStatus: appState.modalStatus,
    }),
    dispatch => ({
        disableNav: () => dispatch(setAppDisableNav(true)),
        enableNav: () => dispatch(setAppDisableNav(false)),
        setModalStatus: status => dispatch(setAppModalStatus(status)),
        setLocations: locations => dispatch(setAppLocations(locations)),
    }),
)(Summary);

export default SummaryContainer;
