import { connect } from 'react-redux';
import { setCanCreateUpdateTrainings } from '../../actions/app-actions';
import Training from './training';

export default connect(
    ({ appState }) => ({
        trainings: appState.trainings,
        localizedLists: appState.localizedLists,
        user: appState.user,
        canCreateUpdateTrainings: appState.canCreateUpdateTrainings,
    }),
    dispatch => ({
        setCanCreateUpdateTrainings: value => dispatch(setCanCreateUpdateTrainings(value)),
    })
)(Training);
