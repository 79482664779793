import { connect } from 'react-redux';
import EditPerson from './edit-person';
import * as actions from '../../../actions/app-actions';
import uniqBy from 'lodash/uniqBy';

export default connect(
    ({ appState }) => ({
        user: appState.user,
        localizedLists: appState.localizedLists,
        countries: appState.countries,
        people: appState.people,
        trainings: appState.trainings,
        locations: appState.locations,
        fields: appState.fields,
        allPeopleGroups: uniqBy(appState.locations.reduce((acc, loc) => ([...acc, { ...loc.peopleGroup }]), []), '_id'),
    }),
    dispatch => ({
        addPerson(person, people) {
            const newPeople = people.set(person._id, person);
            dispatch(actions.setAppPeople(newPeople));
        },
        updatePerson(person, people) {
            const newPeople = people.set(person._id, person);
            dispatch(actions.setAppPeople(newPeople));
        },
        deletePerson(_id, people) {
            const newPeople = people.delete(_id);
            dispatch(actions.setAppPeople(newPeople));
        },
        setLocations: locations => dispatch(actions.setAppLocations(locations)),
        disableNav: () => dispatch(actions.setAppDisableNav(true)),
        enableNav: () => dispatch(actions.setAppDisableNav(false))
    })
)(EditPerson);
