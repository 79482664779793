import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import swal from 'sweetalert';
import * as _ from 'lodash';
import { Map, Set } from 'immutable';
import uuid from 'uuid';
import FilterSelect from '../../../shared/filter-select';
import * as actions from '../../../../actions/app-actions';
import isEqual from 'lodash/isEqual';
import * as dateUtils from '../../../../util/date-utils';

class EditPersonnel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            peopleGroup: {},
            originalStatuses: [],
            statuses: [],
            locations: [],
            disableSave: false,
            editing: false
        };

        _.bindAll(this, [
            'newStatus',
            'onAddStatus',
            'onInputChange',
            'removeStatus',
            'onPersonChange',
            'onSave',
            'onBack'
        ]);
    }

    async componentWillMount() {
        try {

            const { peopleId = '' } = this.props.match.params;

            const res = await gql.transaction(
                'query',
                'getPersonGroupStatuses',
                { peopleGroupId: peopleId },
                [
                    '_id',
                    'peopleGroup',
                    'person',
                    'primaryRole',
                    'primaryRoleDescription',
                    'secondaryRole',
                    'secondaryRoleDescription',
                    'claLevel',
                    'startDate',
                    'endDate',
                    'itinerantStartDate',
                    'itinerantEndDate',
                    'serviceStatus',
                    'location'
                ]
            );
            if(res.errors) throw res.errors[0];
            const { getPersonGroupStatuses: personGroupStatuses } = res.data;

            const res1 = await gql.transaction(
                'query',
                'getPeopleGroup',
                {
                    _id: peopleId
                },
                [
                    '_id',
                    'name'
                ]
            );
            if(res1.errors) throw res1.errors[0];
            const { getPeopleGroup: peopleGroup } = res1.data;

            let locations = [];
            if(this.props.locations.length === 0) {
                gql.transaction(
                    'query',
                    'getLocations',
                    {},
                    ['_id', 'name', 'peopleGroup { _id, name }', 'country { _id, name }', 'field', 'level']
                ).then(res2 => {
                    if(res2.errors) throw res2.errors[0];
                    this.props.setLocations(res2.data.getLocations);
                    this.setState({
                        ...this.state,
                        locations: res2.data.getLocations
                            .filter(l => l.peopleGroup._id === peopleId)
                            .sort((a, b) => a.name.localeCompare(b.name, Localize.locale()))
                    });
                });
            } else {
                locations = this.props.locations
                    .filter(l => l.peopleGroup._id === peopleId)
                    .sort((a, b) => a.name.localeCompare(b.name, Localize.locale()));
            }

            this.setState({
                ...this.state,
                peopleGroup,
                statuses: personGroupStatuses,
                originalStatuses: personGroupStatuses
                    .map(s => Object.assign({}, s)),
                locations
            });

        } catch(err) {
            handleError(err);
        }
    }

    componentDidUpdate() {
        const { disableNav, enableNav } = this.props;

        if (isEqual(this.state.originalStatuses, this.state.statuses)) {
            enableNav();
        } else {
            disableNav();
        }
    }

    newStatus() {

        const { state } = this;

        return {
            _id: uuid.v4(),
            peopleGroup: state.peopleGroup._id,
            person: '',
            primaryRole: '',
            primaryRoleDescription: '',
            secondaryRole: '',
            secondaryRoleDescription: '',
            claLevel: '',
            startDate: '',
            endDate: '',
            itinerantStartDate: '',
            itinerantEndDate: '',
            serviceStatus: '',
            location: ''
        };
    }

    onAddStatus(e) {
        e.preventDefault();
        const { state } = this;
        this.setState({
            ...state,
            editing: true,
            statuses: [
                this.newStatus(),
                ...state.statuses
            ]
        });
        setTimeout(this.props.disableNav, 0);
    }

    onInputChange(e, _id, key) {
        e.preventDefault();
        const { statuses } = this.state;
        const idx = statuses.findIndex(s => s._id === _id);
        const { value } = e.target;
        const newStatus = {
            ...statuses[idx],
            [key]: value
        };
        this.setState({
            ...this.state,
            editing: true,
            statuses: [
                ...statuses.slice(0, idx),
                newStatus,
                ...statuses.slice(idx + 1)
            ]
        });
    }

    removeStatus(e, _id) {
        e.preventDefault();
        const { statuses } = this.state;
        const idx = statuses.findIndex(s => s._id === _id);
        this.setState({
            ...this.state,
            editing: true,
            statuses: [
                ...statuses.slice(0, idx),
                ...statuses.slice(idx + 1)
            ]
        });
        setTimeout(this.props.disableNav, 0);
    }

    onPersonChange(_id, person) {
        const { statuses } = this.state;
        const idx = statuses.findIndex(s => s._id === _id);
        const newStatus = {
            ...statuses[idx],
            person
        };
        this.setState({
            ...this.state,
            editing: true,
            statuses: [
                ...statuses.slice(0, idx),
                newStatus,
                ...statuses.slice(idx + 1)
            ]
        });
    }

    async onBack(e) {
        e.preventDefault();
        const { history } = this.props;
        if(this.state.editing) {
            const confirmed = await swal({
                text: Localize.text('WouldYouLikeToSaveAnyChanges', 'UniversalForms'),
                icon: 'warning',
                buttons: [
                    Localize.text('No', 'Universal'),
                    Localize.text('Yes', 'Universal')
                ]
            });
            if(confirmed) {
                this.onSave(null, true);
            } else {
                history.goBack();
                setTimeout(this.props.enableNav, 0);
            }
        } else {
            history.goBack();
            setTimeout(this.props.enableNav, 0);
        }
    }

    async onSave(e) {
        try {
            if(e) e.preventDefault();

            const { history } = this.props;
            const { statuses, originalStatuses } = this.state;

            for(const status of statuses) {
                status.startDate = status.startDate.trim();
                status.endDate = status.endDate.trim();
                status.itinerantStartDate = status.itinerantStartDate.trim();
                status.itinerantEndDate = status.itinerantEndDate.trim();
                status.primaryRoleDescription = status.primaryRoleDescription.trim();
                status.secondaryRoleDescription = status.secondaryRoleDescription.trim();
                if(!status.person) {
                    swal({
                        title: Localize.text('Oops', 'Universal'),
                        text: Localize.text('YouMustSelectAPersonForEachStatusItem', 'EditWorkers'),
                        button: Localize.text('OK', 'Universal'),
                        icon: 'warning'
                    });
                    return;
                } else if(!status.primaryRole) {
                    swal({
                        title: Localize.text('Oops', 'Universal'),
                        text: Localize.text('YouMustSelectAPrimaryRoleForEachWorker', 'EditWorkers'),
                        button: Localize.text('OK', 'Universal'),
                        icon: 'warning'
                    });
                    return;
                } else if(!status.location) {
                    swal({
                        title: Localize.text('Oops', 'Universal'),
                        text: Localize.text('YouMustSelectALocationForEachWorker', 'EditWorkers'),
                        button: Localize.text('OK', 'Universal'),
                        icon: 'warning'
                    });
                    return;
                } else if(!status.serviceStatus) {
                    swal({
                        title: Localize.text('Oops', 'Universal'),
                        text: Localize.text('YouMustSelectAServiceStatusForEachWorker', 'EditWorkers'),
                        button: Localize.text('OK', 'Universal'),
                        icon: 'warning'
                    });
                    return;
                } else {
                    for(const date of [status.startDate, status.endDate, status.itinerantStartDate, status.itinerantEndDate]) {
                        if(!date) continue;
                        const valid = await dateUtils.isValidDate(date, true);
                        if(!valid) return;
                    }

                    const formatDateOrBlank = dateToFormat => dateToFormat ? dateUtils.formatDate(dateToFormat) : '';

                    status.startDate = formatDateOrBlank(status.startDate);
                    status.endDate = formatDateOrBlank(status.endDate);
                    status.itinerantStartDate = formatDateOrBlank(status.itinerantStartDate);
                    status.itinerantEndDate = formatDateOrBlank(status.itinerantEndDate);
                }

            }

            this.setState({
                ...this.state,
                disableSave: true
            });

            const toDelete = [];
            for(const status of originalStatuses) {
                if(!statuses.find(s => s._id === status._id)) {
                    toDelete.push(status);
                }
            }

            const toAdd = [];
            const toUpdate = [];
            for(const status of statuses) {
                const originalStatus = originalStatuses.find(s => s._id === status._id);
                if(!originalStatus) {
                    toAdd.push(_.omit(status, ['_id']));
                } else if(JSON.stringify(originalStatus) !== JSON.stringify(status)) {
                    toUpdate.push(status);
                }
            }

            for(const status of toAdd) {
                const { errors } = await gql.transaction(
                    'mutation',
                    'createPersonGroupStatus',
                    {
                        input: status
                    },
                    [ '_id']
                );
                if(errors) throw errors[1];
            }


            for(const status of toUpdate) {

                console.log({
                    _id: status._id,
                    peopleGroup: status.peopleGroup,
                    input: status
                });

                const { errors } = await gql.transaction(
                    'mutation',
                    'updatePersonGroupStatus',
                    {
                        _id: status._id,
                        peopleGroup: status.peopleGroup,
                        input: status
                    },
                    ['_id']
                );
                if(errors) throw errors[0];
            }

            for(const status of toDelete) {
                const { errors } = await gql.transaction(
                    'mutation',
                    'deletePersonGroupStatus',
                    {
                        peopleGroup: status.peopleGroup,
                        _id: status._id
                    }
                );
                if(errors) throw errors[0];
            }

            // t194 refresh personGroupStatuses in our redux store
            const { errors: pgStatusErrors, data: pgStatusData } = await gql.transaction(
                'query',
                'getPersonGroupStatuses',
                {},
                ['_id', 'peopleGroup', 'person', 'location']
            );
            if (pgStatusErrors) throw pgStatusErrors[0];
            this.props.setPersonGroupStatuses(pgStatusData.getPersonGroupStatuses);

            // t376 there seems to be a race condition that if we don't put history.goBack() into a timeout,
            //  some users (like Rand) get a message "Are you sure you want to save changes?".
            setTimeout(() => history.goBack(), 300);

            setTimeout(this.props.enableNav, 0);

        } catch(err) {
            handleError(err);
            this.setState({
                ...this.state,
                disableSave: false
            });
        }
    }

    render() {

        console.log('EditPersonnelState', this.state);

        const { peopleGroup, statuses, originalStatuses, disableSave, locations } = this.state;
        const { localizedLists, people, history } = this.props;

        if(!peopleGroup.name) {
            return (
                <div></div>
            );
        }

        const locationRoles = listManager.get(localizedLists, 'Location Roles');
        const claLevels = listManager.get(localizedLists, 'CLA Level');
        const serviceStatuses = listManager.get(localizedLists, 'PG Service Status');

        const items = [...people.values()];

        // t193 when adding a worker, don't allow users to select a worker that is already in the list for this people group.
        const workers = Set(statuses.map(s => s.person));

        return (
            <div className={'container-fluid'} style={{paddingBottom: 15}}>

                <div className={'row'}>
                    <div className={'col'}>
                        <h1>{`Edit ${peopleGroup.name} Personnel`}</h1>
                    </div>
                </div>

                <div className={'row'} style={{marginBottom: 15}}>
                    <div className={'col-12'}>
                        <button type={'button'} className={'btn btn-outline-secondary'} onClick={this.onBack}><i className="fa fa-chevron-left"></i> {Localize.text('Back', 'Universal')}</button>
                    </div>
                </div>

                <div className={'row'} style={{marginBottom: 15}}>
                    <div className={'col'}>
                        <button className={'btn btn-info'} onClick={this.onAddStatus}><i className={'fa fa-plus'} /> {Localize.text('AddWorker', 'EditWorkers')}</button>
                    </div>
                </div>

                {statuses.map((s, i) => {

                    const person = people.get(s.person);

                    return (
                        <div key={s._id} style={{marginBottom: 15}} className={'card'}>
                            <div className={'card-body'}>

                                <button type={'button'} className={'btn btn-sm btn-outline-danger float-right'}><i className={'fa fa-remove'} onClick={e => this.removeStatus(e, s._id)} /></button>

                                <div className={'row'}>

                                    <div className={'col-lg-2'}>
                                        <div className={'form-group'}>
                                            <label>{Localize.text('Person', 'EditConsultants')}</label>
                                            {person ?
                                                <input type={'text'} className={'form-control'} value={`${person.firstName} ${person.lastName}`} readOnly={true} />
                                                :
                                                <FilterSelect items={items.filter(p => !workers.has(p._id))} onSelect={_id => this.onPersonChange(s._id, _id)} />
                                            }
                                        </div>
                                    </div>

                                    <div className={'col-lg-9'}>
                                        <div className={'row'}>

                                            <div className={'col-lg-3'}>
                                                <div className={'form-group'}>
                                                    <label>{Localize.text('PrimaryRole', 'EditWorkers')}</label>
                                                    <select className={'form-control'} value={s.primaryRole} onChange={e => this.onInputChange(e, s._id, 'primaryRole')}>
                                                        <option value={''}>{Localize.text('SelectOne', 'Universal')}</option>
                                                        {locationRoles.map(r => <option key={r._id + '-0'} value={r._id}>{r.values.get('name')}</option>)}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className={'col-lg-3'}>
                                                <div className={'form-group'}>
                                                    <label>{Localize.text('PrimaryRoleDescription', 'EditWorkers')}</label>
                                                    <input type={'text'} className={'form-control'} value={s.primaryRoleDescription} onChange={e => this.onInputChange(e, s._id, 'primaryRoleDescription')} />
                                                </div>
                                            </div>
                                            <div className={'col-lg-3'}>
                                                <div className={'form-group'}>
                                                    <label>{Localize.text('SecondaryRole', 'EditWorkers')}</label>
                                                    <select className={'form-control'} value={s.secondaryRole} onChange={e => this.onInputChange(e, s._id, 'secondaryRole')}>
                                                        <option value={''}>{Localize.text('SelectOne', 'Universal')}</option>
                                                        {locationRoles.map(r => <option key={r._id + '-1'} value={r._id}>{r.values.get('name')}</option>)}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className={'col-lg-3'}>
                                                <div className={'form-group'}>
                                                    <label>{Localize.text('SecondaryRoleDescription', 'EditWorkers')}</label>
                                                    <input type={'text'} className={'form-control'} value={s.secondaryRoleDescription} onChange={e => this.onInputChange(e, s._id, 'secondaryRoleDescription')} />
                                                </div>
                                            </div>
                                            <div className={'col-lg-3'}>
                                                <div className={'form-group'}>
                                                    <label>{Localize.text('Location', 'Universal')}</label>
                                                    <select className={'form-control'} value={s.location} onChange={e => this.onInputChange(e, s._id, 'location')}>
                                                        <option value={''}>{Localize.text('SelectOne', 'Universal')}</option>
                                                        {locations.map(l => <option key={l._id} value={l._id}>{l.name}</option>)}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className={'col-lg-3'}>
                                                <div className={'form-group'}>
                                                    <label>{Localize.text('CLALevel', 'PeopleGroupDetail')}</label>
                                                    <select className={'form-control'} value={s.claLevel} onChange={e => this.onInputChange(e, s._id, 'claLevel')}>
                                                        <option value={''}>{Localize.text('SelectOne', 'Universal')}</option>
                                                        {claLevels.map(r => <option key={r._id} value={r._id}>{r.values.get('name')}</option>)}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className={'col-lg-3'}>
                                                <div className={'form-group'}>
                                                    <label>{Localize.text('ServiceStatus', 'EditWorkers')}</label>
                                                    <select className={'form-control'} value={s.serviceStatus} onChange={e => this.onInputChange(e, s._id, 'serviceStatus')}>
                                                        <option value={''}>{Localize.text('SelectOne', 'Universal')}</option>
                                                        {serviceStatuses.map(r => <option key={r._id + '-0'} value={r._id}>{r.values.get('name')}</option>)}
                                                    </select>
                                                </div>
                                            </div>

                                        </div>

                                        <div className={'row'}>
                                            <div className={'col-lg-3'}>
                                                <div className={'form-group'}>
                                                    <label>{Localize.text('StartDate', 'EditConsultants')}</label>
                                                    <input type={'text'} className={'form-control'} value={s.startDate} placeholder={Localize.text('EGMMYYYY', 'UniversalForms')} onChange={e => this.onInputChange(e, s._id, 'startDate')} />
                                                </div>
                                            </div>
                                            <div className={'col-lg-3'}>
                                                <div className={'form-group'}>
                                                    <label>{Localize.text('EndDate', 'EditConsultants')}</label>
                                                    <input type={'text'} className={'form-control'} value={s.endDate} placeholder={Localize.text('EGMMYYYY', 'UniversalForms')} onChange={e => this.onInputChange(e, s._id, 'endDate')} />
                                                </div>
                                            </div>
                                            <div className={'col-lg-3'}>
                                                <div className={'form-group'}>
                                                    <label>{Localize.text('ItinerantStartDate', 'PeopleGroupDetail')}</label>
                                                    <input type={'text'} className={'form-control'} value={s.itinerantStartDate} placeholder={Localize.text('EGMMYYYY', 'UniversalForms')} onChange={e => this.onInputChange(e, s._id, 'itinerantStartDate')} />
                                                </div>
                                            </div>
                                            <div className={'col-lg-3'}>
                                                <div className={'form-group'}>
                                                    <label>{Localize.text('ItinerantEndDate', 'PeopleGroupDetail')}</label>
                                                    <input type={'text'} className={'form-control'} value={s.itinerantEndDate} placeholder={Localize.text('EGMMYYYY', 'UniversalForms')} onChange={e => this.onInputChange(e, s._id, 'itinerantEndDate')} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );

                })}

                <div style={{display: statuses.length === 0 && originalStatuses.length === 0 ? 'none' : 'block'}} className={'row'}>
                    <div className={'col'}>
                        <button type={'button'} style={{minWidth: 150, marginRight: 10}} className={'btn btn-primary'} onClick={this.onSave} disabled={disableSave}>{Localize.text('Save', 'Universal')}</button>
                        <button type={'button'} style={{minWidth: 150}} className={'btn btn-outline-dark'} onClick={() => history.goBack()}>{Localize.text('Cancel', 'Universal')}</button>
                    </div>
                </div>

            </div>
        );
    }

}
EditPersonnel.propTypes = {
    people: PropTypes.instanceOf(Map),
    localizedLists: PropTypes.arrayOf(PropTypes.object),
    locations: PropTypes.arrayOf(PropTypes.object),
    match: PropTypes.object,
    history: PropTypes.object,
    setLocations: PropTypes.func,
    setPersonGroupStatuses: PropTypes.func,
    disableNav: PropTypes.func,
    enableNav: PropTypes.func
};

export default connect(
    ({ appState }) => ({
        people: appState.people,
        localizedLists: appState.localizedLists,
        locations: appState.locations
    }),
    dispatch => ({
        setLocations: locations => dispatch(actions.setAppLocations(locations)),
        setPersonGroupStatuses: personGroupStatuses => dispatch(actions.setAppPersonGroupStatuses(personGroupStatuses)),
        disableNav: () => dispatch(actions.setAppDisableNav(true)),
        enableNav: () => dispatch(actions.setAppDisableNav(false))
    })
)(EditPersonnel);
