import React from 'react';
import PropTypes from 'prop-types';
import Chart from 'chart.js';

class LineChart extends React.Component {

    componentDidMount() {
        this.renderChart();
    }

    componentWillReceiveProps(newProps) {
        this.updateChart(newProps);
    }

    updateChart({ data = {} }) {
        const { labels = [], datasets = [] } = data;
        this.chart.config.data.labels = labels;
        this.chart.config.data.datasets = datasets;
        this.chart.update();
    }

    renderChart() {
        const { data } = this.props;

        this.chart = new Chart(this.canvasNode.getContext('2d'), {
            // The type of chart we want to create
            type: 'line',

            // The data for our dataset
            data,
            //     {
            //     labels: ["January", "February", "March", "April", "May", "June", "July"],
            //     datasets: [{
            //         label: 'My First dataset',
            //         // backgroundColor: 'rgb(255, 99, 132)',
            //         fill: false,
            //         borderColor: 'rgb(255, 99, 132)',
            //         data: [0, 2, 2, 3, 4, 5, 5]
            //     }]
            // },

            // Configuration options go here
            options: {
                animation: {
                    duration: 0
                },
                scales: {
                    yAxes: [{
                        display: true,
                        ticks: {
                            suggestedMax: 6,
                            suggestMin: 0,
                            stepSize: 1
                        }
                    }]
                },
                elements: {
                    line: {
                        tension: 0
                    }
                }
            }
        });
    }

    render() {
        return (
            <div>
                <canvas ref={node => this.canvasNode = node}></canvas>
            </div>
        );
    }

}
LineChart.propTypes = {
    data: PropTypes.object
};

export default LineChart;
