import React from 'react';
import PropTypes from 'prop-types';

export const TwoColumnLayout = ({ children }) => {
    return (
        <div>{children}</div>
    );
};
TwoColumnLayout.propTypes = {
    children: PropTypes.arrayOf(PropTypes.node)
};

export const TwoColumnRow = ({ col1Width = 275, children }) => {

    const styles = {
        row: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'no-wrap',
            justifyContent: 'flex-start'
        },
        col1: {
            width: col1Width,
            minWidth: col1Width,
            maxWidth: col1Width,
            textAlign: 'right',
            overflowX: 'hidden'
        },
        col2: {
            marginLeft: 25,
            flexGrow: 1,
            overflowX: 'hidden'
        }
    };

    return (
        <div style={styles.row}>
            <div style={styles.col1} className={'text-break'}>
                {children[0]}
            </div>
            <div style={styles.col2} className={'text-break'}>
                {children[1]}
            </div>
        </div>
    );
};
TwoColumnRow.propTypes = {
    col1Width: PropTypes.number,
    children: PropTypes.arrayOf(PropTypes.node)
};

