import { connect } from 'react-redux';
import { updateAppRegions, updateAppCountries } from '../../util/gql-general-calls';

import EditCountry from './edit-country';
import { setAppDisableNav } from '../../actions/app-actions';

export default connect(
    ({ appState }) => ({
        user: appState.user
    }),
    dispatch => ({
        updateRegions: () => updateAppRegions(dispatch),
        updateCountries: () => updateAppCountries(dispatch),
        disableNav: () => dispatch(setAppDisableNav(true)),
        enableNav: () => dispatch(setAppDisableNav(false)),
    })
)(EditCountry);
