import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import bindAll from 'lodash/bindAll';
import omit from 'lodash/omit';
import { Map } from 'immutable';
import uuid from 'uuid';
import swal from 'sweetalert';
import FilterSelect from '../../../shared/filter-select';
import { setAppDisableNav } from '../../../../actions/app-actions';
import isEqual from 'lodash/isEqual';
import * as dateUtils from '../../../../util/date-utils';

class EditConsultants extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            peopleGroup: {},
            originalConsultancies: [],
            consultancies: [],
            disableSave: false
        };

        bindAll(this, [
            'newConsultant',
            'onAddConsultant',
            'onInputChange',
            'removeConsultant',
            'onPersonChange',
            'onSave'
        ]);
    }

    async componentWillMount() {
        try {

            const { peopleId = '' } = this.props.match.params;

            const res = await gql.transaction(
                'query',
                'getPeopleGroupConsultants',
                { peopleGroupIds: [peopleId] },
                [
                    '_id',
                    'peopleGroup',
                    'person',
                    'consultantType',
                    'startDate',
                    'endDate'
                ]
            );
            if(res.errors) throw res.errors[0];
            const { getPeopleGroupConsultants: peopleGroupConsultants } = res.data;

            const { errors, data } = await gql.transaction(
                'query',
                'getPeopleGroup',
                {
                    _id: peopleId
                },
                [
                    '_id',
                    'name'
                ]
            );
            if(errors) throw new Error(errors[0].message);
            const { getPeopleGroup: peopleGroup } = data;

            this.setState({
                ...this.state,
                peopleGroup,
                consultancies: peopleGroupConsultants,
                originalConsultancies: peopleGroupConsultants
                    .map(c => Object.assign({}, c))
            });

        } catch(err) {
            handleError(err);
        }
    }

    componentDidUpdate() {
        const { disableNav, enableNav } = this.props;

        if (isEqual(this.state.originalConsultancies, this.state.consultancies)) {
            enableNav();
        } else {
            disableNav();
        }
    }

    newConsultant() {

        const { state } = this;

        return {
            _id: uuid.v4(),
            person: '',
            peopleGroup: state.peopleGroup._id,
            consultantType: '',
            startDate: '',
            endDate: ''
        };
    }

    onAddConsultant(e) {
        e.preventDefault();
        const { state } = this;
        this.setState({
            ...state,
            consultancies: [
                this.newConsultant(),
                ...state.consultancies
            ]
        });
    }

    onInputChange(e, _id, key) {
        e.preventDefault();
        const { consultancies } = this.state;
        const idx = consultancies.findIndex(c => c._id === _id);
        const { value } = e.target;
        const newConsultancy = {
            ...consultancies[idx],
            [key]: value
        };
        this.setState({
            ...this.state,
            consultancies: [
                ...consultancies.slice(0, idx),
                newConsultancy,
                ...consultancies.slice(idx + 1)
            ]
        });
    }

    removeConsultant(e, idx) {
        const { consultancies } = this.state;
        this.setState({
            ...this.state,
            consultancies: [
                ...consultancies.slice(0, idx),
                ...consultancies.slice(idx + 1)
            ]
        });
    }

    onPersonChange(idx, person) {
        const { consultancies } = this.state;
        const newConsultancy = {
            ...consultancies[idx],
            person
        };
        this.setState({
            ...this.state,
            consultancies: [
                ...consultancies.slice(0, idx),
                newConsultancy,
                ...consultancies.slice(idx + 1)
            ]
        });
    }

    async onSave(e) {
        try {
            e.preventDefault();

            const { history, match: { params } } = this.props;
            const { peopleId, fieldId } = params;
            const { consultancies, originalConsultancies } = this.state;

            for(const consultant of consultancies) {
                consultant.startDate = consultant.startDate.trim();
                consultant.endDate = consultant.endDate.trim();
                if(!consultant.person) {
                    swal({
                        title: Localize.text('Oops', 'Universal'),
                        text: Localize.text('YouMustSelectAPersonForEachConsultancyItem', 'EditConsultants'),
                        button: Localize.text('OK', 'Universal'),
                        icon: 'warning'
                    });
                    return;
                } else if(!consultant.consultantType) {
                    swal({
                        title: Localize.text('Oops', 'Universal'),
                        text: Localize.text('YouMustSelectAConsultantTypeForEveryConsultancyItem', 'EditConsultants'),
                        button: Localize.text('OK', 'Universal'),
                        icon: 'warning'
                    });
                    return;
                } else {
                    for(const date of [consultant.startDate, consultant.endDate]) {
                        if(!date) continue;
                        const valid = await dateUtils.isValidDate(date, true);
                        if(!valid) return;
                    }
                }
                consultant.startDate = (consultant.startDate && consultant.startDate.split('-')[0].length < 2) ? '0' + consultant.startDate : consultant.startDate;
                consultant.endDate = (consultant.endDate && consultant.endDate.split('-')[0].length < 2) ? '0' + consultant.endDate : consultant.endDate;
            }

            this.setState({
                ...this.state,
                disableSave: true
            });

            const toDelete = [];
            for(const consultant of originalConsultancies) {
                if(!consultancies.find(c => c._id === consultant._id)) {
                    toDelete.push(consultant);
                }
            }

            const toAdd = [];
            const toUpdate = [];
            for(const consultant of consultancies) {
                const originalConsultant = originalConsultancies.find(c => c._id === consultant._id);
                if(!originalConsultant) {
                    toAdd.push(omit(consultant, ['_id']));
                } else if(JSON.stringify(originalConsultant) !== JSON.stringify(consultant)) {
                    toUpdate.push(consultant);
                }
            }

            for(const consultant of toAdd) {
                const { errors } = await gql.transaction(
                    'mutation',
                    'createPeopleGroupConsultant',
                    {
                        input: consultant
                    },
                    [ '_id']
                );
                if(errors) throw errors[0];
            }

            for(const consultant of toUpdate) {
                const { errors } = await gql.transaction(
                    'mutation',
                    'updatePeopleGroupConsultant',
                    {
                        _id: consultant._id,
                        peopleGroup: consultant.peopleGroup,
                        input: consultant
                    },
                    ['_id']
                );
                if(errors) throw errors[0];
            }

            for(const consultant of toDelete) {
                const { errors } = await gql.transaction(
                    'mutation',
                    'deletePeopleGroupConsultant',
                    {
                        peopleGroup: consultant.peopleGroup,
                        _id: consultant._id
                    }
                );
                if(errors) throw errors[0];
            }

            this.props.enableNav();
            history.push(`/field/${fieldId}/people/${peopleId}`);

        } catch(err) {
            handleError(err);
            this.setState({
                ...this.state,
                disableSave: false
            });
        }
    }

    render() {

        // console.log('editConsultants state', this.state);

        const { peopleGroup, consultancies, originalConsultancies, disableSave } = this.state;
        const { localizedLists, people, match: { params } } = this.props;

        const { peopleId, fieldId } = params;

        if(!peopleGroup.name) {
            return (
                <div></div>
            );
        }

        const consultantTypes = listManager.get(localizedLists, 'Consultant Type');

        const items = [...people.values()];

        return (
            <div className={'container-fluid'} style={{paddingBottom: 15}}>

                <div className={'row'}>
                    <div className={'col'}>
                        <h1>{`Edit ${peopleGroup.name} Consultants`}</h1>
                    </div>
                </div>

                <div className={'row'} style={{marginBottom: 15}}>
                    <div className={'col'}>
                        <button className={'btn btn-info'} onClick={this.onAddConsultant}><i className={'fa fa-plus'} /> {Localize.text('AddConsultant', 'EditConsultants')}</button>
                    </div>
                </div>

                {consultancies.map((c, i) => {

                    const person = people.get(c.person);

                    return (
                        <div key={c._id} style={{marginBottom: 15}} className={'card'}>
                            <div className={'card-body'}>

                                <button type={'button'} className={'btn btn-sm btn-outline-danger float-right'}><i className={'fa fa-remove'} onClick={e => this.removeConsultant(e, i)} /></button>

                                <div className={'row'}>
                                    <div className={'col-md-3'}>
                                        <div className={'form-group'}>
                                            <label>{Localize.text('Person', 'EditConsultants')}</label>
                                            {person ?
                                                <input type={'text'} className={'form-control'} value={`${person.firstName} ${person.lastName}`} readOnly={true} />
                                                :
                                                <FilterSelect items={items} onSelect={_id => this.onPersonChange(i, _id)} />
                                            }
                                        </div>
                                    </div>
                                    <div className={'col-md-3'}>
                                        <div className={'form-group'}>
                                            <label>{Localize.text('ConsultantType', 'EditConsultants')}</label>
                                            <select className={'form-control'} value={c.consultantType} onChange={e => this.onInputChange(e, c._id, 'consultantType')}>
                                                <option value={''}>{Localize.text('SelectOne', 'Universal')}</option>
                                                {consultantTypes.map(t => <option key={t._id} value={t._id}>{t.values.get('name')}</option>)}
                                            </select>
                                        </div>
                                    </div>
                                    <div className={'col-md-2'}>
                                        <div className={'form-group'}>
                                            <label>{Localize.text('StartDate', 'EditConsultants')}</label>
                                            <input type={'text'} className={'form-control'} value={c.startDate} placeholder={Localize.text('EGMMYYYY', 'UniversalForms')} onChange={e => this.onInputChange(e, c._id, 'startDate')} />
                                        </div>
                                    </div>
                                    <div className={'col-md-2'}>
                                        <div className={'form-group'}>
                                            <label>{Localize.text('EndDate', 'EditConsultants')}</label>
                                            <input type={'text'} className={'form-control'} value={c.endDate} placeholder={Localize.text('EGMMYYYY', 'UniversalForms')} onChange={e => this.onInputChange(e, c._id, 'endDate')} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}

                <div style={{display: consultancies.length === 0 && originalConsultancies.length === 0 ? 'none' : 'block'}} className={'row'}>
                    <div className={'col'}>
                        <button style={{minWidth: 150, marginRight: 10}} className={'btn btn-primary'} onClick={this.onSave} disabled={disableSave}>{Localize.text('Save', 'Universal')}</button>
                        <Link to={`/field/${fieldId}/people/${peopleId}`} style={{minWidth: 150}} className={'btn btn-outline-dark'}>{Localize.text('Cancel', 'Universal')}</Link>
                    </div>
                </div>

            </div>
        );
    }

}
EditConsultants.propTypes = {
    people: PropTypes.instanceOf(Map),
    localizedLists: PropTypes.arrayOf(PropTypes.object),
    locations: PropTypes.arrayOf(PropTypes.object),
    match: PropTypes.object,
    history: PropTypes.object,
    disableNav: PropTypes.func,
    enableNav: PropTypes.func,
};

const EditConsultantsContainer = connect(
    ({ appState }) => ({
        people: appState.people,
        localizedLists: appState.localizedLists,
        locations: appState.locations
    }),
    dispatch => ({
        disableNav: () => dispatch(setAppDisableNav(true)),
        enableNav: () => dispatch(setAppDisableNav(false)),
    })
)(EditConsultants);

export default EditConsultantsContainer;
