import actions from '../enums/actions';
import { Map } from 'immutable';

const getInitialState = () => ({
    active: 'field', // 'field' or 'peopleGroup'
    field: '',
    peopleGroup: '',
    fieldToConsultants: new Map()
});

export default (state = getInitialState(), { type, payload }) => {
    switch(type) {
        case actions.FIELD_SET_ID: {
            if(payload.field && state.field !== payload.field) {
                const returnValue = {
                    ...getInitialState(),
                    field: payload.field
                };
                return returnValue;
            } else if(!payload.field) {
                return getInitialState();
            } else {
                return {
                    ...state,
                    active: 'field',
                    field: payload.field
                };
            }
        }
        case actions.FIELD_SET_PEOPLE_GROUP:
            return {
                ...state,
                active: payload.peopleGroup ? 'peopleGroup' : state.active,
                peopleGroup: payload.peopleGroup
            };
        case actions.FIELD_SET_ACTIVE:
            return {
                ...state,
                active: payload.active
            };
        case actions.FIELD_SET_FIELD_TO_CONSULTANTS:
            return {
                ...state,
                fieldToConsultants: payload.fieldToConsultants
            };
        default:
            return state;
    }
};
