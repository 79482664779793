import React  from 'react';
import PropTypes from 'prop-types';

import MyBootstrapModal from './my-bootstrap-modal';
import { modalWidths } from '../../../constants';

const ButtonModal = ({ children, className, modalTitle, buttonTitle, warnOnNav }) => {
    return (
        <MyBootstrapModal
            className={className}
            modalWidth={modalWidths.DEFAULT}
            modalTitle={modalTitle}
            buttonAsAnchor={false}
            buttonClassName="btn btn-sm btn-outline-info"
            buttonTitle={buttonTitle}
            warnOnNav={warnOnNav}
        >
            { children }
        </MyBootstrapModal>
    );
};
ButtonModal.propTypes = {
    children: PropTypes.element,
    modalTitle: PropTypes.string,
    buttonTitle: PropTypes.string,
    className: PropTypes.string,
    warnOnNav: PropTypes.bool,
};

export default ButtonModal;
