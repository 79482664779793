import PropTypes from 'prop-types';
import { Component } from 'react';

class ScrollToTop extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            // $('#js-scrollableBody')[0].scrollTo(0, 0);
            $('#js-scrollableBody')[0].scrollTop = 0;
            document.activeElement.blur();
        }
    }

    render() {
        return null;
    }
}
ScrollToTop.propTypes = {
    location: PropTypes.object
};

export default ScrollToTop;
