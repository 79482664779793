import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindAll, uniq } from 'lodash';
import swal from 'sweetalert';
import request from 'superagent';
// import FileSaver from 'file-saver';

import ButtonModal from '../shared/modals/button-modal';
import { setAppModalStatus } from '../../actions/app-actions';
import { dbGuids, loggedOutInternalErrorMessage, modalStatuses } from '../../constants';
import shareWithServer from '../../constants/share-with-server';
const { backupSuccess, localizableLists } = shareWithServer;

// t191 if the user deletes an item, replace everywhere the item is used in the db with the 'notSelectedKey'.
const allLists = [
    { name: 'Work Status', fields: ['name', 'description'], notSelectedKey: '3c67fa95-eb8d-42dc-a5d8-bdf0848f659b' },
    { name: 'Field Role Title', fields: ['name'], notSelectedKey: '' }, // for Field Roles, we delete the object from fields.IMTRequestedFieldRoles array
    { name: 'Curriculum Status', fields: ['name'], notSelectedKey: 'fed21934-a162-46af-8017-e10df1c30367' },
    { name: 'Organization', fields: ['abbr', 'name'], notSelectedKey: '956db657-abbc-466c-87c1-5a2f5be8e874' },
    { name: 'Member Status', fields: ['name'], notSelectedKey: '' },
    { name: 'Nationality Countries', fields: ['name'], notSelectedKey: '' },
    { name: 'Sending Entities', fields: ['name'], notSelectedKey: '' },
    { name: 'Location Roles', fields: ['name'], notSelectedKey: 'e8e941e7-2524-4e78-ad1f-4d4c17359c0a' },
    { name: 'PG Service Status', fields: ['name'], notSelectedKey: '' },
    { name: 'Service Status', fields: ['name'], notSelectedKey: '' },
    { name: 'CLA Level', fields: ['name'], notSelectedKey: '9093e754-0bb6-4f5e-974e-6f9ac74c3017' },
    { name: 'Training Names', fields: ['name'], notSelectedKey: '' },
    // { name: 'Other Training Names', fields: ['name'] },
    { name: 'Church Stage', fields: ['name'], notSelectedKey: '5dc7cafc-5a15-4d24-abb7-ee406d9e2f32' },
    { name: 'Consultant Type', fields: ['name'], notSelectedKey: '' },
    { name: 'Primary Care', fields: ['name'], notSelectedKey: '' },
    // { name: 'Old Book', fields: ['name'] },
    // { name: 'New Book', fields: ['name'] }
];

const doNotDelete = uniq(allLists
    .filter(list => list.notSelectedKey)
    .map(list => list.notSelectedKey)
    .concat([
        'cb853298-1b93-4d7a-b303-7a706b4758e8', // administrative support role
        '8b3f5a1f-9c68-48fc-94f9-38507499fe95', // fmdb contact role
        '615cd9f0-50bd-479d-89f6-dbb65e8f2526', // active service status
        'b962dc17-e16a-4ac9-ba60-df01d186798e', // itinerant service status
        '85a9db39-9696-438d-964e-0c5ff676ff0b', // former service status
    ]).concat([...Object.values(dbGuids)])
    .concat([...Object.values(localizableLists)])
);

class ListEditor extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedList: '',
            newListItem: {}
        };
        bindAll(this, [
            'onSelectList',
            'onDone',
            'saveNewItem',
            'onInputChange',
            'resetNewListItem',
            'editValueClick',
            'editNumClick',
            'onDeleteListItemClick'
        ]);
    }

    onSelectList(e, selectedList) {
        e.preventDefault();
        this.setState({
            ...this.state,
            selectedList
        });
        $(this.modalNode).off('shown.bs.modal').on('shown.bs.modal', function() {
            $(document).off('focusin.modal');
        });
        setTimeout(() => {
            $(this.modalNode).modal({
                backdrop: 'static',
                keyboard: false,
                show: true
            });
        }, 0);
        this.props.setModalStatus(modalStatuses.SHOWING_SWAL);
    }

    onDone(e) {
        e.preventDefault();
        $(this.modalNode).modal('hide');
        this.resetNewListItem();
        this.props.setModalStatus(modalStatuses.NO_MODAL);
    }

    resetNewListItem() {
        this.setState({
            ...this.state,
            newListItem: {}
        });
    }

    async saveNewItem(e) {
        try {
            e.preventDefault();
            const { newListItem, selectedList } = this.state;
            const selectedListItems = listManager.get(this.props.localizedLists, selectedList);

            // t370 don't allow items with the same name in the same list
            if (selectedListItems.findIndex(i => i.values.get('name').toLowerCase() === newListItem.name.toLowerCase()) >= 0) {
                await swal({
                    icon: 'error',
                    title: Localize.text('AnItemWithThisNameAlreadyExistsInThisList', 'Admin'),
                    button: Localize.text('OK', 'Universal'),
                });

                return;
            }

            const confirmed = await swal({
                text: Localize.text('AreYouSureThatYouWantToAddThisItemToTheList', 'Admin'),
                icon: 'warning',
                buttons: {
                    cancel: { text: Localize.text('Cancel', 'Universal'), visible: true },
                    confirm: { text: Localize.text('YesAddToList', 'Admin'), closeModal: false }
                }
            });
            if(!confirmed) {
                return;
            }
            const selectedListObj = allLists
                .find(l => l.name === selectedList);
            for(const key of selectedListObj.fields) {
                const val = newListItem[key] ? newListItem[key].trim() : '';
                if(!val) {
                    await swal({
                        title: Localize.text('Oops', 'Universal'),
                        text: Localize.text('AllFieldsMustBeFilledOut', 'UniversalForms'),
                        icon: 'warning',
                        button: Localize.text('OK', 'Universal')
                    });
                    return;
                }
                newListItem[key] = val;
            }
            swal.close();
            await listManager.addItem(selectedList, new Map(Object.keys(newListItem).map(key => [key, newListItem[key]])));
            this.resetNewListItem();
        } catch(err) {
            handleError(err);
        }
    }

    onInputChange(e, field) {
        e.preventDefault();
        this.setState({
            ...this.state,
            newListItem: {
                ...this.state.newListItem,
                [field]: e.target.value
            }
        });
    }

    async editValueClick(e, valueName, item) {
        const locale = Localize.locale();
        const { localizedLists } = this.props;
        let text = await swal({
            text: Localize.text('EnterUpdatedValue', 'Admin'),
            content: {
                element: 'input',
                attributes: {
                    type: 'text',
                    value: item.values.get(valueName)
                }
            },
            buttons: [Localize.text('Cancel', 'Universal'), Localize.text('Save', 'Universal')]
        });
        text = text ? text.trim() : '';
        if(!text) return;
        item = localizedLists.find(i => i._id === item._id);
        const newItem = {...item};
        if(!newItem.values[locale]) return;
        const idx = newItem.values[locale].findIndex(i => i.key === valueName);
        newItem.values[locale][idx] = {key: valueName, val: text};
        listManager.updateItem(newItem).then();
    }

    async editNumClick(e, item) {
        const { localizedLists } = this.props;
        let text = await swal({
            text: Localize.text('EnterSortNumber', 'Admin'),
            content: {
                element: 'input',
                attributes: {
                    type: 'number',
                    value: item.num
                }
            },
            buttons: [Localize.text('Cancel', 'Universal'), Localize.text('Save', 'Universal')]
        });
        if(!text) return;
        text = text.trim().replace(/\D/g, '') || '0';
        const num = Number(text);
        item = localizedLists.find(i => i._id === item._id);
        const newItem = {...item, num};
        listManager.updateItem(newItem).then();
    }

    async onDeleteListItemClick(e, item) {
        e.preventDefault();

        swal({
            text: Localize.text('AreYouSureThatYouWantToDeleteThisListItem', 'Admin'),
            icon: 'warning',
            buttons: [Localize.text('Cancel', 'Universal'), { text: Localize.text('DeleteItem', 'Admin'), closeModal: false }],
        }).then(async ok => {
            if (ok) {
                const listObj = allLists
                    .find(l => l.name === this.state.selectedList);

                const result = await listManager.deleteItem({ oldKey: item._id, newKey: listObj.notSelectedKey });

                return result;
            }

            return ok;
        }).then(totalItemsChanged => {
            if (totalItemsChanged === '0') {
                swal({
                    text: Localize.text('ItemDeleted', 'Admin'),
                    icon: 'success',
                    button: Localize.text('OK', 'Universal'),
                });
            } else if (totalItemsChanged) {
                swal({
                    text: Localize.text('ItemDeletedAndItemCountItemsUpdatedInOtherTables', 'Admin', { ItemCount: totalItemsChanged }),
                    icon: 'success',
                    button: Localize.text('OK', 'Universal'),
                });
            }
        });
    }

    render() {

        const { selectedList, newListItem } = this.state;
        const { localizedLists } = this.props;

        const intCol = new Intl.Collator(Localize.locale());

        const selectedListObj = allLists
            .find(l => l.name === selectedList);

        const selectedListItems = listManager.get(localizedLists, selectedList);

        return (
            <div>

                <div className={'card'}>
                    <div className={'card-header'}>
                        <h4 className={'no-bottom-margin'}>{Localize.text('Lists', 'Admin')}</h4>
                    </div>
                    <div className={'list-group list-group-flush'}>
                        {allLists
                            .sort((a, b) => intCol.compare(a.name, b.name))
                            .map(l => (
                                <a key={l.name} href={'#'} className={'list-group-item'} onClick={e => this.onSelectList(e, l.name)}>{l.name}</a>
                            ))
                        }
                    </div>
                </div>

                <div className={'modal'} ref={node => this.modalNode = node}>
                    <div className={'modal-dialog modal-lg'}>
                        <div className={'modal-content'}>
                            <div className={'modal-header'}>
                                <h5 className="modal-title">{selectedList}</h5>
                                <button type="button" className="close" onClick={this.onDone}>
                                    <span>&times;</span>
                                </button>
                            </div>
                            <div>
                                <div className="modal-body">

                                    <div className={'row'} style={{marginBottom: 15}}>
                                        <div className={'col-12'}>
                                            <form className={'card'} onSubmit={this.saveNewItem}>
                                                <div className={'card-header'}>{Localize.text('AddNewItem', 'Admin')}</div>
                                                <div className={'card-body'}>
                                                    {!selectedListObj ? [] : selectedListObj.fields.map(item => (
                                                        <div key={item + 'input'} className={'form-group'}>
                                                            <label>{item}</label>
                                                            <input type={'text'} className={'form-control'} value={newListItem[item] || ''} onChange={e => this.onInputChange(e, item)} />
                                                        </div>
                                                    ))}
                                                    <div className={'form-group'}>
                                                        <button type={'submit'} className={'btn btn-secondary pull-right'}>{Localize.text('SaveNewItem', 'Admin')}</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>

                                    <div className={'row'}>
                                        <div className={'col-12'}>
                                            <p>{Localize.text('DoubleClickToEditAnyFieldInTheTable', 'Admin')}</p>
                                        </div>
                                    </div>

                                    <div className={'row'}>
                                        <div className={'col-12'}>
                                            <div className={'card'}>
                                                <div className={'card-header'}>{Localize.text('CurrentListItems', 'Admin')}</div>
                                                <div className={'card-body'}>
                                                    <table className={'table table-bordered table-hover'}>
                                                        <thead>
                                                        <tr className={'table-info'}>
                                                            <th>Order</th>
                                                            {!selectedListObj ? [] : selectedListObj.fields.map(item => (
                                                                <th key={item + 'header'}><strong>{item}</strong></th>
                                                            ))}
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {!selectedListObj ?
                                                            []
                                                            :
                                                            selectedListItems
                                                                .sort((a, b) => a.num > b.num ? 1 : a.num < b.num ? -1 : intCol.compare(a.values.get('name'), b.values.get('name')))
                                                                .map(i => (
                                                                    <tr key={i._id}>
                                                                        <td onDoubleClick={e => this.editNumClick(e, i)}>{i.num}</td>
                                                                        {selectedListObj.fields.map(item => (
                                                                            <td onDoubleClick={e => this.editValueClick(e, item, i)} key={i._id + item}>{i.values.get(item)}</td>
                                                                        ))}
                                                                        {doNotDelete.includes(i._id) ?
                                                                            <td />
                                                                            :
                                                                            <td className={'text-center'}><a href={'#'} className={'btn btn-danger'} onClick={e => this.onDeleteListItemClick(e, i)} title={Localize.text('DeleteItem', 'Admin')}><i className={'fa fa-times'} /></a></td>
                                                                        }
                                                                    </tr>
                                                            ))
                                                        }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type={'button'} className={'btn btn-primary'} onClick={this.onDone}>{Localize.text('Done', 'Universal')}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}
ListEditor.propTypes = {
    localizedLists: PropTypes.arrayOf(PropTypes.object),
    setModalStatus: PropTypes.func,
};
const WrappedListEditor = connect(
    ({ appState }) => ({
        localizedLists: appState.localizedLists
    }),
    dispatch => ({
        setModalStatus: status => dispatch(setAppModalStatus(status)),
    }),
)(ListEditor);

const Admin = ({ user }) => {
    const [loggedInUsers, setLoggedInUsers] = useState([]);
    const [downloading, setDownloading] = useState(false);
    const [downloadProgress, setDownloadProgress] = useState(0);
    const [appVersion, setAppVersion] = useState('');

    useEffect(() => {
        const setAppVersionFromServer = async () => {
            const res = await request.get('/api/app-version');
            setAppVersion(` - version ${res.text}`);
        };

        setAppVersionFromServer().then();
    }, []);

    const onDownloadClick = e => {
        try {
            e.preventDefault();

            const downloadInterval = setInterval(() => {
                setDownloadProgress((downloadProgress + 1) % 5);
            }, 500);

            setDownloadProgress(0);
            setDownloading(true);
            const http = new XMLHttpRequest();
            http.onreadystatechange = () => {
                if (http.status === 403) {
                    handleError(new Error(loggedOutInternalErrorMessage));
                    return;
                }

                if(http.readyState === 4 && http.status === 200) {
                    // t215 only backup to S3
                    // const { responseText } = http;
                    // const blob = new Blob([responseText], {type: 'text/plain;charset=utf-8'});
                    // FileSaver.saveAs(blob, `FMDB-${new Date().getTime()}.aes256`);
                    clearInterval(downloadInterval);
                    setDownloading(false);
                    setDownloadProgress(0);

                    console.log('http res', http.responseText);
                    console.log('backupSuccess', backupSuccess);
                    if (http.responseText === backupSuccess) {
                        swal({
                            icon: 'success',
                            title: http.responseText,
                        }).then();
                    } else {
                        swal({
                            icon: 'error',
                            title: Localize.text('Oops', 'Universal'),
                            text: http.responseText,
                        }).then();
                    }
                } else if (http.status === 403) {
                    handleError(new Error(loggedOutInternalErrorMessage));
                }
            };
            http.onerror = handleError;
            http.onprogress = ({ loaded, total }) => {
                console.log('progress', (loaded / total) * 100);
                setDownloadProgress((loaded / total) * 100);
            };
            http.open(
                'POST',
                '/api/backup',
                true
            );
            http.send();

        } catch(err) {
            handleError(err);
        }
    };

    const getLoggedInUsers = async e => {
        e.preventDefault();

        try {
            const res = await request.get('/api/logged-in-users');
            const newLoggedInUsers = res.body;
            setLoggedInUsers(newLoggedInUsers.sort((a, b) => {
                const aVal = a.lastActive;
                const bVal = b.lastActive;
                return aVal > bVal ? -1 : aVal < bVal ? 1 : 0;  // sort descending
            }));
        } catch (myErr) {
            // /api/logged-in-users returns 403 if the user (who has to be admin to get here) has been logged out
            if (myErr.status === 403) handleError(new Error(loggedOutInternalErrorMessage));
            handleError(myErr);
        }
    };

    const loggedInUserButtonText = loggedInUsers.length ?
        Localize.text('RefreshLoggedInUsers', 'Admin')
        : Localize.text('ShowLoggedInUsers', 'Admin');

    const showLoggedInUsers = () => {
        return (
            <div className={'card'}>
                <div className={'card-header'}>
                    <h4 className={'no-bottom-margin'}>{Localize.text('LoggedInUsersLastActiveServerTime', 'Admin')}</h4>
                </div>
                <div className={'list-group list-group-flush'}>
                    {loggedInUsers
                        .map(u => (
                            <div key={u._id} className={'list-group-item'}>{`${u.lastActive}  ${u.firstName} ${u.lastName}`}</div>
                        ))
                    }
                </div>
            </div>
        );
    };

    const showBackup = () => {
        if (!user.fmdbAdministrator) return null;

        return (
            <div className="card">
                <div className="card-header">
                    {Localize.text('DataBackup', 'Summaries')}
                </div>
                <div className="card-body">
                    {/*<p>{Localize.text('DownloadTheEntireContentsOfTheDatabaseForBackingUpOrReportGeneration', 'Summaries')}</p>*/}
                    <p>{Localize.text('BackupTheDatabaseToOurS3Bucket', 'Summaries')}</p>
                    <div>
                        {/*downloading ?
                                <button type={'button'} className={'btn btn-primary'} disabled={true}>{Localize.text('Progress', 'Universal')}: {parseInt(downloadProgress, 10)}%</button>
                                :
                                <button type={'button'} className={'btn btn-primary'} onClick={this.onDownloadClick}>{Localize.text('DownloadNow', 'Summaries')}</button>
                            */}
                        <button type={'button'} className={'btn btn-primary'} disabled={downloading} onClick={onDownloadClick}>{Localize.text('BackupNow', 'Summaries')} {'. '.repeat(downloadProgress)}</button>
                    </div>
                </div>
            </div>
        );
    };

    // I get 2 emails for uncaught errors, 1 email for caught errors.
    const testUncaughtError = e => {
        e.preventDefault();

        throw new Error('my uncaught test error');
    };

    const testCaughtError = e => {
        e.preventDefault();

        try {
            throw new Error('my caught test error');
        } catch (myErr) {
            handleError(myErr);
        }
    };

    return (
        <div>
            <div className={'form-group border-bottom'}>
                <div className={'container-fluid'}>
                    <div className={'row'}>
                        <div className={'col-12'}>
                            <h2>{Localize.text('Administration', 'Universal')} {appVersion}</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className={'container-fluid'}>
                <div className="row">
                    <button className="btn btn-outline-primary ml-3 mb-2" onClick={e => getLoggedInUsers(e)}>{loggedInUserButtonText}</button>
                    <ButtonModal
                        className="ml-auto mr-3"
                        buttonTitle={'Test Error Handling'}
                        modalTitle={'Test Error Handling'}
                        warnOnNav={false}
                    >
                        <div>
                            <p>This is for testing how we handle errors.  Only the developers should use this.</p>
                            <div className="d-flex">
                                <button className="btn btn-outline-danger" onClick={testCaughtError}>Throw and catch error</button>
                                <button className="btn btn-outline-danger ml-auto" onClick={testUncaughtError}>Throw and do NOT catch error</button>
                            </div>
                        </div>
                    </ButtonModal>
                </div>

                <div className="row mb-3">
                {
                    loggedInUsers.length ?
                        <>
                            <div className={'col-sm-12 col-md-8 col-lg-7 col-xl-6 mb-2'}>
                                {showLoggedInUsers()}
                            </div>
                            <div className="col-sm-12 col-md-8 col-lg-5 col-xl-6">
                                {showBackup()}
                            </div>
                        </>
                        :
                        <div className="col-sm-12 col-md-8 col-lg-6 col-xl-6">
                            {showBackup()}
                        </div>
                }
                </div>

                <div className={'row'}>

                    <div className={'col-sm-6 col-md-4'}>
                        <WrappedListEditor />
                    </div>

                </div>
            </div>
        </div>
    );
};

Admin.propTypes = {
    user: PropTypes.object,
};

export default Admin;
