import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';

import Header from './shared/header';
import ScrollToTop from './shared/scroll-to-top';
import Regions from './regions';
import EditRegion from './regions/edit-region';
import EditCountry from './edit-country';
import EditPeopleGroups from './edit-people-groups';
import Field from './field';
import EditConsultants from './field/people-group-detail/components/edit-consultants';
import EditPersonnel from './field/people-group-detail/components/edit-personnel';
import PeopleGroupSearch from './people-group-search';
import Personnel from './personnel';
import EditPerson from './personnel/edit-person';
import EditTraining from './training/edit-training';
import Training from './training';
import Summaries from './summaries';
import Files from './files';
import Admin from './admin';
import AddRegion from './add-region';
import AddField from './add-field';

const App = ({ location, windowHeight, match, headerHeight }) => {

    const styles = {
        container: {
            overflowY: 'hidden'
        },
        childrenContainer: {
            marginTop: 0,
            height: windowHeight - headerHeight,
            paddingTop: 10,
            overflowY: 'auto'
        }
    };

    return (
        <div style={styles.container}>
            <ScrollToTop location={location} />
            <Header routeParams={match.params} />
            <div id={'js-scrollableBody'} style={styles.childrenContainer}>
                <Switch>
                    <Route exact={true} path={'/'} component={Regions} />
                    <Route path={'/region/:regionId'} component={EditRegion} />
                    <Route path={'/country/:countryId'} component={EditCountry} />
                    <Route path={'/edit-people-groups/:fieldId'} component={EditPeopleGroups} />
                    <Route path={'/field/:fieldId/files'} component={Field} />
                    <Route path={'/field/:fieldId/people/:peopleId/edit-consultants'} component={EditConsultants} />
                    <Route path={'/field/:fieldId/people/:peopleId/edit-personnel'} component={EditPersonnel} />
                    <Route path={'/field/:fieldId/people/:peopleId'} component={Field} />
                    <Route path={'/field/:fieldId'} component={Field} />
                    <Route path={'/field'} component={Field} />
                    <Route path={'/people-group-search'} component={PeopleGroupSearch} />
                    <Route path={'/personnel'} component={Personnel} exact={true} />
                    <Route path={'/personnel/new'} component={EditPerson} />
                    <Route path={'/personnel/:personId'} component={EditPerson} />
                    <Route path={'/training/new'} component={EditTraining} />
                    <Route path={'/training/:trainingId'} component={EditTraining} />
                    <Route path={'/training'} component={Training} />
                    <Route path={'/summaries'} component={Summaries} />
                    <Route path={'/files'} component={Files} />
                    <Route path={'/admin'} component={Admin} />
                    <Route path={'/add-region'} component={AddRegion} />
                    <Route path={'/add-field'} component={AddField} />
                    <Route path={'/add-country'} component={EditCountry} />
                </Switch>
            </div>
        </div>
    );
};
App.propTypes = {
    match: PropTypes.object,
    children: PropTypes.element,
    location: PropTypes.object,
    windowHeight: PropTypes.number,
    headerHeight: PropTypes.number,
};

export default connect(
    ({ appState }) => {
        return {
            windowHeight: appState.windowHeight,
            headerHeight: appState.headerHeight,
        };
    }
)(App);
