import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import uniq from 'lodash/uniq';
import swal from 'sweetalert';
import { Map } from 'immutable';
import * as _ from 'lodash';
import RoleSelector from '../../shared/role-selector';
// import ContactSelector from '../../shared/contact-selector';
import ModalTexarea from '../../shared/modal-textarea';
import NumStrSelector from '../../shared/num-str-selector';
import { TwoColumnLayout, TwoColumnRow } from '../../shared/two-column-layout';
import { modalStatuses } from '../../../constants';
import EditFieldConsultants from './components/edit-field-consultants';

const styles = {
    container: {
        marginTop: 15
    }
};

let MultiColumnList = ({ windowWidth, items, minWidth }) => {
    if(items.length < 16 || windowWidth <= minWidth) {
        return (
            <div className={'row'}>
                <div className={'col-12'}>
                    <ul>
                        {items}
                    </ul>
                </div>
            </div>
        );
    } else {
        const half = Math.ceil(items.length / 2);
        const col1 = items
            .slice(0, half);
        const col2 = items
            .slice(half);
        return (
            <div className={'row'}>
                <div className={'col-6'}>
                    <ul>{col1}</ul>
                </div>
                <div className={'col-6'}>
                    <ul>{col2}</ul>
                </div>
            </div>
        );
    }
};
MultiColumnList.propTypes = {
    windowWidth: PropTypes.number,
    items: PropTypes.arrayOf(PropTypes.element),
    columns: PropTypes.number,
    minWidth: PropTypes.number
};
MultiColumnList = connect(
    ({ appState }) => ({
        windowWidth: appState.windowWidth
    })
)(MultiColumnList);

const FieldDetail = ({
                         localizedLists,
                         fields,
                         peopleGroups,
                         locations,
                         user = {},
                         params: { fieldId, peopleId },
                         history,
                         people,
                         fieldToConsultants,
                         setFields,
                         setActive,
                         setFieldToConsultants,
                         setModalStatus,
                     }) => {
    const [currentFieldId, setCurrentFieldId] = useState('');
    const [reloadConsultants, setReloadConsultants] = useState(false);

    useEffect(() => {
        try {
            const updateFieldConsultants = async newFieldId => {
                const newPeopleGroups = _.chain(locations)
                    .filter(l => l.field === newFieldId)
                    .map(l => l.peopleGroup ? l.peopleGroup._id : '')
                    .filter(s => !!s)
                    .uniq()
                    .value();

                // t353 passing an empty array to getPeopleGroupConsultants is how we return all consultants.
                //  So, for fields that have no people groups, we want to show that there are no consultants,
                //  so don't call getPeopleGroupConsultants at all.
                if (newPeopleGroups.length) {
                    const { errors, data } = await gql.transaction(
                        'query',
                        'getPeopleGroupConsultants',
                        { peopleGroupIds: newPeopleGroups },
                        [
                            '_id',
                            'peopleGroup',
                            'person',
                            'consultantType',
                            'startDate',
                            'endDate'
                        ]
                    );
                    if (errors) throw errors[0];

                    // t326 for fields, only show active consultants b/c for large fields especially, that's what we think they want to see here.
                    //  When viewing a specific tribe, it may be nice to see inactive consultants b/c the user may be interested
                    //  in the history.  But here, more likely they want to see who's currently doing what, or where they have needs.
                    const activeConsultants = data.getPeopleGroupConsultants.filter(c => !c.endDate);
                    setFieldToConsultants(fieldToConsultants.set(newFieldId, activeConsultants));
                } else {
                    setFieldToConsultants(fieldToConsultants.set(newFieldId, []));
                }
            };

            if (fieldId !== currentFieldId || reloadConsultants) {
                setReloadConsultants(false);
                setCurrentFieldId(fieldId);
                updateFieldConsultants(fieldId).then();
            }
        } catch (err) {
            handleError(err);
        }
    }, [currentFieldId, fieldId, fieldToConsultants, locations, reloadConsultants, setFieldToConsultants]);

    const saveField = async data => {
        console.log('saveField', data);
        const preppedData = {...data};
        if(preppedData.countries) {
            delete preppedData.countries;   // t159 countries array is not stored in the Fields table anymore.
        }
        const { errors } = await gql.transaction(
            'mutation',
            'updateField',
            {
                _id: data._id,
                input: preppedData
            },
            ['_id']
        );
        if(errors) throw new Error(errors[0].message);
    };

    const onNameChange = async e => {
        try {
            e.preventDefault();
            const field = fields.find(f => f._id === fieldId);
            setModalStatus(modalStatuses.SHOWING_SWAL);
            let name = await swal({
                text: Localize.text('EnterFieldName', 'FieldForm'),
                content: {
                    element: 'input',
                    attributes: {value: field.name}
                },
                buttons: [Localize.text('Cancel', 'Universal'), Localize.text('Save', 'Universal')]
            });

            if (name) {
                name = name.trim();

                if (name && name !== field.name) {
                    const newField = {
                        ...field,
                        name
                    };
                    const newFields = fields
                        .map(f => f._id !== newField._id ? f : newField);

                    setFields(newFields);

                    await saveField({
                        _id: newField._id,
                        name: newField.name
                    });
                }
            }

            setModalStatus(modalStatuses.NO_MODAL);
        } catch(err) {
            handleError(err);
        }
    };

    const onPublicationNameChange = async e => {
        try {
            e.preventDefault();
            const field = fields.find(f => f._id === fieldId);

            setModalStatus(modalStatuses.SHOWING_SWAL);
            let publicationName = await swal({
                text: Localize.text('EnterPublicationName', 'FieldForm'),
                content: {
                    element: 'input',
                    attributes: {value: field.publicationName}
                },
                buttons: [Localize.text('Cancel', 'Universal'), Localize.text('Save', 'Universal')]
            });

            if (publicationName) {
                publicationName = publicationName.trim();

                if (publicationName && publicationName !== field.publicationName) {
                    const newField = {
                        ...field,
                        publicationName
                    };
                    const newFields = fields
                        .map(f => f._id !== newField._id ? f : newField);

                    setFields(newFields);

                    await saveField({
                        _id: newField._id,
                        publicationName: newField.publicationName
                    });
                }
            }

            setModalStatus(modalStatuses.NO_MODAL);
        } catch(err) {
            handleError(err);
        }
    };

    const requestedFieldRolesChanged = async data => {
        try {
            const idx = fields.findIndex(f => f._id === fieldId);
            const field = fields[idx];
            const newField = {
                ...field,
                IMTRequestedFieldRoles: data
                    .map(r => ({
                        title: r.role.trim(),
                        category: r.category.trim(),
                        people: r.people
                    }))
                    .filter(r => !!r.title)
            };
            const newFields = [
                ...fields.slice(0, idx),
                newField,
                ...fields.slice(idx + 1)
            ];
            setFields(newFields);

            await saveField({
                _id: newField._id,
                IMTRequestedFieldRoles: newField.IMTRequestedFieldRoles
            });

        } catch(err) {
            handleError(err);
        }
    };

    const fieldAppointedRolesChanged = async data => {
        try {
            const idx = fields.findIndex(f => f._id === fieldId);
            const field = fields[idx];
            const newField = {
                ...field,
                fieldAppointedRoles: data
                    .map(r => ({
                        title: r.role.trim(),
                        category: r.category.trim(),
                        people: r.people
                    }))
                    .filter(r => !!r.title)
            };
            const newFields = [
                ...fields.slice(0, idx),
                newField,
                ...fields.slice(idx + 1)
            ];
            setFields(newFields);

            await saveField({
                _id: newField._id,
                fieldAppointedRoles: newField.fieldAppointedRoles
            });

        } catch(err) {
            handleError(err);
        }
    };

    const contactsChanged = async value => {
        try {
            const idx = fields.findIndex(f => f._id === fieldId);
            const field = fields[idx];
            const newField = {
                ...field,
                contacts: value
            };
            const newFields = [
                ...fields.slice(0, idx),
                newField,
                ...fields.slice(idx + 1)
            ];
            setFields(newFields);

            await saveField({
                _id: newField._id,
                contacts: newField.contacts
            });

        } catch(err) {
            handleError(err);
        }
        // try {
        //     const { fields, params } = this.props;
        //     const idx = fields.findIndex(f => f._id === params.fieldId);
        //     const field = fields[idx];
        //     const newField = {
        //         ...field,
        //         contacts: data
        //             .map(d => ({
        //                 ...d,
        //                 note: d.note.trim()
        //             }))
        //     };
        //     const newFields = [
        //         ...fields.slice(0, idx),
        //         newField,
        //         ...fields.slice(idx + 1)
        //     ];
        //     this.props.setFields(newFields);
        //
        //     await this.saveField({
        //         _id: newField._id,
        //         contacts: newField.contacts
        //     });
        //
        // } catch(err) {
        //     handleError(err);
        // }
    };

    const personnelNeedsChanged = async data => {
        try {
            const idx = fields.findIndex(f => f._id === fieldId);
            const field = fields[idx];
            const newField = {
                ...field,
                personnelNeeds: data
                    .filter(d => !!d.number)
                    .map(d => ({
                        ...d,
                        comment: d.comment.trim()
                    }))
            };
            const newFields = [
                ...fields.slice(0, idx),
                newField,
                ...fields.slice(idx + 1)
            ];
            setFields(newFields);

            console.log(newFields);

            await saveField({
                _id: newField._id,
                personnelNeeds: newField.personnelNeeds
            });

        } catch(err) {
            handleError(err);
        }
    };

    const onNoteSave = async value => {
        try {
            const idx = fields.findIndex(f => f._id === fieldId);
            const field = fields[idx];
            const newField = {
                ...field,
                note: value
            };
            const newFields = [
                ...fields.slice(0, idx),
                newField,
                ...fields.slice(idx + 1)
            ];
            setFields(newFields);

            await saveField({
                _id: newField._id,
                note: newField.note
            });

        } catch(err) {
            handleError(err);
        }
    };

    const onPeopleGroupClick = (e, newFieldId, newPeopleId) => {
        e.preventDefault();
        if(peopleId === newPeopleId) {
            setActive('peopleGroup');
        } else {
            history.push(`/field/${newFieldId}/people/${newPeopleId}`);

        }
    };

    const onChangeHidden = async e => {
        try {
            e.preventDefault();
            if(!user.fmdbAdministrator) return;
            const idx = fields.findIndex(f => f._id === fieldId);
            const field = fields[idx];
            const { hidden } = field;
            setModalStatus(modalStatuses.SHOWING_SWAL);
            const confirmed = await swal({
                title: hidden ? Localize.text('ShowField', 'Field') : Localize.text('HideField', 'Field'),
                text: hidden ? Localize.text('WouldYouLikeToMakeThisFieldVisible', 'Field') : Localize.text('WouldYouLikeToMakeThisFieldHidden', 'Field'),
                icon: 'warning',
                buttons: [
                    Localize.text('Cancel', 'Universal'),
                    Localize.text('OK', 'Universal')
                ]
            });
            if (confirmed) {
                const newField = {
                    ...field,
                    hidden: !hidden
                };
                const newFields = [
                    ...fields.slice(0, idx),
                    newField,
                    ...fields.slice(idx + 1)
                ];
                setFields(newFields);
                await saveField({
                    _id: newField._id,
                    hidden: !hidden
                });
            }

            setModalStatus(modalStatuses.NO_MODAL);
        } catch(err) {
            handleError(err);
        }
    };

    const setConsultantEndDates = () => {
        setReloadConsultants(true);
    };

    const field = fields.find(f => f._id === fieldId);

    const countryMap = locations
        .filter(l => l.field === fieldId)
        .reduce((map, l) => {
            const prevCountries = map.get(l.peopleGroup._id) || [];
            return map.set(l.peopleGroup._id, uniq([...prevCountries, l.country.name]));
        }, new Map());

    if(!field) return <div />;

    const { listManager } = window;
    const localizedListItems = listManager.getItems(localizedLists);

    const consultants = fieldToConsultants.get(fieldId) || [];
    const preppedConsultants = consultants
        .filter(c => !c.endDate)    // t241 don't show consultants that have an end date
        .reduce((map, s) => {
            if(map.has(s.consultantType)) {
                const arr = map.get(s.consultantType);
                return map.set(s.consultantType, _.uniq([...arr, s.person]));
            } else {
                return map.set(s.consultantType, [s.person]);
            }
        }, Map());

    const peopleGroupListItems = (
        <MultiColumnList
            minWidth={1200}
            items={peopleGroups.map(p => {

                const countries = countryMap.get(p._id).filter(c => c && !field.name.includes(c));

                return (
                    <li key={p._id}><a onClick={e => onPeopleGroupClick(e, field._id, p._id)} href={`/field/${field._id}/people/${p._id}`}>{countries.length ? `${p.name} (${countries.join(', ')})` : p.name}</a></li>
                );
            })} />
    );

    const countriesListItems = (
        <MultiColumnList
            minWidth={1200}
            // t159 compute the countries for this field from all field locations.  With our changes in reducers/index.js
            //  this change may not be needed anymore, so I'm leaving the old code commented out in case we want
            //  to revert to it.
            //  items={field.countries.map(c => (
            //      <li key={c._id}>{c.name}</li>
            //  ))}
            items={
                _.uniqWith(locations.filter(loc => loc.country._id &&  (loc.field === fieldId)), (loc1, loc2) => loc1.country._id === loc2.country._id)
                .map(loc => (
                    <li key={loc.country._id}>{loc.country.name}</li>
                ))} />
    );

    const imtRequestedFieldRoles = listManager
        .get(localizedLists, 'Field Role Title')
        .map(r => ({
            _id: r._id,
            name: r.values.get('name'),
            num: r.num,
        }));

    const editable = field.level > 1;

    return (
        <div style={styles.container}>
            <div className={'container-fluid'}>
                <div className={'row'}>

                    <div className={'col-sm-6'}>

                        <div className={'row'}>
                            <div className={'col-12'}>
                                <div className={'form-group'}>
                                    <label>{Localize.text('NameInDatabase', 'FieldForm')}: <a href={'#'} onClick={onNameChange}><i style={{display: editable ? 'inline' : 'none'}} className={'fa fa-pencil '} /></a></label>
                                    <h1 className={'no-bottom-margin'}>{field.name} </h1>
                                </div>
                            </div>
                        </div>

                        <div className={'row'}>
                            <div className={'col-lg-8 col-md-7 col-sm-6'}>
                                <div className={'form-group'}>
                                    <label>{Localize.text('EntityName', 'FieldForm')}: <a href={'#'} onClick={onPublicationNameChange}><i style={{display: editable ? 'inline' : 'none'}} className={'fa fa-pencil '} /></a></label>
                                    <h4 className={'no-bottom-margin'}>{field.publicationName}</h4>
                                </div>
                            </div>
                            <div className={'col-lg-4 col-md-5 col-sm-6'}>
                                <Link to={`/field/${field._id}/files`} style={{display: editable ? 'block' : 'none'}} className={'btn btn-primary btn-sm'}>{Localize.text('ViewEditFiles', 'PeopleGroupDetail')}</Link>
                            </div>
                        </div>

                        <div style={{display: user && user.fmdbAdministrator ? 'block' : 'none'}} className={'row'}>
                            <div className={'col-lg-8 col-md-7 col-sm-6'}>
                                <div className={'form-group'}>
                                    {field.hidden ?
                                        <a onClick={onChangeHidden} href={'#'} className={'text-danger'}>{Localize.text('FieldIsHidden', 'Field')}</a>
                                        :
                                        <a onClick={onChangeHidden} href={'#'}>{Localize.text('FieldIsVisible', 'Field')}</a>
                                    }
                                </div>
                            </div>
                        </div>

                        <div className={'row'}>
                            <div className={'col-12 form-group'}>
                                <div className={'card'}>
                                    <div className={'card-header'}>

                                        <RoleSelector
                                            readonly={!editable}
                                            roles={field.IMTRequestedFieldRoles.map(r => ({ role: r.title, category: r.category, people: r.people }))}
                                            allRoles={imtRequestedFieldRoles}
                                            title={Localize.text('UpdateIMTRequestedFieldRoles', 'FieldForm')}
                                            onDone={requestedFieldRolesChanged} />

                                        <h4 className={'no-bottom-margin'}>{Localize.text('IMTRequestedFieldRoles', 'FieldForm')}</h4>
                                    </div>
                                    <div className={'card-body'}>
                                        <ul style={{marginBottom: 0}}>
                                            {field.IMTRequestedFieldRoles
                                                .sort((a,b) => {
                                                    const aVal = imtRequestedFieldRoles.find(({ _id }) => _id === a.title).num;
                                                    const bVal = imtRequestedFieldRoles.find(({ _id }) => _id === b.title).num;
                                                    return aVal > bVal ? 1 : aVal < bVal ? -1 : 0;
                                                })
                                                .map((r, i) => {
                                                    const title = imtRequestedFieldRoles.find(({ _id }) => _id === r.title).name;
                                                    const names = r.people
                                                        .map(_id => people.get(_id))
                                                        .map(p => ({_id: p._id, name: p.firstName + ' ' + p.lastName}))
                                                        .reduce((arr, p, ii) => {
                                                            if(ii === r.people.length - 1) {
                                                                return [
                                                                    ...arr,
                                                                    <Link key={`${title}-${p._id}`} to={`/personnel/${p._id}`}>{p.name}</Link>
                                                                ];
                                                            } else {
                                                                return [
                                                                    ...arr,
                                                                    <Link key={`${title}-${p._id}`} to={`/personnel/${p._id}`}>{p.name}</Link>,
                                                                    <span key={`${title}-spacer-${ii}`}>, </span>
                                                                ];
                                                            }
                                                        }, []);

                                                    return (
                                                        <li key={title + i}><strong>{title}{r.category ? `, ${r.category}` : ''}</strong> - <span>{names}</span></li>
                                                    );
                                                })
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={'row'}>
                            <div className={'col-12 form-group'}>
                                <div className={'card'}>
                                    <div className={'card-header'}>

                                        <RoleSelector
                                            readonly={!editable}
                                            variableRole={true}
                                            roles={field.fieldAppointedRoles.map(r => ({ role: r.title, category: r.category, people: r.people }))}
                                            title={Localize.text('UpdateFieldAppointedRoles', 'FieldForm')}
                                            onDone={fieldAppointedRolesChanged} />

                                        <h4 className={'no-bottom-margin'}>{Localize.text('FieldAppointedRoles', 'FieldForm')}</h4>
                                    </div>
                                    <div className={'card-body'}>
                                        <ul style={{marginBottom: 0}}>
                                            {field.fieldAppointedRoles
                                                .map((r, i) => {
                                                    const names = r.people
                                                        .map(_id => people.get(_id))
                                                        .map(p => ({_id: p._id, name: p.firstName + ' ' + p.lastName}))
                                                        .reduce((arr, p, ii) => {
                                                            if(ii === r.people.length - 1) {
                                                                return [
                                                                    ...arr,
                                                                    <Link key={`${r.title}-${p._id}`} to={`/personnel/${p._id}`}>{p.name}</Link>
                                                                ];
                                                            } else {
                                                                return [
                                                                    ...arr,
                                                                    <Link key={`${r.title}-${p._id}`} to={`/personnel/${p._id}`}>{p.name}</Link>,
                                                                    <span key={`${r.title}-spacer-${ii}`}>, </span>
                                                                ];
                                                            }
                                                        }, []);

                                                    return (
                                                        <li key={r.title + i}><strong>{r.title}{r.category ? `, ${r.category}` : ''}</strong> - <span>{names}</span></li>
                                                    );
                                                })
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={'row'}>
                            <div className={'col-12 form-group'}>
                                <div className={'card'}>
                                    <div className={'card-header'}>
                                        <EditFieldConsultants
                                            hidden={!editable || fieldToConsultants.size === 0}
                                            peopleGroups={peopleGroups}
                                            modalTitle={Localize.text('SetConsultantEndDate', 'EditFieldConsultants')}
                                            onDone={setConsultantEndDates}
                                        />
                                        <h4 className={'no-bottom-margin'}>{Localize.text('Consultants', 'FieldForm')}</h4>
                                    </div>
                                    <div className={'card-body'}>
                                        <TwoColumnLayout>
                                            {[...preppedConsultants.entries()]
                                                .map(([ consultantType, peopleArr ]) => {
                                                    consultantType = localizedListItems.has(consultantType) ? localizedListItems.get(consultantType).name : '';
                                                    peopleArr = peopleArr.map(personId => {
                                                        const { firstName = '', lastName = '' } = people.get(personId) || {};
                                                        const fullName =  firstName + ' ' + lastName;
                                                        return { fullName, personId };
                                                    });
                                                    return (
                                                        <TwoColumnRow key={consultantType}>
                                                            <div>{consultantType}:</div>
                                                            <div>{
                                                                peopleArr.reduce((arr, p, i) => {
                                                                    if(i === peopleArr.length - 1) {
                                                                        return [
                                                                            ...arr,
                                                                            <Link key={consultantType + p.personId} to={`/personnel/${p.personId}`}>{p.fullName}</Link>
                                                                        ];
                                                                    } else {
                                                                        return [
                                                                            ...arr,
                                                                            <Link key={consultantType + p.personId} to={`/personnel/${p.personId}`}>{p.fullName}</Link>,
                                                                            <span key={`${consultantType}-space-${i}`}>, </span>
                                                                        ];
                                                                    }
                                                                }, [])
                                                            }</div>
                                                        </TwoColumnRow>
                                                    );
                                                })
                                            }
                                        </TwoColumnLayout>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={'row'}>
                            <div className={'col-12 form-group'}>
                                <div className={'card'}>
                                    <div className={'card-header'}>

                                        <ModalTexarea
                                            readonly={!editable}
                                            value={field.contacts}
                                            onSave={contactsChanged}
                                            title={Localize.text('UpdateFieldContacts', 'UniversalForms')} />

                                        {/*<ContactSelector*/}
                                            {/*contacts={field.contacts}*/}
                                            {/*title={Localize.text('UpdateFieldContacts', 'UniversalForms')}*/}
                                            {/*onDone={contactsChanged} />*/}

                                        <h4 className={'no-bottom-margin'}>{Localize.text('ExternalContacts', 'FieldForm')}</h4>
                                    </div>
                                    <div className={'card-body'}>
                                        <div style={{whiteSpace: 'pre-line'}}>{field.contacts}</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={'row'}>
                            <div className={'col-12 form-group'}>
                                <div className={'card'}>
                                    <div className={'card-header'}>

                                        <ModalTexarea
                                            readonly={!editable}
                                            value={field.note}
                                            onSave={onNoteSave}
                                            title={Localize.text('EditFieldNotes', 'FieldForm')} />

                                        <h4 className={'no-bottom-margin'}>{Localize.text('FieldNotes', 'FieldForm')}</h4>
                                    </div>
                                    <div className={'card-body'}>
                                        <div style={{whiteSpace: 'pre-line'}}>{field.note}</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={'row'}>
                            <div className={'col-12 form-group'}>
                                <div className={'card'}>
                                    <div className={'card-header'}>

                                        <NumStrSelector
                                            readonly={!editable}
                                            title={Localize.text('UpdatePersonnelNeeds', 'FieldForm')}
                                            items={field.personnelNeeds}
                                            onDone={personnelNeedsChanged}
                                        />

                                        <h4 className={'no-bottom-margin'}>{Localize.text('PersonnelNeeds', 'FieldForm')}</h4>
                                    </div>
                                    <div className={'card-body'}>
                                        <ul style={{marginBottom: 0}}>
                                            {field.personnelNeeds
                                                .map((n, i) => {
                                                    return (
                                                        <li key={'personnel-need' + i}><strong>{n.number})</strong> <span>{n.comment}</span></li>
                                                    );
                                                })
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className={'col-sm-6'}>

                        <div className={'row'}>
                            <div className={'col-12 form-group'}>
                                <div className={'card'}>
                                    <div className={'card-header'}>
                                        <Link to={`/edit-people-groups/${field._id}`} className={'pull-right'} style={(user.fmdbAdministrator || user.imtLeadership) ? {} : {display: 'none'}}><i className={'fa fa-pencil'} /></Link>
                                        <h4 className={'no-bottom-margin'}>{Localize.text('PeopleGroups', 'FieldForm')}</h4>
                                    </div>
                                    <div className={'card-body'}>
                                        {peopleGroupListItems}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={'row'}>
                            <div className={'col-12 form-group'}>
                                <div className={'card'}>
                                    <div className={'card-header'}>
                                        <a href={'#'} className={'pull-right'}><i className={'fa fa-pencil'} style={{display: 'none'}} /></a>
                                        <h4 className={'no-bottom-margin'}>{Localize.text('Countries', 'FieldForm')}</h4>
                                    </div>
                                    <div className={'card-body'}>
                                        {countriesListItems}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    );
};
FieldDetail.propTypes = {
    localizedLists: PropTypes.arrayOf(PropTypes.object),
    fields: PropTypes.arrayOf(PropTypes.object),
    peopleGroups: PropTypes.arrayOf(PropTypes.object),
    locations: PropTypes.arrayOf(PropTypes.object),
    user: PropTypes.object,
    params: PropTypes.object,
    history: PropTypes.object,
    people: PropTypes.instanceOf(Map),
    fieldToConsultants: PropTypes.instanceOf(Map),
    setFields: PropTypes.func,
    setActive: PropTypes.func,
    setFieldToConsultants: PropTypes.func,
    setModalStatus: PropTypes.func,
};

export default FieldDetail;
