import React from 'react';
import PropTypes from 'prop-types';
import bindAll from 'lodash/bindAll';
import escapeRegExp from 'lodash/escapeRegExp';

class FilterSelect extends React.Component {

    constructor(props) {
        super(props);

        // got rid of componentWillReceiveProps with this:
        // https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#recommendation-fully-uncontrolled-component-with-a-key
        const getInitialName = () => {
            const { items, initialValue } = props;

            if (initialValue && items.length > 0) {
                const item = items.find(p => p._id === initialValue);
                if (item) {
                    return item.firstName + ' ' + item.lastName;
                }
            }

            return '';
        };

        this.state = {
            value: getInitialName(),
        };
        bindAll(this, [
            'onBlur',
            'onChange',
            'onSelect',
            'onFocus'
        ]);
    }

    onChange(e) {
        e.preventDefault();
        this.setState({
            ...this.state,
            value: e.target.value
        });
    }

    onSelect(e, _id, value = '') {
        e.preventDefault();
        const { retainValueOnSelect = false } = this.props;
        if(!retainValueOnSelect) {
            this.setState({
                ...this.state,
                value: ''
            });
        } else {
            this.setState({
                ...this.state,
                value
            });
        }
        this.props.onSelect(_id);
    }

    onFocus(e) {
        e.preventDefault();
        setTimeout(() => {
            if(!this.dropdownShown) $(this.inputNode).dropdown('toggle');
        }, 400);
    }

    // if setValueOnBlur is true, this allows the user to type a consultant's name and click "Add to Finished Translations and Save",
    //  and it will use the consultant they typed.  Otherwise, the consultant field was not actually updated
    //  until user clicked in the dropdown to select.
    onBlur(e) {
        const { setValueOnBlur, items } = this.props;

        if (setValueOnBlur) {
            const text = e.target.value;

            const item = items.find(p => p.firstName.toLowerCase() + ' ' + p.lastName.toLowerCase() === text.toLowerCase());
            if (item) {
                this.props.onSelect(item._id);
            }
        }
    }

    render() {

        const { items, small = false } = this.props;

        const { value } = this.state;

        const filters = !value ? [] : value
            .trim()
            .split(/\s+/g)
            .map(s => new RegExp(`(?:\\W|^)${escapeRegExp(s)}`, 'i'));
        const wholeWordFilters = !value ? [] : value
            .split(/\s+/)
            .map(f => new RegExp('(?:\\W|^)' + escapeRegExp(f) + '(?:\\W|$)', 'i'));

        let filteredItems;
        if(filters.length > 0) {
            filteredItems = items
                .filter(i => {
                    const name = i.firstName + ' ' + i.lastName;
                    return filters.every(f => f.test(name));
                })
                .sort((a, b) => {
                    const aName = a.firstName + ' ' + a.lastName;
                    const bName = b.firstName + ' ' + b.lastName;
                    const aContainsWholeWords = wholeWordFilters.every(f => f.test(aName));
                    const bContainsWholeWords = wholeWordFilters.every(f => f.test(bName));
                    if(aContainsWholeWords === bContainsWholeWords) {
                        return 0;
                    } else if(aContainsWholeWords) {
                        return -1;
                    } else {
                        return 1;
                    }
                })
                .slice(0, 10);
        } else {
            filteredItems = [];
        }

        return (
            <div onBlur={this.onBlur}>
                <div className={'dropdown'} ref={node => {
                    $(node).on('show.bs.dropdown', () => {
                        this.dropdownShown = true;
                    });
                    $(node).on('hide.bs.dropdown', () => {
                        this.dropdownShown = false;
                    });
                }}>
                    <input type={'text'} className={`form-control ${small ? 'form-control-sm' : ''}`} value={value} onFocus={this.onFocus} onBlur={this.onBlur} ref={node => {
                        $(node).attr('data-toggle', 'dropdown');
                        this.inputNode = node;
                    }} onChange={this.onChange} />
                    <div className={'dropdown-menu'} ref={node => {
                        this.dropdownNode = node;
                    }}>
                        {filteredItems
                            .map(i => {
                                const fullName = i.firstName + ' ' + i.lastName;
                                return (
                                    <a className={'dropdown-item'} href={'#'} key={i._id} onClick={e => this.onSelect(e, i._id, fullName)}>{fullName}</a>
                                );
                            })
                        }
                    </div>
                </div>
            </div>
        );
    }

}
FilterSelect.propTypes = {
    setValueOnBlur: PropTypes.bool,
    initialValue: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.object),
    onSelect: PropTypes.func,
    small: PropTypes.bool,
    retainValueOnSelect: PropTypes.bool,
};

export default FilterSelect;
