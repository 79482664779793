import React from 'react';
import PropTypes from 'prop-types';
import bindAll from 'lodash/bindAll';
import isEqual from 'lodash/isEqual';
import { connect } from 'react-redux';
import { setAppDisableNav, setAppModalStatus } from '../../actions/app-actions';
import { modalStatuses } from '../../constants';

const Selector = ({ data, onChange }) => {
    return (
        <div className={'form-group'}>
            <div className={'card'}>
                <div className={'card-body'}>
                    <div className={'row'}>

                        <div className={'col'}>
                            <div className={'form-group'}>
                                <label>{Localize.text('NumberNeeded', 'FieldForm')}</label>
                                <input type={'number'} className={'form-control'} value={data.number} min={1} onChange={e => {
                                    e.preventDefault();
                                    onChange({
                                        ...data,
                                        number: Number(e.target.value)
                                    });
                                }} />
                            </div>
                        </div>

                        <div className={'col'}>
                            <div className={'form-group'}>
                                <label>{Localize.text('Comment', 'Universal')}</label>
                                <input type={'text'} className={'form-control'} value={data.comment} onChange={e => {
                                    e.preventDefault();
                                    onChange({
                                        ...data,
                                        comment: e.target.value
                                    });
                                }} />
                            </div>
                        </div>

                        <div style={{width: 50, paddingRight: 15}}>
                            <div className={'form-group'}>
                                <button type={'button'} className={'btn btn-danger btn-sm pull-right'} onClick={e => {
                                    e.preventDefault();
                                    onChange();
                                }}><i className={'fa fa-remove'} /></button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};
Selector.propTypes = {
    data: PropTypes.object,
    onChange: PropTypes.func
};

class NumStrSelector extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            items: props.items.length > 0 ? props.items : [
                this.defaultItem()
            ],
            orig: props.items,
        };
        bindAll(this, [
            'openModal',
            'closeModal',
            'onDone',
            'onChange',
            'addItem'
        ]);
    }

    defaultItem() {
        return {number: 1, comment: ''};
    }

    componentWillReceiveProps(newProps) {
        if(!isEqual(newProps.items, this.state.items)) {
            this.setState({
                ...this.state,
                items: newProps.items
            });
        }
    }

    componentDidUpdate() {
        // make sure this update is for the modal we are showing
        if (!($(this.modalNode).data('bs.modal') || {})._isShown) return;

        if (this.props.modalStatus === modalStatuses.CLOSE_MODAL) {
            // user wants to lose changes
            if (!isEqual(this.state.orig, this.state.items)) {
                this.setState({ items: this.state.orig }, () => this.closeModal());
            } else {
                this.closeModal();
            }
        } else if (!isEqual(this.state.orig, this.state.items)) {
            this.props.disableNav();
        } else {
            this.props.enableNav();
        }
    }

    openModal(e) {
        e.preventDefault();
        const $modal = $(this.modalNode);
        $modal.modal('show');
        $modal
            .off('hidden.bs.modal')
            .on('hidden.bs.modal', () => {
                this.onDone();
            });
        this.props.setModalStatus(modalStatuses.SHOWING_MODAL);
    }

    closeModal(e) {
        if (e) e.preventDefault();
        $(this.modalNode).modal('hide');
        this.props.setModalStatus(modalStatuses.NO_MODAL);
        this.props.enableNav();
    }

    onDone() {
        if (!isEqual(this.state.orig, this.state.items)) {
            this.props.onDone(this.state.items);
        }
    }

    onChange(idx, data) {
        const { state } = this;
        if(data) {
            this.setState({
                ...state,
                items: [
                    ...state.items.slice(0, idx),
                    data,
                    ...state.items.slice(idx + 1)
                ]
            });
        } else {
            this.setState({
                ...state,
                items: [
                    ...state.items.slice(0, idx),
                    ...state.items.slice(idx + 1)
                ]
            });
        }
    }

    addItem() {
        this.setState({
            ...this.state,
            items: [
                ...this.state.items,
                this.defaultItem()
            ]
        });
    }

    render() {

        const { title, readonly } = this.props;
        const { items } = this.state;

        return (
            <div className={'pull-right'}>

                <a href={'#'} onClick={this.openModal} style={readonly ? {display: 'none'} : {}}><i className={'fa fa-pencil'} /></a>

                <div className={'modal'} ref={node => this.modalNode = node}>
                    <div className={'modal-dialog modal-lg'}>
                        <div className={'modal-content'}>
                            <div className={'modal-header'}>
                                <h5 className="modal-title">{title}</h5>
                                <button type="button" className="close" onClick={this.closeModal}>
                                    <span>&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                {
                                    items.map((d, i) => (
                                        <Selector key={'selector' + i} data={d} onChange={data => this.onChange(i, data)} />
                                    ))
                                }
                                <div>
                                    <button type={'button'} className={'btn btn-outline-dark'} onClick={this.addItem}><i className={'fa fa-plus'} /> {Localize.text('AddItem', 'UniversalForms')}</button>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type={'button'} className={'btn btn-primary'} onClick={this.closeModal}>{Localize.text('Done', 'Universal')}</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }

}
NumStrSelector.propTypes = {
    title: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.object),
    readonly: PropTypes.bool,
    onDone: PropTypes.func,
    modalStatus: PropTypes.string,
    disableNav: PropTypes.func,
    enableNav: PropTypes.func,
    setModalStatus: PropTypes.func,
};

const WrappedNumStrSelector = connect(
    ({ appState }) => ({
        people: appState.people,
        modalStatus: appState.modalStatus,
    }),
    dispatch => ({
        disableNav: () => dispatch(setAppDisableNav(true)),
        enableNav: () => dispatch(setAppDisableNav(false)),
        setModalStatus: status => dispatch(setAppModalStatus(status)),
    }),
)(NumStrSelector);

export default WrappedNumStrSelector;
