import { connect } from 'react-redux';
import { updateAppRegions, updateAppFields } from '../../util/gql-general-calls';

import AddField from './add-field';
import { setAppDisableNav } from '../../actions/app-actions';

export default connect(
    ({ appState }) => ({
        user: appState.user
    }),
    dispatch => ({
        updateRegions: () => updateAppRegions(dispatch),
        updateFields: () => updateAppFields(dispatch),
        disableNav: () => dispatch(setAppDisableNav(true)),
        enableNav: () => dispatch(setAppDisableNav(false)),
    })
)(AddField);
