import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { Link } from 'react-router-dom';
import NumStrSelector from '../../../shared/num-str-selector';
import shareWithServer from '../../../../constants/share-with-server';
const { pgServiceStatusActive, pgServiceStatusItinerant, pgServiceStatusInactive } = shareWithServer.localizableLists;


const pgServiceStatuses = {
    active: pgServiceStatusActive,
    itinerant: pgServiceStatusItinerant,
    inactive: pgServiceStatusInactive,
};

const WorkersTableBody = ({ statuses, localizedListItems, people }) => {

    console.log('statuses', statuses);

    return (
        <tbody>
        {statuses.map(s => {

            const { firstName = '', lastName = '' } = people.get(s.person) || {};

            const hideItinerant = s.serviceStatus === pgServiceStatuses.active;

            const { primaryRoleDescription = '', secondaryRoleDescription = '' } = s;

            const { name: primaryName = '' } = localizedListItems.get(s.primaryRole) || {};
            const { name: secondaryName = '' } = localizedListItems.get(s.secondaryRole) || {};

            return (
                <tr key={s._id}>
                    <td><Link to={`/personnel/${s.person}`}>{firstName + ' ' + lastName}</Link></td>
                    <td><strong>{primaryName}</strong><br />{primaryRoleDescription}</td>
                    <td><strong>{secondaryName}</strong><br />{secondaryRoleDescription}</td>
                    <td>{localizedListItems.has(s.claLevel) ? localizedListItems.get(s.claLevel).name : ''}</td>
                    <td>{s.startDate}</td>
                    <td>{s.endDate}</td>
                    <td><span style={hideItinerant ? {display: 'none'} : {}}>{s.itinerantStartDate}</span></td>
                    <td><span style={hideItinerant ? {display: 'none'} : {}}>{s.itinerantEndDate}</span></td>
                </tr>
            );
        })}
        </tbody>
    );
};
WorkersTableBody.propTypes = {
    statuses: PropTypes.arrayOf(PropTypes.object),
    localizedListItems: PropTypes.instanceOf(Map),
    people: PropTypes.instanceOf(Map)
};

const Personnel = ({ peopleGroup, statuses, localizedListItems, people, peopleId, fieldId, readonly, save }) => {

    const active = statuses
        .filter(s => s.serviceStatus === pgServiceStatuses.active);
    const itinerant = statuses
        .filter(s => s.serviceStatus === pgServiceStatuses.itinerant);
    const other = statuses
        .filter(s => [pgServiceStatuses.inactive].includes(s.serviceStatus));

    return (
        <div className={'container-fluid'}>

            <div className={'row'}>
                <div className={'col-12'}>
                    <Link style={{display: readonly ? 'none' : 'block'}} to={`/field/${fieldId}/people/${peopleId}/edit-personnel`} className={'btn btn-info float-right'}><i className={'fa fa-pencil'} /> {Localize.text('EditPersonnel', 'PeopleGroupDetail')}</Link>
                    <h2>{Localize.text('ActiveWorkers', 'PeopleGroupDetail')}</h2>
                </div>
            </div>

            <div className={'row'}>
                <div className={'col-12'}>
                    <table className={'table '}>
                        <thead>
                        <tr>
                            <th>{Localize.text('Contact', 'PeopleGroupDetail')}</th>
                            <th>{Localize.text('PrimaryRole', 'PeopleGroupDetail')}</th>
                            <th>{Localize.text('SecondaryRole', 'PeopleGroupDetail')}</th>
                            <th>{Localize.text('CLALevel', 'PeopleGroupDetail')}</th>
                            <th>{Localize.text('StartDate', 'PeopleGroupDetail')}</th>
                            <th>{Localize.text('EndDate', 'PeopleGroupDetail')}</th>
                            <th style={{ width: 200 }} />
                            <th style={{ width: 200 }} />
                        </tr>
                        </thead>
                        <WorkersTableBody statuses={active} localizedListItems={localizedListItems} peopleGroup={peopleGroup} people={people} />
                    </table>
                </div>
            </div>

            <div className={'row'}>
                <div className={'col-12'}>
                    <h2>{Localize.text('ItinerantWorkers', 'PeopleGroupDetail')}</h2>
                </div>
            </div>

            <div className={'row'}>
                <div className={'col-12'}>
                    <table className={'table '}>
                        <thead>
                        <tr>
                            <th>{Localize.text('Contact', 'PeopleGroupDetail')}</th>
                            <th>{Localize.text('PrimaryRole', 'PeopleGroupDetail')}</th>
                            <th>{Localize.text('SecondaryRole', 'PeopleGroupDetail')}</th>
                            <th>{Localize.text('CLALevel', 'PeopleGroupDetail')}</th>
                            <th>{Localize.text('StartDate', 'PeopleGroupDetail')}</th>
                            <th>{Localize.text('EndDate', 'PeopleGroupDetail')}</th>
                            <th style={{width: 200}}>{Localize.text('ItinerantStartDate', 'PeopleGroupDetail')}</th>
                            <th style={{width: 200}}>{Localize.text('ItinerantEndDate', 'PeopleGroupDetail')}</th>
                        </tr>
                        </thead>
                        <WorkersTableBody statuses={itinerant} localizedListItems={localizedListItems} peopleGroup={peopleGroup} people={people} />
                    </table>
                </div>
            </div>

            <div className={'row'}>
                <div className={'col-12'}>
                    <h2>{Localize.text('InactiveWorkers', 'PeopleGroupDetail')}</h2>
                </div>
            </div>

            <div className={'row'}>
                <div className={'col-12'}>
                    <table className={'table '}>
                        <thead>
                        <tr>
                            <th>{Localize.text('Contact', 'PeopleGroupDetail')}</th>
                            <th>{Localize.text('PrimaryRole', 'PeopleGroupDetail')}</th>
                            <th>{Localize.text('SecondaryRole', 'PeopleGroupDetail')}</th>
                            <th>{Localize.text('CLALevel', 'PeopleGroupDetail')}</th>
                            <th>{Localize.text('StartDate', 'PeopleGroupDetail')}</th>
                            <th>{Localize.text('EndDate', 'PeopleGroupDetail')}</th>
                            <th style={{width: 200}}>{Localize.text('ItinerantStartDate', 'PeopleGroupDetail')}</th>
                            <th style={{width: 200}}>{Localize.text('ItinerantEndDate', 'PeopleGroupDetail')}</th>
                        </tr>
                        </thead>
                        <WorkersTableBody statuses={other} localizedListItems={localizedListItems} peopleGroup={peopleGroup} people={people} />
                    </table>
                </div>
            </div>

            {/*<div className={'row'}>*/}
                {/*<div className={'col-12'}>*/}
                    {/*<h2>{Localize.text('PersonnelNeeded', 'PeopleGroupDetail')}*/}
                    {/*<NumStrSelector*/}
                        {/*inline={true}*/}
                        {/*readonly={readonly}*/}
                        {/*title={Localize.text('UpdatePersonnelNeeds', 'FieldForm')}*/}
                        {/*items={peopleGroup.personnelNeeds}*/}
                        {/*onDone={console.log}*/}
                    {/*/></h2>*/}
                    {/*<ul>*/}
                        {/*{peopleGroup.personnelNeeds*/}
                            {/*.map((n, i) => <li key={`need${i}`}><strong>{n.number + ') '}</strong>{n.comment}</li>)*/}
                        {/*}*/}
                    {/*</ul>*/}
                {/*</div>*/}
            {/*</div>*/}

            <div className={'row'}>
                <div className={'col-md-6 form-group'}>
                    <div className={'card'}>
                        <div className={'card-header'}>

                            <NumStrSelector
                                readonly={readonly}
                                title={Localize.text('UpdatePersonnelNeeds', 'FieldForm')}
                                items={peopleGroup.personnelNeeds}
                                onDone={res => save({ personnelNeeds: res })}
                            />

                            <h4 className={'no-bottom-margin'}>{Localize.text('PersonnelNeeds', 'FieldForm')}</h4>
                        </div>
                        <div className={'card-body'}>
                            <ul style={{marginBottom: 0}}>
                                {peopleGroup.personnelNeeds
                                    .map((n, i) => {
                                        return (
                                            <li key={'personnel-need' + i}><strong>{n.number})</strong> <span>{n.comment}</span></li>
                                        );
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};
Personnel.propTypes = {
    fieldId: PropTypes.string,
    peopleId: PropTypes.string,
    readonly: PropTypes.bool,
    peopleGroup: PropTypes.object,
    statuses: PropTypes.arrayOf(PropTypes.object),
    localizedLists: PropTypes.arrayOf(PropTypes.object),
    localizedListItems: PropTypes.instanceOf(Map),
    people: PropTypes.instanceOf(Map),
    save: PropTypes.func
};

export default connect(
    ({ appState }) => ({
        people: appState.people
    })
)(Personnel);
