import { connect } from 'react-redux';

import Regions from './regions';
import {
    setAppRegions
} from '../../actions/app-actions';

export default connect(
    ({ appState }) => ({
        user: appState.user,
        regions: appState.regions,
        countries: appState.countries
    }),
    dispatch => ({
        setRegions(regions) {
            dispatch(setAppRegions(regions));
        }
    })
)(Regions);
