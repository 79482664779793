import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import swal from 'sweetalert';
import bindAll from 'lodash/bindAll';
import ModalTextarea from '../../../shared/modal-textarea';
import { TwoColumnLayout, TwoColumnRow } from '../../../shared/two-column-layout';
import * as dateUtils from '../../../../util/date-utils';
import { connect } from 'react-redux';
import { setAppDisableNav, setAppModalStatus } from '../../../../actions/app-actions';
import { modalStatuses } from '../../../../constants';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';

const yesNo = bool => bool ? <span>{Localize.text('Yes', 'Universal')}</span> : <span>{Localize.text('No', 'Universal')}</span>;

class Materials extends React.Component {
    constructor(props) {
        super(props);
        const { peopleGroup } = props;
        this.state = this.getInitialState(peopleGroup);
        bindAll(this, [
            'literacyProgramDevelopedClicked',
            'openBibleLessonModal',
            'onBibleLessonModalSave',
            'onBibleLessonModalCancel',
            'onInputChange',
            'openTranslationModal',
            'onTranslationModalSave',
            'onTranslationModalCancel',
            'onTranslationInProgressChange',
            'saveNote',
            'getInitialState',
            'onOrganizationChange'
        ]);
    }
    getInitialState(peopleGroup) {
        const orig = {
            phase1CurriculumStatus: peopleGroup.phase1CurriculumStatus,
            phase2CurriculumStatus: peopleGroup.phase2CurriculumStatus,
            phase3CurriculumStatus: peopleGroup.phase3CurriculumStatus,
            phase4CurriculumStatus: peopleGroup.phase4CurriculumStatus,
            translationInProgress: peopleGroup.translationInProgress,
            translationOrganization: peopleGroup.translationOrganization,
            dateTranslationStarted: peopleGroup.dateTranslationStarted,
            targetNTCompleteDate: peopleGroup.targetNTCompleteDate,
            dateNTCompleted: peopleGroup.dateNTCompleted,
            // otherMaterialsNote: peopleGroup.otherMaterialsNote
        };
        return { ...orig, orig };
    }
    componentWillReceiveProps(newProps) {
        if(newProps.peopleGroup !== this.props.peopleGroup) {
            this.setState(this.getInitialState(newProps.peopleGroup));
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.modalStatus === modalStatuses.CLOSE_MODAL) {
            if (($(this.bibleLessonsModalNode).data('bs.modal') || {})._isShown) this.onBibleLessonModalCancel();
            if (($(this.translationModalNode).data('bs.modal') || {})._isShown) this.onTranslationModalCancel();
        } else if (this.props.modalStatus === modalStatuses.SHOWING_MODAL &&
            // have to check for our specific modals, because for otherMaterialsNote we use a ModalTextarea, which sets modalStatus to SHOWING_MODAL.
            (($(this.bibleLessonsModalNode).data('bs.modal') || {})._isShown ||
            ($(this.translationModalNode).data('bs.modal') || {})._isShown))
        {
            // one of our modals is visible.
            if (!isEqual(this.state.orig, omit(this.state, 'orig'))) {
                this.props.disableNav();
            } else {
                this.props.enableNav();
            }
        }
    }

    async literacyProgramDevelopedClicked(e) {
        try {
            e.preventDefault();
            this.props.setModalStatus(modalStatuses.SHOWING_SWAL);
            const confirmed = await swal({
                title: Localize.text('LiteracyProgram', 'PeopleGroupDetail'),
                text: Localize.text('HasTheLiteracyProgramBeenDeveloped', 'PeopleGroupDetail'),
                buttons: ['No', 'Yes']
            });
            this.props.save({literacyProgramDeveloped: confirmed || false});
            this.props.setModalStatus(modalStatuses.NO_MODAL);
        } catch(err) {
            handleError(err);
        }
    }
    openBibleLessonModal(e) {
        e.preventDefault();
        $(this.bibleLessonsModalNode).modal({
            backdrop: 'static',
            keyboard: false,
            show: true
        });
        this.props.setModalStatus(modalStatuses.SHOWING_MODAL);
    }
    onBibleLessonModalSave(e) {
        e.preventDefault();
        const {
            phase1CurriculumStatus,
            phase2CurriculumStatus,
            phase3CurriculumStatus,
            phase4CurriculumStatus
        } = this.state;
        this.props.save({
            phase1CurriculumStatus,
            phase2CurriculumStatus,
            phase3CurriculumStatus,
            phase4CurriculumStatus
        });
        $(this.bibleLessonsModalNode).modal('hide');
        this.props.setModalStatus(modalStatuses.NO_MODAL);
        this.props.enableNav();
    }
    onBibleLessonModalCancel(e) {
        if (e) e.preventDefault();
        const {
            phase1CurriculumStatus,
            phase2CurriculumStatus,
            phase3CurriculumStatus,
            phase4CurriculumStatus
        } = this.props.peopleGroup;
        this.setState({
            ...this.state,
            phase1CurriculumStatus,
            phase2CurriculumStatus,
            phase3CurriculumStatus,
            phase4CurriculumStatus
        });
        $(this.bibleLessonsModalNode).modal('hide');
        this.props.setModalStatus(modalStatuses.NO_MODAL);
        this.props.enableNav();
    }
    onInputChange(e, key) {
        e.preventDefault();
        console.log(e.target.value);
        this.setState({
            ...this.state,
            [key]: e.target.value
        });
    }
    onOrganizationChange(e) {
        const { options } = e.target;
        const newArray = [];
        for(let i = 0; i < options.length; i++) {
            const option = options[i];
            if(option.selected) newArray.push(option.value);
        }
        this.setState({
            ...this.state,
            translationOrganization: newArray
        });
    }
    openTranslationModal(e) {
        e.preventDefault();
        $(this.translationModalNode).modal({
            backdrop: 'static',
            keyboard: false,
            show: true
        });
        this.props.setModalStatus(modalStatuses.SHOWING_MODAL);
    }
    async onTranslationModalSave(e) {
        e.preventDefault();
        let {
            translationOrganization,
            dateTranslationStarted,
            targetNTCompleteDate,
            dateNTCompleted,
            translationInProgress
        } = this.state;

        dateTranslationStarted = dateTranslationStarted.trim();
        targetNTCompleteDate = targetNTCompleteDate.trim();
        dateNTCompleted = dateNTCompleted.trim();

        for(const date of [dateTranslationStarted, targetNTCompleteDate, dateNTCompleted]) {
            if(!date) continue;
            const valid = await dateUtils.isValidDate(date, true);
            if(!valid) return;
        }

        dateTranslationStarted = dateTranslationStarted ? dateUtils.formatDate(dateTranslationStarted) : '';
        targetNTCompleteDate = targetNTCompleteDate ? dateUtils.formatDate(targetNTCompleteDate) : '';
        dateNTCompleted = dateNTCompleted ? dateUtils.formatDate(dateNTCompleted) : '';

        this.props.save({
            translationOrganization,
            dateTranslationStarted,
            targetNTCompleteDate,
            dateNTCompleted,
            translationInProgress
        });
        $(this.translationModalNode).modal('hide');
        this.props.setModalStatus(modalStatuses.NO_MODAL);
        this.props.enableNav();
    }
    onTranslationModalCancel(e) {
        if (e) e.preventDefault();
        const {
            translationOrganization,
            dateTranslationStarted,
            targetNTCompleteDate,
            dateNTCompleted,
            translationInProgress,
        } = this.props.peopleGroup;
        this.setState({
            ...this.state,
            translationOrganization,
            dateTranslationStarted,
            targetNTCompleteDate,
            dateNTCompleted,
            translationInProgress,
        });
        $(this.translationModalNode).modal('hide');
        this.props.setModalStatus(modalStatuses.NO_MODAL);
        this.props.enableNav();
    }
    onTranslationInProgressChange(e) {
        e.preventDefault();
        this.setState({
            ...this.state,
            translationInProgress: e.target.value === 'true' ? true : false
        });
    }
    saveNote(otherMaterialsNote) {
        this.props.save({ otherMaterialsNote });
    }
    render() {

        const { state } = this;
        const { peopleGroup, localizedListItems, localizedLists, windowHeight, readonly } = this.props;

        const styles = {
            leftPadding: {
                // paddingLeft: 10,
                // minHeight: 24,
                // minWidth: 50
            }
        };

        const translationOrganizationItems = peopleGroup.translationOrganization
            .map(id => {
                if(localizedListItems.has(id)) {
                    const text = `${localizedListItems.get(id).name} (${localizedListItems.get(id).abbr})`;
                    return <div key={id}>{text}</div>;
                } else {
                    return '';
                }
            })
            .filter(i => i ? true : false);

        return (
            <div className={'container-fluid'}>
                <div className={'row'}>
                    <div className={'col-6'}>

                        <div className={'row'}>
                            <div className={'col-12 form-group'}>
                                <div className={'card'}>
                                    <div className={'card-header'}>
                                        <div className={'pull-right'}>
                                            <a href={'#'} onClick={this.literacyProgramDevelopedClicked} style={readonly ? {display: 'none'} : {}}><i className={'fa fa-pencil'} /></a>
                                        </div>
                                        <h4 className={'no-bottom-margin'}>{Localize.text('Literacy', 'PeopleGroupDetail')}</h4>
                                    </div>
                                    <div className={'card-body'}>
                                        <TwoColumnLayout>{
                                            [
                                                <TwoColumnRow key={'materials-developed-literacy'}>
                                                    <div>{Localize.text('LiteracyProgramDeveloped', 'PeopleGroupDetail')}:</div>
                                                    <div
                                                        style={styles.leftPadding}>{yesNo(peopleGroup.literacyProgramDeveloped)}</div>
                                                </TwoColumnRow>
                                            ]
                                        }</TwoColumnLayout>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={'row'}>
                            <div className={'col-12 form-group'}>
                                <div className={'card'}>
                                    <div className={'card-header'}>
                                        <div className={'pull-right'}>
                                            <a href={'#'} onClick={this.openBibleLessonModal} style={readonly ? {display: 'none'} : {}}><i className={'fa fa-pencil'} /></a>
                                        </div>
                                        <h4 className={'no-bottom-margin'}>{Localize.text('FoundationalBibleLessonsForIndigenousTeachers', 'PeopleGroupDetail')}</h4>
                                    </div>
                                    <div className={'card-body'}>
                                        <TwoColumnLayout>
                                            <TwoColumnRow>
                                                <div>{Localize.text('Phase1', 'PeopleGroupDetail')}:</div>
                                                <div style={styles.leftPadding}>{localizedListItems.get(peopleGroup.phase1CurriculumStatus).name}</div>
                                            </TwoColumnRow>
                                            <TwoColumnRow>
                                                <div>{Localize.text('Phase2', 'PeopleGroupDetail')}:</div>
                                                <div style={styles.leftPadding}>{localizedListItems.get(peopleGroup.phase2CurriculumStatus).name}</div>
                                            </TwoColumnRow>
                                            <TwoColumnRow>
                                                <div>{Localize.text('Phase3', 'PeopleGroupDetail')}:</div>
                                                <div style={styles.leftPadding}>{localizedListItems.get(peopleGroup.phase3CurriculumStatus).name}</div>
                                            </TwoColumnRow>
                                            <TwoColumnRow>
                                                <div>{Localize.text('Phase4', 'PeopleGroupDetail')}:</div>
                                                <div style={styles.leftPadding}>{localizedListItems.get(peopleGroup.phase4CurriculumStatus).name}</div>
                                            </TwoColumnRow>
                                        </TwoColumnLayout>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={'modal'} ref={node => this.bibleLessonsModalNode = node}>
                            <div className={'modal-dialog modal-lg'}>
                                <div className={'modal-content'}>
                                    <div className={'modal-header'}>
                                        <h5 className="modal-title">{Localize.text('FoundationalBibleLessonsForIndigenousTeachers', 'PeopleGroupDetail')}</h5>
                                        <button type="button" className="close" onClick={this.onBibleLessonModalCancel}>
                                            <span>&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body" style={{maxHeight: windowHeight - 300, overflowY: 'auto'}}>

                                        <div className={'form-group row'}>
                                            <label className={'col-lg-3'}>{Localize.text('Phase1', 'PeopleGroupDetail') + ':'}</label>
                                            <div className={'col-lg-6'}>
                                                <select className={'form-control form-control-sm'} value={state.phase1CurriculumStatus} onChange={e => this.onInputChange(e, 'phase1CurriculumStatus')}>
                                                    <option value={''}>{Localize.text('SelectOne', 'UniversalForms')}</option>
                                                    {
                                                        listManager
                                                            .get(localizedLists, 'Curriculum Status')
                                                            .map(d => <option key={d._id} value={d._id}>{d.values.get('name')}</option>)
                                                    }
                                                </select>
                                            </div>
                                        </div>

                                        <div className={'form-group row'}>
                                            <label className={'col-lg-3'}>{Localize.text('Phase2', 'PeopleGroupDetail') + ':'}</label>
                                            <div className={'col-lg-6'}>
                                                <select className={'form-control form-control-sm'} value={state.phase2CurriculumStatus} onChange={e => this.onInputChange(e, 'phase2CurriculumStatus')}>
                                                    <option value={''}>{Localize.text('SelectOne', 'UniversalForms')}</option>
                                                    {
                                                        listManager
                                                            .get(localizedLists, 'Curriculum Status')
                                                            .map(d => <option key={d._id} value={d._id}>{d.values.get('name')}</option>)
                                                    }
                                                </select>
                                            </div>
                                        </div>

                                        <div className={'form-group row'}>
                                            <label className={'col-lg-3'}>{Localize.text('Phase3', 'PeopleGroupDetail') + ':'}</label>
                                            <div className={'col-lg-6'}>
                                                <select className={'form-control form-control-sm'} value={state.phase3CurriculumStatus} onChange={e => this.onInputChange(e, 'phase3CurriculumStatus')}>
                                                    <option value={''}>{Localize.text('SelectOne', 'UniversalForms')}</option>
                                                    {
                                                        listManager
                                                            .get(localizedLists, 'Curriculum Status')
                                                            .map(d => <option key={d._id} value={d._id}>{d.values.get('name')}</option>)
                                                    }
                                                </select>
                                            </div>
                                        </div>

                                        <div className={'form-group row'}>
                                            <label className={'col-lg-3'}>{Localize.text('Phase4', 'PeopleGroupDetail') + ':'}</label>
                                            <div className={'col-lg-6'}>
                                                <select className={'form-control form-control-sm'} value={state.phase4CurriculumStatus} onChange={e => this.onInputChange(e, 'phase4CurriculumStatus')}>
                                                    <option value={''}>{Localize.text('SelectOne', 'UniversalForms')}</option>
                                                    {
                                                        listManager
                                                            .get(localizedLists, 'Curriculum Status')
                                                            .map(d => <option key={d._id} value={d._id}>{d.values.get('name')}</option>)
                                                    }
                                                </select>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="modal-footer">
                                        <button type={'button'} className={'btn btn-secondary'} onClick={this.onBibleLessonModalCancel}>{Localize.text('Cancel', 'Universal')}</button>
                                        <button type={'button'} className={'btn btn-primary'} onClick={this.onBibleLessonModalSave}>{Localize.text('Save', 'Universal')}</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={'row'}>
                            <div className={'col-12 form-group'}>
                                <div className={'card'}>
                                    <div className={'card-header'}>
                                        <div className={'pull-right'}>
                                            <a href={'#'} onClick={this.openTranslationModal} style={readonly ? {display: 'none'} : {}}><i className={'fa fa-pencil'} /></a>
                                        </div>
                                        <h4 className={'no-bottom-margin'}>{Localize.text('Translation', 'PeopleGroupDetail')}</h4>
                                    </div>
                                    <div className={'card-body'}>
                                        <TwoColumnLayout>
                                            <TwoColumnRow>
                                                <div>{Localize.text('OldTestamentTranslated', 'PeopleGroupDetail')}:</div>
                                                <div style={styles.leftPadding}>{peopleGroup.oldTestamentPercentage || 0}%</div>
                                            </TwoColumnRow>
                                            <TwoColumnRow>
                                                <div>{Localize.text('NewTestamentTranslated', 'PeopleGroupDetail')}:</div>
                                                <div style={styles.leftPadding}>{peopleGroup.newTestamentPercentage || 0}%</div>
                                            </TwoColumnRow>
                                            <TwoColumnRow>
                                                <div>{Localize.text('TranslationInProgress', 'PeopleGroupDetail')}:</div>
                                                <div style={styles.leftPadding}>{yesNo(peopleGroup.translationInProgress)}</div>
                                            </TwoColumnRow>
                                            <TwoColumnRow>
                                                <div>{Localize.text('TranslationOrganization', 'PeopleGroupDetail')}:</div>
                                                <div style={styles.leftPadding}>{translationOrganizationItems}</div>
                                            </TwoColumnRow>
                                            <TwoColumnRow>
                                                <div>{Localize.text('DateTranslationStarted', 'PeopleGroupDetail')}:</div>
                                                <div style={styles.leftPadding}>{peopleGroup.dateTranslationStarted}</div>
                                            </TwoColumnRow>
                                            <TwoColumnRow>
                                                <div>{Localize.text('TargetNTCompleteDate', 'PeopleGroupDetail')}:</div>
                                                <div style={styles.leftPadding}>{peopleGroup.targetNTCompleteDate}</div>
                                            </TwoColumnRow>
                                            <TwoColumnRow>
                                                <div>{Localize.text('DateNTCompleted', 'PeopleGroupDetail')}:</div>
                                                <div style={styles.leftPadding}>{peopleGroup.dateNTCompleted}</div>
                                            </TwoColumnRow>
                                        </TwoColumnLayout>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={'modal'} ref={node => this.translationModalNode = node}>
                            <div className={'modal-dialog modal-lg'}>
                                <div className={'modal-content'}>
                                    <div className={'modal-header'}>
                                        <h5 className="modal-title">{Localize.text('Translation', 'PeopleGroupDetail')}</h5>
                                        <button type="button" className="close" onClick={this.onTranslationModalCancel}>
                                            <span>&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body" style={{maxHeight: windowHeight - 300, overflowY: 'auto'}}>

                                        <div className={'form-group row'}>
                                            <label className={'col-lg-3'}>{Localize.text('TranslationInProgress', 'PeopleGroupDetail') + ':'}</label>
                                            <div className={'col-lg-6'}>
                                                <select className={'form-control form-control-sm'} value={state.translationInProgress} onChange={this.onTranslationInProgressChange}>
                                                    <option value={true}>{Localize.text('Yes', 'Universal')}</option>
                                                    <option value={false}>{Localize.text('No', 'Universal')}</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className={'form-group row'}>
                                            <label className={'col-lg-3'}>{Localize.text('TranslationOrganization', 'PeopleGroupDetail') + ':'}</label>
                                            <div className={'col-lg-6'}>
                                                <select multiple={true} className={'form-control form-control-sm'} value={state.translationOrganization} onChange={this.onOrganizationChange}>
                                                    {
                                                        listManager
                                                            .get(localizedLists, 'Organization')
                                                            .map(d => <option key={d._id} value={d._id}>{d.values.get('name')} ({d.values.get('abbr')})</option>)
                                                    }
                                                </select>
                                            </div>
                                        </div>

                                        <div className={'form-group row'}>
                                            <label className={'col-lg-3'}>{Localize.text('DateTranslationStarted', 'PeopleGroupDetail') + ':'}</label>
                                            <div className={'col-lg-6'}>
                                                <input type={'text'} className={'form-control form-control-sm'} value={state.dateTranslationStarted} onChange={e => this.onInputChange(e, 'dateTranslationStarted')} />
                                            </div>
                                        </div>

                                        <div className={'form-group row'}>
                                            <label className={'col-lg-3'}>{Localize.text('TargetNTCompleteDate', 'PeopleGroupDetail') + ':'}</label>
                                            <div className={'col-lg-6'}>
                                                <input type={'text'} className={'form-control form-control-sm'} value={state.targetNTCompleteDate} onChange={e => this.onInputChange(e, 'targetNTCompleteDate')} />
                                            </div>
                                        </div>

                                        <div className={'form-group row'}>
                                            <label className={'col-lg-3'}>{Localize.text('DateNTCompleted', 'PeopleGroupDetail') + ':'}</label>
                                            <div className={'col-lg-6'}>
                                                <input type={'text'} className={'form-control form-control-sm'} value={state.dateNTCompleted} onChange={e => this.onInputChange(e, 'dateNTCompleted')} />
                                            </div>
                                        </div>

                                    </div>
                                    <div className="modal-footer">
                                        <button type={'button'} className={'btn btn-secondary'} onClick={this.onTranslationModalCancel}>{Localize.text('Cancel', 'Universal')}</button>
                                        <button type={'button'} className={'btn btn-primary'} onClick={this.onTranslationModalSave}>{Localize.text('Save', 'Universal')}</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={'row'}>
                            <div className={'col-12 form-group'}>
                                <div className={'card'}>
                                    <div className={'card-header'}>
                                        <ModalTextarea
                                            readonly={readonly}
                                            value={peopleGroup.otherMaterialsNote}
                                            onSave={this.saveNote}
                                            title={Localize.text('OtherMaterialsCreatedOrTranslated', 'PeopleGroupDetail')} />
                                        <h4 className={'no-bottom-margin'}>{Localize.text('OtherMaterialsCreatedOrTranslated', 'PeopleGroupDetail')}</h4>
                                    </div>
                                    <div style={{whiteSpace: 'pre-line'}} className={'card-body'}>
                                        {peopleGroup.otherMaterialsNote}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}
Materials.propTypes = {
    peopleGroup: PropTypes.object,
    readonly: PropTypes.bool,
    localizedLists: PropTypes.arrayOf(PropTypes.object),
    localizedListItems: PropTypes.instanceOf(Map),
    windowHeight: PropTypes.number,
    save: PropTypes.func,
    modalStatus: PropTypes.string,
    disableNav: PropTypes.func,
    enableNav: PropTypes.func,
    setModalStatus: PropTypes.func,
};

const WrappedMaterials = connect(
    ({ appState }) => ({
        modalStatus: appState.modalStatus,
    }),
    dispatch => ({
        disableNav: () => dispatch(setAppDisableNav(true)),
        enableNav: () => dispatch(setAppDisableNav(false)),
        setModalStatus: status => dispatch(setAppModalStatus(status)),
    }),
)(Materials);

export default WrappedMaterials;
