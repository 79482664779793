import { connect } from 'react-redux';

import Admin from './admin';

export default connect(
    ({ appState }) => ({
        localizedLists: appState.localizedLists,
        user: appState.user,
    })
)(Admin);
