import actions from '../enums/actions';

const getInitialState = () => ({
    trainings: []
});

export default (state = getInitialState(), { type, payload }) => {
    switch(type) {
        case actions.TRAININGS_SET_TRAININGS:
            return {
                ...state,
                trainings: payload.trainings
            };
        default:
            return state;
    }
};
