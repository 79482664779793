import { connect } from 'react-redux';

import Field from './field';
import { setAppFields, setAppLocations } from '../../actions/app-actions';
import { setField, setPeopleGroup, setActive } from '../../actions/field-actions';

export default connect(
    ({ appState, fieldState }) => ({
        active: fieldState.active,
        field: fieldState.field,
        peopleGroup: fieldState.peopleGroup,
        fields: appState.fields,
        locations: appState.locations,
    }),
    dispatch => ({
        setActive: active => dispatch(setActive(active)),
        setField: field => dispatch(setField(field)),
        setPeopleGroup: peopleGroup => dispatch(setPeopleGroup(peopleGroup)),
        setFields: fields => dispatch(setAppFields(fields)),
        setLocations: locations => dispatch(setAppLocations(locations)),
        // enableNav: () => dispatch(setAppDisableNav(false))
    })
)(Field);
