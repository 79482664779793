export default {
    // app actions
    'APP_SET_LOCALE': 'APP_SET_LOCALE',
    'APP_SET_LOCALE_DATA': 'APP_SET_LOCALE_DATA',
    'APP_SET_USER_DATA': 'APP_SET_USER_DATA',
    'APP_SET_CAN_CREATE_UPDATE_TRAININGS': 'APP_SET_CAN_CREATE_UPDATE_TRAININGS',
    'APP_SET_WINDOW_SIZE': 'APP_SET_WINDOW_SIZE',
    'APP_SET_COUNTRIES': 'APP_SET_COUNTRIES',
    'APP_SET_REGIONS': 'APP_SET_REGIONS',
    'APP_SET_LOCATIONS': 'APP_SET_LOCATIONS',
    'APP_SET_FIELDS': 'APP_SET_FIELDS',
    'APP_SET_PEOPLE': 'APP_SET_PEOPLE',
    'APP_SET_LOCALIZED_LISTS': 'APP_SET_LOCALIZED_LISTS',
    'APP_SET_PERSON_GROUP_STATUSES': 'APP_SET_PERSON_GROUP_STATUSES',
    'APP_SET_TRAININGS': 'APP_SET_TRAININGS',
    'APP_SET_DISABLE_NAV': 'APP_SET_DISABLE_NAV',
    'APP_SET_PERSON_TO_FIELD_PEOPLE_GROUP': 'APP_SET_PERSON_TO_FIELD_PEOPLE_GROUP',
    'APP_SET_MODAL_STATUS': 'APP_SET_MODAL_STATUS',
    'APP_SET_HEADER_HEIGHT': 'APP_SET_HEADER_HEIGHT',
    'APP_SET_INITIAL_SERVER_LOAD_DONE': 'APP_SET_INITIAL_SERVER_LOAD_DONE',

    // field actions
    'FIELD_SET_ID': 'FIELD_SET_ID',
    'FIELD_SET_PEOPLE_GROUP': 'FIELD_SET_PEOPLE_GROUP',
    'FIELD_SET_ACTIVE': 'FIELD_SET_ACTIVE',
    'FIELD_SET_FIELD_TO_CONSULTANTS': 'FIELD_SET_FIELD_TO_CONSULTANTS',

    // people group actions
    'PEOPLE_GROUP_SET_PEOPLE_GROUP': 'PEOPLE_GROUP_SET_PEOPLE_GROUP',
    'PEOPLE_GROUP_SET_LOCATIONS': 'PEOPLE_GROUP_SET_LOCATIONS',
    'PEOPLE_GROUP_SET_STATUSES': 'PEOPLE_GROUP_SET_STATUSES',
    'PEOPLE_GROUP_SET_CONSULTANTS': 'PEOPLE_GROUP_SET_CONSULTANTS',

    // trainings actions
    'TRAININGS_SET_TRAININGS': 'TRAININGS_SET_TRAININGS'

};
