import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink, Link } from 'react-router-dom';
import * as appActions from '../../actions/app-actions';
import * as fieldActions from '../../actions/field-actions';
import swal from 'sweetalert';
import useDimensions from 'react-use-dimensions';

const tabBorderColor = '#aaa';

const styles = {
    outerContainer: {
        paddingTop: 10,
        position: 'relative'
    },
    container: {
        height: 32,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'flex-start',
        borderBottomWidth: 1,
        borderBottomColor: tabBorderColor,
        borderBottomStyle: 'solid'
    },
    header: {
        marginTop: 0,
        marginBottom: 0,
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 10,
        paddingRight: 10,
        fontSize: 14,
        lineHeight: '22px',
        fontWeight: 'bold'
    },
    headerLink: {
        color: '#333',
        textDecoration: 'none'
    },
    tabLink: {
        textDecoration: 'none'
    },
    tabLinkIcon: {
        // marginTop: -1
        // fontSize: 13
    },
    logoutBtn: {
        // float: 'right',
        // marginRight: 5,
        // marginTop: -5
        position: 'fixed',
        right: 10,
        top: 5
    },
    profileBtn: {
        position: 'fixed',
        right: 103,
        top: 5
    },
};

const Header = ({ routeParams, user, windowWidth, field, peopleGroup, setActive, setHeaderHeight }) => {
    const [headerRef, { height: headerHeight }] = useDimensions({ liveMeasure: false });

    useEffect(() => {
        if (headerHeight) setHeaderHeight(headerHeight);
    }, [headerHeight, setHeaderHeight]);

    const administrator = user.fmdbAdministrator || user.imtLeadership;

    const { Localize } = window;

    styles.tabLink = {
        ...styles.tabLink,
        minWidth: windowWidth < 1250 ? 0 : 120
    };

    const logout = async function(e) {
        e.preventDefault();
        const confirmed = await swal({
            icon: 'warning',
            text: Localize.text('AreYouSureThatYouWantToLogOut', 'Universal'),
            buttons: [Localize.text('Cancel', 'Universal'), Localize.text('OK', 'Universal')]
        });
        if(confirmed) window.location = '/logout';
    };

    const onFieldClick = e => {
        e.preventDefault();
        setActive('field');
    };

    return (
        <div ref={headerRef} style={styles.outerContainer}>
            <a onClick={logout} href={'#'} style={styles.logoutBtn} className={'btn btn-sm btn-outline-secondary'}><i className={'fa fa-sign-out'} /> {Localize.text('LogOut', 'Header')}</a>
            <Link to={`/personnel/${user._id}`} href={'#'} style={styles.profileBtn} className={'btn btn-sm btn-outline-secondary'} title={Localize.text('UserProfileSettings', 'Universal')}><i className={'fa fa-user'} /></Link>
            <div style={styles.container} className="header-tab-bar">
                {/* t332 This was probably intended to be a home page, but it simply logs out the user now, so I commented it out. */}
                {/*<h1 style={styles.header}>*/}
                {/*    <a href="/" style={styles.headerLink}>{Localize.text('FieldMinistryDB', 'Universal')}</a>*/}
                {/*</h1>*/}
                <NavLink exact={true} to={'/'} activeClassName={'active'} className="header-tab-link" style={styles.tabLink}>
                    <i style={styles.tabLinkIcon} className="fa fa-globe" />{' ' + Localize.text('Regions', 'Header')}
                </NavLink>
                {!field ?
                    <NavLink to={'/field'} activeClassName={'active'} className="header-tab-link" style={styles.tabLink}>
                        <i style={styles.tabLinkIcon} className="fa fa-users" />{' ' + Localize.text('Field', 'Header')}
                    </NavLink>
                    :
                    !peopleGroup ?
                        <NavLink to={`/field/${field}`} activeClassName={'active'} className="header-tab-link" style={styles.tabLink}>
                            <i style={styles.tabLinkIcon} className="fa fa-users" />{' ' + Localize.text('Field', 'Header')}
                        </NavLink>
                        :
                        routeParams.peopleId ?
                            <NavLink to={`/field/${field}/people/${peopleGroup}`} activeClassName={'active'} className="header-tab-link" onClick={onFieldClick} style={{...styles.tabLink, cursor: 'pointer'}}>
                                <i style={styles.tabLinkIcon} className="fa fa-users" />{' ' + Localize.text('Field', 'Header')}
                            </NavLink>
                            :
                            <NavLink to={`/field/${field}/people/${peopleGroup}`} activeClassName={'active'} className="header-tab-link" style={styles.tabLink}>
                                <i style={styles.tabLinkIcon} className="fa fa-users" />{' ' + Localize.text('Field', 'Header')}
                            </NavLink>
                }
                <NavLink to={'/people-group-search'} activeClassName={'active'} className="header-tab-link" style={styles.tabLink}>
                    <i style={styles.tabLinkIcon} className="fa fa-search" />{' ' + Localize.text('PeopleGroup', 'Universal')}
                </NavLink>
                <NavLink to={'/personnel'} activeClassName={'active'} className="header-tab-link" style={styles.tabLink}>
                    <i style={styles.tabLinkIcon} className="fa fa-address-book" />{' ' + Localize.text('Personnel', 'Header')}
                </NavLink>
                <NavLink to={'/training'} activeClassName={'active'} className="header-tab-link" style={styles.tabLink}>
                    <i style={styles.tabLinkIcon} className="fa fa-calendar" />{' ' + Localize.text('Training', 'Header')}
                </NavLink>
                <NavLink to={'/summaries'} activeClassName={'active'} className="header-tab-link" style={styles.tabLink}>
                    <i style={styles.tabLinkIcon} className="fa fa-tasks" />{' ' + Localize.text('Summaries', 'Header')}
                </NavLink>
                <NavLink to={'/files'} activeClassName={'active'} className="header-tab-link" style={styles.tabLink}>
                    <i style={styles.tabLinkIcon} className="fa fa-folder-open" />{' ' + Localize.text('GeneralFiles', 'Header')}
                </NavLink>
                <NavLink to={'/admin'} activeClassName={'active'} className="header-tab-link" style={administrator ? styles.tabLink : {display: 'none'}}>
                    <i style={styles.tabLinkIcon} className="fa fa-cog" />{' ' + Localize.text('Admin', 'Header')}
                </NavLink>
            </div>
        </div>
    );
};
Header.propTypes = {
    routeParams: PropTypes.object,
    field: PropTypes.string,
    peopleGroup: PropTypes.string,
    windowWidth: PropTypes.number,
    user: PropTypes.object,
    setActive: PropTypes.func,
    setHeaderHeight: PropTypes.func,
};

export default connect(
    ({ appState, fieldState }) => ({
        user: appState.user,
        windowWidth: appState.windowWidth,
        field: fieldState.field,
        peopleGroup: fieldState.peopleGroup
    }),
    dispatch => ({
        setActive: active => dispatch(fieldActions.setActive(active)),
        setHeaderHeight: height => dispatch(appActions.setAppHeaderHeight(height)),
    })
)(Header);
