import React from 'react';
import PropTypes from 'prop-types';
import * as _ from 'lodash';
import { Map } from 'immutable';
import swal from 'sweetalert';
import uniq from 'lodash/uniq';
import isEqual from 'lodash/isEqual';

import gqlCallAttributes from '../../../util/gql-call-attributes';
import * as dateUtils from '../../../util/date-utils';
import shareWithServer from '../../../constants/share-with-server';
import { ternary } from '../../../constants';
const { hasElevatedPermissions } = shareWithServer;

class EditTraining extends React.Component {

    constructor(props) {
        super(props);
        const intCol = new Intl.Collator(Localize.locale());
        const { trainings } = props;
        const { trainingId } = props.match.params;
        const training = trainings.find(t => t._id === trainingId) || {};

        const orig = {
            type: training.trainingName || this.getDefaultType(),
            date: training.date || '',
            location: training.location || '',
            instructors: training.instructors ? training.instructors.map(i => i._id) : [],
            attendees: training.attendees ? training.attendees.map(a => a._id) : [],
            note: training.note || '',
            creator: training.creator,
        };

        this.state = {
            ...orig,
            orig,
            view: 'main', // main, instructors, attendees
            people: [...props.people.values()]
                .map(p => ({...p, wholeName: `${p.firstName} ${p.lastName}`}))
                .sort((a, b) => {
                    if(a.firstName === b.firstName) {
                        return intCol.compare(a.lastName, b.lastName);
                    } else {
                        return intCol.compare(a.firstName, b.firstName);
                    }
                }),
            peopleSelectSelected: [],
            filter: '',
            selected: [],
            selectedSelectSelected: [],
        };
        _.bindAll(this, [
            'showAddInstructors',
            'showAddAttendees',
            'onSaveTraining',
            'onCancelTraining',
            'onPeopleChangeDone',
            'onFilterChange',
            'onPeopleChange',
            'onSelectedChange',
            'onAdd',
            'onRemove',
            'onTypeChange',
            'onDateChange',
            'onLocationChange',
            'onNoteChange',
            'getDefaultType',
            'onBack',
            'onDeleteTraining',
            'changed',
        ]);
    }

    componentDidUpdate() {
        const { disableNav, enableNav } = this.props;

        if (this.changed()) {
            disableNav();
        } else {
            enableNav();
        }
    }

    changed() {
        const current = Object
            .keys(this.state.orig)
            .reduce((acc, k) => ({
                ...acc,
                [k]: this.state[k],
            }), {});

        return !isEqual(this.state.orig, current);
    }

    getDefaultType() {
        const { localizedLists } = this.props;
        const list = listManager
            .get(localizedLists, 'Training Names');
        return list[0]._id;
    }

    async onSaveTraining(e) {
        try {
            if(e) e.preventDefault();
            const { trainingId } = this.props.match.params;
            const { state } = this;
            let { date, location, note } = state;
            date = date.trim();
            const valid = await dateUtils.isValidDate(date, true);
            if(!valid) return;
            date = dateUtils.formatDate(date);
            location = location.trim();
            note = note.trim();

            if(trainingId) {
                const dataToSave = {
                    _id: trainingId,
                    trainingName: state.type,
                    date,
                    location,
                    note,
                    instructors: state.instructors,
                    attendees: state.attendees
                };

                const res = await gql.transaction(
                    'mutation',
                    'updateTraining',
                    {
                        _id: trainingId,
                        input: dataToSave
                    },
                    ['_id']
                );
                if(res.errors) throw new Error(res.errors[0].message);

                const { errors, data } = await gql.transaction(
                    'query',
                    'getTraining',
                    { _id: trainingId },
                    gqlCallAttributes.training()
                );
                if(errors) throw new Error(errors[0].message);
                const { getTraining } = data;
                const { trainings } = this.props;
                const idx = trainings.findIndex(t => t._id === trainingId);
                this.props.setTrainings([
                    ...trainings.slice(0, idx),
                    getTraining,
                    ...trainings.slice(idx + 1)
                ]);

            } else {
                const dataToSave = {
                    trainingName: state.type,
                    date,
                    location,
                    note,
                    instructors: state.instructors,
                    attendees: state.attendees,
                    creator: this.props.user._id,
                };

                const res = await gql.transaction(
                    'mutation',
                    'createTraining',
                    {
                        input: dataToSave
                    },
                    ['_id']
                );
                if(res.errors) throw new Error(res.errors[0].message);
                const { _id } = res.data.createTraining;

                const { errors, data } = await gql.transaction(
                    'query',
                    'getTraining',
                    { _id },
                    gqlCallAttributes.training()
                );
                if(errors) throw new Error(errors[0].message);
                const { getTraining: training } = data;
                const { trainings } = this.props;
                this.props.setTrainings(
                    [training, ...trainings]
                );
            }

            this.props.enableNav();
            this.props.history.goBack();
        } catch(err) {
            handleError(err);
        }
    }

    onCancelTraining(e) {
        e.preventDefault();
        this.props.enableNav();
        this.props.history.goBack();
    }

    showAddInstructors(e) {
        e.preventDefault();
        this.setState({
            ...this.state,
            view: 'instructors',
            selected: this.state.instructors
        });
    }

    showAddAttendees(e) {
        e.preventDefault();
        this.setState({
            ...this.state,
            view: 'attendees',
            selected: this.state.attendees
        });
    }

    onPeopleChangeDone(e) {
        e.preventDefault();
        const { view, selected } = this.state;
        const newState = {
            ...this.state,
            view: 'main',
            filter: '',
            selected: [],
            peopleSelectSelected: [],
            selectedSelectSelected: [],
        };
        if(view === 'instructors') {
            newState.instructors = selected;
        } else if(view === 'attendees') {
            newState.attendees = selected;
        }
        this.setState(newState);
    }

    onFilterChange(e) {
        e.preventDefault();
        this.setState({
            ...this.state,
            filter: e.target.value.trim().toLowerCase()
        });
    }

    onPeopleChange(e) {
        const selected = [];
        for(const o of e.target.options) {
            if(o.selected) selected.push(o.value);
        }
        this.setState({
            ...this.state,
            peopleSelectSelected: selected
        });
    }

    onSelectedChange(e) {
        const selected = [];
        for(const o of e.target.options) {
            if(o.selected) selected.push(o.value);
        }
        this.setState({
            ...this.state,
            selectedSelectSelected: selected
        });
    }

    onAdd(e) {
        e.preventDefault();
        const selected = this.state.peopleSelectSelected;
        const { selected: prevSelected } = this.state;
        const newSelected = uniq([...selected, ...prevSelected]);
        this.setState({
            ...this.state,
            selected: newSelected,
            peopleSelectSelected: []
        });
    }

    onRemove(e) {
        e.preventDefault();
        const selected = this.state.selectedSelectSelected;
        const { selected: prevSelected } = this.state;
        const newSelected = prevSelected.filter(_id => !selected.includes(_id));
        this.setState({
            ...this.state,
            selected: newSelected,
            selectedSelectSelected: []
        });
    }

    onTypeChange(e) {
        e.preventDefault();
        const { value } = e.target;
        this.setState({
            ...this.state,
            type: value,
        });
    }

    onDateChange(e) {
        e.preventDefault();
        const { value } = e.target;
        const newDate = value.replace(/[^\d-]/g, '');
        this.setState({
            ...this.state,
            date: newDate,
        });
    }

    onLocationChange(e) {
        e.preventDefault();
        const { value } = e.target;
        this.setState({
            ...this.state,
            location: value,
        });
    }

    onNoteChange(e) {
        e.preventDefault();
        this.setState({
            ...this.state,
            note: e.target.value,
        });
    }

    async onBack(e) {
        e.preventDefault();
        const { history } = this.props;

        if (this.changed()) {
            const confirmed = await swal({
                text: Localize.text('WouldYouLikeToSaveAnyChanges', 'UniversalForms'),
                icon: 'warning',
                buttons: [
                    Localize.text('No', 'Universal'),
                    Localize.text('Yes', 'Universal')
                ]
            });
            if(confirmed) {
                await this.onSaveTraining();
            } else {
                this.props.enableNav();
                history.goBack();
            }
        } else {
            this.props.enableNav();
            history.goBack();
        }
    }

    async onDeleteTraining(e) {
        try {
            e.preventDefault();

            // verify this user is allowed to delete this training.  Must:
            //  - have elevated permissions, or
            //  - be the one who created this training
            const { user } = this.props;
            if (!hasElevatedPermissions(user) && this.state.creator !== user._id) {
                await swal(Localize.text('YourUserAccountDoesNotHaveAuthorizationToDeleteThisTrainingSessionCRLFCRLFPleaseContactImoGlobalPartnersForHelp', 'Training', { CRLF: '\n'}));
                return;
            }

            const confirmed = await swal({
                text: Localize.text('AreYouSureThatYouWantToDeleteThisTraining', 'Training'),
                buttons: [
                    Localize.text('Cancel', 'Universal'),
                    Localize.text('OK', 'Universal')
                ],
                icon: 'warning'
            });
            if(!confirmed) return;
            const { trainings, match: { params } } = this.props;
            const { trainingId } = params;
            const { errors } = await gql.transaction(
                'mutation',
                'deleteTraining',
                { _id: trainingId }
            );
            if(errors) throw new Error(errors[0].message);
            this.props.enableNav();
            this.props.history.push('/training');
            const newTrainings = trainings
                .filter(t => t._id !== trainingId);
            this.props.setTrainings(newTrainings);
        } catch(err) {
            console.error(err);
        }
    }

    render() {

        const { view, people, selected, filter, peopleSelectSelected, selectedSelectSelected, attendees, instructors, date, type, location, note } = this.state;

        const { canCreateUpdateTrainings, match: { params }, localizedLists, people: peopleMap, windowHeight } = this.props;
        const canUpdate = canCreateUpdateTrainings === ternary.TRUE;

        const { trainingId } = params;

        const styles = {
            peopleColumn: {
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                flexWrap: 'nowrap',
                justifyContent: 'flex-start'
            },
            addRemoveButton: {
                width: '100%',
                marginTop: 32
            },
            peopleCard: {
                minHeight: 100
            }
        };

        const joinedInstructors = instructors
            .map(_id => {
                const p = peopleMap.get(_id);
                return `${p.firstName} ${p.lastName}`;
            })
            .join(', ');
        const joinedAttendees = attendees
            .map(_id => {
                const p = peopleMap.get(_id);
                return `${p.firstName} ${p.lastName}`;
            })
            .join(', ');
        const peopleList = people
            .filter(p => p.wholeName.toLowerCase().includes(filter))
            .map(p => {
                return <option value={p._id} key={p._id}>{p.wholeName}</option>;
            });
        const selectedList = selected
            .map(_id => {
                const p = peopleMap.get(_id);
                return <option value={_id} key={_id + 'selected'}>{`${p.firstName} ${p.lastName}`}</option>;
            });

        const trainingTypes = listManager
            .get(localizedLists, 'Training Names');

        if (type && !trainingTypes.some(t => t._id === type)) {
            // we are editing a legacy training type - add it to the list
            const legacyType = listManager
                .get(localizedLists, 'Other Training Names')
                .find(t => t._id === type);
            if (legacyType) {
                trainingTypes.push(legacyType);
            }
        }

        const trainingTypesDropdownItems = trainingTypes.map(d => <option key={d._id} value={d._id}>{d.values.get('name')}</option>);

        return (
            <div className={'container-fluid'} style={{paddingBottom: 15}}>

                {view === 'main' ?
                    <div className={'row'}>
                        <div className={'col-lg-6'}>
                            <div className={'row'}>
                                <div className={'col'}>
                                    <button type={'button'} className={'btn btn-outline-secondary'} onClick={this.onBack}><i className="fa fa-chevron-left" /> {Localize.text('Back', 'Universal')}</button>
                                </div>
                            </div>
                            <div className={'row'}>
                                <div className={'col'}>
                                    {trainingId ?
                                        canUpdate ?
                                            <h1>{Localize.text('EditTrainingSession', 'Training')}</h1>
                                            :
                                            canCreateUpdateTrainings === ternary.UNKNOWN ?
                                                <h1>.</h1>  // hold the h1 space so when canCreateUpdateTrainings is resolved, the screen doesn't jump.
                                                :
                                                <h1>{Localize.text('ViewTrainingSession', 'Training')}</h1>
                                        :
                                        <h1>{Localize.text('NewTrainingSession', 'Training')}</h1>
                                    }
                                </div>
                            </div>

                            <div className={'form-group row'}>
                                <label className={'col-lg-4'}>{Localize.text('Type', 'Universal') + ':'}</label>
                                <div className={'col-lg-8'}>
                                    <select className={'form-control'} value={type} onChange={this.onTypeChange} disabled={!canUpdate}>
                                        {trainingTypesDropdownItems}
                                    </select>
                                </div>
                            </div>

                            <div className={'form-group row'}>
                                <label className={'col-lg-4'}>{Localize.text('Date', 'Universal') + ':'} <small className={'text-muted'}>{`(${Localize.text('FormatMMYYYY', 'Training')})`}</small></label>
                                <div className={'col-lg-8'}>
                                    <input type={'text'} className={'form-control'} placeholder={Localize.text('MMYYYY', 'Training')} value={date} onChange={this.onDateChange} readOnly={!canUpdate} />
                                </div>
                            </div>

                            <div className={'form-group row'}>
                                <label className={'col-lg-4'}>{Localize.text('Location', 'Universal') + ':'}</label>
                                <div className={'col-lg-8'}>
                                    <input type={'text'} className={'form-control'} value={location} onChange={this.onLocationChange} readOnly={!canUpdate} />
                                </div>
                            </div>

                            <div className={'row'}>
                                <div className={'col form-group'}>
                                    <label>{Localize.text('Note', 'Universal') + ':'}</label>
                                    <textarea style={{minHeight: 100, resize: 'y'}} className={'form-control'} value={note} onChange={this.onNoteChange} readOnly={!canUpdate} />
                                </div>
                            </div>

                            <div className={'row'}>
                                <div className={'col-lg-3'}>
                                    <h3>{Localize.text('Instructors', 'Training')}</h3>
                                </div>
                                <div className={'col'}>
                                    <button type={'button'} className={'btn btn-outline-dark btn-sm'} onClick={this.showAddInstructors}  disabled={!canUpdate}><i className={'fa fa-pencil'} /> {Localize.text('AddRemoveInstructors', 'Training')}</button>
                                </div>
                            </div>

                            <div className={'form-group row'}>
                                <div className={'col'}>
                                    <div className={'card'}>
                                        <div className={'card-body'} style={{...styles.peopleCard, minHeight: 50}}>{joinedInstructors}</div>
                                    </div>
                                </div>
                            </div>

                            <div className={'row'}>
                                <div className={'col-lg-3'}>
                                    <h3>{Localize.text('Attendees', 'Training')}</h3>
                                </div>
                                <div className={'col'}>
                                    <button type={'button'} className={'btn btn-outline-dark btn-sm'} onClick={this.showAddAttendees} disabled={!canUpdate}><i className={'fa fa-pencil'} /> {Localize.text('AddRemoveAttendees', 'Training')}</button>
                                </div>
                            </div>

                            <div className={'form-group row'}>
                                <div className={'col'}>
                                    <div className={'card'}>
                                        <div className={'card-body'} style={styles.peopleCard}>{joinedAttendees}</div>
                                    </div>
                                </div>
                            </div>

                            <div className={'form-group row'}>
                                <div className={'col'}>
                                    {canUpdate ?
                                        <>
                                            <button style={{ minWidth: 150, marginRight: 10 }} type={'button'} className={'btn btn-primary'} onClick={this.onSaveTraining}>{Localize.text('Save', 'Universal')}</button>
                                            <button style={{ minWidth: 150, marginRight: 10 }} type={'button'} className={'btn btn-outline-dark'} onClick={this.onCancelTraining}>{Localize.text('Cancel', 'Universal')}</button>
                                            {trainingId ?
                                                <button style={{ minWidth: 150 }} type={'button'} className={'btn btn-danger'} onClick={this.onDeleteTraining}>{Localize.text('DeleteTraining', 'Training')}</button>
                                                :
                                                <div />
                                            }
                                        </>
                                        :
                                        <div className="text-center">
                                            <div>{Localize.text('YourUserAccountDoesNotHaveAuthorizationToEditThisTrainingSession', 'Training')}</div>
                                            <div>{Localize.text('ToEditPleaseContactImoGlobalPartners', 'Training')}</div>
                                        </div>
                                    }
                                </div>
                            </div>

                        </div>
                    </div>
                    :
                    <div className={'row'}>
                        <div className={'col-lg-6'}>
                            <div className={'row'}>
                                <div className={'col'}>
                                    <h3>{view === 'instructors' ? Localize.text('Instructors', 'Training') : Localize.text('Attendees', 'Training')}</h3>
                                </div>
                            </div>
                            <div className={'row'} style={{height: windowHeight - 150}}>

                                <div className={'col-4'} style={{...styles.peopleColumn, display: 'flex'}}>
                                    <div className={'form-group'}>
                                        <label>{Localize.text('Search', 'Universal')}</label>
                                        <input type={'text'} className={'form-control form-control-sm'} onChange={this.onFilterChange} value={filter} />
                                    </div>
                                    <div className={'form-group'} style={{display: 'flex', flexGrow: 1}}>
                                        <select className={'form-control'} multiple={true} style={{flexGrow: 1}} onChange={this.onPeopleChange} value={peopleSelectSelected}>
                                            {peopleList}
                                        </select>
                                    </div>
                                </div>

                                <div className={'col-4'}>
                                    <div className={'row'}>
                                        <div className={'col'}>
                                            <button type={'button'} className={'btn btn-outline-dark btn-sm'} style={{...styles.addRemoveButton, marginTop: 120}} onClick={this.onAdd}>{Localize.text('AddSelectedToList', 'Training')} <i className={'fa fa-chevron-right'} /></button>
                                        </div>
                                    </div>
                                    <div className={'row'}>
                                        <div className={'col'}>
                                            <button type={'button'} className={'btn btn-outline-dark btn-sm'} style={styles.addRemoveButton} onClick={this.onRemove}><i className={'fa fa-chevron-left'} /> {Localize.text('RemoveFromList', 'Training')}</button>
                                        </div>
                                    </div>
                                </div>
                                <div className={'col-4'} style={styles.peopleColumn}>
                                    <div className={'form-group'}>
                                        <h3>{Localize.text('CurrentList', 'Training')}</h3>
                                    </div>
                                    <div className={'form-group'} style={{display: 'flex', flexGrow: 1}}>
                                        <select className={'form-control'} multiple={true} style={{flexGrow: 1}} onChange={this.onSelectedChange} value={selectedSelectSelected}>
                                            {selectedList}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className={'row'}>
                                <div className={'col'}>
                                    <button type={'button'} style={{minWidth: 150}} className={'btn btn-primary'} onClick={this.onPeopleChangeDone}>{Localize.text('Done', 'Universal')}</button>
                                </div>
                            </div>

                        </div>

                    </div>
                }

            </div>
        );
    }

}
EditTraining.propTypes = {
    windowHeight: PropTypes.number,
    trainings: PropTypes.arrayOf(PropTypes.object),
    user: PropTypes.object,
    canCreateUpdateTrainings: PropTypes.string,
    people: PropTypes.instanceOf(Map),
    localizedLists: PropTypes.arrayOf(PropTypes.object),
    history: PropTypes.object,
    match: PropTypes.object,
    setTrainings: PropTypes.func,
    disableNav: PropTypes.func,
    enableNav: PropTypes.func,
};

export default EditTraining;
