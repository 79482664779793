import React, { useState, useEffect, useReducer, useCallback, useRef } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import swal from 'sweetalert';
import parse from 'html-react-parser';
import * as clipboardy from 'clipboardy';
import moment from 'moment';
import request from 'superagent';
import SplitPane from 'react-split-pane';
import useDimensions from 'react-use-dimensions';
import { throttle } from 'lodash';

import Report1 from './reports/report1';
import MultiCheckbox from '../shared/multi-checkbox';
import { loggedOutInternalErrorMessage } from '../../constants';
import shareWithServer from '../../constants/share-with-server';
const {
    reportGeneralStatistics,
    reportChurchGrowthByLoc,
    reportActiveWorker,
    reportAllFieldPersonnel,
    reportActiveFieldPersonnel,
    reportActivePersonnelByNationality,
    reportPeopleGroupInfo,
    reportStatusOfWorksTotals,
    reportTranslationInfoByPortion,
    reportTranslation,
    reportIMTRequestedRolesAndConsultants,
    reportPeopleGroupConsultants,
    reportAllPersonnel,
    comparatorTypeParseInt,
    comparatorTypeMMdashYYYY,
} = shareWithServer;

const styles = {
    dropdownLabel: {
        // lineHeight: '38px',
        lineHeight: '30px',
        backgroundColor: '#fff',
        color: '#333',
        paddingRight: 6,
        marginTop: 10
    },
    splitPaneContainer: {
        display: 'flex',
    },
    splitPane: {
        position: 'relative',
        overflow: 'visible',    // so when we first load the Summaries tab, the reports drop-down is visible below the split bar.
    },
    hideResizer: {
        display: 'none',
    },
    resizerLabel: {
        maxWidth: '250px',
    },
    videoPlayer: {
        maxHeight: '100%',
        maxWidth: '90%',    // this works better than 100% if the user makes the window narrow
        position: 'relative',
        border: '3px solid silver',
        outline: 'none',
    },
    closeVideo: {
        borderRadius: '50%',
        color: 'silver',
        textColor: 'silver',
        borderColor: 'silver',
    },
};

const timezoneOffset = new Date().getTimezoneOffset();

const onCellDblclk = ({ value }) => {
    swal({
        text: value,
    });
};

const reportDetailText = reportId => {
    const replacers = {
        CRLF: '\n',
        B: '<b>',
        BOFF: '</b>',
    };
    switch (reportId) {
        case reportGeneralStatistics:
            return Localize.text('reportDetailsGeneralStatistics', 'Summaries', replacers);
        case reportChurchGrowthByLoc:
            return Localize.text('reportDetailsChurchGrowthByLocation', 'Summaries', replacers);
        case reportAllFieldPersonnel:
            return Localize.text('reportDetailsAllFieldPersonnel', 'Summaries', replacers);
        case reportActiveFieldPersonnel:
            return Localize.text('reportDetailsActiveFieldPersonnel', 'Summaries', replacers);
        case reportActivePersonnelByNationality:
            return Localize.text('reportDetailsActivePersonnelByNationality', 'Summaries', replacers);
        case reportPeopleGroupInfo:
            return Localize.text('reportDetailsPeopleGroupInfo', 'Summaries', replacers);
        case reportStatusOfWorksTotals:
            return Localize.text('reportDetailsStatusOfWorksTotals', 'Summaries', replacers);
        case reportTranslationInfoByPortion:
            return Localize.text('reportDetailsTranslationDetails', 'Summaries', replacers);
        case reportTranslation:
            return Localize.text('reportDetailsTranslation', 'Summaries', replacers);
        case reportIMTRequestedRolesAndConsultants:
            return Localize.text('reportDetailsIMTRequestedRolesAndConsultants', 'Summaries', replacers);
        case reportPeopleGroupConsultants:
            return Localize.text('reportDetailsPeopleGroupConsultants', 'Summaries', replacers);
        case reportAllPersonnel:
            return Localize.text('reportDetailsAllPersonnel', 'Summaries', replacers);
        default:
            return Localize.text('UnknownReportCRLFNoDetailsAreAvailableForThisReport', 'Summaries', replacers);
    }
};

// Have to make this a function so we have access to Localize.
const fullReportList = () => ({
    [reportGeneralStatistics]: Localize.text('GeneralStatistics', 'Summaries'),
    [reportChurchGrowthByLoc]: Localize.text('ChurchGrowthByLocation', 'Summaries'),
    // [reportActiveWorker]: Localize.text('ActiveWorker', 'Summaries'),    // t281 replaced this with reportAllFieldPersonnel and reportActiveFieldPersonnel
    [reportAllFieldPersonnel]: Localize.text('AllFieldPersonnel', 'Summaries'),
    [reportActiveFieldPersonnel]: Localize.text('ActiveFieldPersonnel', 'Summaries'),
    [reportActivePersonnelByNationality]: Localize.text('ActivePersonnelByNationality', 'Summaries'),
    [reportPeopleGroupInfo]: Localize.text('PeopleGroupInfo', 'Summaries'),
    [reportStatusOfWorksTotals]: Localize.text('StatusOfWorksTotals', 'Summaries'),
    [reportTranslationInfoByPortion]: Localize.text('TranslationDetails', 'Summaries'),
    [reportTranslation]: Localize.text('Translation', 'Summaries'),
    [reportIMTRequestedRolesAndConsultants]: Localize.text('IMTRequestedRolesAndConsultants', 'Summaries'),
    [reportPeopleGroupConsultants]: Localize.text('PeopleGroupConsultants', 'Summaries'),
    [reportAllPersonnel]: Localize.text('AllPersonnel', 'Summaries'),
});

const getReportList = user => {
    const reportList = fullReportList();

    // filter the report list here
    if (!userIsTopSecurity(user)) {
        delete reportList.reportIMTRequestedRolesAndConsultants;
        delete reportList.reportAllPersonnel;
    }

    return reportList;
};

const getReportDefaultState = () => ({
    reportToShow: '',
    reportData: {},
    reportToShowName: '',
});

const actions = {
    setReport: 'setReport',
    setReportData: 'setReportData',
    setColumnVisibility: 'setColumnVisibility',
    clearReportData: 'clearReportData',
};

const reducer = (state, action) => {
    switch (action.type) {
        case actions.setReport:
            return { ...state,
                reportToShow: action.value,
                reportToShowName: fullReportList()[action.value],
            };
        case actions.setReportData:
            return { ...state,
                reportData: { ...action.value }
            };
        case actions.setColumnVisibility: {
            const { columnDefs } = state.reportData;

            const newColumnDefs = [...columnDefs];
            for (const colVis of action.value) {
                const idx = newColumnDefs.findIndex(c => c.headerName === colVis.text);
                newColumnDefs[idx] = { ...newColumnDefs[idx], hide: !colVis.show };
            }

            return { ...state,
                reportData: { ...state.reportData, columnDefs: newColumnDefs }
            };
        }
        case actions.clearReportData:
            return { ...state, reportData: {} };
        default:
            throw new Error('Invalid Summaries action');
    }
};

const userIsTopSecurity = user => user.fmdbAdministrator || user.imtLeadership || user.internationalConsultantCoordinator || user.securityTeamMember;
const allFieldsId = 'allfields';
const getFieldList = (user, fields) => {
    if (userIsTopSecurity(user)) {
        return [
            { _id: allFieldsId, name: Localize.text('AllFields', 'Summaries') },
            ...fields
                .map(f => ({ _id: f._id, name: f.name }))
                .sort((a, b) => {
                    const aVal = a.name;
                    const bVal = b.name;
                    return aVal > bVal ? 1 : aVal < bVal ? -1 : 0;
                })
        ];
    }

    return fields
        .filter(f => {
            return f.IMTRequestedFieldRoles
                .findIndex(r => r.people.includes(user._id)) >= 0;
        })
        .map(f => ({_id: f._id, name: f.name}))
        .sort((a, b) => {
            const aVal = a.name;
            const bVal = b.name;
            return aVal > bVal ? 1 : aVal < bVal ? -1 : 0;
        });
};

const Summaries = ({ user, fields, headerHeight, initialServerLoadDone, windowHeight }) => {
    const [reportState, reportDispatch] = useReducer(reducer, null, getReportDefaultState);
    const [fieldList, setFieldList] = useState(getFieldList(user, fields));
    const [userHasNoPermissions, setUserHasNoPermissions] = useState(fieldList.length === 0);
    const [selectedFieldId, setSelectedFieldId] = useState('');
    const [selectedFieldName, setSelectedFieldName] = useState('');
    const [hideFieldDropdown, setHideFieldDropdown] = useState(true);
    const [reportList, setReportList] = useState(getReportList(user));
    const [visibleRows, setVisibleRows] = useState(0);
    const [showRowCount, setShowRowCount] = useState(false);
    const gridApi = useRef(null);
    const [showVideo, setShowVideo] = useState(false);
    const [videoUrl, setVideoUrl] = useState('');
    const [videoHeight, setVideoHeight] = useState(0);
    const [splitSize, setSplitSize] = useState(0);  // includes buttonRowHeight
    const [resizerStyle, setResizerStyle] = useState(styles.hideResizer);
    const [videoSplitSize, setVideoSplitSize] = useState(650);  // the size of the split when displaying video. We keep this separate from splitSize so we can hide and show the video again at the same size.
    const [showSplitBarBubble, setShowSplitBarBubble] = useState(true);
    const videoRef = useRef(null);
    const [buttonRowRef, { height: buttonRowHeight }] = useDimensions({ liveMeasure: false });
    const [videoCloseRef, { height: videoCloseHeight }] = useDimensions({ liveMeasure: false });


    const { reportToShow, reportToShowName, reportData: { rowData } } = reportState;

    // initially select the first field
    useEffect(() => {
        if (fieldList.length) {
            setSelectedFieldId(fieldList[0]._id);
        }
    }, [fieldList]);

    // initially select the first report
    useEffect(() => {
        const reportKeys = Object.keys(reportList);

        if (reportKeys.length) {
            reportDispatch({ type: actions.setReport, value: reportKeys[0] });
        }
    }, [reportList]);

    // Someday the list of reports may depend on the user's permissions, so reset it if the user changes
    //  (I don't think the user can change, except if one user logs out and another user logs in, and
    //      we load the site initially from cache.  That shouldn't matter here, though, because we don't
    //      display anything on this tab until initialServerLoadDone.)
    useEffect(() => {
        setReportList(getReportList(user));
    }, [user]);

    // get the list of fields this user is allowed to run reports on
    useEffect(() => {
        setFieldList(getFieldList(user, fields));
    }, [user, fields]);

    // if the user cannot request reports for any field (fieldList.length is 0), they are only allowed to see the General Statistics report.
    useEffect(() => {
        const noPermissions = fieldList.length === 0;

        if (noPermissions) {
            reportDispatch({ type: actions.setReport, value: reportGeneralStatistics });
        }

        setUserHasNoPermissions(noPermissions);
    }, [fieldList]);

    // If the user has no permissions then automatically run the General Statistics report when the user switches to this tab.
    useEffect(() => {
        if (userHasNoPermissions && reportToShow && onClickGo && initialServerLoadDone) {
            onClickGo();
        }
    }, [userHasNoPermissions, reportToShow, onClickGo, initialServerLoadDone]);

    // If the selected field Id changes, change the selected field name.
    useEffect(() => {
        const fieldItem = fieldList.find(f => f._id === selectedFieldId);
        const fieldName = fieldItem ? fieldItem.name : '';
        setSelectedFieldName(fieldName);
    }, [fieldList, selectedFieldId]);

    // If the user selects a different field or report, stop showing the results of the previous report.
    useEffect(() => {
        reportDispatch({ type: actions.clearReportData });
    }, [selectedFieldId, reportToShow]);

    // if a report is selected which always run on all fields, or if this user can see at most 1 field, don't show the field drop-down.
    useEffect(() => {
        setHideFieldDropdown([reportGeneralStatistics, reportIMTRequestedRolesAndConsultants, reportAllPersonnel].includes(reportToShow) || fieldList.length <= 1);
    }, [reportToShow, fieldList.length]);

    // handle loading video and pausing it if user hides it
    useEffect(() => {
        const tutorialErrorHandler = err => {
            console.log('error from /api/reports-tutorial');
            console.error(err);

            if (err.status === 403) {
                handleError(new Error(loggedOutInternalErrorMessage));
                return;
            }

            swal('Unable to access tutorial video.')
                .then(() => setShowVideo(false));
        };

        if (showVideo && !videoUrl) {
            request.get('/api/reports-tutorial')
                .then(res => {
                    if (res.text) {
                        setVideoUrl(res.text);
                    } else {
                        tutorialErrorHandler(`no res.text:\n${JSON.stringify(res, null, '  ')}`);
                    }
                })
                .catch(err => tutorialErrorHandler(err));
        } else if (!showVideo && videoUrl) {
            // make sure the video is paused
            videoRef.current.pause();
        }
    }, [showVideo, videoUrl]);

    // set split size to buttonRowHeight when initializing the Summaries tab, and when we hide the video
    useEffect(() => {
        if (buttonRowHeight && !showVideo) {
            setSplitSize(buttonRowHeight);
        }
    }, [buttonRowHeight, showVideo]);

    // set the height of the video when the videoSplitSize changes
    useEffect(() => {
        setVideoHeight(videoSplitSize - buttonRowHeight);
    }, [videoSplitSize, buttonRowHeight]);

    // if we are showing the video and the split size changes, set the videoSplitSize
    useEffect(() => {
        if (showVideo) {
            setVideoSplitSize(splitSize);
        }
    }, [splitSize, showVideo]);

    // if the user resizes the window, adjust the splitSize as necessary
    useEffect(() => {
        if (maxSplitSize < splitSize) {
            setSplitSize(maxSplitSize);
        }
    }, [windowHeight, splitSize, maxSplitSize]);

    // only show the split bar when we are showing video
    useEffect(() => {
        setResizerStyle(showVideo ? {} : styles.hideResizer);
    }, [showVideo]);

    // When user runs a report or closes report (by selecting a different report or field),
    //  update the row count and show it there is an active report.
    useEffect(() => {
        if (rowData) setVisibleRows(rowData.length);
        setShowRowCount(!!rowData);
    }, [rowData]);

    const reportHasData = !!rowData && rowData.length > 0;

    const setGridApi = newApi => gridApi.current = newApi;

    const showReport = () => {
        const { columnDefs } = reportState.reportData;

        if (!reportHasData) {
            return <div />;
        } else {
            return <Report1
                reportTitle={''}
                columnDefs={columnDefs}
                rowData={rowData}
                aboveGridHeight={splitSize + headerHeight + 10 + 4}
                setGridApi={setGridApi}
                onFilterChanged={onFilterChanged}
            />;
        }
    };

    const columnNames = (reportState && reportState.reportData && reportState.reportData.columnDefs) ?
        reportState.reportData.columnDefs.map(c => ({ text: c.headerName, show: !c.hide })) : [];

    const selectField = e => {
        e.preventDefault();
        const id = e.target.id;
        setSelectedFieldId(id);
    };

    const selectReport = e => {
        e.preventDefault();
        const id = e.target.id;
        reportDispatch({ type: actions.setReport, value: id });
    };

    const onClickGo = useCallback(e => {
        if (e) e.preventDefault();

        const getReport = async ({ field }) => {
            swal({
                text: Localize.text('LoadingReport', 'Summaries'),
                buttons: false,
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then();

            const fieldId = selectedFieldId === allFieldsId ? '' : field;

            const { errors, data } = await gql.transaction(
                'query',
                'getReport',
                { locale, timezoneOffset, fieldId, reportToRun: reportToShow },
                ['columnDefs', 'rowData'],
            );
            if (errors) {
                // Currently, the error we throw is caught in the runtime somewhere instead of by our window.handleError.
                // Perhaps we can fix that using react-error-boundary, and if so, we might get rid of this swal.
                await swal({
                    text: Localize.text('ErrorRunningReport', 'Summaries'),
                    icon: 'error',
                    button: Localize.text('OK', 'Universal'),
                });
                throw new Error(errors[0].message);
            }

            const { getReport: reportResult } = data;
            const { columnDefs, rowData: newRowData } = reportResult;

            const rowDataArray = JSON.parse(newRowData);

            if (rowDataArray.length === 0) {
                await swal({
                    text: Localize.text('ReportReturnedNoRows', 'Summaries'),
                    icon: 'info',
                    button: Localize.text('OK', 'Universal'),
                });
            } else {
                swal.close();
            }

            return { columnDefs: JSON.parse(columnDefs), rowData: rowDataArray };
        };

        const setReportData = async (reportFn, params) => {
            const { columnDefs, rowData: newRowData } = await reportFn(params);

            const blankNumTest = cellValue => cellValue === '' || isNaN(cellValue); // have to check isNaN for numericColumnPostfix columns
            const blankStringTest = cellValue => cellValue === '';

            const getDate = val => {
                const getYear = mmDashYYYY => parseInt(mmDashYYYY.slice(3), 10);
                const getMonth = mmDashYYYY => mmDashYYYY.slice(0, 2);
                const convertDateToUTC = localDate => new Date(localDate.getUTCFullYear(), localDate.getUTCMonth(), localDate.getUTCDate(), localDate.getUTCHours(), localDate.getUTCMinutes(), localDate.getUTCSeconds());

                const localDate = val ? new Date(`${getYear(val)}-${getMonth(val)}-01`) : new Date('1700-01-01');   // if !val, I just picked a date earlier than I expect any of our data to be.
                return convertDateToUTC(localDate);
            };

            const dateIsGreaterThan = (cvDate, filterValue) => {
                return cvDate.getFullYear() > filterValue.getFullYear() || (cvDate.getFullYear() === filterValue.getFullYear() && cvDate.getMonth() >= filterValue.getMonth());
            };

            const dateIsLessThan = (cvDate, filterValue) => {
                return cvDate.getFullYear() < filterValue.getFullYear() || (cvDate.getFullYear() === filterValue.getFullYear() && cvDate.getMonth() <= filterValue.getMonth());
            };

            const getFilterOptions = colDef => {
                switch (colDef.filter) {
                    case 'agNumberColumnFilter':
                        return [
                            {
                                displayKey: 'equals',
                                displayName: Localize.text('Equals', 'Summaries'),
                                test: function(filterValue, cellValue) {
                                    return parseInt(filterValue, 10) === parseInt(cellValue, 10);
                                }
                            },
                            {
                                displayKey: 'notEqual',
                                displayName: Localize.text('NotEqual', 'Summaries'),
                                test: function(filterValue, cellValue) {
                                    return parseInt(filterValue, 10) !== parseInt(cellValue, 10);
                                }
                            },
                            'greaterThan',
                            'lessThan',
                            'inRange',
                            {
                                displayKey: 'blank',
                                displayName: Localize.text('Blank', 'Summaries'),
                                hideFilterInput: true,
                                test: function (filterValue, cellValue) {
                                    return blankNumTest(cellValue);
                                },
                            },
                            {
                                displayKey: 'notblank',
                                displayName: Localize.text('NotBlank', 'Summaries'),
                                hideFilterInput: true,
                                test: function (filterValue, cellValue) {
                                    return !blankNumTest(cellValue);
                                },
                            },
                        ];
                    case 'agDateColumnFilter':
                        return [
                            {
                                displayKey: 'equals',
                                displayName: Localize.text('Equals', 'Summaries'),
                                test: function(filterValue, cellValue) {
                                    const cvDate = getDate(cellValue);
                                    return filterValue.getFullYear() === cvDate.getFullYear() && filterValue.getMonth() === cvDate.getMonth();
                                }
                            },
                            {
                                displayKey: 'notEqual',
                                displayName: Localize.text('NotEqual', 'Summaries'),
                                test: function(filterValue, cellValue) {
                                    const cvDate = getDate(cellValue);
                                    return filterValue.getFullYear() !== cvDate.getFullYear() || filterValue.getMonth() !== cvDate.getMonth();
                                }
                            },
                            // I think our users will expect "Less than" and "Greater than" to function like "Less than or equal" and "Greater than or equal", so I coded it that way.
                            {
                                displayKey: 'greaterThan',
                                displayName: Localize.text('GreaterThan', 'Summaries'),
                                test: function(filterValue, cellValue) {
                                    const cvDate = getDate(cellValue);
                                    return dateIsGreaterThan(cvDate, filterValue);
                                }
                            },
                            {
                                displayKey: 'lessThan',
                                displayName: Localize.text('LessThan', 'Summaries'),
                                test: function(filterValue, cellValue) {
                                    const cvDate = getDate(cellValue);
                                    return dateIsLessThan(cvDate, filterValue);
                                }
                            },
                            {
                                displayKey: 'inRange',
                                displayName: Localize.text('InRange', 'Summaries'),
                                test: function(filterValue, cellValue) {
                                    // hokey, but I don't know a better way to get the "to" date.  See https://stackoverflow.com/questions/63567485/ag-grid-date-column-inrange-filter-how-do-i-access-the-to-date-in-the-test-f
                                    const { appliedModel } = gridApi.current.getFilterInstance(colDef.field);

                                    // If user has specified more than one condition, appliedModel changes forms.
                                    //  So, I just reformat the single-condition filter into the form of a multi-condition fitler,
                                    //  and handle everything as a multi-condition filter.
                                    const standardizedModel = appliedModel.condition1 ? { ...appliedModel } : { condition1: { ...appliedModel }, operator: 'AND' };

                                    const conditions = Object
                                        .keys(standardizedModel)
                                        .filter(k => k.startsWith('condition'))
                                        .map(k => standardizedModel[k])
                                        .filter(c => c.type === 'inRange');

                                    const evaluateCondition = c => {
                                        const fromDate = new Date(c.dateFrom);
                                        const toDate = new Date(c.dateTo);
                                        const cvDate = getDate(cellValue);
                                        return dateIsGreaterThan(cvDate, fromDate) && dateIsLessThan(cvDate, toDate);
                                    };

                                    return standardizedModel.operator === 'AND' ? conditions.every(c => evaluateCondition(c)) : conditions.some(c => evaluateCondition(c));
                                }
                            },
                            {
                                displayKey: 'blank',
                                displayName: Localize.text('Blank', 'Summaries'),
                                hideFilterInput: true,
                                test: function (filterValue, cellValue) {
                                    return blankStringTest(cellValue);
                                },
                            },
                            {
                                displayKey: 'notblank',
                                displayName: Localize.text('NotBlank', 'Summaries'),
                                hideFilterInput: true,
                                test: function (filterValue, cellValue) {
                                    return !blankStringTest(cellValue);
                                },
                            },
                        ];
                    default:    // agTextColumnFilter
                        return [
                            // 'empty',
                            'equals',
                            'notEqual',
                            'contains',
                            'notContains',
                            'startsWith',
                            'endsWith',
                            {
                                displayKey: 'textGreaterThan',
                                displayName: Localize.text('GreaterThan', 'Summaries'),
                                test: function (filterValue, cellValue) {
                                    return filterValue.localeCompare(cellValue) < 0;
                                },
                            },
                            {
                                displayKey: 'textLessThan',
                                displayName: Localize.text('LessThan', 'Summaries'),
                                test: function (filterValue, cellValue) {
                                    return filterValue.localeCompare(cellValue) > 0;
                                },
                            },
                            {
                                displayKey: 'blank',
                                displayName: Localize.text('Blank', 'Summaries'),
                                hideFilterInput: true,
                                test: function (filterValue, cellValue) {
                                    return blankStringTest(cellValue);
                                },
                            },
                            {
                                displayKey: 'notblank',
                                displayName: Localize.text('NotBlank', 'Summaries'),
                                hideFilterInput: true,
                                test: function (filterValue, cellValue) {
                                    return !blankStringTest(cellValue);
                                },
                            },
                        ];
                }
            };

            const finalColumnDefs = columnDefs.map(c => {
                const def = {
                    ...c,
                    onCellDoubleClicked: onCellDblclk,
                    filterParams: {
                        // I think we only need the reset button.  See https://www.ag-grid.com/javascript-grid-filter-number/#number-filter-parameters
                        // buttons: ['apply', 'clear', 'reset', 'cancel'],
                        buttons: ['reset'],
                        inRangeInclusive: true,
                        filterOptions: getFilterOptions(c),
                    },
                };

                if (c.numericColumnPostfix) {
                    def.filterValueGetter = getterParams => `${parseInt(getterParams.data[def.field], 10)}`;
                    delete def.numericColumnPostfix;
                }

                if (c.comparator === comparatorTypeParseInt) {
                    def.comparator = (a, b) => {
                        const getVal = val => parseInt(val, 10) ? parseInt(val, 10) : 0;
                        const aVal = getVal(a);
                        const bVal = getVal(b);
                        return aVal - bVal;
                    };
                } else if (c.comparator === comparatorTypeMMdashYYYY) {
                    def.comparator = (a, b) => {
                        const aVal = getDate(a);
                        const bVal = getDate(b);
                        return aVal.getTime() - bVal.getTime();
                    };
                }

                return def;
            });

            console.log('rowcount', newRowData.length);
            reportDispatch({
                type: actions.setReportData,
                value: { columnDefs: finalColumnDefs, rowData: newRowData },
            });
        };

        const getData = async () => {
            await setReportData(getReport, { field: selectedFieldId });
        };

        const locale = Localize.locale();
        getData().then();
    }, [reportToShow, selectedFieldId]);

    const onFilterChanged = () => setVisibleRows(gridApi.current.getDisplayedRowCount());

    const getReportText = () => {
        return gridApi.current.getDataAsCsv({ columnSeparator: '\t' });
    };

    const showSecurityMsg = isCopy => {
        return new Promise(resolve => {
            let irritateSeconds = 10;

            const msg = async () => {
                const mainText = Localize.text('ThisReportMayContainSensitiveInformationCROnceYouAreFinishedWithTheDataOfThisReportPleaseDeleteTheFileCRWeDoNotWantThisDataSittingInFilesOnHardDrivesOrThumbDrivesForSecurityReasons', 'Summaries', { CR: '\n' });
                const fullText = mainText + '\n\n' + Localize.text('YouMayCloseThisWindowInSECSeconds', 'Summaries', { SEC: irritateSeconds });
                swal({
                    title: Localize.text('PLEASEREAD', 'Summaries'),
                    text: fullText,
                    icon: 'warning',
                    buttons: false,
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                }).then();

                if (irritateSeconds <= 0) {
                    clearInterval(msgInterval);
                    const result = await swal({
                        title: Localize.text('PLEASEREAD', 'Summaries'),
                        text: mainText,
                        icon: 'warning',
                        buttons: [Localize.text('Cancel', 'Universal'), isCopy ? Localize.text('Copy', 'Universal') : Localize.text('Save', 'Universal')],
                    });
                    resolve(result);
                }

                irritateSeconds--;
            };

            msg().then();
            const msgInterval = setInterval(() => {
                msg().then();
            }, 1000);
        });
    };

    const onClickCopy = async e => {
        e.preventDefault();

        const ok = await showSecurityMsg(true);
        if (ok) {
            await clipboardy.write(getReportText());
        }
    };

    const onClickSave = async e => {
        e.preventDefault();

        const ok = await showSecurityMsg(false);
        if (ok) {
            // t269 we decided to use the default csv for saving, and tab-delimited for copying.  That way,
            //  pasting into Excel works nicely, and double-clicking the saved .csv also works nicely.
            gridApi.current.exportDataAsCsv({ fileName: `${selectedFieldName} - ${reportState.reportToShowName} - ${ moment().format('YYYY-MM-DD')}.csv` });    // Excel uses .txt for tab-delimited files
        }
    };

    const onClickReportDetails = async e => {
        e.preventDefault();

        // Use the first line of the full text as the title.
        const fullText = reportDetailText(reportToShow);
        const textLines = fullText.split('\n');
        const title = textLines.length ? textLines[0] : ''; // textLines.length should always be true
        const detailText = textLines.slice(1).join('<br />');

        // We want to show some text in bold, so this is adapted from https://sweetalert.js.org/guides/#advanced-examples
        const wrapperElement = document.createElement('div');
        ReactDOM.render(
            <div className="text-left">{parse(detailText)}</div>
            , wrapperElement);
        const el = wrapperElement.firstChild;
        const content = {
            element: el,
            attributes: {
                value: detailText,
            },
        };

        await swal({
            title,
            content,
            icon: 'info',
            button: Localize.text('OK', 'Universal'),
            className: 'swal-lg',
        });
    };

    const onClickTutorial = async e => {
        e.preventDefault();

        setSplitSize(videoSplitSize);
        setShowVideo(!showVideo);
    };

    const onClickCloseBubble = e => {
        e.preventDefault();
        setShowSplitBarBubble(false);
    };

    const doSetSplitSize = throttle(newSize => {
        setSplitSize(newSize);
    }, 100);

    const allUsersButtons =
        <>
            <li>
                <button className="btn btn-sm btn-outline-danger ml-3" disabled={!reportHasData}
                        onClick={onClickCopy}>{Localize.text('CopyReport', 'Summaries')}</button>
            </li>

            <li>
                <button className="btn btn-sm btn-outline-danger ml-3" disabled={!reportHasData}
                        onClick={onClickSave}>{Localize.text('SaveReport', 'Summaries')}</button>
            </li>
        </>;

    // The first time we render <video>, we must have the correct videoUrl.
    //  It seems if it is set later, the value is set when you look at it in dev tools, but it never loads the video.  So
    //  <video> and its children have the correct values for all attributes in dev tools, but the video never loads.
    const tutorialVideo = videoUrl ?
        <div className="d-flex justify-content-center" style={{ height: videoHeight }} hidden={!showVideo}>
            <div hidden={!showSplitBarBubble} className="d-flex align-items-end mr-3">
                <div className="speech-bubble-ds" style={styles.resizerLabel}>
                    <p><strong>Section Split Bar</strong><i className="fa fa-times pull-right" onClick={onClickCloseBubble} /></p>
                    <p>Drag the bar up or down to change video and report sizes.</p>
                    <div className="speech-bubble-ds__arrow" />
                </div>
            </div>
            <video ref={videoRef} style={styles.videoPlayer} controls={true} controlsList={['nodownload']} autoPlay={true} onContextMenu={e => e.preventDefault()}>
                <source src={videoUrl} type="video/mp4" />
                <p>{Localize.text('YourBrowserDoesNotSupportHTML5Video', 'Summaries')}</p>
            </video>
            <div className="ml-2">
                <button ref={videoCloseRef} className="btn btn-outline-light" style={styles.closeVideo} onClick={onClickTutorial}><i className="fa fa-times" /></button>
            </div>
        </div>
        : null;

    const maxSplitSize = windowHeight - headerHeight - 20;

    const devShowButtonsImmediately = false; // set this to true to not have to wait for the db to load, to test the Tutorial button.
// return <h1 className="text-center">Under Development</h1>;
    return (
        // Check for initialServerLoadDone b/c when it finishes, it resets our field and report selections.
        //  So, just don't show the real stuff until initialServerLoadDone.
        initialServerLoadDone || devShowButtonsImmediately ?
        <div style={styles.splitPaneContainer}>
            <SplitPane split="horizontal" style={styles.splitPane} onChange={doSetSplitSize} size={splitSize} minSize={buttonRowHeight + videoCloseHeight} maxSize={maxSplitSize} resizerStyle={resizerStyle}>
                <div className="w-100">
                    <div ref={buttonRowRef} style={{ paddingBottom: userHasNoPermissions ? 0 : 10 }}>  {/* Don't pad the bottom for normal users since we show no buttons for them. */}
                        <div className={'container-fluid'}>
                            <ul hidden={userHasNoPermissions} className="nav nav-pills d-flex">

                                <li><a style={{ ...styles.dropdownLabel }}>{Localize.text('Report', 'Summaries')} </a></li>
                                <li className="nav-item-dropdown" style={styles.dropdown}>
                                    <a href="#" className="btn btn-sm btn-outline-dark dropdown-toggle"
                                       ref={node => $(node).attr('data-toggle', 'dropdown')}>
                                        {`${reportToShowName || Localize.text('SelectOne', 'Universal')} `}<span className="caret" />
                                    </a>
                                    <div className="dropdown-menu">
                                        {
                                            Object.entries(reportList)
                                                .map(([id, name]) => <div key={id} id={id} onClick={selectReport} className={'dropdown-item'}>{name}</div>)
                                        }
                                    </div>
                                </li>

                                <li hidden={hideFieldDropdown} className="ml-3"><a style={styles.dropdownLabel}>{Localize.text('Field', 'Summaries')}: </a></li>
                                <li hidden={hideFieldDropdown} className="nav-item-dropdown" style={styles.dropdown}>
                                    <a href="#" className="btn btn-sm btn-outline-dark dropdown-toggle"
                                       ref={node => $(node).attr('data-toggle', 'dropdown')}>
                                        {`${selectedFieldName || Localize.text('SelectOne', 'Universal')} `}<span className="caret" />
                                    </a>
                                    <div className="dropdown-menu">
                                        {
                                            fieldList
                                                .map(f => <div className={'dropdown-item'} key={f._id} id={f._id}
                                                               onClick={selectField}>{f.name}</div>)
                                        }
                                    </div>
                                </li>

                                <li>
                                    <button className="btn btn-sm btn-outline-primary ml-3" onClick={onClickGo}
                                            disabled={!initialServerLoadDone}>{Localize.text('Go', 'Summaries')}</button>
                                </li>

                                <li>
                                    <div className="ml-3">
                                        <MultiCheckbox
                                            buttonTitle={Localize.text('ShowHideColumns', 'Summaries')}
                                            editorTitle={Localize.text('ShowHideColumns', 'Summaries')}
                                            hideAllButton={true}
                                            showAllButton={true}
                                            filterable={true}
                                            items={columnNames}
                                            disabled={!reportHasData}
                                            onDone={newItemStates => reportDispatch({
                                                type: actions.setColumnVisibility,
                                                value: newItemStates
                                            })}
                                        />
                                    </div>
                                </li>

                                {/* we center this by using both ml-auto and mr-auto */}
                                <li className="ml-auto mr-auto">
                                    <div hidden={!showRowCount} className="d-flex justify-content-center align-items-center h-100">
                                        <label htmlFor="rowcount" className="ml-3 mr-2 my-0">{Localize.text('Rows', 'Summaries')}</label>
                                        <span>{visibleRows}</span>
                                    </div>
                                </li>

                                {/* we right-justify the following buttons by mr-auto above. */}
                                {allUsersButtons}

                                <li>
                                    <button className="btn btn-sm btn-outline-info ml-3" disabled={!reportHasData}
                                            onClick={onClickReportDetails}>{Localize.text('ReportDetails', 'Summaries')}</button>
                                </li>

                                <li>
                                    <button className="btn btn-sm btn-outline-info ml-3"
                                            onClick={onClickTutorial}>{Localize.text('Tutorial', 'Summaries')}</button>
                                </li>
                            </ul>
                            {/* Now we don't want to show the copy/save buttons to normal users.
                            <ul hidden={!userHasNoPermissions} className="nav nav-pills">
                                {allUsersButtons}
                            </ul>
                            */}
                        </div>
                    </div>
                    {tutorialVideo}
                </div>
                {showReport()}
            </SplitPane>
        </div> : <h1 className="text-center mt-5">{Localize.text('FieldMinistryDBIsLoading', 'Summaries')}</h1>
    );
};
Summaries.propTypes = {
    user: PropTypes.object,
    fields: PropTypes.arrayOf(PropTypes.object),
    headerHeight: PropTypes.number,
    initialServerLoadDone: PropTypes.bool,
    windowHeight: PropTypes.number,
};

const SummariesContainer = connect(
    ({ appState }) => ({
        user: appState.user,
        fields: appState.fields,
        headerHeight: appState.headerHeight,
        initialServerLoadDone: appState.initialServerLoadDone,
        windowHeight: appState.windowHeight,
    })
)(Summaries);

export default SummariesContainer;
