import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import bindAll from 'lodash/bindAll';
import FileType from '../../../../types/file';
import FileBrowser from '../../../shared/file-browser';
import gqlAttributes from '../../../../util/gql-call-attributes';

class Files extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            disabled: true,
            items: [],
        };
        bindAll(this, [
            'onChange',
        ]);
    }

    async componentDidMount() {
        const { peopleGroup } = this.props;
        const { errors, data } = await gql.transaction(
            'query',
            'getFiles',
            {
                peopleGroup: peopleGroup._id,
            },
            gqlAttributes.file()
        );
        if(errors) throw errors[0];
        const files = data.getFiles
            .map(f => new FileType(f));
        const topLevel = [], other = [];
        for(const file of files) {
            if(!file.folder) topLevel.push(file);
            else other.push(file);
        }
        const children = [], grandchildren = [];
        for(const file of other) {
            if(topLevel.find(f => f._id === file.folder)) children.push(file);
            else grandchildren.push(file);
        }
        for(const file of grandchildren) {
            file.depth = 2;
            const parentIdx = children.findIndex(f => f._id === file.folder);
            children[parentIdx].children.push(file);
        }
        for(const file of children) {
            file.depth = 1;
            const parentIdx = topLevel.findIndex(f => f._id === file.folder);
            topLevel[parentIdx].children.push(file);
        }
        this.setState({
            ...this.state,
            disabled: false,
            items: topLevel,
        });
    }

    onChange(items) {
        this.setState({
            ...this.state,
            items,
        });
    }

    render() {

        const { peopleGroup } = this.props;
        const { items, disabled } = this.state;

        return (
            <div>
                <h1>{Localize.text('EntitynameFiles', 'FilesForm', { Entityname: peopleGroup.name})}</h1>
                <div>
                    <FileBrowser items={items} disabled={disabled} onChange={this.onChange} aboutPeopleGroup={peopleGroup._id} />
                </div>
            </div>
        );
    }

}
Files.propTypes = {
    peopleGroup: PropTypes.object,
};

export default connect()(Files);
