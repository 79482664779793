import * as _ from 'lodash';

class Localizer {

    constructor(data) {
        const { locale } = data;
        this._locale = locale;
        this._intlData = {...data};
    }

    // text(key, context) {
    //     const intlData = this._intlData;
    //     if(intlData[key] && intlData[key][context]) {
    //         return intlData[key][context].val;
    //     } else {
    //         return key;
    //     }
    // }

    text(key, context, replacers = {}) {
        const { _intlData } = this;
        if (_.isString(key) && _.isString(context) && _.has(_intlData, [ key, context ])) {
            return Object
                .keys(replacers)
                // str.replace only replaces the first occurance, so it is unacceptable.  Use the split/join idiom instead.
                .reduce((str, key1) => str.split(`{{${key1}}}`).join(replacers[key1]), _intlData[key][context].val);
        } else {
            return key;
        }
}

    locale() {
        return this._locale;
    }

}

export default Localizer;
