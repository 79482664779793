import React from 'react';
import PropTypes from 'prop-types';
import { bindAll, omit } from 'lodash';
import swal from 'sweetalert';
import isEqual from 'lodash/isEqual';

const emptyCountry = {
    name: '',
    publicationName: '',
    hidden: false,
};

class EditCountry extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            ...emptyCountry,
            disabled: false,
            orig: { ...emptyCountry },
        };
        bindAll(this, [
            'onChange',
            'onSubmit',
        ]);
    }

    async componentWillMount() {
        try {
            const { countryId } = this.props.match.params;
            let country;
            if(countryId) {
                const [ res1 ] = await Promise.all([
                    await gql.transaction(
                        'query',
                        'getCountry',
                        {
                            _id: countryId
                        },
                        ['_id', 'name', 'publicationName', 'hidden']
                    )
                ]);
                if(res1.errors) throw new Error(res1.errors[0].message);
                country = {
                    ...res1.data.getCountry,
                };
            } else {
                country = { ...emptyCountry };
            }
            this.setState({
                ...this.state,
                ...country,
                orig: { ...country },
            });

        } catch(err) {
            handleError(err);
        }
    }

    componentDidUpdate() {
        const { disableNav, enableNav } = this.props;

        const current = Object
            .keys(emptyCountry)
            .reduce((acc, k) => ({
                ...acc,
                [k]: this.state[k],
            }), {});

        if (isEqual(this.state.orig, current)) {
            enableNav();
        } else {
            disableNav();
        }
    }

    onChange(e, key) {
        e.preventDefault();
        const { value } = e.target;
        this.setState({
            ...this.state,
            [key]: key !== 'hidden' ? value : value === 'true' ? true : false
        });
    }

    async onSubmit(e) {
        e.preventDefault();
        const newCountry = omit(this.state, ['disabled', 'orig']);
        console.log('newCountry', newCountry);

        const name = newCountry.name.trim();

        if(!name) {
            swal(Localize.text('Oops', 'Universal'), Localize.text('YouMustEnterAName', 'UniversalForms'), 'warning');
            return;
        }

        const publicationName = newCountry.publicationName.trim();

        try {
            this.setState({
                ...this.state,
                disabled: true
            });

            // t363 don't allow a new country to be created if it has the same name as an existing country
            let idToUpdate = this.state._id;
            let done = false;

            if (!idToUpdate) {
                const { errors, data } = await gql.transaction(
                    'query',
                    'getCountries',
                    {},
                    [
                        '_id',
                        'name',
                        'publicationName',
                        'hidden',
                    ]
                );

                if (errors) {
                    this.setState({
                        ...this.state,
                        disabled: false
                    });
                    throw errors[0];
                }

                const foundCountry = data.getCountries.find(c => c.name.toLowerCase() === name.toLowerCase());

                if (foundCountry) {
                    if (foundCountry.publicationName === publicationName && foundCountry.hidden === newCountry.hidden) {
                        done = true;
                    } else {
                        console.log('found', foundCountry.publicationName, foundCountry.hidden)

                        const confirmed = await swal({
                            icon: 'warning',
                            title: Localize.text('CountryAlreadyExists', 'CountryForm'),
                            text: Localize.text('ThereIsAlreadyACountryNamedCOUNTRYNAMEItHasPublicationNamePUBLICATIONNAMEAndHiddenISHIDDENCRLFDoYouWantToUpdateIt', 'CountryForm', {
                                COUNTRYNAME: foundCountry.name,
                                PUBLICATIONNAME: foundCountry.publicationName,
                                ISHIDDEN: foundCountry.hidden,
                                CRLF: '\n',
                            }),
                            buttons: [Localize.text('Cancel', 'Universal'), Localize.text('YesUpdateCOUNTRYNAME', 'CountryForm', { COUNTRYNAME: name })],
                        });

                        if (confirmed) {
                            idToUpdate = foundCountry._id;
                        } else {
                            this.setState({
                                ...this.state,
                                disabled: false
                            });

                            return;
                        }
                    }
                }
            }

            if (!done) {
                const { errors } = idToUpdate ?
                    await gql.transaction(
                        'mutation',
                        'updateCountry',
                        {
                            _id: idToUpdate,
                            input: {
                                name,
                                publicationName,
                                hidden: newCountry.hidden
                            }
                        },
                        ['_id']
                    )
                    : await gql.transaction(
                        'mutation',
                        'createCountry',
                        {
                            input: {
                                name,
                                publicationName,
                                hidden: newCountry.hidden
                            }
                        },
                        ['_id']
                    );
                if(errors) {
                    this.setState({
                        ...this.state,
                        disabled: false
                    });
                    throw errors[0];
                }
                // t159 not needed
                // const countryId = data.createCountry._id;
                //
                // const { allFields } = this.state;
                //
                // const resArr = await Promise.all(
                //     fields.map(f => {
                //
                //         const fieldObject = allFields
                //             .find(ff => ff._id === f);
                //
                //         return gql.transaction(
                //             'mutation',
                //             'updateField',
                //             {
                //                 _id: f,
                //                 input: {
                //                     countries: fieldObject.countries
                //                         .map(c => c._id)
                //                         .concat([countryId])
                //                 },
                //             },
                //             ['_id']
                //         );
                //     })
                // );
                //
                // for(const res of resArr) {
                //     if(res.errors) {
                //         this.setState({
                //             ...this.state,
                //             disabled: false
                //         });
                //         throw res.errors[0];
                //     }
                // }
            }

            this.props.enableNav();
            this.props.updateRegions();
            this.props.updateCountries();
            routerHistory.push('/');
        } catch(err) {
            handleError(err);
        }
    }

    // onFieldAdd(_id) {
    //     const { fields } = this.state;
    //     this.setState({
    //         ...this.state,
    //         fields: [...fields, _id]
    //     });
    // }
    //
    // async onFieldDelete(idToDelete) {
    //     try {
    //         const { fields } = this.state;
    //         const confirmed = await swal({
    //             icon: 'warning',
    //             title: Localize.text('RemoveField', 'RegionForm'),
    //             text: Localize.text('AreYouSureThatYouWantToRemoveThisField', 'RegionForm'),
    //             buttons: [Localize.text('Cancel', 'Universal'), Localize.text('YesRemoveField', 'RegionForm')]
    //         });
    //         if(!confirmed) return;
    //         this.setState({
    //             ...this.state,
    //             fields: fields.filter(_id => _id !== idToDelete)
    //         });
    //     } catch(err) {
    //         handleError(err);
    //     }
    // }

    render() {
        const { disabled, name, publicationName, fields, hidden, allFields } = this.state;
        const { user, match: { params } } = this.props;

        return (
            <div className={'container-fluid'}>
                {user.fmdbAdministrator ?
                    <div>

                        <div className={'row border-bottom'}>
                            <h1 className={'col-12'}>{params.countryId ? Localize.text('EditCountry', 'CountryForm') : Localize.text('CreateNewCountry', 'CountryForm')}</h1>
                        </div>

                        <div className={'row'}>
                            <div className={'col-sm-9 col-md-7 col-lg-5'}>
                                <div className={'form-group'}>
                                    <label>{Localize.text('CountryName', 'CountryForm')}</label>
                                    <input type={'text'} className={'form-control'} value={name} onChange={e => this.onChange(e, 'name')} />
                                </div>
                            </div>
                        </div>

                        <div className={'row'}>
                            <div className={'col-sm-9 col-md-7 col-lg-5'}>
                                <div className={'form-group'}>
                                    <label>{Localize.text('PublicationName', 'Universal')}</label>
                                    <input type={'text'} className={'form-control'} value={publicationName} onChange={e => this.onChange(e, 'publicationName')} />
                                </div>
                            </div>
                        </div>

                        <div className={'row'}>
                            <div className={'col-sm-9 col-md-7 col-lg-5'}>
                                <div className={'form-group'}>
                                    <label>{Localize.text('HiddenQuestion', 'Universal')}</label>
                                    <select className={'form-control'} value={hidden} onChange={e => this.onChange(e, 'hidden')}>
                                        <option value={'true'}>{Localize.text('Yes', 'Universal')}</option>
                                        <option value={'false'}>{Localize.text('No', 'Universal')}</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        {/* t159 don't change field info when creating a country.  We will automatically compute
                            this data from the Locations table.
                        <div className={'row'}>
                            <div className={'col-sm-9 col-md-7 col-lg-5'}>
                                <MultiSelect title={Localize.text('Fields', 'RegionForm')} addTitle={Localize.text('AddField', 'RegionForm')} filterable={false} items={allFields} selected={fields} onAdd={this.onFieldAdd} onDelete={this.onFieldDelete} />
                            </div>
                        </div>
                        */}

                        <div className={'row'}>
                            <div className={'col-12'}>
                                <div className={'form-group'}>
                                    <button type={'button'} className={'btn btn-primary'} onClick={this.onSubmit} disabled={disabled}>{Localize.text('SaveCountry', 'CountryForm')}</button>
                                </div>
                            </div>
                        </div>

                    </div>
                    :
                    <div></div>
                }
            </div>
        );
    }

}
EditCountry.propTypes = {
    user: PropTypes.object,
    match: PropTypes.object,
    updateRegions: PropTypes.func,
    updateCountries: PropTypes.func,
    disableNav: PropTypes.func,
    enableNav: PropTypes.func,
};

export default EditCountry;
