import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import bindAll from 'lodash/bindAll';
import swal from 'sweetalert';
import { Map, Set } from 'immutable';
import * as _ from 'lodash';
import isEqual from 'lodash/isEqual';

import { checkPassword } from '../../../util/password-utils';
import gqlCallAttributes from '../../../util/gql-call-attributes';
import shareWithServer from '../../../constants/share-with-server';
import MultiCheckbox from '../../shared/multi-checkbox';
const { itinerant } = shareWithServer;

const emptyPerson = {
    _id: '',
    firstName: '',
    lastName: '',
    nationality: '',
    sendingEntity: '',
    serviceStatus: '',
    memberStatus: '',
    organization: '',
    activeField: '',
    previousFields: [],
    activePeopleGroup: '',
    emails: '',
    phoneNumbers: '',
    note: '',
    internationalConsultantCoordinator: false,
    securityTeamMember: false,
    imtLeadership: false,
    fmdbAdministrator: false,
    username: '',
    password: '',
    passwordRepeat: '',
    level: 0,
};

class EditPerson extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.defaultData();
        bindAll(this, [
            'defaultData',
            'onFirstNameChange',
            'onLastNameChange',
            'onNationalityChange',
            'onSendingEntityChange',
            'onActiveFieldChange',
            'onActivePeopleGroupChange',
            'onCancel',
            'onSave',
            'onDelete',
            'onInputChange',
            'onBooleanInputChange',
            'onShowLoginCredentials',
            'onRemoveLoginCredentials',
            'onCancelLoginCredentials',
            'setEditing',
            'onBack',
            'changed',
        ]);
    }

    setEditing(e, editing = false) {
        e.preventDefault();
        this.setState({
            ...this.state,
            editing,
        });

        // enabling/disabling appropriately in componentDidUpdate causes an infinite loop.
        //  so, just disable when we start editing, and enable when we stop editing.
        if (editing) {
            this.props.disableNav();
        } else {
            this.props.enableNav();
        }
    }

    componentWillReceiveProps(newProps) {
        const { personId } = this.props.match.params;
        const { personId: newPersonId } = newProps.match.params;
        if(personId !== newPersonId) {
            this.setState(this.defaultData());
            this.getData(newPersonId);
        }
    }

    async componentDidMount() {
        const { personId } = this.props.match.params;
        this.getData(personId);
    }

    // This causes an infinite loop for some reason.
    // componentDidUpdate(prevProps, prevState) {
    //     const { disableNav, enableNav } = this.props;
    //
    //     if (this.changed()) {
    //         disableNav();
    //     } else {
    //         enableNav();
    //     }
    // }

    changed() {
        const current = Object
            .keys(emptyPerson)
            .reduce((acc, k) => ({
                ...acc,
                [k]: this.state[k],
            }), {});

        return !isEqual(this.state.user, current);
    }

    async getData(personId = '') {
        try {
            if(personId) {

                swal({
                    text: Localize.text('LoadingUserData', 'PersonDetail'),
                    buttons: false,
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                });

                await new Promise(resolve => setTimeout(resolve, 501)); // change all 500ms timeouts to track down t223

                const { errors, data } = await gql.transaction(
                    'query',
                    'getPerson',
                    { _id: personId },
                    [
                        '_id',
                        'firstName',
                        'lastName',
                        'nationality',
                        'sendingEntity',
                        'serviceStatus',
                        'memberStatus',
                        'organization',
                        'activeField',
                        'previousFields',
                        'activePeopleGroup',
                        'emails',
                        'phoneNumbers',
                        'note',
                        'fmdbAdministrator',
                        'securityTeamMember',
                        'internationalConsultantCoordinator',
                        'imtLeadership',
                        'username',
                        'level',
                    ]
                );
                if(errors) throw new Error(`${errors[0].message}\n_id = ${personId}`);
                const { getPerson: person } = data;
                const preppedPerson = {
                    ...person,
                    emails: person.emails.join(', '),
                    phoneNumbers: person.phoneNumbers.join(', '),
                };

                const res = await gql.transaction(
                    'query',
                    'getPersonGroupStatuses',
                    { personId },
                    [
                        '_id',
                        'peopleGroup',
                        'person',
                        'primaryRole',
                        'primaryRoleDescription',
                        'secondaryRole',
                        'secondaryRoleDescription',
                        'claLevel',
                        'startDate',
                        'endDate',
                        'itinerantStartDate',
                        'itinerantEndDate',
                        'serviceStatus',
                        'location',
                    ]
                );
                if(res.errors) throw res.errors[0];
                const { getPersonGroupStatuses: personGroupStatuses } = res.data;

                const res1 = await gql.transaction(
                    'query',
                    'getPeopleGroupConsultants',
                    { personId },
                    [
                        '_id',
                        'peopleGroup',
                        'person',
                        'consultantType',
                        'startDate',
                        'endDate',
                    ]
                );
                if(res1.errors) throw res1.errors[0];
                const { getPeopleGroupConsultants: peopleGroupConsultants } = res1.data;

                const peopleGroupsToGet = personGroupStatuses.length + peopleGroupConsultants.length ? _
                    .chain([...personGroupStatuses, ...peopleGroupConsultants])
                    .filter(s => s.peopleGroup)
                    .map(s => s.peopleGroup)
                    .uniq()
                    .value()
                    : [];

                const getPeopleGroups = async () => {
                    if (peopleGroupsToGet.length === 0) return [];

                    const { errors: errors1, data: data1 } = await gql.transaction(
                        'query',
                        'getPeopleGroups',
                        {
                            ids: peopleGroupsToGet,
                        },
                        ['_id', 'name']
                    );
                    if(errors1) throw new Error(errors1[0].message);

                    return data1.getPeopleGroups;
                };
                const peopleGroups = await getPeopleGroups();

                let peopleGroupToField = Map();
                if(this.props.locations.length === 0) {
                    gql.transaction(
                        'query',
                        'getLocations',
                        {},
                        ['_id', 'name', 'peopleGroup { _id, name }', 'country { _id, name }', 'field', 'level']
                    ).then(({ errors: errors2, data: data2 }) => {
                        if(errors2) throw errors2[0];
                        this.props.setLocations(data2.getLocations);
                        for(const peopleGroup of peopleGroupsToGet) {
                            const location = data2.getLocations.find(l => l.peopleGroup && l.peopleGroup._id === peopleGroup);
                            peopleGroupToField = peopleGroupToField.set(peopleGroup, location.field);
                        }
                        this.setState({
                            ...this.state,
                            peopleGroupToField,
                        });
                    });
                } else {
                    for(const peopleGroup of peopleGroupsToGet) {
                        const { locations } = this.props;
                        const location = locations.find(l => l.peopleGroup && l.peopleGroup._id === peopleGroup);
                        if(location) peopleGroupToField = peopleGroupToField.set(peopleGroup, location.field);
                    }
                }

                setTimeout(() => {
                    this.setState({
                        ...this.state,
                        ...preppedPerson,
                        user: { ...preppedPerson, password: '', passwordRepeat: '' },
                        statuses: personGroupStatuses,
                        peopleGroupConsultants,
                        peopleGroups,
                        peopleGroupToField,
                    });
                    swal.close();
                }, 0);

            }
        } catch(err) {
            swal.close();
            handleError(err);
        }
    }

    defaultData() {

        const { personId } = this.props.match.params;

        return {
            editing: !personId ? true : false,
            ..._.omit(emptyPerson, ['_id']),    // when I created emptyPerson, there was no _id here.  Not sure why not, but omitting it to be safe.
            showLoginCredentials: false,
            user: {},
            statuses: [],
            peopleGroupConsultants: [],
            peopleGroups: [],
            peopleGroupToField: Map(),
        };
    }

    onInputChange(e, key) {
        e.preventDefault();
        const { value } = e.target;
        const inputType = e.target.getAttribute('type');
        this.setState({
            ...this.state,
            [key]: inputType === 'number' ? Number(value) : value,
        });
    }

    onBooleanInputChange(e, key) {
        e.preventDefault();
        const { value } = e.target;
        this.setState({
            ...this.state,
            [key]: value === 'true' ? true : false,
        });
    }

    onFirstNameChange(e) {
        e.preventDefault();
        this.setState({
            firstName: e.target.value,
        });
    }

    onLastNameChange(e) {
        e.preventDefault();
        this.setState({
            lastName: e.target.value,
        });
    }

    onNationalityChange(e) {
        e.preventDefault();
        this.setState({
            nationality: e.target.value,
        });
    }

    onSendingEntityChange(e) {
        e.preventDefault();
        this.setState({
            sendingEntity: e.target.value,
        });
    }

    onActiveFieldChange(e) {
        e.preventDefault();
        this.setState({
            activeField: e.target.value,
        });
    }

    onActivePeopleGroupChange(e) {
        e.preventDefault();
        this.setState({
            activePeopleGroup: e.target.value,
        });
    }

    onCancel(e) {
        e.preventDefault();
        this.props.enableNav();
        this.props.history.push('/personnel');
    }

    onShowLoginCredentials(e) {
        e.preventDefault();
        this.setState({
            ...this.state,
            editing: true,
            showLoginCredentials: true,
        });
    }

    async onRemoveLoginCredentials(e) {
        try {
            e.preventDefault();
            const confirmed = await swal({
                title: Localize.text('AreYouSure', 'Universal'),
                text: Localize.text('ThisUserWillNoLongerBeAbleToLogInAreYouSureThatYouWantToRemoveTheirUserCredentials', 'Contacts'),
                icon: 'warning',
                buttons: [
                    Localize.text('Cancel', 'Universal'),
                    Localize.text('OK', 'Universal'),
                ],
            });
            if(confirmed) {
                this.setState({
                    ...this.state,
                    showLoginCredentials: false,
                    username: '',
                    password: '',
                    passwordRepeat: '',
                });

                // t161 enable Admins to remove login credentials
                if (!this.state.editing) {
                    this.setEditing(e, true);
                }

                await swal({
                    title: Localize.text('AlmostDone', 'PeopleGroupDetail'),
                    text: Localize.text('ThisPersonSLoginCredentialsWillBeDeletedWhenYouClickTheSaveButtonAtTheBottomOfTheEditPersonWindow', 'PeopleGroupDetail'),
                    icon: 'warning',
                    button: Localize.text('OK', 'Universal'),
                });
            }
        } catch(err) {
            handleError(err);
        }
    }

    onCancelLoginCredentials(e) {
        e.preventDefault();
        const { username = '' } = this.state.user;
        this.setState({
            ...this.state,
            showLoginCredentials: false,
            username,
            password: '',
            passwordRepeat: '',
        });
    }

    async onSave(e, goBack = false) {
        try {
            if(e) e.preventDefault();
            const { personId } = this.props.match.params;
            const { state } = this;
            let { user, firstName, lastName } = state;
            firstName = firstName.trim();
            lastName = lastName.trim();
            if(!firstName) {
                await swal({
                    title: Localize.text('EmptyField', 'UniversalForms'),
                    text: Localize.text('YouMustEnterAFirstName', 'UniversalForms'),
                    icon: 'warning',
                });
                return;
            }
            if(!lastName) {
                await swal({
                    title: Localize.text('EmptyField', 'UniversalForms'),
                    text: Localize.text('YouMustEnterALastName', 'UniversalForms'),
                    icon: 'warning',
                });
                return;
            }

            if(!personId) {
                const fullName = firstName + ' ' + lastName;
                const people = [...this.props.people.values()];
                const found = people.some(p => {
                    return fullName === p.firstName + ' ' + p.lastName;
                });
                if(found) {
                    await swal({
                        title: Localize.text('PersonAlreadyExists', 'PersonDetail'),
                        text: Localize.text('ThisPersonSFullNameAlreadyExistsInTheDatabasePleaseAddAMiddleInitialOrSomethingToMakeThePersonSWholeNameUnique', 'PersonDetail'),
                        icon: 'warning',
                    });
                    return;
                }
            }

            let newUser = {
                firstName: firstName.slice(0, 1).toUpperCase() + firstName.slice(1),
                lastName: lastName.slice(0, 1).toUpperCase() + lastName.slice(1),
                nationality: state.nationality,
                sendingEntity: state.sendingEntity,
                serviceStatus: state.serviceStatus,
                memberStatus: state.memberStatus,
                organization: state.organization,
                activeField: state.activeField,
                previousFields: state.previousFields,
                activePeopleGroup: state.activePeopleGroup,
                emails: state.emails
                    .trim()
                    .split(/[,\s]+/g)
                    .map(s => s.trim().toLowerCase())
                    .filter(s => !!s),
                phoneNumbers: state.phoneNumbers
                    .trim()
                    .split(/[,\s]+/g)
                    .map(s => s.trim())
                    .filter(s => !!s),
                note: state.note.trim(),
            };
            if(this.props.user._id === personId || this.props.user.fmdbAdministrator || this.props.user.imtLeadership) {
                if(this.props.user.fmdbAdministrator || this.props.user.imtLeadership) {
                    newUser = {
                        ...newUser,
                        fmdbAdministrator: state.fmdbAdministrator,
                        securityTeamMember: state.securityTeamMember,
                        internationalConsultantCoordinator: state.internationalConsultantCoordinator,
                        imtLeadership: state.imtLeadership,
                    };
                }
                const username = state.username.trim();
                if(state.showLoginCredentials) {
                    const { password, passwordRepeat } = this.state;

                    if(!username) {
                        await swal({
                            title: Localize.text('EmptyField', 'UniversalForms'),
                            text: Localize.text('YouMustEnterAUsername', 'Contacts'),
                            icon: 'warning',
                        });
                        return;
                    }
                    if(!password) {
                        await swal({
                            title: Localize.text('EmptyField', 'UniversalForms'),
                            text: Localize.text('YouMustEnterAPassword', 'Contacts'),
                            icon: 'warning',
                        });
                        return;
                    }
                    if(password !== passwordRepeat) {
                        await swal({
                            title: Localize.text('Oops', 'Universal'),
                            text: Localize.text('PasswordAndRepeatedPasswordMustMatch', 'Contacts'),
                            icon: 'warning',
                        });
                        return;
                    }
                    if(!checkPassword(password)) {
                        await swal({
                            title: Localize.text('Oops', 'Universal'),
                            text: Localize.text('YourPasswordIsInvalidItMustBeAtLeastTenCharactersLongAndIncludeAtLeastOneUpperCaseCharacterAtLeastOneLowerCaseCharacterAndAtLeastOneNumber', 'Login'),
                            icon: 'warning',
                        });
                        return;
                    }
                    newUser = {
                        ...newUser,
                        username,
                        password,
                    };
                } else if(state.user.username && !username) {
                    newUser = {
                        ...newUser,
                        username: '',
                        password: '',
                    };
                }
            }
            // t136 If the user we are saving has a username, make sure that username exists in his emails list.
            //  This is necessary because if he forgets his password later and tries to change it, our procedure
            //  for handling that will look for his username in the emails list of all users, to find who he is.
            if (newUser.username && !newUser.emails.includes(newUser.username)) {
                newUser.emails.push(newUser.username);
            }
            // t136 Make sure no one uses anyone else's email addresses.
            const emailInUse = this.props.people.find(p => (personId !== p._id) && _.intersection(p.emails, newUser.emails).length);
            if (emailInUse) {
                await swal({
                    title: Localize.text('InvalidEmail', 'UniversalForms'),
                    text: `${Localize.text('SomeoneHasAlreadyUsedYourLoginEmailOrAnEmailYouHaveInYourList', 'PersonDetail')}\n\n${emailInUse.firstName} ${emailInUse.lastName}`,
                    icon: 'warning',
                });
                return;
            }
            if(personId) {
                console.log('Update user!', newUser);
                const { errors } = await gql.transaction(
                    'mutation',
                    'updatePerson',
                    {
                        _id: personId,
                        input: newUser,
                    },
                    ['_id', 'firstName', 'lastName']
                );
                if(errors) throw new Error(errors[0].message);
                const { errors: errors1, data } = await gql.transaction(
                    'query',
                    'getPerson',
                    { _id: personId },
                    gqlCallAttributes.people()
                );
                if(errors1) throw new Error(`${errors1[0].message}\n_id = ${personId}`);
                const { getPerson: person } = data;
                this.props.updatePerson(person, this.props.people);
            } else {
                console.log('Save new user!', newUser);
                const { errors, data } = await gql.transaction(
                    'mutation',
                    'createPerson',
                    {
                        input: newUser,
                    },
                    [ '_id', 'firstName', 'lastName' ]
                );
                if(errors) throw new Error(errors[0].message);
                const { _id } = data.createPerson;
                // console.log('Created!', person);
                const { errors: errors1, data: data1 } = await gql.transaction(
                    'query',
                    'getPerson',
                    { _id },
                    gqlCallAttributes.people()
                );
                if(errors1) throw new Error(`${errors1[0].message}\n_id = ${_id}`);
                const { getPerson: person } = data1;
                this.props.addPerson(person, this.props.people);
            }

            this.props.enableNav();

            if(goBack) {
                this.props.history.goBack();
            } else {
                routerHistory.push('/personnel');
            }
        } catch(err) {
            handleError(err);
        }
    }

    async onBack(e) {
        e.preventDefault();
        const { history } = this.props;

        if (this.changed()) {
            const confirmed = await swal({
                text: Localize.text('WouldYouLikeToSaveAnyChanges', 'UniversalForms'),
                icon: 'warning',
                buttons: [
                    Localize.text('No', 'Universal'),
                    Localize.text('Yes', 'Universal'),
                ],
            });
            if(confirmed) {
                this.onSave(null, true);
            } else {
                this.props.enableNav();
                history.goBack();
            }
        } else {
            this.props.enableNav();
            history.goBack();
        }
    }

    async onDelete(e, canDelete = false) {
        try {
            e.preventDefault();
            const { firstName, lastName } = this.state;
            if(!canDelete) {
                await swal({
                    title: Localize.text('Oops', 'Universal'),
                    text: Localize.text('YouCannotDeleteAPersonWhoIsAttachedToTrainingsAndPeopleGroups', 'PersonDetail'),
                });
                return;
            }
            const { personId: _id } = this.props.match.params;
            const confirmed = await swal({
                icon: 'warning',
                text: Localize.text('AreYouSureThatYouWantToDeletePerson', 'PersonDetail', {person: (firstName + ' ' + lastName).trim()}),
                buttons: [
                    Localize.text('Cancel', 'Universal'),
                    Localize.text('OK', 'Universal'),
                ],
            });
            if(!confirmed) return;
            const { errors, data } = await gql.transaction(
                'mutation',
                'deletePerson',
                { _id, locale: Localize.locale() }
            );
            if(errors) throw new Error(errors[0].message);
            const result = JSON.parse(data.deletePerson);
            if (Array.isArray(result)) {
                const msg = `${Localize.text('ThisPersonCannotBeDeletedWhileHeSheIsReferencedInTheDatabase', 'Contacts')}\n\n${result.join('\n')}${this.props.user.fmdbAdministrator ? '' : Localize.text('ContactAFieldMinistriesDatabaseAdministratorToResolveThisIssue', 'Contacts')}`;
                await swal({
                    icon: 'error',
                    title: Localize.text('CannotDelete', 'Universal'),
                    text: msg,
                });
                return;
            }

            this.props.enableNav();
            const { people, deletePerson } = this.props;
            deletePerson(_id, people);
            routerHistory.push('/personnel');
        } catch(err) {
            handleError(err);
        }
    }

    render() {

        const { state } = this;
        const { editing, firstName, lastName, nationality, sendingEntity, activeField, activePeopleGroup, statuses, peopleGroupConsultants, peopleGroups, peopleGroupToField, level } = state;
        const { user, match, localizedLists, trainings, fields, allPeopleGroups } = this.props;
        const { personId } = match.params;

        const locale = Localize.locale();
        const intCol = new Intl.Collator(locale);

        const sendingEntitiesList = listManager
            .get(localizedLists, 'Sending Entities')
            .map(d => ({ _id: d._id, name: d.values.get('name')}));

        const listItems = window.listManager.getItems(localizedLists);

        const peopleGroupsMap = peopleGroups
            .reduce((map, p) => {
                return map.set(p._id, p);
            }, Map());

        const trainingsInstructed = [];
        const trainingsAttended = [];

        for(const training of trainings) {
            if(training.instructors.find(i => i._id === personId)) {
                trainingsInstructed.push(training);
            } else if(training.attendees.find(i => i._id === personId)) {
                trainingsAttended.push(training);
            }
        }

        const consultantTypesList = listManager
            .get(localizedLists, 'Consultant Type');

        const consultancyItems = consultantTypesList
            .filter(c => peopleGroupConsultants.find(cc => cc.consultantType === c._id ))
            .map(c => {

                let used = Set();

                return (
                    <div key={c._id} className={'col-lg-3'}>
                        <h4>{c.values.get('name')} {Localize.text('Consultant', 'Universal')}</h4>
                        <div>
                            <ul>
                                {peopleGroupConsultants
                                    .filter(cc => cc.consultantType === c._id)
                                    .filter(cc => {
                                        if(used.has(cc.peopleGroup)) return false;
                                        used = used.add(cc.peopleGroup);
                                        return true;
                                    })
                                    .map(cc => {
                                        const peopleGroupName = peopleGroupsMap.has(cc.peopleGroup) ? peopleGroupsMap.get(cc.peopleGroup).name : '';
                                        const field = peopleGroupToField.get(cc.peopleGroup);
                                        return (
                                            <li key={cc._id}>{field ? <Link to={`/field/${field}/people/${cc.peopleGroup}`}>{peopleGroupName}</Link> : <span>{peopleGroupName}</span>} {cc.endDate ? `${Localize.text('EndDateColon', 'UniversalForms')} ${cc.endDate}` : ''}</li>
                                        );
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                );
            });

        const countries = listManager
            .get(localizedLists, 'Nationality Countries')
            .map(c => ({ _id: c._id, name: c.values.get('name')}));

        const canDelete = trainingsAttended.length === 0 && trainingsInstructed.length === 0 && statuses.length === 0 && peopleGroupConsultants.length === 0;

        const getFieldName = fieldId => {
            const field = fields.find(f => f._id === fieldId);
            if (field) return field.name;
            if (fieldId === itinerant) return Localize.text('Itinerant', 'UniversalForms');
            return Localize.text('Unknown', 'UniversalForms');
        };

        const prevFields = state.previousFields.length ?
            state.previousFields
                .map(pf => getFieldName(pf))
                .sort((a, b) => intCol.compare(a, b))
                .join(', ') :
            Localize.text('None', 'Universal');

        const setPreviousFields = newFields => {
            this.setState({
                ...this.state,
                previousFields: newFields.filter(f => f.show).map(f => f._id),
            });
        };

        return (
            <div className={'container-fluid'}>
                <div className={'row'}>
                    <div className={'col-12'}>
                        <button type={'button'} className={'btn btn-outline-secondary'} onClick={this.onBack}><i className="fa fa-chevron-left" /> {Localize.text('Back', 'Universal')}</button>
                    </div>
                </div>
                <div className={'row'}>
                    <div className={'col-12'}>
                        {personId && editing ?
                            <h1>{Localize.text('EditPerson', 'Contacts')}</h1>
                            :
                            personId ?
                                <h1>{Localize.text('ViewPerson', 'PersonDetail')} <a href="#" style={{display: level >= 2 ? 'inline-block' : 'none'}} className={'btn btn-primary'} onClick={e => this.setEditing(e, true)}><i className={'fa fa-pencil'} /></a></h1>
                                :
                                <h1>{Localize.text('NewPerson', 'Contacts')}</h1>
                        }
                    </div>
                </div>

                <div className={'row'}>

                    <div className={'col-lg-6'}>

                        <div className={'form-group row'}>
                            <label className={'col-lg-4'}>{Localize.text('FirstName', 'Universal') + ':'}</label>
                            <div className={'col-lg-8'}>
                                <input readOnly={!editing} type={'text'} className={'form-control form-control-sm'} value={firstName} onChange={this.onFirstNameChange} />
                            </div>
                        </div>

                        <div className={'form-group row'}>
                            <label className={'col-lg-4'}>{Localize.text('LastName', 'Universal') + ':'}</label>
                            <div className={'col-lg-8'}>
                                <input readOnly={!editing} type={'text'} className={'form-control form-control-sm'} value={lastName} onChange={this.onLastNameChange} />
                            </div>
                        </div>

                        <div className={'form-group row'}>
                            <label className={'col-lg-4'}>{Localize.text('Emails', 'UniversalFOrms') + ':'}</label>
                            <div className={'col-lg-8'}>
                                <input readOnly={!editing} type={'text'} className={'form-control form-control-sm'} value={state.emails} placeholder={Localize.text('EGEmail1SomethingComEmail2SomethingCom', 'Contacts')} onChange={e => this.onInputChange(e, 'emails')} />
                            </div>
                        </div>

                        <div className={'form-group row'}>
                            <label className={'col-lg-4'}>{Localize.text('PhoneNumbers', 'UniversalForms') + ':'}</label>
                            <div className={'col-lg-8'}>
                                <input readOnly={!editing} type={'text'} className={'form-control form-control-sm'} value={state.phoneNumbers} placeholder={Localize.text('EG1222333444413334445555', 'Contacts')} onChange={e => this.onInputChange(e, 'phoneNumbers')} />
                            </div>
                        </div>

                        <div className={'form-group row'}>
                            <label className={'col-lg-4'}>{Localize.text('Organization', 'UniversalForms') + ':'}</label>
                            <div className={'col-lg-8'}>
                                <select disabled={!editing} className={'form-control form-control-sm'} value={state.organization} onChange={e => this.onInputChange(e, 'organization')}>
                                    <option value={''}>{editing ? Localize.text('SelectOne', 'Universal') : ''}</option>
                                    {
                                        listManager.get(localizedLists, 'Organization')
                                            .sort((a, b) => intCol.compare(a.values.get('name'), b.values.get('name')))
                                            .map(d => <option key={d._id} value={d._id}>{d.values.get('name')} ({d.values.get('abbr')})</option>)
                                    }
                                </select>
                            </div>
                        </div>

                        <div className={'form-group row'}>
                            <label className={'col-lg-4'}>{Localize.text('MemberStatus', 'UniversalForms') + ':'}</label>
                            <div className={'col-lg-8'}>
                                <select disabled={!editing} className={'form-control form-control-sm'} value={state.memberStatus} onChange={e => this.onInputChange(e, 'memberStatus')}>
                                    <option value={''}>{editing ? Localize.text('SelectOne', 'Universal') : ''}</option>
                                    {
                                        listManager.get(localizedLists, 'Member Status')
                                            .sort((a, b) => intCol.compare(a.values.get('name'), b.values.get('name')))
                                            .map(d => <option key={d._id} value={d._id}>{d.values.get('name')}</option>)
                                    }
                                </select>
                            </div>
                        </div>

                        <div className={'form-group row'}>
                            <label className={'col-lg-4'}>{Localize.text('ServiceStatus', 'UniversalForms') + ':'}</label>
                            <div className={'col-lg-8'}>
                                <select disabled={!editing} className={'form-control form-control-sm'} value={state.serviceStatus} onChange={e => this.onInputChange(e, 'serviceStatus')}>
                                    <option value={''}>{editing ? Localize.text('SelectOne', 'Universal') : ''}</option>
                                    {
                                        listManager.get(localizedLists, 'Service Status')
                                            .sort((a, b) => intCol.compare(a.values.get('name'), b.values.get('name')))
                                            .map(d => <option key={d._id} value={d._id}>{d.values.get('name')}</option>)
                                    }
                                </select>
                            </div>
                        </div>

                        <div className={'form-group row'}>
                            <label className={'col-lg-4'}>{Localize.text('Nationality', 'UniversalForms') + ':'}</label>
                            <div className={'col-lg-8'}>
                                <select disabled={!editing} className={'form-control form-control-sm'} value={nationality} onChange={this.onNationalityChange}>
                                    <option value={''}>{editing ? Localize.text('SelectOne', 'Universal') : ''}</option>
                                    {
                                        countries
                                            .sort((a, b) => intCol.compare(a.name, b.name))
                                            .map(e => <option key={e._id} value={e._id}>{e.name}</option>)
                                    }
                                </select>
                            </div>
                        </div>

                        <div className={'form-group row'}>
                            <label className={'col-lg-4'}>{Localize.text('SendingEntity', 'UniversalForms') + ':'}</label>
                            <div className={'col-lg-8'}>
                                <select disabled={!editing} className={'form-control form-control-sm'} value={sendingEntity} onChange={this.onSendingEntityChange}>
                                    <option value={''}>{editing ? Localize.text('SelectOne', 'Universal') : ''}</option>
                                    {
                                        sendingEntitiesList
                                            .sort((a, b) => intCol.compare(a.name, b.name))
                                            .map(e => <option key={e._id} value={e._id}>{e.name}</option>)
                                    }
                                </select>
                            </div>
                        </div>

                        <div className={'form-group row'}>
                            <label className={'col-lg-4'}>{Localize.text('ActiveField', 'UniversalForms') + ':'}</label>
                            <div className={'col-lg-8'}>
                                <select disabled={!editing} className={'form-control form-control-sm'} value={activeField} onChange={this.onActiveFieldChange}>
                                    <option value={''}>{Localize.text('NoActiveField', 'UniversalForms')}</option>
                                    <option value={itinerant}>{Localize.text('Itinerant', 'UniversalForms')}</option>
                                    {
                                        fields
                                            .sort((a, b) => intCol.compare(a.name, b.name))
                                            .map(e => <option key={e._id} value={e._id}>{e.name}</option>)
                                    }
                                </select>
                            </div>
                        </div>

                        <div className={'form-group row'}>
                            <label className={'col-lg-4'}>{Localize.text('PreviousFields', 'UniversalForms') + ':'}</label>
                            <div className={'col-lg-8'}>
                                <MultiCheckbox
                                    buttonAsTextbox={true}
                                    buttonTitle={prevFields}
                                    editorTitle={Localize.text('PreviousFields', 'UniversalForms')}
                                    hideAllButton={true}
                                    filterable={true}
                                    items={
                                        fields
                                            .sort((a, b) => intCol.compare(a.name, b.name))
                                            .map(f => ({ text: f.name, _id: f._id, show: state.previousFields.includes(f._id) }))
                                    }
                                    disabled={!editing}
                                    onDone={setPreviousFields}
                                />
                            </div>
                        </div>

                        <div className={'form-group row'}>
                            <label className={'col-lg-4'}>{Localize.text('ActivePeopleGroup', 'UniversalForms') + ':'}</label>
                            <div className={'col-lg-8'}>
                                <select disabled={!editing} className={'form-control form-control-sm'} value={activePeopleGroup} onChange={this.onActivePeopleGroupChange}>
                                    <option value={''}>{Localize.text('NoActivePeopleGroup', 'UniversalForms')}</option>
                                    {
                                        allPeopleGroups
                                            .sort((a, b) => intCol.compare(a.name, b.name))
                                            .map(e => <option key={e._id} value={e._id}>{e.name}</option>)
                                    }
                                </select>
                            </div>
                        </div>

                        <div className={'form-group row'}>
                            <label className={'col-lg-4'}>{Localize.text('Note', 'Universal') + ':'}</label>
                            <div className={'col-lg-8'}>
                                <textarea readOnly={!editing} style={{resize: 'vertical'}} rows={3} className={'form-control form-control-sm'} value={state.note} onChange={e => this.onInputChange(e, 'note')} />
                            </div>
                        </div>

                    </div>

                    <div className={'col-lg-6'}>
                        <div className={'card mb-3'} style={{display: (user.fmdbAdministrator || user.imtLeadership) ? 'block' : 'none'}}>
                            <div className={'card-header'}>
                                <h4>{Localize.text('ElevatedPermissions', 'UniversalForms')}</h4>
                            </div>
                            <div className={'card-body'}>
                                <div className={'row'}>
                                    <div className={'col-lg-12'}>
                                        <div className={'form-group row'}>
                                            <label className={'col-lg-4'}>{Localize.text('InternationalConsultantCoordinator', 'UniversalForms')}</label>
                                            <div className={'col-lg-8'}>
                                                <select disabled={!editing} className={'form-control form-control-sm'} value={state.internationalConsultantCoordinator} onChange={e => this.onBooleanInputChange(e, 'internationalConsultantCoordinator')}>
                                                    <option value={true}>{Localize.text('Yes', 'Universal')}</option>
                                                    <option value={false}>{Localize.text('No', 'Universal')}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className={'form-group row'}>
                                            <label className={'col-lg-4'}>{Localize.text('SecurityTeamMember', 'UniversalForms')}</label>
                                            <div className={'col-lg-8'}>
                                                <select disabled={!editing} className={'form-control form-control-sm'} value={state.securityTeamMember} onChange={e => this.onBooleanInputChange(e, 'securityTeamMember')}>
                                                    <option value={true}>{Localize.text('Yes', 'Universal')}</option>
                                                    <option value={false}>{Localize.text('No', 'Universal')}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className={'form-group row'}>
                                            <label className={'col-lg-4'}>{Localize.text('IMTLeadership', 'UniversalForms')}</label>
                                            <div className={'col-lg-8'}>
                                                <select disabled={!editing} className={'form-control form-control-sm'} value={state.imtLeadership} onChange={e => this.onBooleanInputChange(e, 'imtLeadership')}>
                                                    <option value={true}>{Localize.text('Yes', 'Universal')}</option>
                                                    <option value={false}>{Localize.text('No', 'Universal')}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className={'form-group row'}>
                                            <label className={'col-lg-4'}>{Localize.text('FMDBAdministrator', 'UniversalForms')}</label>
                                            <div className={'col-lg-8'}>
                                                <select disabled={!editing} className={'form-control form-control-sm'} value={state.fmdbAdministrator} onChange={e => this.onBooleanInputChange(e, 'fmdbAdministrator')}>
                                                    <option value={true}>{Localize.text('Yes', 'Universal')}</option>
                                                    <option value={false}>{Localize.text('No', 'Universal')}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* t161 enable Admins to remove login credentials */}
                        <div className={'card'} style={{display: (user._id !== personId && user.fmdbAdministrator && state.username) ? 'block' : 'none'}}>
                            <div className={'card-header'}>
                                <div className={'pull-right'}>
                                    <button type={'button'} className={'btn btn-danger'} onClick={this.onRemoveLoginCredentials}>{Localize.text('RemoveLoginCredentials', 'UniversalForms')}</button>
                                </div>
                                <h4>{Localize.text('LoginCredentials', 'UniversalForms')}</h4>
                            </div>
                        </div>

                        <div className={'card'} style={{display: (user._id === personId) ? 'block' : 'none'}}>
                            <div className={'card-header'}>
                                {state.showLoginCredentials ?
                                    <div className={'pull-right'}>
                                        <button type={'button'} className={'btn btn-danger'} onClick={this.onRemoveLoginCredentials}>{Localize.text('RemoveLoginCredentials', 'UniversalForms')}</button>
                                        <button type={'button'} style={{marginLeft: 10}} className={'btn btn-secondary'} onClick={this.onCancelLoginCredentials}>{Localize.text('Hide', 'UniversalForms')}</button>
                                    </div>
                                    :
                                    state.username ?
                                        <button type={'button'} className={'btn btn-primary pull-right'} onClick={this.onShowLoginCredentials}>{Localize.text('EditLoginCredentials', 'UniversalForms')}</button>
                                        :
                                        <button type={'button'} className={'btn btn-primary pull-right'} onClick={this.onShowLoginCredentials}>{Localize.text('AddLoginCredentials', 'UniversalForms')}</button>
                                }
                                <h4>{Localize.text('LoginCredentials', 'UniversalForms')}</h4>
                            </div>
                            <div className={'card-body'}>
                                {state.showLoginCredentials ?
                                    <div className={'row'}>
                                        <div className={'col-lg-12'}>
                                            <div className={'form-group row'}>
                                                <label className={'col-lg-4'}>{Localize.text('LoginEmail', 'UniversalForms')}</label>
                                                <div className={'col-lg-8'}>
                                                    <input type={'text'} className={'form-control form-control-sm'} value={state.username} onChange={e => this.onInputChange(e, 'username')} />
                                                </div>
                                            </div>
                                            <div className={'form-group row'}>
                                                <label className={'col-lg-4'}>{Localize.text('Password', 'UniversalForms')}</label>
                                                <div className={'col-lg-8'}>
                                                    {state.password ?
                                                        <input type={'password'}
                                                               className={'form-control form-control-sm is-valid'}
                                                               value={state.password}
                                                               onChange={e => this.onInputChange(e, 'password')} />
                                                        :
                                                        <input type={'password'}
                                                               className={'form-control form-control-sm'}
                                                               value={state.password}
                                                               onChange={e => this.onInputChange(e, 'password')} />
                                                    }
                                                </div>
                                            </div>
                                            <div className={'form-group row'}>
                                                <label className={'col-lg-4'}>{Localize.text('RepeatPassword', 'UniversalForms')}</label>
                                                <div className={'col-lg-8'}>
                                                    {!state.password ?
                                                        <input type={'password'} className={'form-control form-control-sm'} value={state.passwordRepeat} onChange={e => this.onInputChange(e, 'passwordRepeat')} />
                                                        :
                                                        state.password === state.passwordRepeat ?
                                                            <input type={'password'} className={'form-control form-control-sm is-valid'} value={state.passwordRepeat} onChange={e => this.onInputChange(e, 'passwordRepeat')} />
                                                            :
                                                            <input type={'password'} className={'form-control form-control-sm is-invalid'} value={state.passwordRepeat} onChange={e => this.onInputChange(e, 'passwordRepeat')} />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div></div>
                                }
                            </div>
                        </div>
                    </div>

                </div>

                <div className={'form-group row'}>
                    <table className={'table'}>
                        <thead>
                        <tr>
                            <th>{Localize.text('PeopleGroup', 'UniversalForms')}</th>
                            <th>{Localize.text('Status', 'PeopleGroupDetail')}</th>
                            <th>{Localize.text('PrimaryRole', 'UniversalForms')}</th>
                            <th>{Localize.text('SecondaryRole', 'UniversalForms')}</th>
                            <th>{Localize.text('CLALevel', 'UniversalForms')}</th>
                            <th>{Localize.text('StartDate', 'UniversalForms')}</th>
                            <th>{Localize.text('EndDate', 'UniversalForms')}</th>
                            <th>{Localize.text('ItinerantStartDate', 'PeopleGroupDetail')}</th>
                            <th>{Localize.text('ItinerantEndDate', 'PeopleGroupDetail')}</th>
                            <th>{}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {statuses
                            .map(s => {

                                const peopleGroupName = peopleGroupsMap.has(s.peopleGroup) ? peopleGroupsMap.get(s.peopleGroup).name : '';
                                const field = peopleGroupToField.get(s.peopleGroup);

                                return (
                                    <tr key={s._id}>
                                        <td>{field ? <Link to={`/field/${field}/people/${s.peopleGroup}`}>{peopleGroupName}</Link> : <span>{peopleGroupName}</span>}</td>
                                        <td>{listItems.has(s.serviceStatus) ? listItems.get(s.serviceStatus).name : ''}</td>
                                        <td>{listItems.has(s.primaryRole) ? listItems.get(s.primaryRole).name : ''}</td>
                                        <td>{listItems.has(s.secondaryRole) ? listItems.get(s.secondaryRole).name : ''}</td>
                                        <td>{listItems.has(s.claLevel) ? listItems.get(s.claLevel).name : ''}</td>
                                        <td>{s.startDate}</td>
                                        <td>{s.endDate}</td>
                                        <td>{s.itinerantStartDate}</td>
                                        <td>{s.itinerantEndDate}</td>
                                    </tr>
                                );
                            })
                        }
                        </tbody>
                    </table>
                </div>

                <div className={'form-group row'}>
                    <div className={'col-lg-6'}>
                        <h4>{Localize.text('Training', 'UniversalForms')}</h4>
                        <div>
                            <ul>
                                {trainingsAttended
                                    .map(t => {
                                        return (
                                            <li key={'instructed' + t._id}><Link to={`/training/${t._id}`}>{listItems.has(t.trainingName) ? listItems.get(t.trainingName).name : ''}</Link>, {t.location}, {t.date}</li>
                                        );
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                    <div className={'col-lg-6'}>
                        <h4>{Localize.text('InstructorForTraining', 'UniversalForms')}</h4>
                        <div>
                            <ul>
                                {trainingsInstructed
                                    .map(t => {
                                        return (
                                            <li key={'instructed' + t._id}><Link to={`/training/${t._id}`}>{listItems.has(t.trainingName) ? listItems.get(t.trainingName).name : ''}</Link>, {t.location}, {t.date}</li>
                                        );
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                </div>

                <div className={'form-group row'}>
                    {consultancyItems}
                    {/*<div className={'col-lg-4'}>*/}
                        {/*<h4>{Localize.text('CLAConsultancies', 'UniversalForms')}</h4>*/}
                        {/*<div>*/}
                            {/*<ul>*/}
                                {/*<li>One</li>*/}
                                {/*<li>Two</li>*/}
                                {/*<li>Three</li>*/}
                            {/*</ul>*/}
                        {/*</div>*/}
                    {/*</div>*/}
                    {/*<div className={'col-lg-4'}>*/}
                        {/*<h4>{Localize.text('LiteracyConsultancies', 'UniversalForms')}</h4>*/}
                        {/*<div>*/}
                            {/*<ul>*/}
                                {/*<li>One</li>*/}
                                {/*<li>Two</li>*/}
                                {/*<li>Three</li>*/}
                            {/*</ul>*/}
                        {/*</div>*/}
                    {/*</div>*/}
                    {/*<div className={'col-lg-4'}>*/}
                        {/*<h4>{Localize.text('ChurchPlantingConsultancies', 'UniversalForms')}</h4>*/}
                        {/*<div>*/}
                            {/*<ul>*/}
                                {/*<li>One</li>*/}
                                {/*<li>Two</li>*/}
                                {/*<li>Three</li>*/}
                            {/*</ul>*/}
                        {/*</div>*/}
                    {/*</div>*/}
                </div>

                {!personId || editing ?
                    <div className={'form-group row'}>
                        <div className={'col'}>
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', flextWrap: 'wrap'}}>
                                <button type={'button'} className={'btn btn-primary'} style={{width: 120}} onClick={this.onSave}>{Localize.text('Save', 'Universal')}</button>
                                <button type={'button'} className={'btn btn-outline-secondary'} style={{marginLeft: 10, width: 120}} onClick={this.onCancel}>{Localize.text('Cancel', 'Universal')}</button>
                                <div style={{height: 10, flexGrow: 1}}></div>
                                <button type={'button'} className={'btn btn-danger'} style={{width: 120}} onClick={e => this.onDelete(e, canDelete)}><i className={'fa fa-trash'} /> {Localize.text('Delete', 'UniversalForms')}</button>
                            </div>
                        </div>
                    </div>
                    :
                    <div></div>
                }

            </div>
        );
    }

}
EditPerson.propTypes = {
    user: PropTypes.object,
    people: PropTypes.instanceOf(Map),
    history: PropTypes.object,
    match: PropTypes.object,
    localizedLists: PropTypes.arrayOf(PropTypes.object),
    countries: PropTypes.arrayOf(PropTypes.object),
    trainings: PropTypes.arrayOf(PropTypes.object),
    locations: PropTypes.arrayOf(PropTypes.object),
    fields: PropTypes.arrayOf(PropTypes.object),
    allPeopleGroups: PropTypes.arrayOf(PropTypes.object),
    addPerson: PropTypes.func,
    updatePerson: PropTypes.func,
    deletePerson: PropTypes.func,
    setLocations: PropTypes.func,
    disableNav: PropTypes.func,
    enableNav: PropTypes.func,
};

export default EditPerson;
