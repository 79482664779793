import React from 'react';
import PropTypes from 'prop-types';
import { bindAll } from 'lodash';
import {connect} from 'react-redux';
import { setAppDisableNav, setAppModalStatus } from '../../actions/app-actions';
import { modalStatuses } from '../../constants';
import isEqual from 'lodash/isEqual';

class ModalTextarea extends React.Component {

    constructor(props) {
        super(props);

        const origValue = props.value || '';

        this.state = {
            origValue: origValue,
            value: origValue
        };
        bindAll(this, [
            'openModal',
            'onSave',
            'onCancel',
            'onChange'
        ]);
    }

    componentWillReceiveProps({ value }) {
        const { origValue } = this.state;
        if(origValue !== value) {
            this.setState({
                ...this.state,
                origValue: value,
                value
            });
        }
    }

    componentDidUpdate() {
        // make sure this update is for the modal we are showing
        if (!($(this.modalNode).data('bs.modal') || {})._isShown) return;

        if (this.props.modalStatus === modalStatuses.CLOSE_MODAL) {
            this.onCancel();
        } else if (!isEqual(this.state.origValue, this.state.value)) {
            this.props.disableNav();
        } else {
            this.props.enableNav();
        }
    }

    async openModal(e) {
        e.preventDefault();
        if(this.props.onBeforeOpen) await this.props.onBeforeOpen();
        const $modal = $(this.modalNode);
        $modal.modal('show');
        $modal
            .off('hidden.bs.modal')
            .on('hidden.bs.modal', () => {
                this.onCancel();
            });
        this.props.setModalStatus(modalStatuses.SHOWING_MODAL);
    }

    onSave(e) {
        e.preventDefault();
        const $modal = $(this.modalNode);
        $modal.off('hidden.bs.modal');
        const { state } = this;
        const value = state.value.trim();
        this.props.onSave(value);
        this.setState({
            ...this.state,
            origValue: value,
            value
        });
        $modal.modal('hide');
        this.props.setModalStatus(modalStatuses.NO_MODAL);
        this.props.enableNav();
    }

    onCancel(e) {
        if(e) e.preventDefault();
        this.setState({
            ...this.state,
            value: this.state.origValue
        });
        $(this.modalNode).modal('hide');
        this.props.setModalStatus(modalStatuses.NO_MODAL);
        this.props.enableNav();
    }

    onChange(e) {
        e.preventDefault();
        this.setState({
            ...this.state,
            value: e.target.value
        });
    }

    render() {

        const { value } = this.state;
        const { title, readonly } = this.props;

        return (
            <div className={'pull-right'}>

                <a href={'#'} onClick={this.openModal} style={readonly ? {display: 'none'} : {}}><i className={'fa fa-pencil'} /></a>

                <div className={'modal'} ref={node => this.modalNode = node}>
                    <div className={'modal-dialog modal-lg'}>
                        <div className={'modal-content'}>
                            <div className={'modal-header'}>
                                <h5 className="modal-title">{title}</h5>
                                <button type="button" className="close" onClick={this.onCancel}>
                                    <span>&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className={'form-group'}>
                                    <textarea className={'form-control'} value={value} onChange={this.onChange} rows={5} />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type={'button'} className={'btn btn-secondary'} onClick={this.onCancel}>{Localize.text('Cancel', 'Universal')}</button>
                                <button type={'button'} className={'btn btn-primary'} onClick={this.onSave}>{Localize.text('Save', 'Universal')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}
ModalTextarea.propTypes = {
    title: PropTypes.string,
    value: PropTypes.string,
    readonly: PropTypes.bool,
    onSave: PropTypes.func,
    onBeforeOpen: PropTypes.func,
    modalStatus: PropTypes.string,
    disableNav: PropTypes.func,
    enableNav: PropTypes.func,
    setModalStatus: PropTypes.func,
};

const WrappedModalTextarea = connect(
    ({ appState }) => ({
        modalStatus: appState.modalStatus,
    }),
    dispatch => ({
        disableNav: () => dispatch(setAppDisableNav(true)),
        enableNav: () => dispatch(setAppDisableNav(false)),
        setModalStatus: status => dispatch(setAppModalStatus(status)),
    }),
)(ModalTextarea);

export default WrappedModalTextarea;
