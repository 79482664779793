module.exports = {
    backupSuccess: 'Backup complete.',

    // LocalizableLists
    localizableLists: {
        pgServiceStatusActive: '89492ccc-6a67-4f20-8d9a-baea1b844986',
        pgServiceStatusItinerant: '390e3f6e-b97c-46dc-b007-b65fe44736ad',
        pgServiceStatusInactive: '39303cb8-d589-4580-92c1-1f619cdad53e',

        curriculumStatusReadyForUse: '4b837093-034a-4516-8e80-46b9df9221bd',

        churchStageGrowthAndMaturity: '25b1412a-73aa-4666-8d1e-e8948da4ef2f',
        churchStageEquippedToPartner: '8aa5fcc0-f7e6-41d5-8f00-82a4e50277ec',

        fieldRoleTitleFieldChairman: '684a1564-648b-4613-a57a-20f42b0dc80d',
        fieldRoleTitleFieldLeadershipTeam: '00bc8d15-5f49-48e1-8c5f-f0b77fe02044',
        fieldRoleTitleAreaLeadership: '00d2c54c-99a3-468e-b52a-f36dbf8a41e7',
        fieldRoleTitleIMTPointPerson: 'cb52cceb-e8b0-4487-bf92-768c7a71e1fa',
        fieldRoleTitleFieldFMDBContactGSP: '8b3f5a1f-9c68-48fc-94f9-38507499fe95',
        fieldRoleTitleAdministrativeSupport: 'cb853298-1b93-4d7a-b303-7a706b4758e8',
        fieldRoleTitleSecurity: '73e2bf1e-5fb4-4835-a533-858e6c55d1b3',
        fieldRoleTitleChildSafety: '9c303537-3f44-41a7-9a9f-7f0903cf51e9',

        consultantTypeCLA: '97efd3e2-b514-4c69-8b79-7bb9a4d0cc47',
        consultantTypeChurchPlanting: '76cdf5e3-4cbb-4cf9-bd8c-c70c3408b3ed',
        consultantTypeLiteracy: 'c7f2a41b-2a04-410a-ae98-e1dd2882b8c9',
        consultantTypeTranslation: '9340c3f9-8352-475d-8217-e52bdbae5d39',
        consultantTypeCurriculumDevelopment: 'c3bcb4f7-8f54-43b9-aa88-caaf1a62ce27',
        consultantTypeCLAInTraining: '18738afd-400e-4169-bba2-b42dac80c0d1',
        consultantTypeChurchPlantingInTraining: 'e427296a-2fd8-4c8b-9b9c-a84330eda57e',
        consultantTypeLiteracyInTraining: '094bbe1d-2c61-4788-9e13-d1f73846c0c1',
        consultantTypeTranslationInTraining: 'd6f6aad8-cf6f-459e-a40e-e713934cb00b',
    },

    // reports
    reportGeneralStatistics: 'reportGeneralStatistics',
    reportChurchGrowthByLoc: 'reportChurchGrowthByLoc',
    reportActiveWorker: 'reportActiveWorker',
    reportAllFieldPersonnel: 'reportAllFieldPersonnel',
    reportActiveFieldPersonnel: 'reportActiveFieldPersonnel',
    reportActivePersonnelByNationality: 'reportActivePersonnelByNationality',
    reportPeopleGroupInfo: 'reportPeopleGroupInfo',
    reportStatusOfWorksTotals: 'reportStatusOfWorksTotals',
    reportTranslationInfoByPortion: 'reportTranslationInfoByPortion',
    reportTranslation: 'reportTranslation',
    reportIMTRequestedRolesAndConsultants: 'reportIMTRequestedRolesAndConsultants',
    reportPeopleGroupConsultants: 'reportPeopleGroupConsultants',
    reportAllPersonnel: 'reportAllPersonnel',

    // other
    itinerant: 'itinerant',

    comparatorTypeParseInt: 'comparatorTypeParseInt',
    comparatorTypeMMdashYYYY: 'comparatorTypeMMdashYYYY',

    partialResponseDelimiter: '$',

    swapMMdashYYYY: function(val) {
        const datePatt = /\d{2}-\d{4}/;
        return datePatt.test(val) ? val.split('-').reverse().join('-') : val;
    },

    hasElevatedPermissions: user => {
        return user.fmdbAdministrator || user.imtLeadership || user.securityTeamMember || user.internationalConsultantCoordinator;
    },
};
