import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Map } from 'immutable';
import swal from 'sweetalert';
import bindAll from 'lodash/bindAll';
import escapeRegExp from 'lodash/escapeRegExp';

// import gql from '../../util/gql';
import { setAppPersonToFieldPeopleGroup } from '../../actions/app-actions';
import shareWithServer from '../../constants/share-with-server';
const { itinerant } = shareWithServer;

const IMTRequestedRolesThatCanAddPerson = [
    '8b3f5a1f-9c68-48fc-94f9-38507499fe95', // fmdb contact role
];

class Contacts extends React.Component {

    constructor(props) {
        super(props);
        bindAll(this, [
            'authorizeEmailAddress'
        ]);
    }

    async componentDidMount() {
        try {
            const { errors, data } = await gql.transaction(
                'query',
                'getPersonToFieldPeopleGroup',
                {},
                ['_id', 'peopleGroups { _id, name, field }', 'fields { _id, name }']
            );
            if(errors) throw errors[0];
            const personToFieldPeopleGroup = data.getPersonToFieldPeopleGroup
                .map(p => ({ ...p, fields: (p.fields && p.fields[0]._id === itinerant) ? [{ _id: itinerant, name: Localize.text('Itinerant', 'UniversalForms')}] : p.fields })) // t276 handle itinerant as an activeField
                .reduce((obj, p) => Object.assign({}, obj, {[p._id]: p}), {});
            this.props.setPersonToFieldPeopleGroup(personToFieldPeopleGroup);
        } catch(err) {
            handleError(err);
        }
    }

    async authorizeEmailAddress(e) {
        try {
            e.preventDefault();

            // ToDo Finish implementing this.

            const confirmed = await swal({
                title: Localize.text('AuthorizeEmailAddress', 'Contacts'),
                text: Localize.text('WhenSomebodyRegistersForTheThisSiteTheirEmailAddressIsChecked', 'Contacts'),
                content: {
                    element: 'input',
                    attributes: {
                        id: 'js-authorizeEmailInput',
                        type: 'text',
                        class: 'form-control',
                        placeholder: Localize.text('EnterEmailAddressHere', 'Contacts')
                    },
                    closeOnEsc: false,
                    closeOnClickOutside: false
                },
                buttons: [
                    Localize.text('Cancel', 'Universal'),
                    Localize.text('OK', 'Universal')
                    // {
                    //     text: Localize.text('OK', 'Universal'),
                    //     // closeModal: false,
                    //     value: 'confirmed'
                    // }
                ]
            });
            console.log('confirmed', confirmed);
        } catch(err) {
            handleError(err);
        }
    }

    render() {

        const { user, location, history, people: peopleMap, personToFieldPeopleGroup, fields } = this.props;

        // const isNew = /\/new$/.test(location.pathname);
        // const { personId } = location.query;

        const generateQueryParams = obj => {
            const newQuery = {
                ...location.query,
                ...obj
            };
            return Object
                .keys(newQuery)
                .map(key => `${key}=${encodeURI(newQuery[key])}`)
                .join('&');
        };

        const people = [...peopleMap.values()];

        const locale = Localize.locale();
        const intCol = new Intl.Collator(locale);

        const sortBy = location.query.sort || 'firstName';
        const nameFilter = location.query.namefilter || '';
        const fieldFilter = location.query.fieldfilter || '';
        const pgFilter = location.query.pgfilter || '';
        const qty = location.query.q ? Number(location.query.q) : 100;

        const sortChanged = e => {
            e.preventDefault();
            history.push(`/personnel?${generateQueryParams({sort: e.target.value})}`);
        };
        const nameFilterChanged = e => {
            e.preventDefault();
            history.push(`/personnel?${generateQueryParams({namefilter: e.target.value})}`);
        };
        const fieldFilterChanged = e => {
            e.preventDefault();
            history.push(`/personnel?${generateQueryParams({fieldfilter: e.target.value})}`);
        };
        const pgFilterChanged = e => {
            e.preventDefault();
            history.push(`/personnel?${generateQueryParams({pgfilter: e.target.value})}`);
        };

        const doFilter = (toFilter, filter, getTestValue, setFullName) => {
            const lowerFilter = filter.toLowerCase();

            const filterPatt0 = new RegExp(`^${escapeRegExp(lowerFilter)}\\W`, 'i');
            const filterPatt1 = new RegExp(`^${escapeRegExp(lowerFilter)}`, 'i');
            const filterPatt2 = new RegExp(`\\W${escapeRegExp(lowerFilter)}`, 'i');
            const filterPatt3 = new RegExp(escapeRegExp(lowerFilter), 'i');

            const filtered0 = [];
            const filtered1 = [];
            const filtered2 = [];
            const filtered3 = [];

            const newPersonObj = p => setFullName ? {...p, fullName: getTestValue(p)} : { ...p };

            for(const p of toFilter) {
                const testValue = getTestValue(p);

                if(filterPatt0.test(testValue)) {
                    filtered0.push(newPersonObj(p));
                } else if(filterPatt1.test(testValue)) {
                    filtered1.push(newPersonObj(p));
                } else if(filterPatt2.test(testValue)) {
                    filtered2.push(newPersonObj(p));
                } else if(filterPatt3.test(testValue)) {
                    filtered3.push(newPersonObj(p));
                }
            }

            const sort = arr => arr.sort((a, b) => {
                return sortBy === 'firstName' ? intCol.compare(a.firstName, b.firstName) : intCol.compare(a.lastName, b.lastName);
            });

            sort(filtered0);
            sort(filtered1);
            sort(filtered2);
            sort(filtered3);

            return [
                ...filtered0,
                ...filtered1,
                ...filtered2,
                ...filtered3
            ];
        };

        // t260 First render happens before componentDidMount, so if we added a new person, we'll have them in 'people'
        //  but not in 'personToFieldPeopleGroup' until after componentDidMount.  So, we can't assume personToFieldPeopleGroup[p._id] is valid.
        //  See https://daveceddia.com/where-fetch-data-componentwillmount-vs-componentdidmount/ for a discussion of why it's correct to use componentDidMount instead of componentWillMount.
        const filteredByPg = doFilter(people, pgFilter, p => personToFieldPeopleGroup[p._id] ? personToFieldPeopleGroup[p._id].peopleGroups.map(f => f.name).join(',') : '', false);
        const filteredByField = doFilter(filteredByPg, fieldFilter, p => personToFieldPeopleGroup[p._id] ? personToFieldPeopleGroup[p._id].fields.map(f => f.name).join(',') : '', false);
        const filteredPeople = doFilter(filteredByField, nameFilter, p => p.firstName + ' ' + p.lastName, true);

        const tableRows = filteredPeople
            .slice(0, qty)
            .map(p => {
                const fieldPeoplGroup = personToFieldPeopleGroup[p._id] || {};
                const { fields: pgFields = [], peopleGroups = [] } = fieldPeoplGroup;
                return (
                    <tr key={p._id}>
                        <td><Link to={`${location.pathname}/${p._id}`} style={{color: '#212529'}}>{p.fullName}</Link></td>
                        <td>{pgFields
                            .filter(f => f)
                            .map((f, i) => {
                                // t242 remove link
                                if(i === pgFields.length - 1) {
                                    // return <span key={f._id}><Link to={`/field/${f._id}`}>{f.name}</Link></span>;
                                    return <span key={f._id}>{f.name}</span>;
                                } else {
                                    // return <span key={f._id}><Link to={`/field/${f._id}`}>{f.name}</Link>, </span>;
                                    return <span key={f._id}>{f.name}, </span>;
                                }
                            })
                        }</td>
                        <td>{peopleGroups
                            .filter(pp => pp)
                            .map((pp, i) => {
                                // t242 remove link
                                if(i === peopleGroups.length - 1) {
                                    // return <span key={pp._id}><Link to={`/field/${pp.field}/people/${pp._id}`}>{pp.name}</Link></span>;
                                    return <span key={pp._id}>{pp.name}</span>;
                                } else {
                                    // return <span key={pp._id}><Link to={`/field/${pp.field}/people/${pp._id}`}>{pp.name}</Link>, </span>;
                                    return <span key={pp._id}>{pp.name}, </span>;
                                }
                            })
                        }</td>
                    </tr>
                );
            });

        const canAddPerson = fields.filter(f => {
            return f.IMTRequestedFieldRoles
                .filter(r => IMTRequestedRolesThatCanAddPerson.includes(r.title) && r.people.includes(user._id))
                .length > 0;
        }).length > 0;

        return (
            <div className={'container-fluid'}>
                <div className={'row'}>
                    <div className={'col-12'}>
                        <form className="form-inline">
                            <a href={'#'} className={'btn btn-primary pull-right d-none'} style={{marginTop: 12}} onClick={this.authorizeEmailAddress}>Authorize Email Address</a>
                            <h1>{Localize.text('Personnel', 'Contacts')}
                                <Link to={`${location.pathname}/new`} className={'btn btn-outline-dark ml-3'}
                                      style={{display: (user.fmdbAdministrator || user.imtLeadership || canAddPerson) ? 'inline-block' : 'none'}}>
                                    <i className="fa fa-plus" /> {Localize.text('AddNew', 'Contacts')}
                                </Link>
                            </h1>
                            <div className="form-group ml-3">
                                <label className="mr-2">{Localize.text('SortBy', 'Contacts')}</label>
                                <select className={'form-control'} onChange={sortChanged} value={sortBy}>
                                    <option value={'firstName'}>{Localize.text('FirstName', 'Contacts')}</option>
                                    <option value={'lastName'}>{Localize.text('LastName', 'Contacts')}</option>
                                </select>
                            </div>
                        </form>
                    </div>
                </div>

                <form className="form-inline">
                        <div className="form-group">
                            <label className="mr-2">{Localize.text('NameFilter', 'Contacts')}</label>
                            <input type={'text'} className="form-control mb-2" value={nameFilter} onChange={nameFilterChanged} />
                        </div>
                        <div className="form-group">
                            <label className="ml-3 mr-2">{Localize.text('FieldFilter', 'Contacts')}</label>
                            <input type={'text'} className="form-control mb-2" value={fieldFilter} onChange={fieldFilterChanged} />
                        </div>
                        <div className="form-group">
                            <label className="ml-3 mr-2">{Localize.text('PeopleGroupFilter', 'Contacts')}</label>
                            <input type={'text'} className="form-control mb-2" value={pgFilter} onChange={pgFilterChanged} />
                        </div>
                </form>

                <div className={'row'}>
                    <div className={'col-12'}>
                        <table className={'table table-bordered table-hover'}>
                            <thead>
                            <tr>
                                <th>{Localize.text('Name', 'Contacts')}</th>
                                <th>{Localize.text('Field', 'Contacts')}</th>
                                <th>{Localize.text('PeopleGroup', 'Contacts')}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {tableRows}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className={'row'}>
                    <div className={'col-12'}>
                        <div className={'form-group d-flex justify-content-center'}>
                            {filteredPeople.length > tableRows.length ? <Link to={`/personnel?${generateQueryParams({q: qty + 100})}`} className={'btn btn-primary'}>{'Load More'}</Link> : <div />}
                        </div>
                    </div>
                </div>

                {/*<div className={(isNew || personId) ? 'modal show' : 'modal'} style={(isNew || personId) ? {display: 'block'} : {}} ref={node => this.modalNode = node}>*/}
                {/*<div className={'modal-dialog modal-lg'} style={{maxWidth: windowWidth - 200}}>*/}
                {/*<div className={'modal-content'}>*/}
                {/*<div className={'modal-header'}>*/}
                {/*<h5 className="modal-title">{Localize.text('AddNewContact', 'Contacts')}</h5>*/}
                {/*<button type="button" className="close" onClick={this.onCancel}>*/}
                {/*<span>&times;</span>*/}
                {/*</button>*/}
                {/*</div>*/}
                {/*<div className="modal-body" style={{height: windowHeight - 200, overflowY: 'auto'}}>*/}



                {/*<div className={'form-group'}>*/}
                {/*<label>{Localize.text('ActiveInFields', 'Universal') + ':'}</label>*/}
                {/*<div style={{display: 'flex',flexDirection: 'row',flexWrap: 'wrap',justifyContent: 'flexStart'}}>*/}
                {/*{*/}
                {/*fields.map(f => {*/}
                {/*return (*/}
                {/*<div key={f._id} className={'form-check'} style={{width: '33%'}}>*/}
                {/*<input className="form-check-input" type="checkbox" />*/}
                {/*<label className="form-check-label">{f.name}</label>*/}
                {/*</div>*/}
                {/*);*/}
                {/*})*/}
                {/*}*/}
                {/*</div>*/}
                {/*</div>*/}

                {/*</div>*/}
                {/*<div className="modal-footer">*/}
                {/*<button type={'button'} classname={'btn btn-secondary'} onclick={this.oncancel}>{localize.text('cancel', 'universal')}</button>*/}
                {/*<button type={'button'} classname={'btn btn-primary'} onclick={this.onsave}>{localize.text('save', 'universal')}</button>*/}
                {/*</div>*/}
                {/*</div>*/}
                {/*</div>*/}
                {/*</div>*/}

            </div>
        );

    }
}

// class Contacts extends React.Component {
//
//     constructor(props) {
//         super(props);
//     }

    // componentWillReceiveProps(newProps) {
        // const isNew = /\/new$/.test(this.props.route.path);
        // const { personId } = this.props.routeParams;

        // const isNew = /\/new$/.test(newProps.route.path);
        // const { personId: newPersonId } = newProps.routeParams;
        // if(this.props.route !== newProps.route) {
        //     if(isNew) {
        //         console.log('New!');
        //     } else if(newPersonId) {
        //         console.log('Person!');
        //     } else {
        //         console.log('Personnel!');
        //     }
        // }
    // }

    // render() {
    //
    // }
//
// }
Contacts.propTypes = {
    user: PropTypes.object,
    location: PropTypes.object,
    history: PropTypes.object,
    people: PropTypes.instanceOf(Map),
    windowHeight: PropTypes.number,
    windowWidth: PropTypes.number,
    localizedLists: PropTypes.arrayOf(PropTypes.object),
    countries: PropTypes.arrayOf(PropTypes.object),
    fields: PropTypes.arrayOf(PropTypes.object),
    personToFieldPeopleGroup: PropTypes.object,
    setPersonToFieldPeopleGroup: PropTypes.func
};

export default connect(
    ({ appState }) => ({
        personToFieldPeopleGroup: appState.personToFieldPeopleGroup
    }),
    dispatch => ({
        setPersonToFieldPeopleGroup: data => dispatch(setAppPersonToFieldPeopleGroup(data))
    })
)(Contacts);
