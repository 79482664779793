import { connect } from 'react-redux';
import { setAppTrainings, setAppDisableNav } from '../../../actions/app-actions';
import EditTraining from './edit-training';

export default connect(
    ({ appState }) => ({
        localizedLists: appState.localizedLists,
        people: appState.people,
        windowHeight: appState.windowHeight,
        trainings: appState.trainings,
        user: appState.user,
        canCreateUpdateTrainings: appState.canCreateUpdateTrainings,
    }),
    dispatch => ({
        setTrainings(trainings) {
            dispatch(setAppTrainings(trainings));
        },
        disableNav: () => dispatch(setAppDisableNav(true)),
        enableNav: () => dispatch(setAppDisableNav(false)),
    })
)(EditTraining);
