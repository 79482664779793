import React from 'react';
import PropTypes from 'prop-types';
import {bindAll} from 'lodash';
import isEqual from 'lodash/isEqual';
import { Map } from 'immutable';
import swal from 'sweetalert';

import MultiSelect from '../shared/multi-select';
import {dbGuids, dummyLocation} from '../../constants';
import gqlAttributes from '../../util/gql-call-attributes';

class CreatePeopleGroupForm extends React.Component {

    constructor(props) {
        super(props);
        bindAll(this, [
            'onChange'
        ]);
    }

    onChange(e, key) {
        e.preventDefault();
        const { value } = e.target;
        this.props.onChange({
            ...this.props.peopleGroup,
            [key]: key !== 'hidden' ? value : value === 'true' ? true : false
        });
    }

    render() {

        const { peopleGroup } = this.props;

        return (
            <div>

                <div className={'form-group'}>
                    <label>{Localize.text('Name', 'NewPeopleGroupForm')}</label>
                    <input type={'text'} className={'form-control'} value={peopleGroup.name} onChange={e => this.onChange(e, 'name')} />
                </div>

                <div className={'form-group'}>
                    <label>{Localize.text('AlternateName', 'NewPeopleGroupForm')}</label>
                    <input type={'text'} className={'form-control'} value={peopleGroup.alternateName} onChange={e => this.onChange(e, 'alternateName')} />
                </div>

                <div className={'form-group'}>
                    <label>{Localize.text('PublicationName', 'NewPeopleGroupForm')}</label>
                    <input type={'text'} className={'form-control'} value={peopleGroup.publicationName} onChange={e => this.onChange(e, 'publicationName')} />
                </div>

                <div className={'form-group'}>
                    <label>{Localize.text('EthnologueCode', 'NewPeopleGroupForm')}</label>
                    <input type={'text'} className={'form-control'} value={peopleGroup.ethnologueCode} onChange={e => this.onChange(e, 'ethnologueCode')} />
                </div>

                <div className={'form-group'}>
                    <label>{Localize.text('LanguageFamily', 'NewPeopleGroupForm')}</label>
                    <input type={'text'} className={'form-control'} value={peopleGroup.languageFamily} onChange={e => this.onChange(e, 'languageFamily')} />
                </div>

                <div className={'form-group'}>
                    <label>{Localize.text('Population', 'NewPeopleGroupForm')}</label>
                    <input type={'number'} className={'form-control'} value={peopleGroup.population} onChange={e => this.onChange(e, 'population')} />
                </div>

                <div className={'row'}>
                    <div className={'col-12'}>
                        <div className={'form-group'}>
                            <label>{Localize.text('HiddenQuestion', 'Universal')}</label>
                            <select className={'form-control'} value={peopleGroup.hidden} onChange={e => this.onChange(e, 'hidden')}>
                                <option value={true}>{Localize.text('Yes', 'Universal')}</option>
                                <option value={false}>{Localize.text('No', 'Universal')}</option>
                            </select>
                        </div>
                    </div>
                </div>

            </div>
        );

    }

}
CreatePeopleGroupForm.propTypes = {
    peopleGroup: PropTypes.object,
    onChange: PropTypes.func
};

class EditPeopleGroups extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            newPeopleGroup: this.defaultPeopleGroup(),
            allPeopleGroups: [],
            originalSelected: [],
            selected: [],
            disabled: false
        };
        bindAll(this, [
            'openNewPeopleGroupModal',
            'closeNewPeopleGroupModal',
            'saveNewPeopleGroup',
            'newPeopleGroupChanged',
            'defaultPeopleGroup',
            'resetNewPeopleGroup',
            'updateLocations',
            'onPeopleGroupAdd',
            'getPeopleGroupInOtherField',
            'onPeopleGroupDelete',
            'onSave',
            'fieldInfo',
        ]);
    }

    defaultPeopleGroup() {
        return {
            name: '',
            alternateName: '',
            publicationName: '',
            ethnologueCode: '',
            languageFamily: '',
            population: 0,
            hidden: false
        };
    }

    async componentWillMount() {
        try {
            const intCol = new Intl.Collator(Localize.locale());
            const { fieldId } = this.props.match.params;
            const locations = await this.updateLocations();
            const selected = locations
                .filter(l => l.field === fieldId)
                .map(l => l.peopleGroup)
                .reduce((arr, p) => arr.some(d => d._id === p._id) ? arr : [...arr, p], [])
                .sort((a, b) => intCol.compare(a.name, b.name))
                .map(p => p._id);
            this.setState({
                ...this.state,
                originalSelected: selected,
                selected
            });
            if(this.props.fields.length === 0) {
                const { errors, data } = await gql.transaction(
                    'query',
                    'getFields',
                    {},
                    ['_id', 'name', 'publicationName', 'countries { _id, name }', 'IMTRequestedFieldRoles { title, category, people }']
                );
                if(errors) throw new Error(errors[0].message);
                const { getFields: fields } = data;
                this.props.setAppFields(fields);
            }
            {
                const { errors, data } = await gql.transaction(
                    'query',
                    'getPeopleGroups',
                    {},
                    ['_id', 'name', 'translationTranslated']
                );
                if(errors) throw new Error(errors[0].message);
                const { getPeopleGroups: peopleGroups } = data;
                this.setState({
                    ...this.state,
                    allPeopleGroups: peopleGroups
                        .sort((a, b) => intCol.compare(a.name, b.name))
                });
            }
            console.log('Done!');
        } catch(err) {
            handleError(err);
        }
    }

    componentDidUpdate() {
        const { disableNav, enableNav } = this.props;

        if (isEqual(this.state.originalSelected, this.state.selected)) {
            enableNav();
        } else {
            disableNav();
        }
    }

    resetNewPeopleGroup() {
        this.setState({
            ...this.state,
            newPeopleGroup: this.defaultPeopleGroup()
        });
    }

    openNewPeopleGroupModal(e) {
        e.preventDefault();
        $(this.newPeopleGroupModal).modal({
            show: true,
            backdrop: 'static',
            keyboard: false
        });
    }

    closeNewPeopleGroupModal(e) {
        if(e) e.preventDefault();
        $(this.newPeopleGroupModal).modal('hide');
        this.resetNewPeopleGroup();
    }

    async saveNewPeopleGroup(e) {
        try {
            e.preventDefault();
            const { fieldId } = this.props.match.params;
            let peopleGroup = this.state.newPeopleGroup;
            peopleGroup = {
                ...peopleGroup,
                population: peopleGroup.population ? parseInt(peopleGroup.population, 10) : 0,
                literacyRateVernacular: peopleGroup.literacyRateVernacular ? parseInt(peopleGroup.literacyRateVernacular, 10) : 0,
                literacyRateLWC: peopleGroup.literacyRateLWC ? parseInt(peopleGroup.literacyRateLWC, 10) : 0,
                newTestamentPercentage: peopleGroup.newTestamentPercentage ? parseInt(peopleGroup.newTestamentPercentage, 10) : 0,
                oldTestamentPercentage: peopleGroup.oldTestamentPercentage ? parseInt(peopleGroup.oldTestamentPercentage, 10) : 0,
                phase1CurriculumStatus: dbGuids.CURRICULUM_STATUS_NOT_STARTED,
                phase2CurriculumStatus: dbGuids.CURRICULUM_STATUS_NOT_STARTED,
                phase3CurriculumStatus: dbGuids.CURRICULUM_STATUS_NOT_STARTED,
                phase4CurriculumStatus: dbGuids.CURRICULUM_STATUS_NOT_STARTED,
            };
            const { errors, data } = await gql.transaction(
                'mutation',
                'createPeopleGroup',
                {
                    input: peopleGroup
                },
                ['_id', 'name']
            );
            if(errors) throw new Error(errors[0].message);
            const newPeopleGroup = data.createPeopleGroup;
            const fieldInfo = this.fieldInfo(fieldId);
            const { errors: errors1 } = await gql.transaction(
                'mutation',
                'createLocation',
                {
                    input: {
                        name: `${dummyLocation}${fieldInfo}`,
                        peopleGroup: newPeopleGroup._id,
                        field: fieldId,
                        churchStage: dbGuids.CHURCH_STAGE_UNKNOWN,
                        country: dbGuids.COUNTRY_UNKNOWN,
                        literacyRateVernacular: 0,
                        literacyRateLWC: 0,
                    },
                },
                ['_id']
            );
            if(errors1) throw new Error('createLocation ' + errors1[0].message);

            this.closeNewPeopleGroupModal();
            // const { errors: errors2, data: data2 } = await gql.transaction(
            //     'query',
            //     'getLocations',
            //     {},
            //     ['_id', 'name', 'peopleGroup', 'country', 'field']
            // );
            // if(errors2) throw new Error(errors2[0].message);
            // this.props.setAppLocations(data2.getLocations);

            // Have to call this before pushing to routerHistory, or when you create a new people group and click on
            //  the Locations tab, it is blank.
            await this.updateLocations();

            routerHistory.push(`/field/${fieldId}/people/${newPeopleGroup._id}`);
        } catch(err) {
            handleError(err);
        }
    }

    fieldInfo(fieldId) {
        const field = this.props.fields.find(f => f._id === fieldId);
         // I think there should always be a field, but check to be safe.
        return field ? ` - ${field.name}` : '';
    }

    async updateLocations() {
        const { errors, data } = await gql.transaction(
            'query',
            'getLocations',
            {},
            ['_id', 'name', 'peopleGroup { _id, name }', 'country { _id, name }', 'field']
        );
        if(errors) throw new Error(errors[0].message);
        const locations = data.getLocations;
        this.props.setAppLocations(locations);
        return locations;
    }

    newPeopleGroupChanged(peopleGroup) {
        this.setState({
            ...this.state,
            newPeopleGroup: peopleGroup
        });
    }

    onPeopleGroupAdd(_id) {
        console.log('add', _id);
        this.setState({
            ...this.state,
            selected: [...this.state.selected, _id]
        });
    }

    getPeopleGroupInOtherField(pgId) {
        const { locations, match: { params } } = this.props;
        const { fieldId } = params;

        for (const loc of locations.filter(l => l.peopleGroup._id === pgId)) {
            if (loc.field !== fieldId) return loc.field;
        }

        return '';
    }

    async onPeopleGroupDelete(_id) {
        console.log('delete', _id);

        const { allPeopleGroups } = this.state;
        const { user, locations, match: { params }, fields } = this.props;
        const { fieldId } = params;

        if (!user.fmdbAdministrator) {
            await swal({
                text: Localize.text('ToRemoveAPeopleGroupFromAFieldOrToDeleteAPeopleGroupPleaseContactAnAdministrator', 'PeopleGroupsForm'),
                button: Localize.text('OK', 'Universal'),
            });
            return;
        }

        // t194 handle peoplegroup delete request better
        const peopleGroupIsInMultipleFields = !!this.getPeopleGroupInOtherField(_id);

        // if the people group has no locations for this field, we just added it and haven't saved yet,
        //  and now we are removing it.
        const peopleGroupHasFieldLocations = locations
            .some(l => l.field === fieldId && l.peopleGroup._id === _id);
        const peopleGroup = allPeopleGroups.find(pg => pg._id === _id);
        const peopleGroupName = peopleGroup ? peopleGroup.name : '';

        if (peopleGroupHasFieldLocations) {
            const { alertText, deleteButtonText } = peopleGroupIsInMultipleFields ? {
                alertText: Localize.text('AreYouSureThatYouWantToRemovePeopleGroupFromField', 'PeopleGroupsForm', {
                    peopleGroup: peopleGroupName,
                    field: fields.find(f => f._id === fieldId).name
                }),
                deleteButtonText: Localize.text('YesRemovePeopleGroup', 'PeopleGroupsForm'),
            } : {
                alertText: Localize.text('AreYouSureThatYouWantToPermanentlyDELETEThePeopleGroupPeopleGroupAndAllItsLocations', 'PeopleGroupsForm', {
                    peopleGroup: peopleGroupName,
                }),
                deleteButtonText: Localize.text('YesDELETEPeopleGroup', 'PeopleGroupsForm')
            };

            const confirmed = await swal({
                text: alertText,
                icon: 'warning',
                buttons: [
                    Localize.text('Cancel', 'Universal'),
                    deleteButtonText,
                ]
            });

            if (!confirmed) return;
        }

        const passDeleteChecks = async () => {
            const failedChecks = [];

            // Translation?
            const { allPeopleGroups } = this.state;
            const pg = allPeopleGroups.find(g => g._id === _id);
            if (pg.translationTranslated && pg.translationTranslated.length) {
                failedChecks.push(Localize.text('YouMustDeleteAllTranslationForThisPeopleGroupBeforeYouDeleteIt', 'PeopleGroupsForm'));
            }

            // Personnel Statuses?
            if (this.props.personGroupStatuses.some(s => s.peopleGroup === _id)) {
                failedChecks.push(Localize.text('YouMustRemoveAllPersonnelFromThisPeopleGroupBeforeYouCanDeleteIt', 'PeopleGroupsForm'));
            }

            // Personnel activePeopleGroup?
            const peopleWithActivePeopleGroup = this.props.people.filter(p => p.activePeopleGroup === _id);
            if (peopleWithActivePeopleGroup.size) {
                failedChecks.push(Localize.text('TheFollowingPeopleHaveThisPeopleGroupSetAsTheirActivePeopleGroupSoThePeopleGroupCannotBeDeletedPeopleList',
                    'PeopleGroupsForm', {
                        peopleList: [...peopleWithActivePeopleGroup.values()]
                            .map(p => `${p.firstName} ${p.lastName}`)
                            .join(', '),
                    }));
            }

            // Files?
            const { errors: fileErrors, data: fileData } = await gql.transaction(
                'query',
                'getFiles',
                {
                    peopleGroup: _id,
                },
                gqlAttributes.file()
            );
            if(fileErrors) throw fileErrors[0];
            if (fileData.getFiles.length) {
                failedChecks.push(Localize.text('YouMustDeleteAllFilesAndFoldersFromThisPeopleGroupBeforeYouCanDeleteIt', 'PeopleGroupsForm'));
            }

            // Consultants?
            const { errors: consultantErrors, data: consultantData } = await gql.transaction(
                'query',
                'getPeopleGroupConsultants',
                { peopleGroupIds: [_id] },
                [
                    '_id',
                ]
            );
            if(consultantErrors) throw consultantErrors[0];
            if (consultantData.getPeopleGroupConsultants.length) {
                failedChecks.push(Localize.text('YouMustRemoveAllConsultantsFromThisPeopleGroupBeforeYouCanDeleteIt', 'PeopleGroupsForm'));
            }

            if (failedChecks.length) {
                await swal({
                    text: failedChecks.join('\n'),
                    icon: 'info',
                    button: Localize.text('OK', 'Universal'),
                });
                return false;
            }

            return true;
        };

        if (peopleGroupIsInMultipleFields || await passDeleteChecks()) {
            this.setState({
                ...this.state,
                selected: this.state.selected
                    .filter(s => s !== _id)
            });
        }
    }

    async onSave() {
        try {

            this.setState({
                ...this.state,
                disabled: true
            });

            const { locations, match: { params } } = this.props;
            const { fieldId } = params;
            const { selected, originalSelected } = this.state;
            const added = selected
                .filter(_id => !originalSelected.includes(_id));
            const removed = originalSelected
                .filter(_id => !selected.includes(_id));

            // t194 handle delete people group better
            for (const pgId of removed) {
                const otherField = this.getPeopleGroupInOtherField(pgId);
                if (otherField) {
                    // Move all locations from fieldId to otherField:
                    //  in Locations table where peopleGroup is pgId and field is fieldId, set field to otherField.
                    for (const loc of locations.filter(l => l.peopleGroup._id === pgId && l.field === fieldId)) {
                        const { errors } = await gql.transaction(
                            'mutation',
                            'updateLocation',
                            {
                                _id: loc._id,
                                input: { field: otherField }
                            },
                            ['_id']
                        );
                        if(errors) throw new Error(errors[0].message);
                    }
                } else {
                    // Delete the people group:
                    // delete all locations where peopleGroup is pgId from Locations table
                    for (const loc of locations.filter(l => l.peopleGroup._id === pgId)) {
                        const { errors: locationErrors, data } = await gql.transaction(
                            'mutation',
                            'deleteLocation',
                            { _id: loc._id }
                        );
                        if(locationErrors) throw new Error(locationErrors[0].message);
                        const result = JSON.parse(data.deleteLocation);
                        if (Array.isArray(result)) {
                            const msg = `${Localize.text('ThisPeopleGroupCannotBeDeletedBecauseItHasALocationThatIsReferencedInOtherPlacesInTheDatabase', 'PeopleGroupsForm')}\n\n${result.join('\n')}`;
                            await swal({
                                icon: 'error',
                                title: Localize.text('CannotDelete', 'Universal'),
                                text: msg,
                            });
                            return;
                        }
                    }

                    // delete all TranslationDetails where peopleGroup is pgId
                    const { errors: tdErrors } = await gql.transaction(
                        'mutation',
                        'deleteTranslationDetails',
                        { peopleGroup: pgId }
                    );
                    if(tdErrors) throw new Error(tdErrors[0].message);

                    //  - delete the row where _id is pgId from the PeopleGroups table
                    const { errors: pgErrors, data } = await gql.transaction(
                        'mutation',
                        'deletePeopleGroup',
                        { _id: pgId, locale: Localize.locale() }
                    );
                    if(pgErrors) throw new Error(pgErrors[0].message);
                    const result = JSON.parse(data.deletePeopleGroup);
                    if (Array.isArray(result)) {
                        const msg = `${Localize.text('ThisPeopleGroupCannotBeDeletedBecauseItIsReferencedInOtherPlacesInTheDatabase', 'PeopleGroupsForm')}\n\n${result.join('\n')}`;
                        await swal({
                            icon: 'error',
                            title: Localize.text('CannotDelete', 'Universal'),
                            text: msg,
                        });
                        return;
                    }
                }
            }

            const fieldInfo = this.fieldInfo(fieldId);
            for(const _id of added) {
                const { errors } = await gql.transaction(
                    'mutation',
                    'createLocation',
                    {
                        input: {
                            name: `${dummyLocation}${fieldInfo}`,
                            peopleGroup: _id,
                            field: fieldId,
                            churchStage: dbGuids.CHURCH_STAGE_UNKNOWN,
                            country: dbGuids.COUNTRY_UNKNOWN,
                            literacyRateVernacular: 0,
                            literacyRateLWC: 0,
                        },
                    },
                    ['_id']
                );
                if(errors) throw new Error(errors[0].message);
            }

            await this.updateLocations();
            // We have to enableNav as the last thing before pushing to history,
            //  or else a re-render will cause disableNav to be called from componentDidUpdate if we deleted a peopleGroup.
            this.props.enableNav();
            routerHistory.push(`/field/${fieldId}`);
        } catch(err) {
            handleError(err);
        }
    }

    render() {

        const { newPeopleGroup, allPeopleGroups, selected, disabled } = this.state;
        const { fields, match: { params } } = this.props;
        const field = fields.find(f => f._id === params.fieldId);

        if(!field) return <div />;

        return (
            <div className={'container-fluid'}>

                <div className={'row'}>
                    <div className={'col-12'}>
                        <h1>{Localize.text('FieldPeopleGroups', 'PeopleGroupsForm', {field: field.name})}</h1>
                    </div>
                </div>

                <div className={'row'}>
                    <div className={'col-sm-9 col-md-7 col-lg-5'}>
                        <MultiSelect title={Localize.text('PeopleGroups', 'PeopleGroupsForm')}
                                     addTitle={Localize.text('AddPeopleGroup', 'PeopleGroupsForm')}
                                     filterable={true}
                                     items={allPeopleGroups}
                                     selected={selected}
                                     onAdd={this.onPeopleGroupAdd}
                                     onDelete={this.onPeopleGroupDelete} />
                    </div>
                </div>

                <div className={'row'}>
                    <div className={'col-sm-9 col-md-7 col-lg-5'}>
                        <div className={'form-group'}>
                            <button style={{width: '100%'}} className={'btn btn-outline-secondary'} onClick={this.openNewPeopleGroupModal}><i className={'fa fa-plus'} /> {Localize.text('CreateNewPeopleGroup', 'PeopleGroupsForm')}</button>
                        </div>
                    </div>
                </div>

                <div className={'row'}>
                    <div className={'col-12'}>
                        <div className={'form-group'}>
                            <button className={'btn btn-primary'} onClick={this.onSave} disabled={disabled}>{Localize.text('SavePeopleGroupChanges', 'PeopleGroupsForm')}</button>
                        </div>
                    </div>
                </div>

                <div className={'modal'} ref={node => this.newPeopleGroupModal = node}>
                    <div className={'modal-dialog modal-lg'}>
                        <div className={'modal-content'}>
                            <div className={'modal-header'}>
                                <h5 className="modal-title">{Localize.text('NewPeopleGroup', 'PeopleGroupsForm')}</h5>
                                <button type="button" className="close" onClick={this.closeNewPeopleGroupModal}>
                                    <span>&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">

                                <CreatePeopleGroupForm peopleGroup={newPeopleGroup} onChange={this.newPeopleGroupChanged} />

                            </div>
                            <div className="modal-footer">
                                <button type={'button'} className={'btn btn-secondary'} onClick={this.closeNewPeopleGroupModal}>{Localize.text('Cancel', 'Universal')}</button>
                                <button type={'button'} className={'btn btn-primary'} onClick={this.saveNewPeopleGroup}>{Localize.text('SaveNewPeopleGroup', 'PeopleGroupsForm')}</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }

}
EditPeopleGroups.propTypes = {
    windowHeight: PropTypes.number,
    user: PropTypes.object,
    fields: PropTypes.arrayOf(PropTypes.object),
    locations: PropTypes.arrayOf(PropTypes.object),
    personGroupStatuses: PropTypes.arrayOf(PropTypes.object),
    people: PropTypes.instanceOf(Map),
    match: PropTypes.object,
    setAppLocations: PropTypes.func,
    setAppFields: PropTypes.func,
    disableNav: PropTypes.func,
    enableNav: PropTypes.func,
};

export default EditPeopleGroups;
