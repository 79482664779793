export const checkPassword = s => {
    s = s.trim();
    if(!s || s.length < 10) return false;
    const split = s.split('');
    const hasUpper = split.some(c => /[ABCDEFGHIJKLMNOPQRSTUVWXYZ]/.test(c));
    if(!hasUpper) return false;
    const hasLower = split.some(c => /[abcdefghijklmnopqrstuvwxyz]/.test(c));
    if(!hasLower) return false;
    const hasNumber = /\d/.test(s);
    if(!hasNumber) return false;
    return true;
};
