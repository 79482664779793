import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import swal from 'sweetalert';
import Translation from './components/translation';
import Summary from './components/summary';
import Materials from './components/materials';
import Locations from './components/locations';
import Personnel from './components/personnel';
import Files from './components/files';
import callAttributes from '../../../util/gql-call-attributes';
import { modalStatuses } from '../../../constants';

// const yesNo = bool => {
//     if(bool) {
//         return <span>{Localize.text('Yes', 'Universal')}</span>;
//     } else {
//         return <span>{Localize.text('No', 'Universal')}</span>;
//     }
// };
const tabBorderColor = '#aaa';

const styles = {
    tabContainer: {
        height: 32,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'flex-start',
        borderBottomWidth: 1,
        borderBottomColor: tabBorderColor,
        borderBottomStyle: 'solid'
    },
    container: {
        // marginTop: 15
    },
    title: {
        marginTop: 0,
    },
    innerContainer: {
        paddingTop: 15,
        borderLeftWidth: '1px',
        borderRightWidth: '1px',
        borderLeftStyle: 'solid',
        borderRightStyle: 'solid',
        borderLeftColor: '#dee2e6',
        borderRightColor: '#dee2e6',
    },
};

const PeopleGroupDetail = ({
                               peopleGroups,
                               user,
                               locations: locationsMap,
                               statusesMap,
                               consultantsMap,
                               allLocations,
                               allFields,
                               params,
                               queryParams,
                               localizedLists,
                               windowHeight,
                               windowWidth,
                               setPeopleGroup,
                               setLocations,
                               setStatuses,
                               setConsultants,
                               setModalStatus,
                           }) => {
    const { fieldId = '', peopleId = '' } = params;

    useEffect(() => {
        const getData = async peopleGroupId => {
            try {
                swal({
                    text: Localize.text('LoadingPeopleGroupData', 'PeopleGroupDetail'),
                    buttons: false,
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                });

                const res = await gql.transaction(
                    'query',
                    'getPersonGroupStatuses',
                    { peopleGroupId: peopleGroupId },
                    [
                        '_id',
                        'peopleGroup',
                        'person',
                        'primaryRole',
                        'primaryRoleDescription',
                        'secondaryRole',
                        'secondaryRoleDescription',
                        'claLevel',
                        'startDate',
                        'endDate',
                        'itinerantStartDate',
                        'itinerantEndDate',
                        'serviceStatus',
                        'location',
                    ]
                );
                if(res.errors) throw res.errors[0];
                const { getPersonGroupStatuses: personGroupStatuses } = res.data;

                setStatuses(peopleGroupId, personGroupStatuses);

                const res1 = await gql.transaction(
                    'query',
                    'getPeopleGroupConsultants',
                    { peopleGroupIds: [peopleGroupId] },
                    [
                        '_id',
                        'peopleGroup',
                        'person',
                        'consultantType',
                        'startDate',
                        'endDate',
                    ]
                );
                if(res1.errors) throw res1.errors[0];
                const { getPeopleGroupConsultants: peopleGroupConsultants } = res1.data;

                setConsultants(peopleGroupId, peopleGroupConsultants);

                const { errors, data } = await gql.transaction(
                    'query',
                    'getPeopleGroup',
                    {
                        _id: peopleGroupId,
                    },
                    callAttributes.peopleGroupDetail()
                );
                if(errors) throw new Error(errors[0].message);
                const { getPeopleGroup: peopleGroup } = data;
                setPeopleGroup(peopleGroup);
                if(allLocations.length === 0) {
                    const { errors: errors1, data: data1 } = await gql.transaction(
                        'query',
                        'getLocations',
                        {},
                        ['_id', 'peopleGroup { _id }']
                    );
                    if(errors1) throw new Error(errors1[0].message);
                    allLocations = data1.getLocations;
                }
                const ids = allLocations
                    .filter(l => (l.peopleGroup && l.peopleGroup._id === peopleGroup._id))
                    .map(l => l._id);
                const locationData = await Promise.all(ids.map(_id => gql.transaction(
                    'query',
                    'getLocation',
                    { _id },
                    callAttributes.locationDetail()
                )));
                const errorIdx = locationData
                    .findIndex(d => d.errors && d.errors.length > 0);
                if(errorIdx > -1) throw new Error(locationData[errorIdx].errors[0].message);
                const locations = locationData
                    .map(l => l.data.getLocation);
                setLocations(locations);
                swal.close();
            } catch(err) {
                swal.close();
                handleError(err);
            }
        };

        if(peopleId) getData(peopleId).then();
    }, [peopleId]);

    const onTranslationChange = async (book, passages, percentage) => {
        try {
            const peopleGroup = peopleGroups.get(peopleId);
            const { translationTranslated } = peopleGroup;
            const newTranslated = translationTranslated
                .filter(t => t.slice(0, 3) !== book)
                .concat(passages);
            const newItems = {
                translationTranslated: newTranslated,
                translationPercentages: peopleGroup.translationPercentages
                    .filter(({ book: b }) => b !== book)
                    .concat({ book, percentage }),
            };

            const newPeopleGroup = {
                ...peopleGroup,
                ...newItems,
            };

            setPeopleGroup(newPeopleGroup);
            await savePeopleGroup(peopleGroup._id, newItems);
        } catch(err) {
            handleError(err);
        }

    };

    const onTotalPercentageChange = async (otPercent, ntPercent) => {
        try {
            const peopleGroup = peopleGroups.get(peopleId);
            const newItems = {
                oldTestamentPercentage: Math.round(otPercent),
                newTestamentPercentage: Math.round(ntPercent),
            };

            const newPeopleGroup = {
                ...peopleGroup,
                ...newItems,
            };

            setPeopleGroup(newPeopleGroup);
            await savePeopleGroup(peopleGroup._id, newItems);

        } catch(err) {
            handleError(err);
        }
    };

    const savePeopleGroup = async (_id, newPeopleGroup) => {
        const { errors, data } = await gql.transaction(
            'mutation',
            'updatePeopleGroup',
            {
                _id,
                input: newPeopleGroup,
            },
            ['_id', 'name']
        );
        if(errors) throw new Error(errors[0].message);
        const peopleGroup = data.updatePeopleGroup;
    };

    const setPeopleGroupFields = data => {
        const peopleGroup = peopleGroups.get(peopleId);
        const newPeopleGroup = {
            ...peopleGroup,
            ...data,
        };
        setPeopleGroup(newPeopleGroup);
        savePeopleGroup(peopleId, data).then();
    };

    const onNameChange = async e => {
        try {
            e.preventDefault();
            const peopleGroup = peopleGroups.get(peopleId);
            setModalStatus(modalStatuses.SHOWING_SWAL);
            let name = await swal({
                text: Localize.text('EnterPeopleGroupName', 'PeopleGroupDetail'),
                content: {
                    element: 'input',
                    attributes: {value: peopleGroup.name}
                },
                buttons: [Localize.text('Cancel', 'Universal'), Localize.text('Save', 'Universal')]
            });

            if (name) {
                name = name.trim();

                if (name && name !== peopleGroup.name) {
                    const newField = {
                        name
                    };

                    setPeopleGroupFields(newField);
                }
            }

            setModalStatus(modalStatuses.NO_MODAL);
        } catch(err) {
            handleError(err);
        }
    };

    const onChangeHidden = async e => {
        try {
            e.preventDefault();
            if(user && !user.fmdbAdministrator) return;
            const peopleGroup = peopleGroups.get(peopleId);
            const { hidden } = peopleGroup;
            setModalStatus(modalStatuses.SHOWING_SWAL);
            const confirmed = await swal({
                title: hidden ? Localize.text('ShowPeopleGroup', 'PeopleGroupDetail') : Localize.text('HidePeopleGroup', 'PeopleGroupDetail'),
                text: hidden ? Localize.text('WouldYouLikeToMakeThisPeopleGroupVisible', 'PeopleGroupDetail') : Localize.text('WouldYouLikeToMakeThisPeopleGroupHidden', 'PeopleGroupDetail'),
                icon: 'warning',
                buttons: [
                    Localize.text('Cancel', 'Universal'),
                    Localize.text('OK', 'Universal'),
                ],
            });
            if (confirmed) {
                const newItems = { hidden: !hidden };
                const newPeopleGroup = {
                    ...peopleGroup,
                    ...newItems,
                };
                setPeopleGroup(newPeopleGroup);
                await savePeopleGroup(newPeopleGroup._id, newItems);
            }

            setModalStatus(modalStatuses.NO_MODAL);
        } catch(err) {
            handleError(err);
        }
    };

    const { listManager } = window;
    const listItems = listManager.getItems(localizedLists);

    const { tab } = queryParams;

    if(!peopleGroups.has(peopleId)) {
        return (
            <div />
        );
    }

    const peopleGroup = peopleGroups.get(peopleId);

    const editable = peopleGroup.level > 1;

    console.log('peopleGroup', peopleGroup);

    const locations = allLocations
        .filter(l => (l.peopleGroup && l.peopleGroup._id === peopleGroup._id))
        .map(l => l._id)
        .filter(_id => locationsMap.has(_id))
        .map(_id => locationsMap.get(_id));

    // console.log('locations', locations);

    const field = allFields.find(f => f._id === fieldId);

    let locationAvailable;
    const locationId = queryParams.location ? queryParams.location : locations.length > 0 ? locations[0]._id : '';
    if(tab === 'locations' && locationId) {
        locationAvailable = locations.some(l => l._id === locationId);
    } else {
        locationAvailable = false;
    }

    const personGroupStatuses = statusesMap.has(peopleId) ? statusesMap.get(peopleId) : [];
    const consultants = consultantsMap.has(peopleId) ? consultantsMap.get(peopleId) : [];

    return (
        <div style={styles.container}>
            <div className={'row'}>
                <div className={'col-12'}>
                    <div className={'form-group'}>
                        <h6>{Localize.text('PeopleGroupName', 'PeopleGroupDetail')}: <a href={'#'} onClick={onNameChange}><i style={{display: editable ? 'inline' : 'none'}} className={'fa fa-pencil '} /></a></h6>
                        <h4 style={styles.title}>{`${peopleGroup.name} (${field ? field.name : ''})`}</h4>
                    </div>
                </div>
            </div>
            <div style={{display: user && user.fmdbAdministrator ? 'block' : 'none'}} className={'row'}>
                <div className={'col-lg-8 col-md-7 col-sm-6'}>
                    <div className={'form-group'}>
                        {peopleGroup.hidden ?
                            <a onClick={onChangeHidden} href={'#'} className={'text-danger'}>{Localize.text('PeopleGroupIsHidden', 'PeopleGroupDetail')}</a>
                            :
                            <a onClick={onChangeHidden} href={'#'}>{Localize.text('PeopleGroupIsVisible', 'PeopleGroupDetail')}</a>
                        }
                    </div>
                </div>
            </div>
            <div style={styles.tabContainer} className="header-tab-bar">
                <NavLink to={`/field/${fieldId}/people/${peopleId}?tab=summary`} isActive={() => !tab || tab === 'summary'} activeClassName={'active'} style={{minWidth: 120}} className={'header-tab-link'}>{Localize.text('Summary', 'PeopleGroupDetail')}</NavLink>
                <NavLink to={`/field/${fieldId}/people/${peopleId}?tab=personnel`} isActive={() => tab === 'personnel'} activeClassName={'active'} style={{minWidth: 120}} className={'header-tab-link'}>{Localize.text('Personnel', 'PeopleGroupDetail')}</NavLink>
                <NavLink to={`/field/${fieldId}/people/${peopleId}?tab=materials`} isActive={() => tab === 'materials'} activeClassName={'active'} style={{minWidth: 120}} className={'header-tab-link'}>{Localize.text('MaterialsDeveloped', 'PeopleGroupDetail')}</NavLink>
                <NavLink to={`/field/${fieldId}/people/${peopleId}?tab=locations`} isActive={() => tab === 'locations'} activeClassName={'active'} style={{minWidth: 120}} className={'header-tab-link'}>{Localize.text('Locations', 'PeopleGroupDetail')}</NavLink>
                <NavLink to={`/field/${fieldId}/people/${peopleId}?tab=translation`} isActive={() => tab === 'translation'} activeClassName={'active'} style={{minWidth: 120}} className={'header-tab-link'}>{Localize.text('Translation', 'PeopleGroupDetail')}</NavLink>
                <NavLink to={`/field/${fieldId}/people/${peopleId}?tab=files`} isActive={() => tab === 'files'} activeClassName={'active'} style={{display: editable ? {minWidth: 120} : 'none'}} className={'header-tab-link'}>{Localize.text('PeopleGroupFiles', 'PeopleGroupDetail')}</NavLink>
            </div>
            <div style={styles.innerContainer}>{
                tab === 'personnel' ?
                    <Personnel fieldId={fieldId} peopleId={peopleId} statuses={personGroupStatuses} localizedListItems={listItems} localizedLists={localizedLists} peopleGroup={peopleGroup} readonly={!editable} save={setPeopleGroupFields} />
                    :
                    tab === 'materials' ?
                        <Materials peopleGroup={peopleGroup} localizedListItems={listItems} localizedLists={localizedLists} save={setPeopleGroupFields} windowHeight={windowHeight} readonly={!editable} />
                        :
                        (tab === 'locations' && locationAvailable) ?
                            <Locations locationId={locationId} allLocations={allLocations} windowHeight={windowHeight} params={params} queryParams={queryParams} field={field} peopleGroup={peopleGroup} locations={locations} localizedLists={localizedLists} localizedListItems={listItems} readonly={!editable} />
                            :
                            (tab === 'locations' && !locationAvailable) ?
                                <div />
                                :
                                tab === 'translation' ?
                                    <Translation peopleGroup={peopleGroup} windowWidth={windowWidth} windowHeight={windowHeight} onTranslationChange={onTranslationChange} readonly={!editable}  onTotalPercentageChange={onTotalPercentageChange} />
                                    :
                                    tab === 'files' ?
                                        <Files peopleGroup={peopleGroup} />
                                        :
                                        <Summary fieldId={fieldId} peopleId={peopleId} peopleGroup={peopleGroup} statuses={personGroupStatuses} consultants={consultants} locations={locations} localizedListItems={listItems} localizedLists={localizedLists} windowHeight={windowHeight} save={setPeopleGroupFields} readonly={!editable} isAdmin={user && user.fmdbAdministrator} />
            }</div>
        </div>
    );
};
PeopleGroupDetail.propTypes = {
    peopleGroups: PropTypes.instanceOf(Map),
    user: PropTypes.object,
    locations: PropTypes.instanceOf(Map),
    statusesMap: PropTypes.instanceOf(Map),
    consultantsMap: PropTypes.instanceOf(Map),
    location: PropTypes.object,
    allLocations: PropTypes.arrayOf(PropTypes.object),
    allFields: PropTypes.arrayOf(PropTypes.object),
    localizedLists: PropTypes.arrayOf(PropTypes.object),
    params: PropTypes.object,
    queryParams: PropTypes.object,
    windowHeight: PropTypes.number,
    windowWidth: PropTypes.number,
    setPeopleGroup: PropTypes.func,
    setLocations: PropTypes.func,
    setStatuses: PropTypes.func,
    setConsultants: PropTypes.func,
    setModalStatus: PropTypes.func,
};

export default PeopleGroupDetail;
