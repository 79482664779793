import React from 'react';
import PropTypes from 'prop-types';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { connect } from 'react-redux';

const myLoading = '<span />';//'<span>I am loading</span>';
const myNoRows = '<span />';

const Report1 = ({ reportTitle, columnDefs, rowData, aboveGridHeight, windowHeight, setGridApi, onFilterChanged }) => {
    const showTitle = reportTitle.length ? <h4 className="text-center">{reportTitle}</h4> : null;

    const styles = {
        wrapper: {
            display: 'flex',
            flexDirection: 'column',
            height: windowHeight - aboveGridHeight,
        },
        gridWrapper: {
            flex: '1 1 auto',
            width: '100%'
        }
    };

    const onGridReady = params => {
        setGridApi(params.api);
    };

    // Now that I found the Reset button for the filter, I don't think we need the 'empty' option anymore.
    //  But I'm leaving localeTextFunc in place in case we ever activate the 'empty' option.
    //
    // WE DON'T NEED THIS NOW:  We want to change the empty filter (which currently displays 'Choose One') to display 'No Filter'.
    //  We will also want to localize the grid at some point probably:  see https://www.ag-grid.com/javascript-grid-localisation/
    //  NOTE: whether or not use use localTextFunc, the width of the filter drop-down seems to be determined
    //  by the text that is displayed for the first drop-down item.  So we use 'Not Filtered' to be wide enough
    //  for our other English texts in the drop-down.
    const localeTextFunc = (key, defaultValue) => key === 'empty' ? 'Not Filtered' : defaultValue;

    return (
        <div style={styles.wrapper}>
            <div style={styles.gridWrapper}>
                <div className="ag-theme-alpine" style={{height: '100%'}}>
                    <AgGridReact
                        columnDefs={columnDefs}
                        rowData={rowData}
                        accentedSort={true}
                        overlayLoadingTemplate={myLoading}
                        overlayNoRowsTemplate={myNoRows}
                        onGridReady={onGridReady}
                        onFilterChanged={onFilterChanged}
                        localeTextFunc={localeTextFunc}
                        rowSelection={'multiple'}       /* shift-click to select multiple rows */
                    >
                    </AgGridReact>
                </div>
            </div>
        </div>
    );
};
Report1.propTypes = {
    reportTitle: PropTypes.string,
    columnDefs: PropTypes.arrayOf(PropTypes.object),
    rowData: PropTypes.arrayOf(PropTypes.object),
    aboveGridHeight: PropTypes.number,
    setGridApi: PropTypes.func,
    onFilterChanged: PropTypes.func,
    user: PropTypes.object,
    windowHeight: PropTypes.number,
};
const Report1Container = connect(
    ({ appState }) => ({
        user: appState.user,
        windowHeight: appState.windowHeight,
    })
)(Report1);

export default Report1Container;
