import '@babel/polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, combineReducers } from 'redux';
import { Provider } from 'react-redux';
import { Router, Route } from 'react-router-dom';
import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import qhistory from 'qhistory';
import { stringify, parse } from 'qs';
import { Map } from 'immutable';
import swal from 'sweetalert';
import request from 'superagent';
import { IdleSessionTimeout } from 'idle-session-timeout';
import StackTrace from 'stacktrace-js';

import Localizer from './util/localize';
import appReducer from './reducers/app-reducer';
import fieldReducer from './reducers/field-reducer';
import peopleGroupReducer from './reducers/people-group-reducer';
import trainingReducer from './reducers/training-reducer';
import {
    setAppLocale,
    setAppLocaleData,
    setAppUserData,
    setAppWindowSize,
    setAppPeople,
    setAppCountries,
    setAppFields,
    setAppRegions,
    setAppPersonGroupStatuses,
    setAppTrainings,
    setAppPersonToFieldPeopleGroup,
    setAppDisableNav,
    setAppModalStatus,
    setAppInitialServerLoadDone,
} from './actions/app-actions';
import App from './components/app';
import GQL from './util/gql';
import LocalizedLists from './util/localized-list';
import gqlCallAttributes from './util/gql-call-attributes';
import {
    loggedOutInternalErrorMessage,
    maxInactiveMinutes,
    minutesToShowWarningBeforeLogout,
    modalStatuses,
    suppressErrorEmail,
} from './constants';

// class Timer {
//     constructor(label) {
//         this._label = label;
//     }
//     start() {
//         this._start = performance.now();
//     }
//     stop() {
//         const end = performance.now();
//         const total = end - this._start;
//         console.log(`${this._label} finished in ` + total + ' milliseconds.');
//     }
// }
// window.Timer = Timer;

require('superagent-jsonapify')(request);

const endpoint = '/graphql';
const gql = new GQL({ endpoint });
window.gql = gql;
let loggedOutOnServer = false;

const showLoggedOut = async () => {
    loggedOutOnServer = true;

    // Probably we returned null from a server endpoint, probably b/c the user was signed out.  So show login.
    if (window.Localize) {
        await swal({
            title: Localize.text('LoggedOut', 'Universal'),
            text: Localize.text('YouHaveBeenLoggedOutPerhapsInAnotherBrowserTabPleaseLogInAgain', 'Universal'),
            icon: 'error',
        });
    } else {
        await swal({
            title: 'Logged out',
            text: 'You have been logged out, perhaps in another browser tab.  Please log in again.',
            icon: 'error',
        });
    }

    window.location = '/logout';
};

window.handleError = err => {
    console.error('window.handleError', err);

    StackTrace.fromError(err).then((stackFrames) => {
        console.log('stackFrames', stackFrames);
        let needToReportToDevelopers = true;

        // t230 show a reasonable error message if the user was logged out on the server somehow,
        //  for example, if they were logged in on another tab that timed out with no activity.
        // NB: different browsers give different messages: https://stackoverflow.com/a/52180625/385655
        // Instead of trying to handle all browsers, refactor so all superagent and gql.transactions go through
        //  a function that tests for null return value.
        // if ((err && err.message) && (err.message === 'Cannot read property \'errors\' of null' || /^null is not an object \(evaluating '.\.errors'\)$/.test(err.message))) {
        if ((err && err.message) && (err.message === loggedOutInternalErrorMessage)) {
            showLoggedOut().then();
            return;
        }

        if (err.message.startsWith(suppressErrorEmail)) {
            needToReportToDevelopers = false;
            err.message = err.message.replace(suppressErrorEmail, '');
        }

        if(window.Localize) {
            swal(Localize.text('Error', 'Universal'), err.message, 'error').then();
        } else {
            swal('Error', err.message, 'error').then();
        }

        if (!needToReportToDevelopers) return;

        const { message = '', stack = '' } = err;

        const user = window.localStorage ? localStorage.getItem('user') : {};
        const userObj = JSON.parse(user);
        const userInfo = userObj.firstName + ' ' + userObj.lastName + '  user._id: ' + userObj._id;

        request
            .post('/errorreports')
            .send({ message, stack, userInfo, stackFrames })
            .catch(console.error);
    }).catch(myErr => console.log('myErr', myErr));
};

window.addEventListener('error', ({ error }) => {
    handleError(error);
});

window.onunhandledrejection = event => handleError(new Error(event.reason.message));

// t374 In test or live (not dev server), closing tab does not log user out.
// 'unload' should be avoided.  See https://developer.mozilla.org/en-US/docs/Web/API/Window/unload_event
// window.addEventListener('unload', function logData() {
//     if (!devNoAutoLogout) navigator.sendBeacon('/logmeout');
// });
// got this from https://developers.google.com/web/updates/2018/07/page-lifecycle-api#developer-recommendations-for-each-state
window.addEventListener('pagehide', (event) => {
    if (!event.persisted && !devNoAutoLogout) {
        // If the event's persisted property is not `true` the page is about to be unloaded.
        navigator.sendBeacon('/logmeout');
    }
}, {capture: true});

const devNoAutoLogout = false;   // set this to true for development, to avoid having to keep re-logging in
const productionTimeout = true;    // set this to false to test session timeout stuff.
const msSessionTimeout = productionTimeout ? maxInactiveMinutes * 60 * 1000 : 20 * 1000;
const msSessionShowWarning = productionTimeout ? minutesToShowWarningBeforeLogout * 60 * 1000 : 10 * 1000;

const session = new IdleSessionTimeout(msSessionTimeout);

session.onTimeOut = () => {
    if (!devNoAutoLogout) window.location = '/logout';
};

let timestampMs = Date.now();

session.onTimeLeftChange = async msLeft => {
    // t284 Rand's computer doesn't log out automatically when he walks away and comes back the next day.
    //  I wonder if his computer goes to sleep before the session times out.  If so, keeping track of the timestamp as below
    //  should cause him to be logged out when he wakes the computer.
    //  I have not been able to duplicate the issue, but whatever the cause, this should fix it I think.

    if (devNoAutoLogout) return;

    const newTimestampMs = Date.now();
    if (newTimestampMs - timestampMs > msSessionTimeout) {
        window.location = '/logout';
    }

    timestampMs = newTimestampMs;

    if (msLeft <= msSessionShowWarning && !loggedOutOnServer) {
        const logout = await swal({
            title: Localize.text('DoYouWantToStayLoggedIn', 'Universal'),
            icon: 'warning',
            buttons: [
                Localize.text('StayLoggedIn', 'Universal'),
                Localize.text('LogOut', 'Universal'),
            ],
        });

        if (logout) {
            window.location = '/logout';
        }
    }
};

session.start();

const history = qhistory(
    createBrowserHistory({
        // the function below DOES get called when needed (I assume by qHistory), in spite of the linter not realizing that.
        async getUserConfirmation(msg, callback) {
            const state = store.getState();

            if (state.appState.modalStatus === modalStatuses.SHOWING_SWAL) {
                callback(false);
                return;
            }

            const canceled = state.appState.disableNav ?
                await swal({
                    title: Localize.text('LoseChangesQ', 'UniversalForms'),
                    text: Localize.text('YouHaveMadeChangesThatHaveNotBeenSavedClickCancelForAChanceToSaveYourChanges', 'UniversalForms'),
                    icon: 'warning',
                    buttons: [
                        Localize.text('LoseChanges', 'UniversalForms'),
                        Localize.text('Cancel', 'Universal'),
                    ]
                }) : false;

            if (!canceled) {
                if (state.appState.modalStatus === modalStatuses.SHOWING_MODAL) {
                    store.dispatch(setAppModalStatus(modalStatuses.CLOSE_MODAL));
                }

                store.dispatch(setAppDisableNav(false));
            }

            callback(!canceled);
        }
    }),
    stringify,
    parse
);
history.block('');  // so getUserConfirmation is called.
window.routerHistory = history;

const combinedReducers = combineReducers({
    appState: appReducer,
    fieldState: fieldReducer,
    peopleGroupState: peopleGroupReducer,
    trainingState: trainingReducer,
    router: connectRouter(history),
});

const store = createStore(combinedReducers);

store.subscribe(() => {
    const state = store.getState();
    console.log('state', state);
    // console.log('people', [...state.appState.people.values()]);
});

window.addEventListener('resize', e => {
    const { innerWidth, innerHeight } = e.target;
    store.dispatch(setAppWindowSize({
        width: innerWidth,
        height: innerHeight
    }));
});

/***** CONFIGURE ROUTING AND RENDER APPLICATION *****/

let rendered = false;

const renderApplication = () => {
    ReactDOM.render(
        <Provider store={store}>
            <Router history={history}>
                <Route path={'/'} component={App} />
            </Router>
        </Provider>,
        document.getElementById('root')
    );
};

const total = 8;
const increment = Math.floor(100 / total);
let percentage = 0;

const updatePercentage = p => {
    percentage = (p || p === 0) ? p : percentage + increment;
    document.getElementById('js-loadingDetail').innerHTML = percentage;
};

const hideLoading = () => {
    document.getElementById('js-loadingOverlay').style.display = 'none';
};

const getNewData = async function() {
    try {

        updatePercentage(0);

        const [ res1, res2, res3, res4, res5, res6, res7, res8 ] = await Promise.all([
            new Promise(resolve => {
                // If locale is empty, we get a cryptic error when we try to use res1 further down in the code.
                //  So default to English here, too.
                const locale = store.getState().appState.locale ? store.getState().appState.locale : 'en';
                request.get(`/api/locales/${locale}`)
                    .then(res => {
                        updatePercentage();
                        resolve(res);
                    })
                    .catch(err => {
                        if (err.status === 403) {
                            handleError(new Error(loggedOutInternalErrorMessage));
                            return;
                        }

                        throw new Error(`Error getting locale data from server for locale '${locale}' from '${store.getState().appState.locale}'\n${err.message}`);
                    });
            }),
            new Promise(resolve => {
                request.get('/api/user')
                    .then(res => {
                        updatePercentage();
                        resolve(res);
                    })
                    .catch(err => {
                        if (err.status === 403) {
                            handleError(new Error(loggedOutInternalErrorMessage));
                            return;
                        }

                        console.error(err);
                    });
            }),
            new Promise((resolve, reject) => {
                gql.transaction(
                    'query',
                    'getPeople',
                    {},
                    gqlCallAttributes.people()
                )
                    .then(res => {
                        updatePercentage();
                        resolve(res);
                    })
                    .catch(err => reject(err));
            }),
            new Promise((resolve, reject) => {
                gql.transaction(
                    'query',
                    'getFields',
                    {},
                    [
                        '_id',
                        'hidden',
                        'name',
                        'publicationName',
                        'countries { _id, name }',
                        'IMTRequestedFieldRoles { title, category, people }',
                        'fieldAppointedRoles { title, category, people }',
                        'note',
                        'contacts',
                        'personnelNeeds { number, comment }',
                        'level'
                    ]
                )
                    .then(res => {
                        updatePercentage();
                        resolve(res);
                    })
                    .catch(err => reject(err));
            }),
            new Promise((resolve, reject) => {
                gql.transaction(
                    'query',
                    'getCountries',
                    {},
                    [
                        '_id',
                        'name'
                    ]
                )
                    .then(res => {
                        updatePercentage();
                        resolve(res);
                    })
                    .catch(err => reject(err));
            }),
            new Promise((resolve, reject) => {
                gql.transaction(
                    'query',
                    'getRegions',
                    {},
                    gqlCallAttributes.region()
                )
                    .then(res => {
                        updatePercentage();
                        resolve(res);
                    })
                    .catch(err => reject(err));
            }),
            new Promise((resolve, reject) => {
                gql.transaction(
                    'query',
                    'getPersonGroupStatuses',
                    {},
                    ['_id', 'peopleGroup', 'person', 'location']
                )
                    .then(res => {
                        updatePercentage();
                        resolve(res);
                    })
                    .catch(err => reject(err));
            }),
            new Promise((resolve, reject) => {
                gql.transaction(
                    'query',
                    'getTrainings',
                    {},
                    gqlCallAttributes.training()
                )
                    .then(res => {
                        updatePercentage();
                        resolve(res);
                    })
                    .catch(err => reject(err));
            })
        ]);

        updatePercentage(100);

        await new Promise(resolve => setTimeout(resolve, 503)); // change all 500ms timeouts to track down t223

        const localeData = res1 ? res1.body : {};
        if (!localeData || Object.keys(localeData).length === 0) throw new Error('no localeData\n' + res1);
        if (!localeData.locale) throw new Error('no locale in localeData\n' + localeData);
        store.dispatch(setAppLocale(localeData.locale));
        store.dispatch(setAppLocaleData(localeData));
        window.Localize = new Localizer(localeData);

        const intCol = new Intl.Collator(localeData.locale);

        if(res3.errors) throw new Error(res3.errors[0].message);
        // console.log(res3.getPeople);
        store.dispatch(setAppPeople(
            new Map(res3.data.getPeople
                .sort((a, b) => {
                    const comp = intCol.compare(a.lastName, b.lastName);
                    if(comp === 0) {
                        return intCol.compare(a.firstName, b.firstName);
                    } else {
                        return comp;
                    }
                })
                .map(p => [p._id, p]))
        ));

        if(res4.errors) throw new Error(res4.errors[0].message);
        const { getFields: fields } = res4.data;
        store.dispatch(setAppFields(fields));

        if(res5.errors) throw new Error(res5.errors[0].message);
        const { getCountries: countries } = res5.data;
        store.dispatch(setAppCountries(countries));

        if(res6.errors) throw res6.errors[0];
        const { getRegions: regions } = res6.data;
        store.dispatch(setAppRegions(regions));

        if(res7.errors) throw res7.errors[0];
        const { getPersonGroupStatuses: personGroupStatuses } = res7.data;
        store.dispatch(setAppPersonGroupStatuses(personGroupStatuses));

        if(res8.errors) throw res8.errors[0];
        const { getTrainings: trainings } = res8.data;
        store.dispatch(setAppTrainings(trainings));

        const localizedLists = new LocalizedLists({ store });
        await localizedLists.initialize(localeData.locale);
        window.listManager = localizedLists;

        // console.log(localizedLists.get(store.getState().appState.localizedLists, 'newList'));
        // const values = new Map();
        // values.set('some', String(new Date().getTime()));
        // await localizedLists.addItem('newList', values);localizedLists.get(store.getState().appState.localizedLists, 'newList')

        const userData = res2.body;
        store.dispatch(setAppUserData(userData));

        if(!rendered) renderApplication();

        await new Promise(resolve => setTimeout(resolve, 504)); // change all 500ms timeouts to track down t223
        hideLoading();

        store.dispatch(setAppInitialServerLoadDone());
    } catch(err) {
        hideLoading();
        handleError(err);
    }
};

(async function() {
    try {

        if(!window.localStorage) throw new Error('Local storage is not available in this browser.');

        const res = await request.get('/api/user').catch(err => {
            if (err.status === 403) {
                handleError(new Error(loggedOutInternalErrorMessage));
                return;
            }

            console.error(err);
        });
        const userData = res.body;
        store.dispatch(setAppUserData(userData));

        const userStr = localStorage.getItem('user');
        const user = JSON.parse(userStr);

        if(user._id !== userData._id) {
            localStorage.setItem('user', '');
            localStorage.setItem('localeData', '');
            localStorage.setItem('people', '');
            localStorage.setItem('fields', '');
            localStorage.setItem('countries', '');
            localStorage.setItem('regions', '');
            localStorage.setItem('personGroupStatuses', '');
            localStorage.setItem('personToFieldPeopleGroup', '');
            await getNewData();
            return;
        }

        updatePercentage(100);

        const localeData = JSON.parse(localStorage.getItem('localeData'));

        store.dispatch(setAppLocale(localeData.locale));
        store.dispatch(setAppLocaleData(localeData));
        window.Localize = new Localizer(localeData);

        const localizedLists = new LocalizedLists({ store });
        await localizedLists.initialize(localeData.locale);
        window.listManager = localizedLists;

        const people = JSON.parse(localStorage.getItem('people'));
        store.dispatch(setAppPeople(new Map(people)));

        const fields = JSON.parse(localStorage.getItem('fields'));
        console.log('dispatching previous fields', fields);
        store.dispatch(setAppFields(fields));

        const countries = JSON.parse(localStorage.getItem('countries'));
        store.dispatch(setAppCountries(countries));

        const regions = JSON.parse(localStorage.getItem('regions'));
        store.dispatch(setAppRegions(regions));

        const personGroupStatuses = JSON.parse(localStorage.getItem('personGroupStatuses'));
        store.dispatch(setAppPersonGroupStatuses(personGroupStatuses));

        const trainings = JSON.parse(localStorage.getItem('trainings'));
        store.dispatch(setAppTrainings(trainings));

        const personToFieldPeopleGroupJSON = localStorage.getItem('personToFieldPeopleGroup');
        if(personToFieldPeopleGroupJSON) {
            const personToFieldPeopleGroup = JSON.parse(personToFieldPeopleGroupJSON);
            store.dispatch(setAppPersonToFieldPeopleGroup(personToFieldPeopleGroup));
        }

        renderApplication();

        await new Promise(resolve => setTimeout(resolve, 1000));
        hideLoading();

        console.log('Rendered from local data.');

        rendered = true;

        setTimeout(getNewData, 0);

    } catch(err) {
        console.error(err);
        await getNewData();
    }
})();

// Promise
//     .all([
//         request.get(`/api/locales/${store.getState().appState.locale}`),
//         request.get('/api/user')
//     ])
//     .then(([ res1, res2 ]) => {
//
//     })
//     .catch(err => {
//         console.error(err);
//         swal('Oops!', err.message, 'error');
//     });

// Promise
//     .all([
//         request.get('/api/field'),
//         request.get('/api/country')
//     ])
//     .then(([ res2, res3 ]) => {
//         const fields = res2.body;
//         const countries = res3.body;
//         store.dispatch(setAppCountries(countries));
//         store.dispatch(setAppFields(fields));
//     })
//     .catch(err => {
//         console.error(err);
//         swal('Oops!', err.message, 'error');
//     });
