import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { bindAll } from 'lodash';

class Regions extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            activeRegion: ''
        };
        bindAll(this, [
            'onRegionHover',
            'offRegionHover'
        ]);
    }

    // shouldComponentUpdate(newProps) {
    //     const oldProps = this.props;
    //     if(
    //         oldProps.user !== newProps.user ||
    //         oldProps.regions !== newProps.regions ||
    //         oldProps.fields !== newProps.fields ||
    //         oldProps.countries !== newProps.countries
    //     ) {
    //         return true;
    //     } else {
    //         return false;
    //     }
    // }

    onRegionHover(e, _id) {
        e.preventDefault();
        this.setState({
            ...this.state,
            activeRegion: _id
        });
    }

    offRegionHover(e) {
        e.preventDefault();
        this.setState({
            ...this.state,
            activeRegion: ''
        });
    }

    // async componentWillMount() {
    //     try {
    //         const { errors, data } = await gql.transaction(
    //             'query',
    //             'getRegions',
    //             {},
    //             gqlCallAttributes.region()
    //         );
    //         if(errors) throw errors[0];
    //         const regions = data.getRegions;
    //         this.props.setRegions(regions);
    //     } catch(err) {
    //         handleError(err);
    //     }
    // }

    render() {
        const { user, regions } = this.props;
        const { activeRegion } = this.state;

        const styles = {
            panel: {
                marginTop: 8
            },
            panelHeading: {
                padding: 8,
                paddingLeft: 10,
                paddingRight: 10
            },
            panelItem: {
                // padding: 8,
                // paddingLeft: 10,
                // paddingRight: 10
            },
            fieldName: {
                fontWeight: 'bold',
                marginBottom: 0
            },
            countriesContainer: {
                // paddingLeft: 50
            },
            countryLink: {
                color: '#333'
            }
        };

        const { Localize } = window;

        const intCol = new Intl.Collator(Localize.locale());

        // if(regions.length === 0) {
        //     return <div className={'container-fluid'}></div>;
        // }

        // const fieldsMap = new Map(fields.map(f => [f._id, f]));
        // const countriesMap = new Map(countries.map(f => [f._id, f]));

        const administrator = user.fmdbAdministrator || user.imtLeadership;

        const panels = [...regions]
            .sort((a, b) => intCol.compare(a.name, b.name))
            .map(r => {
                return (
                    <div key={r._id} style={styles.panel} className="card">
                        <div style={styles.panelHeading} className="card-header" onMouseOver={e => this.onRegionHover(e, r._id)} onMouseOut={this.offRegionHover}>
                            <Link style={{display: administrator && r._id === activeRegion ? 'inline' : 'none'}} to={`/region/${r._id}`} className={'float-right text-primary'} title={Localize.text('EditRegion', 'RegionForm')}><i className={'fa fa-pencil'} /></Link>
                            <h4 className={'no-bottom-margin'}><strong>{r.name}</strong></h4>
                        </div>
                        <div className="card-body">
                            <div className={'card'}>
                                {
                                    r.fields
                                        .sort((a, b) => intCol.compare(a.name, b.name))
                                        .map(f => (
                                            <div key={f._id} style={styles.panelItem} className="list-group-item">
                                                <div style={styles.fieldName}><Link to={`/field/${f._id}`}>{f.name}</Link></div>
                                                <div style={styles.countriesContainer}>
                                                    {
                                                        f.countries.length > 0 && f.countries[0].name !== f.name ?
                                                            f.countries
                                                                .sort((a, b) => intCol.compare(a.name, b.name))
                                                                .map((c, i) => (
                                                                    i < f.countries.length - 1 ?
                                                                        <span key={c._id}>
                                                                            {administrator ?
                                                                                <Link style={styles.countryLink} to={`/country/${c._id}`}><span>{c.name}</span></Link>
                                                                                :
                                                                                <span style={styles.countryLink}>{c.name}</span>
                                                                            }
                                                                            <span>{', '}</span>
                                                                        </span>
                                                                        :
                                                                        <span key={c._id}>
                                                                            {administrator ?
                                                                                <Link style={styles.countryLink} to={`/country/${c._id}`}>{c.name}</Link>
                                                                                :
                                                                                <span style={styles.countryLink}>{c.name}</span>
                                                                            }
                                                                        </span>
                                                                ))
                                                            :
                                                            []
                                                    }
                                                </div>
                                            </div>
                                        ))
                                }

                            </div>
                        </div>
                    </div>
                );
            });

        return (
            <div className="container-fluid">
                <div className={'row'}>
                    {(user.fmdbAdministrator || user.imtLeadership) ?
                        <div className={'col-12'}>
                            <div>
                                <Link to={'add-region'} className={'btn btn-sm btn-outline-dark'}><i className={'fa fa-plus'} /> {Localize.text('AddRegion', 'Region')}</Link>
                                <Link style={{marginLeft: 10}} to={'add-field'} className={'btn btn-sm btn-outline-dark'}><i className={'fa fa-plus'} /> {Localize.text('AddField', 'Region')}</Link>
                                <Link style={{marginLeft: 10}} to={'add-country'} className={'btn btn-sm btn-outline-dark'}><i className={'fa fa-plus'} /> {Localize.text('AddCountry', 'Region')}</Link>
                            </div>
                        </div>
                        :
                        <div></div>
                    }
                </div>
                <div className="row">
                    <div className="col-lg-5 col-md-6 col-sm-7">
                        {panels}
                    </div>
                </div>
            </div>
        );
    }

}
Regions.propTypes = {
    user: PropTypes.object,
    regions: PropTypes.arrayOf(PropTypes.object),
    setRegions: PropTypes.func
};

export default Regions;
