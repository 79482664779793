import { connect } from 'react-redux';
import PeopleGroupSearch from './people-group-search';
import * as appActions from '../../actions/app-actions';

export default connect(
    ({ appState }) => ({
        locations: appState.locations
    }),
    dispatch => ({
        setLocations: locations => dispatch(appActions.setAppLocations(locations))
    })
)(PeopleGroupSearch);
