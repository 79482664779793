import { Map } from 'immutable';
import actions from '../enums/actions';
import { ternary } from '../constants';

const getLocale = () => {
    const { language = 'en' } = navigator;
    const locale = language.split('-')[0];
    return locale.toLowerCase();
};

const getInitialState = () => ({
    disableNav: false,
    disableNavMessage: '',
    user: {},
    canCreateUpdateTrainings: ternary.UNKNOWN,
    locale: getLocale(),
    localeData: {},
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight,
    headerHeight: 42,
    countries: [],
    fields: [],
    regions: [],
    locations: [],
    people: new Map(),
    personGroupStatuses: [],
    localizedLists: [],
    trainings: [],
    personToFieldPeopleGroup: {},
    initialServerLoadDone: false,
});

const save = (key, val) => {
    try {
        if(localStorage) localStorage.setItem(key, JSON.stringify(val));
    } catch(err) {
        console.error(err);
    }
};

export default (state = getInitialState(), { type, payload }) => {
    switch(type) {
        case actions.APP_SET_LOCALE:
            return {
                ...state,
                locale: payload.locale
            };
        case actions.APP_SET_LOCALE_DATA:
            save('localeData', payload.data);
            return {
                ...state,
                localeData: payload.data
            };
        case actions.APP_SET_DISABLE_NAV:
            return {
                ...state,
                disableNav: payload.disableNav,
                disableNavMessage: payload.disableNav ? payload.message : ''
            };
        case actions.APP_SET_USER_DATA:
            save('user', payload.user);
            return {
                ...state,
                user: payload.user
            };
        case actions.APP_SET_CAN_CREATE_UPDATE_TRAININGS:
            return {
                ...state,
                canCreateUpdateTrainings: payload.value,
            };
        case actions.APP_SET_COUNTRIES:
            save('countries', payload.countries);
            return {
                ...state,
                countries: payload.countries
            };
        case actions.APP_SET_FIELDS:
            save('fields', payload.fields);
            return {
                ...state,
                fields: payload.fields
            };
        case actions.APP_SET_REGIONS:
            save('regions', payload.regions);
            return {
                ...state,
                regions: payload.regions
            };
        case actions.APP_SET_LOCATIONS: {
            const intlCollator = new Intl.Collator(Localize.locale());
            return {
                ...state,
                locations: [...payload.locations].sort((a, b) => intlCollator.compare(a.name, b.name))
            };
        } case actions.APP_SET_PEOPLE:
            save('people', [...payload.people.entries()]);
            return {
                ...state,
                people: payload.people
            };
        case actions.APP_SET_PERSON_GROUP_STATUSES:
            save('personGroupStatuses', [...payload.personGroupStatuses]);
            return {
                ...state,
                personGroupStatuses: payload.personGroupStatuses
            };
        case actions.APP_SET_WINDOW_SIZE:
            return {
                ...state,
                windowWidth: payload.width,
                windowHeight: payload.height
            };
        case actions.APP_SET_HEADER_HEIGHT:
            return {
                ...state,
                headerHeight: payload.height,
            };
        case actions.APP_SET_LOCALIZED_LISTS:
            return {
                ...state,
                localizedLists: payload.localizedLists
            };
        case actions.APP_SET_TRAININGS:
            save('trainings', [...payload.trainings]);
            return {
                ...state,
                trainings: payload.trainings
            };
        case actions.APP_SET_PERSON_TO_FIELD_PEOPLE_GROUP:
            save('personToFieldPeopleGroup', {...payload.personToFieldPeopleGroup});
            return {
                ...state,
                personToFieldPeopleGroup: payload.personToFieldPeopleGroup
            };
        case actions.APP_SET_MODAL_STATUS:
            return {
                ...state,
                modalStatus: payload.modalStatus,
            };
        case actions.APP_SET_INITIAL_SERVER_LOAD_DONE:
            return {
                ...state,
                initialServerLoadDone: true,
            };
        default:
            return state;
    }
};
