export const dummyLocation = 'location1';

export const suppressErrorEmail = 'SuppressErrorEmail ';

export const maxInactiveMinutes = 17;
export const minutesToShowWarningBeforeLogout = 2;
export const loggedOutInternalErrorMessage = 'User has been logged out';

export const fileTypes = {
    DIR: 'DIR',
    FILE: 'FILE',
};

export const dbGuids = {
    CHURCH_STAGE_UNKNOWN: '5dc7cafc-5a15-4d24-abb7-ee406d9e2f32',
    CHURCH_STAGE_GROWTH_AND_MATURITY: '25b1412a-73aa-4666-8d1e-e8948da4ef2f',
    CHURCH_STAGE_EQUIPPED_TO_PARTNER: '8aa5fcc0-f7e6-41d5-8f00-82a4e50277ec',
    COUNTRY_UNKNOWN: '3dd92fb8-76f9-46a4-8c8d-6ce26ce3da66',
    CURRICULUM_STATUS_NOT_STARTED: 'fed21934-a162-46af-8017-e10df1c30367',
};

export const modalStatuses = {
    NO_MODAL: 'NO_MODAL',
    SHOWING_MODAL: 'SHOWING_MODAL',
    CLOSE_MODAL: 'CLOSE_MODAL',
    SHOWING_SWAL: 'SHOWING_SWAL',   // used to suppress all navigation, such as when displaying a sweetalert.
};

export const ternary = {
    TRUE: 'TRUE',
    FALSE: 'FALSE',
    UNKNOWN: 'UNKNOWN',
    fromBoolean: boolValue => boolValue ? ternary.TRUE : ternary.FALSE,
};

// from https://getbootstrap.com/docs/4.3/components/modal/#optional-sizes
export const modalWidths = {
    'SMALL': 'SMALL',    // 300px
    'DEFAULT': 'DEFAULT', // 500px
    'LARGE': 'LARGE',   // 800px
    'EXTRA_LARGE': 'EXTRA_LARGE',   // 1140px
    'FULL_WIDTH': 'FULL_WIDTH', // not a bootstrap option, but seems to work
};
