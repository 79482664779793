import React, { useState, useReducer } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import uniq from 'lodash/uniq';

import PencilEditModal from '../../../shared/modals/pencil-edit-modal';
import useDimensions from 'react-use-dimensions';

import shareWithServer from '../../../../constants/share-with-server';
import * as dateUtils from '../../../../util/date-utils';
const { swapMMdashYYYY } = shareWithServer;

const changedEndDatesReducerAction = {
    CHANGE_END_DATE: 'CHANGE_END_DATE',
    RESET_STATE: 'RESET_STATE',
};

const bootstrapModalHeights = {
    margin: 56,
    header: 57,
    bodyPadding: 32,
    footer: 71,
};

const bootstrapHeightFactor = Object.values(bootstrapModalHeights).reduce((sum, v) => sum + v, 0);

const createColumnDef = ({ field, headerName }) => {
    return ({
        //colId: k,
        headerName,
        field,
        sortable: true,
        filter: 'agTextColumnFilter',
        resizable: true,
        autoHeight: true,   // so we can have multiline cells
        //menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
    });
};

// I'm keeping getLatestDate in case we add a button to show all consultants on the field with their end dates.  Currently we only include people-group-consultant rows with no enddate.
const getLatestDate = items => {
    let latestDate = '';

    for (const item of items) {
        const yyyyDashMM = swapMMdashYYYY(item.endDate);

        if (yyyyDashMM > latestDate) {
            latestDate = yyyyDashMM;
        }
    }

    return latestDate;
};

const ConsultantsGridBase = ({ aboveGridHeight, setChangedEndDates, localizedLists, people, peopleGroups, windowHeight, field, fieldToConsultants }) => {
    const localizedListItems = listManager.getItems(localizedLists);
    const consultants = fieldToConsultants.size ? fieldToConsultants
            .get(field)
            .reduce((obj, c) => {
                const items = obj[c.person];

                if (!items) {
                    return { ...obj, [c.person]: [c] };
                } else {
                    return { ...obj, [c.person]: [...items, c] };
                }
            }, {})
        : {};

    const getPeopleGroupName = pg => {
        const peopleGroup = peopleGroups.find(g => g._id === pg);
        return peopleGroup ? peopleGroup.name : pg;
    };

    const rowData = Object.entries(consultants).map(([personId, items]) => {
        const person = people.get(personId);
        return {
            name: `${person.firstName} ${person.lastName}`,
            peopleGroupConsultantKeys: items.map(i => ({ _id: i._id, peopleGroup: i.peopleGroup })),
            consultantType: uniq(items.map(i => i.consultantType)).map(t => localizedListItems.has(t) ? localizedListItems.get(t).name : ''),
            peopleGroups: uniq(items.map(i => i.peopleGroup)).map(pg => getPeopleGroupName(pg)),
            //latestEndDate: getLatestDate(items),
        };
    }).sort((a, b) => {
        const aVal = a.name;
        const bVal = b.name;
        return aVal > bVal ? 1 : aVal < bVal ? -1 : 0;
    });

    const maxHeight = windowHeight - bootstrapHeightFactor - aboveGridHeight - 4;

    const styles = {
        wrapper: {
            display: 'flex',
            flexDirection: 'column',
            minHeight: 120,
            height: maxHeight ? maxHeight : 0,
            maxHeight: maxHeight ? maxHeight : 0,
        },
        gridWrapper: {
            flex: '1 1 auto',
            width: '100%'
        }
    };

    const columnDefs = [
        createColumnDef({ field: 'name', headerName: Localize.text('Name', 'EditFieldConsultants') }),
        createColumnDef({ field: 'consultantType', headerName: Localize.text('Type', 'EditFieldConsultants') }),
        {
            ...createColumnDef({ field: 'peopleGroups', headerName: Localize.text('PeopleGroups', 'EditFieldConsultants') }),
            cellStyle: { 'white-space': 'pre-line', 'line-height': '1.3' },
        },
        {
            ...createColumnDef({ field: 'endDate', headerName: Localize.text('EndDate', 'EditFieldConsultants') }),
            editable: true,
            flex: 1,    // make this column take the remaining width
            minWidth: 120,
            cellStyle: { 'display': 'flex', 'align-items': 'center' },
            valueSetter: params => {
                // accept empty string to allow user to remove an endDate in case they enter it in the wrong row, for example.
                if (params.newValue.trim() === '') {
                    params.data.endDate = '';
                    return true;
                }

                const valid = dateUtils.isValidDateSync(params.newValue, true);
                if (!valid) return false;

                params.data.endDate = dateUtils.formatDate(params.newValue);
                return true;
            },
        },
    ];

    const cellChanged = ({ data: { endDate, peopleGroupConsultantKeys } }) => {
        setChangedEndDates({ type: changedEndDatesReducerAction.CHANGE_END_DATE, value: { endDate, peopleGroupConsultantKeys } });
    };

    const onGridReady = params => {
        // Workaround for ag-grid bug, to set the row heights appropriately:  https://github.com/ag-grid/ag-grid/issues/3160#issuecomment-502005304
        params.api.resetRowHeights();
    };

    const onColumnResized = params => {
        // Workaround for ag-grid bug, to set the row heights appropriately:  https://github.com/ag-grid/ag-grid/issues/3160#issuecomment-502005304
        params.api.resetRowHeights();
    };

    const noRowsText = Localize.text('ThereAreNoActiveConsultantsInThisField', 'EditFieldConsultants');
    const loadingText = Localize.text('Loading', 'UniversalForms');
    const gridLoadingOverlay = `<span>${loadingText}</span>`;
    const gridNoRowsOverlay = `<span>${noRowsText}</span>`;

    return (
        <div style={styles.wrapper}>
            <div style={styles.gridWrapper}>
                <div className="ag-theme-alpine" style={{ height: '100%' }}>
                    <AgGridReact
                        columnDefs={columnDefs}
                        rowData={rowData}
                        accentedSort={true}
                        stopEditingWhenGridLosesFocus={true}
                        onCellValueChanged={cellChanged}
                        overlayLoadingTemplate={gridLoadingOverlay}
                        overlayNoRowsTemplate={gridNoRowsOverlay}
                        onGridReady={onGridReady}
                        onColumnResized={onColumnResized}
                        // onFilterChanged={onFilterChanged}
                        // localeTextFunc={localeTextFunc}
                        // rowSelection={'multiple'}       /* shift-click to select multiple rows */
                    />
                </div>
            </div>
        </div>
    );
};
ConsultantsGridBase.propTypes = {
    aboveGridHeight: PropTypes.number,
    peopleGroups: PropTypes.arrayOf(PropTypes.object),
    setChangedEndDates: PropTypes.func,
    windowHeight: PropTypes.number,
    localizedLists: PropTypes.arrayOf(PropTypes.object),
    people: PropTypes.instanceOf(Map),
    field: PropTypes.string,
    fieldToConsultants: PropTypes.instanceOf(Map),
};
const ConsultantsGrid = connect(
    ({ appState, fieldState }) => ({
        windowHeight: appState.windowHeight,
        localizedLists: appState.localizedLists,
        people: appState.people,
        field: fieldState.field,
        fieldToConsultants: fieldState.fieldToConsultants,
    })
)(ConsultantsGridBase);

const changedEndDatesReducer = (state, action) => {
    switch (action.type) {
        case changedEndDatesReducerAction.CHANGE_END_DATE: {
            const { endDate, peopleGroupConsultantKeys } = action.value;
            const newState = peopleGroupConsultantKeys.reduce((obj, key) => ({ ...obj, [key._id]: { _id: key._id, peopleGroup: key.peopleGroup, endDate } }), {});
            return { ...state, ...newState };
        }
        case changedEndDatesReducerAction.RESET_STATE:
            return {};
        default: {
            throw new Error('invalid action type for edit-field-consultant.js changedEndDatesReducer: ' + action.type);
        }
    }
};

const EditFieldConsultants = ({ modalTitle, hidden, peopleGroups, onDone }) => {
    const [changedEndDates, setChangedEndDates] = useReducer(changedEndDatesReducer, {});
    const [aboveGridRef, { height: aboveGridHeight }] = useDimensions({ liveMeasure: true });
    const [modalIsShowing, setModalIsShowing] = useState(false);

    const updateEndDates = async () => {
        const consultanciesToUpdate = Object.values(changedEndDates);

        if (!consultanciesToUpdate.length) return;  // state is already empty and no reason to re-load consultants, so just return.

        const { errors } = await gql.transaction(
            'mutation',
            'updatePeopleGroupConsultantsBatch',
            {
                input: consultanciesToUpdate,
            },
            [
                '_id',
                'peopleGroup',
                'person',
                'consultantType',
                'startDate',
                'endDate',
            ]
        );
        if(errors) throw errors[0];

        setChangedEndDates({ type: changedEndDatesReducerAction.RESET_STATE });

        onDone();
    };

    return (
        <PencilEditModal
            modalTitle={modalTitle}
            hidden={hidden}
            setModalIsShowing={setModalIsShowing}
            done={updateEndDates}
        >
            {
                modalIsShowing ?
                    <div>
                        <div ref={aboveGridRef}>
                            <p>{Localize.text('WhenAConsultantIsNoLongerConsultingOnThisFieldUseTheGridBelowToSetAnEndDateForAllTheirConsultanciesWithAllPeopleGroupsThatHaveLocationsInThisFieldEnterTheEndDateInTheMMYYYYFormat', 'EditFieldConsultants')}</p>
                            <p>{Localize.text('IfYouWantToEditConsultantInformationForAParticularPeopleGroupInTheSummaryTabForThatPeopleGroupClickThePencilIconInTheConsultantsSection', 'EditFieldConsultants')}</p>
                        </div>
                        <ConsultantsGrid
                            aboveGridHeight={aboveGridHeight + 16}  // add 16 for the margin underneath the second <p>, since that margin isn't included in the useDimensions height.
                            peopleGroups={peopleGroups}
                            setChangedEndDates={setChangedEndDates}
                        />
                    </div>
                    :
                    null
            }
        </PencilEditModal>
    );
};
EditFieldConsultants.propTypes = {
    hidden: PropTypes.bool,
    modalTitle: PropTypes.string,
    onDone: PropTypes.func,
    peopleGroups: PropTypes.arrayOf(PropTypes.object),
};

export  default EditFieldConsultants;
