import React from 'react';
import PropTypes from 'prop-types';
import { bindAll } from 'lodash';
import RegionForm from '../shared/region-form';

class AddRegion extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            disabled: false
        };
        bindAll(this, [
            'onSubmit'
        ]);
    }

    async onSubmit(data) {
        console.log('data', data);
        try {
            this.setState({
                ...this.state,
                disabled: true
            });
            const { errors } = await gql.transaction(
                'mutation',
                'createRegion',
                {
                    input: data
                },
                ['_id']
            );
            if(errors) {
                this.setState({
                    ...this.state,
                    disabled: false
                });
                throw errors[0];
            }
            this.props.enableNav();
            this.props.updateRegions();
            routerHistory.push('/');
        } catch(err) {
            handleError(err);
        }
    }

    render() {
        const { disabled } = this.state;
        const { user, location, disableNav, enableNav } = this.props;

        return (
            <div className={'container-fluid'}>
                {user.fmdbAdministrator ?
                    <RegionForm location={location} onSubmit={this.onSubmit} disabled={disabled} disableNav={disableNav} enableNav={enableNav} />
                    :
                    <div></div>
                }
            </div>
        );
    }

}
AddRegion.propTypes = {
    location: PropTypes.object,
    user: PropTypes.object,
    updateRegions: PropTypes.func,
    disableNav: PropTypes.func,
    enableNav: PropTypes.func,
};

export default AddRegion;
