import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import gqlCallAttributes from '../../util/gql-call-attributes';
import FieldDetail from './field-detail';
import PeopleGroupDetail from './people-group-detail';
import Files from './files';
import swal from 'sweetalert';

const styles = {
    dropdown: {
        // borderColor: '#aaa',
        // borderStyle: 'solid',
        // borderWidth: 1,
        // borderRadius: 4
    },
    dropdownLabel: {
        // lineHeight: '38px',
        lineHeight: '30px',
        backgroundColor: '#fff',
        color: '#333',
        paddingRight: 10,
        marginTop: 10
    }
};

const Field = ({
                   active,
                   field,
                   peopleGroup: peopleGroupId,
                   fields,
                   location,
                   locations,
                   match: { params },
                   history,
                   setField,
                   setPeopleGroup,
                   setFields,
                   setLocations,
                   setActive,
                   // enableNav,
               }) => {
    const { fieldId = '', peopleId = '' } = params;
    const [doneInitialLoad, setDoneInitialLoad] = useState(false);

    useEffect(() => {
        const initialDataLoad = async () => {
            try {
                swal({
                    text: Localize.text('LoadingFieldData', 'Field'),
                    buttons: false,
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                });

                const { errors, data } = await gql.transaction(
                    'query',
                    'getFields',
                    {},
                    [
                        '_id',
                        'name',
                        'publicationName',
                        'countries { _id, name }',
                        'IMTRequestedFieldRoles { title, category, people }',
                        'fieldAppointedRoles { title, category, people }',
                        'note',
                        'contacts',
                        'personnelNeeds { number, comment }',
                        'level',
                        'hidden'
                    ]
                );
                if(errors) throw new Error(errors[0].message);
                const { getFields: loadedFields } = data;
                setFields(loadedFields);

                const { errors: errors1, data: data1 } = await gql.transaction(
                    'query',
                    'getLocations',
                    {},
                    gqlCallAttributes.locationLimited()
                );
                if(errors1) throw new Error(errors1[0].message);
                setLocations(data1.getLocations);

                swal.close();
            } catch(err) {
                swal.close();
                handleError(err);
            }
        };

        if (!doneInitialLoad) {
            initialDataLoad().then(setDoneInitialLoad(true));
        }
    }, [doneInitialLoad, setFields, setLocations]);

    useEffect(() => {
        if(field !== fieldId) setField(fieldId);

        if(peopleGroupId !== peopleId) setPeopleGroup(peopleId);
    }, [fieldId, peopleId, field, peopleGroupId, setField, setPeopleGroup]);

    const onChangeActive = (e, newActive) => {
        try {
            e.preventDefault();
            document.activeElement.blur();
            setActive(newActive);

        } catch(err) {
            handleError(err);
        }
    };

    const filesPath = !peopleId && /\/files/.test(location.pathname);

    const fieldData = fields.find(f => f._id === fieldId) || {};

    const intCol = new Intl.Collator(Localize.locale());

    const peopleGroups = locations
        .filter(l => l.field === fieldId)
        .map(l => l.peopleGroup)
        .reduce((arr, p) => arr.some(d => d._id === p._id) ? arr : [...arr, p], [])
        .sort((a, b) => intCol.compare(a.name, b.name));

    const peopleGroup = peopleGroups
        .find(p => p._id === peopleId);

    const sortedFields = fields
        .sort((a, b) => intCol.compare(a.name, b.name));

    return (
        <div>
            <div style={{paddingBottom: 10}} className={'border-bottom'}>
                <div className={'container-fluid'}>
                    <ul className="nav nav-pills">

                        <li><a style={styles.dropdownLabel}>{Localize.text('SelectedField', 'Field')}: </a></li>
                        <li className="nav-item-dropdown" style={styles.dropdown}>
                            <a href="#" className="btn btn-sm btn-outline-dark dropdown-toggle" ref={node => $(node).attr('data-toggle', 'dropdown')}>
                                {`${fieldData.name || Localize.text('SelectOne', 'Universal')} `}<span className="caret" />
                            </a>
                            <div className="dropdown-menu">
                                {
                                    sortedFields
                                        .map(f => {

                                            // const onClick = e => {
                                            //     e.preventDefault();
                                            // };

                                            return (
                                                <Link className={'dropdown-item'} key={f._id} to={`/field/${f._id}`}>{f.name}</Link>
                                            );
                                        })
                                }
                            </div>
                        </li>

                        <li><a style={{...styles.dropdownLabel, marginLeft: 10}}>{Localize.text('SelectedPeopleGroup', 'Field')} </a></li>
                        <li className="nav-item-dropdown" style={styles.dropdown}>
                            <a href="#" className="btn btn-sm btn-outline-dark dropdown-toggle" ref={node => $(node).attr('data-toggle', 'dropdown')}>
                                {`${peopleGroup ? peopleGroup.name : Localize.text('SelectOne', 'Universal')} `}<span className="caret" />
                            </a>
                            <div className="dropdown-menu">
                                {
                                    peopleGroups
                                        .map(p => {

                                            const onClick = e => {
                                                e.preventDefault();
                                                if(peopleId === p._id) {
                                                    setActive('peopleGroup');
                                                } else {
                                                    history.push(`/field/${fieldId}/people/${p._id}`);
                                                }
                                            };

                                            return (
                                                <a onClick={onClick} className={'dropdown-item'} key={p._id} href={`/field/${fieldId}/people/${p._id}`}>{p.name}</a>
                                            );
                                        })
                                }
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

            <div style={{marginTop: 10}}>
                {fieldId && peopleId ?
                    <ul className={'nav nav-tabs'}>
                        <li style={{marginLeft: 10}} className={'nav-item'}>
                            <a href={'#'} style={{minWidth: 120}} className={'nav-link' + (active === 'field' ? ' active' : '')} onClick={e => onChangeActive(e, 'field')}>{Localize.text('Field', 'Universal')}</a>
                        </li>
                        <li className={'nav-item'}>
                            <a style={{minWidth: 120}} href={'#'} className={'nav-link' + (active === 'peopleGroup' ? ' active' : '')} onClick={e => onChangeActive(e, 'peopleGroup')}>{Localize.text('PeopleGroup', 'Universal')}</a>
                        </li>
                    </ul>
                    :
                    fieldId ?
                        <ul className={'nav nav-tabs'}>
                            <li style={{marginLeft: 10}} className={'nav-item'}>
                                <a style={{minWidth: 120}} href={'#'} className={'nav-link active'} onClick={e => onChangeActive(e, 'field')}>{Localize.text('Field', 'Universal')}</a>
                            </li>
                        </ul>
                        :
                        null
                }
            </div>

            {/*<RadarGraph width={420} divisions={6} data={[*/}
                {/*{*/}
                    {/*label: 'Discipleship In The Church',*/}
                    {/*color: '#ffa',*/}
                    {/*value: 1*/}
                {/*},*/}
                {/*{*/}
                    {/*label: 'God\'s Word For The Church',*/}
                    {/*color: '#faa',*/}
                    {/*value: 3*/}
                {/*},*/}
                {/*{*/}
                    {/*label: 'Life Of The Church',*/}
                    {/*color: '#afa',*/}
                    {/*value: 2*/}
                {/*},*/}
                {/*{*/}
                    {/*label: 'Identity Of The Church',*/}
                    {/*color: '#aaf',*/}
                    {/*value: 4*/}
                {/*}*/}
            {/*]} />*/}

            <div className={'container-fluid'}>
                <div style={{height: 10}} />
                {(filesPath && !fieldData._id) ?
                    <div />
                    :
                    filesPath ?
                        <Files field={fieldData} />
                            :
                            (fieldId && locations.length > 0) && (!peopleId || active === 'field') ?
                                <FieldDetail peopleGroups={peopleGroups} location={location} locations={locations} params={params} history={history} />
                                :
                                fieldId && peopleId && active === 'peopleGroup' ?
                                    <PeopleGroupDetail params={params} queryParams={location.query} />
                                    :
                                    <div />
                }
            </div>
        </div>
    );
};
Field.propTypes = {
    active: PropTypes.string,
    field: PropTypes.string,
    peopleGroup: PropTypes.string,
    fields: PropTypes.arrayOf(PropTypes.object),
    location: PropTypes.object,
    locations: PropTypes.arrayOf(PropTypes.object),
    match: PropTypes.object,
    history: PropTypes.object,
    setField: PropTypes.func,
    setPeopleGroup: PropTypes.func,
    setFields: PropTypes.func,
    setLocations: PropTypes.func,
    setActive: PropTypes.func,
    // enableNav: PropTypes.func
};

export default Field;
