import { connect } from 'react-redux';
// import { withRouter } from 'react-router';
import Contacts from './personnel';

export default connect(
    ({ appState }) => ({
        user: appState.user,
        countries: appState.countries,
        windowWidth: appState.windowWidth,
        people: appState.people,
        localizedLists: appState.localizedLists,
        windowHeight: appState.windowHeight,
        fields: appState.fields
    })
)(Contacts);
