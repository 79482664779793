import swal from 'sweetalert';
const datePatt = /\d{1,2}-\d{4}/;

const testDate = date => {
    if(datePatt.test(date)) {
        const [ monthStr ] = date.split('-');
        const month = Number(monthStr);
        if(month > 0 && month < 13) return true;
    }

    return false;
};

const showInvalidDateMessage = async () => {
    await swal({
        title: Localize.text('InvalidDate', 'Universal'),
        text: Localize.text('DatesMustBeFormattedAsMMYYYYWithTheMonthBetween1And12', 'Universal'),
        icon: 'warning'
    });
};

const isValidDate = async function(date = '', notifyUser = false) {
    if (testDate(date)) return true;
    if(notifyUser) {
        await showInvalidDateMessage();
    }
    return false;
};

const isValidDateSync = function(date = '', notifyUser = false) {
    if (testDate(date)) return true;
    if(notifyUser) {
        showInvalidDateMessage().then();
    }
    return false;
};

const addPreceedingZeroes = (totalPlaces, numStr) => {
    return numStr.length >= totalPlaces ? numStr : addPreceedingZeroes(totalPlaces, '0' + numStr);
};

const formatDate = date => {
    const [ month, year ] = date.split('-');
    return `${addPreceedingZeroes(2, month)}-${year}`;
};

export {
    datePatt,
    isValidDate,
    isValidDateSync,
    formatDate
};
