import { connect } from 'react-redux';
import PeopleGroupDetail from './people-group-detail';
import { setAppModalStatus } from '../../../actions/app-actions';
import { setPeopleGroup, setLocations, setStatuses, setConsultants } from '../../../actions/people-group-actions';

export default connect(
    ({ appState, peopleGroupState }) => ({
        peopleGroups: peopleGroupState.peopleGroups,
        user: appState.user,
        locations: peopleGroupState.locations,
        statusesMap: peopleGroupState.statuses,
        consultantsMap: peopleGroupState.consultants,
        allLocations: appState.locations,
        allFields: appState.fields,
        localizedLists: appState.localizedLists,
        windowHeight: appState.windowHeight,
        windowWidth: appState.windowWidth,
    }),
    dispatch => ({
        setPeopleGroup(peopleGroup) {
            dispatch(setPeopleGroup(peopleGroup));
        },
        setLocations(locations) {
            dispatch(setLocations(locations));
        },
        setStatuses(peopleGroupId, statuses) {
            dispatch(setStatuses(peopleGroupId, statuses));
        },
        setConsultants(peopleGroupId, consultants) {
            dispatch(setConsultants(peopleGroupId, consultants));
        },
        setModalStatus: status => dispatch(setAppModalStatus(status)),
    })
)(PeopleGroupDetail);
