import React from 'react';
import PropTypes from 'prop-types';
import { bindAll, omit } from 'lodash';
import swal from 'sweetalert';
import isEqual from 'lodash/isEqual';

const emptyField = {
    name: '',
    publicationName: '',
    region: '',
    hidden: false,
};

class AddField extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            ...emptyField,
            allRegions: [],
            disabled: false,
            orig: { ...emptyField },
        };
        bindAll(this, [
            'onChange',
            'onSubmit'
        ]);
    }

    async componentWillMount() {
        try {

            const { errors, data } = await gql.transaction(
                'query',
                'getRegions',
                {},
                ['_id', 'name', 'fields { _id }']
            );
            if(errors) throw new Error(errors[0].message);
            const intCol = new Intl.Collator(Localize.locale());
            const allRegions = data.getRegions
                .sort((a, b) => intCol.compare(a.name, b.name));
            this.setState({
                ...this.state,
                allRegions
            });

        } catch(err) {
            handleError(err);
        }
    }

    componentDidUpdate() {
        const { disableNav, enableNav } = this.props;

        const current = Object
            .keys(emptyField)
            .reduce((acc, k) => ({
                ...acc,
                [k]: this.state[k],
            }), {});

        if (isEqual(this.state.orig, current)) {
            enableNav();
        } else {
            disableNav();
        }
    }

    onChange(e, key) {
        e.preventDefault();
        const { value } = e.target;
        this.setState({
            ...this.state,
            [key]: key !== 'hidden' ? value : value === 'true' ? true : false
        });
    }

    async onSubmit(e) {
        e.preventDefault();
        const { after = '' } = this.props.location.query;
        const newField = omit(this.state, ['disabled', 'allRegions', 'region', 'orig']);
        console.log('newField', newField);

        const name = newField.name.trim();

        if(!name) {
            swal(Localize.text('Oops', 'Universal'), Localize.text('YouMustEnterAName', 'UniversalForms'), 'warning');
            return;
        }

        const publicationName = newField.publicationName.trim();

        const { region } = this.state;

        if(!region) {
            swal(Localize.text('Oops', 'Universal'), Localize.text('YouMustSelectARegion', 'FieldForm'), 'warning');
            return;
        }

        try {
            this.setState({
                ...this.state,
                disabled: true
            });
            const { errors: errors1, data } = await gql.transaction(
                'mutation',
                'createField',
                {
                    input: {
                        name,
                        publicationName,
                        hidden: newField.hidden
                    }
                },
                ['_id']
            );
            if(errors1) {
                this.setState({
                    ...this.state,
                    disabled: false
                });
                throw errors1[0];
            }
            const fieldId = data.createField._id;

            const regionObject = this.state.allRegions.find(r => r._id === region);

            const { errors: errors2 } = await gql.transaction(
                'mutation',
                'updateRegion',
                {
                    _id: region,
                    input: {
                        fields: regionObject.fields
                            .map(f => f._id)
                            .concat([fieldId])
                    }
                },
                ['_id', 'name', 'fields { _id }']
            );
            if(errors2) {
                this.setState({
                    ...this.state,
                    disabled: false
                });
                throw errors2[0];
            }
            this.props.enableNav();
            this.props.updateRegions();
            this.props.updateFields();
            if(after) {
                routerHistory.push(after);
            } else {
                routerHistory.push('/');
            }
        } catch(err) {
            handleError(err);
        }
    }

    render() {
        const { disabled, name, publicationName, region, hidden, allRegions } = this.state;
        const { user } = this.props;

        return (
            <div className={'container-fluid'}>
                {user.fmdbAdministrator ?
                    <div>

                        <div className={'row border-bottom'}>
                            <h1 className={'col-12'}>{Localize.text('CreateNewField', 'FieldForm')}</h1>
                        </div>

                        <div className={'row'}>
                            <div className={'col-sm-9 col-md-7 col-lg-5'}>
                                <div className={'form-group'}>
                                    <label>{Localize.text('FieldName', 'FieldForm')}</label>
                                    <input type={'text'} className={'form-control'} value={name} onChange={e => this.onChange(e, 'name')} />
                                </div>
                            </div>
                        </div>

                        <div className={'row'}>
                            <div className={'col-sm-9 col-md-7 col-lg-5'}>
                                <div className={'form-group'}>
                                    <label>{Localize.text('PublicationName', 'Universal')}</label>
                                    <input type={'text'} className={'form-control'} value={publicationName} onChange={e => this.onChange(e, 'publicationName')} />
                                </div>
                            </div>
                        </div>

                        <div className={'row'}>
                            <div className={'col-sm-9 col-md-7 col-lg-5'}>
                                <div className={'form-group'}>
                                    <label>{Localize.text('SelectRegion', 'FieldForm')}</label>
                                    <select className={'form-control'} value={region} onChange={e => this.onChange(e, 'region')}>
                                        <option key={'noRegion'} value={''}>{Localize.text('PleaseSelectARegion', 'FieldForm')}</option>
                                        {allRegions.map(r => <option key={r._id} value={r._id}>{r.name}</option>)}
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className={'row'}>
                            <div className={'col-sm-9 col-md-7 col-lg-5'}>
                                <div className={'form-group'}>
                                    <label>{Localize.text('HiddenQuestion', 'Universal')}</label>
                                    <select className={'form-control'} value={hidden} onChange={e => this.onChange(e, 'hidden')}>
                                        <option value={'true'}>{Localize.text('Yes', 'Universal')}</option>
                                        <option value={'false'}>{Localize.text('No', 'Universal')}</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className={'row'}>
                            <div className={'col-12'}>
                                <div className={'form-group'}>
                                    <button type={'button'} className={'btn btn-primary'} onClick={this.onSubmit} disabled={disabled}>{Localize.text('SaveField', 'FieldForm')}</button>
                                </div>
                            </div>
                        </div>

                    </div>
                    :
                    <div />
                }
            </div>
        );
    }

}
AddField.propTypes = {
    location: PropTypes.object,
    user: PropTypes.object,
    updateRegions: PropTypes.func,
    updateFields: PropTypes.func,
    disableNav: PropTypes.func,
    enableNav: PropTypes.func,
};

export default AddField;
