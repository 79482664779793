import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { setAppDisableNav, setAppModalStatus } from '../../../actions/app-actions';
import { modalStatuses, modalWidths } from '../../../constants';

const getModalWidth = modalWidth => {
    switch (modalWidth) {
        case modalWidths.SMALL: return 'modal-dialog modal-sm';
        case modalWidths.LARGE: return 'modal-dialog modal-lg';
        case modalWidths.EXTRA_LARGE: return 'modal-dialog modal-xl';
        case modalWidths.FULL_WIDTH: return '';
        default: return 'modal-dialog';
    }
};

const MyBootstrapModal = ({
                              hidden = false,
                              className = '',
                              buttonAsAnchor = true,
                              buttonContents = null,
                              buttonTitle,
                              buttonClassName = '',
                              modalWidth = modalWidths.LARGE,
                              modalTitle,
                              setModalIsShowing = () => {},
                              done = () => {},
                              warnOnNav = true,
                              setModalStatus,
                              modalStatus,
                              disableNav,
                              enableNav,
                              children,
                          }) => {
    useEffect(() => {
        const eventHandlerModalShow = () => {
            if (warnOnNav) disableNav();
            setModalStatus(modalStatuses.SHOWING_MODAL);
        };
        const eventHandlerModalHide = () => {
            setModalStatus(modalStatuses.NO_MODAL);
            if (warnOnNav) enableNav();
            done();
        };

        const el = $('#myBootstrapModal');
        el.on('show.bs.modal', eventHandlerModalShow);
        el.on('show.bs.modal', () => setModalIsShowing(true));
        el.on('hide.bs.modal', eventHandlerModalHide);
        el.on('hide.bs.modal', () => setModalIsShowing(false));

        return () => {
            if (modalStatus === modalStatuses.CLOSE_MODAL) {
                $('#myBootstrapModal').modal('hide');
            }

            el.off('show.bs.modal');
            el.off('hide.bs.modal');
        };
    }, [disableNav, done, enableNav, modalStatus, setModalIsShowing, setModalStatus, warnOnNav]);

    const btnElement = buttonAsAnchor ?
        <a href={'#'} className={buttonClassName} data-toggle="modal" data-target="#myBootstrapModal">{buttonContents}{buttonTitle}</a>
        :
        <button type="button" className={buttonClassName} data-toggle="modal" data-target="#myBootstrapModal">{buttonContents}{buttonTitle}</button>;

    const modalDialogClassName = getModalWidth(modalWidth);

    return (
        <div className={className} hidden={hidden}>
            {btnElement}
            <div className="modal" id="myBootstrapModal" data-backdrop="static" data-keyboard="false">  {/* data-backdrop and data-keyboard prevent closing by clicking the backdrop or hitting Esc */}
                <div className={modalDialogClassName}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{modalTitle}</h5>
                            <button type="button" className="close" data-dismiss="modal">
                                <span>&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div style={{ overflowY: 'auto' }}>
                                {children}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" data-dismiss="modal">{Localize.text('Done', 'Universal')}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
MyBootstrapModal.propTypes = {
    hidden: PropTypes.bool,
    className: PropTypes.string,
    buttonAsAnchor: PropTypes.bool,
    buttonContents: PropTypes.element,
    buttonClassName: PropTypes.string,
    buttonTitle: PropTypes.string,
    modalWidth: PropTypes.string,
    modalTitle: PropTypes.string,
    setModalIsShowing: PropTypes.func,
    done: PropTypes.func,
    warnOnNav: PropTypes.bool,
    children: PropTypes.element,
    setModalStatus: PropTypes.func,
    modalStatus: PropTypes.string,
    disableNav: PropTypes.func,
    enableNav: PropTypes.func,
};
export default connect(
    ({ appState }) => ({
        modalStatus: appState.modalStatus,
    }),
    dispatch => ({
        disableNav: () => dispatch(setAppDisableNav(true)),
        enableNav: () => dispatch(setAppDisableNav(false)),
        setModalStatus: status => dispatch(setAppModalStatus(status)),
    }),
)(MyBootstrapModal);

