import React from 'react';
import PropTypes from 'prop-types';
import { bindAll } from 'lodash';
import RegionForm from '../../shared/region-form';

class EditRegion extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            regionId: props.match.params.regionId,
            disabled: false,
        };
        bindAll(this, [
            'onSubmit',
            'onDelete',
        ]);
    }

    async onSubmit(data) {
        console.log('data', data);
        try {
            this.setState({
                ...this.state,
                disabled: true,
            });
            const { errors } = await gql.transaction(
                'mutation',
                'updateRegion',
                {
                    _id: data._id,
                    input: data,
                },
                ['_id']
            );
            if(errors) {
                this.setState({
                    ...this.state,
                    disabled: false,
                });
                throw errors[0];
            }
            this.props.enableNav();
            this.props.updateRegions();
            routerHistory.push('/');
        } catch(err) {
            handleError(err);
        }
    }

    async onDelete() {
        try {
            const { regionId } = this.props.match.params;
            this.setState({
                ...this.state,
                disabled: true,
            });
            const { errors } = await gql.transaction(
                'mutation',
                'deleteRegion',
                {
                    _id: regionId,
                }
            );
            if(errors) {
                this.setState({
                    ...this.state,
                    disabled: false,
                });
                throw errors[0];
            }
            this.props.enableNav();
            this.props.updateRegions();
            routerHistory.push('/');
        } catch(err) {
            handleError(err);
        }
    }

    render() {
        const { regionId, disabled } = this.state;
        const { user, location, disableNav, enableNav } = this.props;

        return (
            <div className={'container-fluid'}>
                {user.fmdbAdministrator ?
                    <RegionForm location={location} regionId={regionId} onSubmit={this.onSubmit} onDelete={this.onDelete} disabled={disabled} disableNav={disableNav} enableNav={enableNav} />
                    :
                    <div />
                }
            </div>
        );
    }

}
EditRegion.propTypes = {
    location: PropTypes.object,
    user: PropTypes.object,
    match: PropTypes.object,
    updateRegions: PropTypes.func,
    disableNav: PropTypes.func,
    enableNav: PropTypes.func,
};

export default EditRegion;
