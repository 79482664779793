import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindAll, escapeRegExp } from 'lodash';

const styles = {
    listItem: {
        // cursor: 'default'
    },
    table: {
        cursor: 'pointer',
        overflowX: 'hidden'
    },
    listTitle: {
        marginBottom: 0
    }
};

class MultiSelect extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.defaultState();
        bindAll(this, [
            'onItemDelete',
            'onItemAdd',
            'onListItemHover',
            'offListItemHover',
            'openAddModal',
            'onFilterChange',
            'defaultState',
            'onDone'
        ]);
    }

    defaultState() {
        return {
            active: '',
            filter: ''
        };
    }

    // shouldComponentUpdate(newProps) {
    //     const { props } = this;
    //     if(newProps.selected !== props.selected) {
    //         return true;
    //     } else if(newProps.items !== props.items) {
    //         return true;
    //     } else {
    //         return false;
    //     }
    // }

    onListItemHover(e, _id) {
        e.preventDefault();
        this.setState({
            ...this.state,
            active: _id
        });
    }

    offListItemHover(e) {
        e.preventDefault();
        this.setState({
            ...this.state,
            active: ''
        });
    }

    onItemDelete(e, _id) {
        e.preventDefault();
        this.props.onDelete(_id);
    }

    onItemAdd(e, _id) {
        e.preventDefault();
        this.props.onAdd(_id);
        this.onDone();
    }

    openAddModal(e) {
        e.preventDefault();
        $(this.modalNode).modal();
    }

    onFilterChange(e) {
        e.preventDefault();
        this.setState({
            ...this.state,
            filter: e.target.value
        });
    }

    onDone(e) {
        if(e) e.preventDefault();
        $(this.modalNode).modal('hide');
        this.setState(this.defaultState());
    }

    render() {

        const { active, filter } = this.state;
        const { items, selected, title, addTitle, filterable, windowHeight } = this.props;

        const listItems = items
            .filter(i => selected.includes(i._id))
            .map(i => {
                return (
                    <div key={i._id} className={'list-group-item list-group-item-action'} style={styles.listItem} onClick={this.onListItemClicked} onMouseOver={e => this.onListItemHover(e, i._id)} onMouseOut={e => this.offListItemHover(e, i._id)}>
                        <a style={{display: i._id === active ? 'inline-block' : 'none'}} href={'#'} className={'pull-right text-danger'} onClick={e => this.onItemDelete(e, i._id)}><i className={'fa fa-remove'} /></a><span>{i.name}</span>
                    </div>
                );
            });

        const filters = filter
            .trim()
            .split(/\s+/)
            .map(f => new RegExp(`(?:\\W|^)${escapeRegExp(f)}`, 'i'));

        const wholeWordFilters = filter
            .trim()
            .split(/\s+/)
            .map(f => new RegExp('(?:\\W|^)' + escapeRegExp(f) + '(?:\\W|$)', 'i'));

        const filteredItems = items
            .filter(i => !selected.includes(i._id))
            .filter(i => filters.every(f => f.test(i.name)))
            .sort((a, b) => {
                const aName = a.name;
                const bName = b.name;
                const aContainsWholeWords = filter.trim().length ? wholeWordFilters.every(f => f.test(aName)) : false;
                const bContainsWholeWords = filter.trim().length ? wholeWordFilters.every(f => f.test(bName)) : false;
                if(aContainsWholeWords === bContainsWholeWords) {
                    return 0;
                } else if(aContainsWholeWords) {
                    return -1;
                } else {
                    return 1;
                }
            });

        return (
            <div>
                <div className={'row'}>
                    <div className={'col-12'}>
                        <div className={'form-group'}>
                            <div className={'list-group'}>
                                <div className={'list-group-item list-group-item-secondary'}>
                                    <h4 style={styles.listTitle}>{title}</h4>
                                </div>
                                {listItems}
                            </div>
                        </div>
                        <div className={'form-group'}>
                            <button type={'button'} className={'btn btn-outline-secondary w-100'} onClick={this.openAddModal}><i className={'fa fa-plus'} /> {addTitle}</button>
                        </div>
                    </div>
                </div>

                <div id={'js-multiSelectModal'} className={'modal'} ref={node => this.modalNode = node}>
                    <div className={'modal-dialog'}>
                        <div className={'modal-content'}>
                            <div className={'modal-header'}>
                                <h5 className="modal-title">{addTitle}</h5>
                                <button type="button" className="close" onClick={this.onDone}>
                                    <span>&times;</span>
                                </button>
                            </div>
                            {filterable ?

                                <div>
                                    <div className="modal-body">
                                        <div className={'form-group'}>
                                            <label>{Localize.text('Filter', 'Universal')}</label>
                                            <input className={'form-control'} type={'text'} value={filter} onChange={this.onFilterChange} />
                                        </div>
                                        <div className={'form-group'}>
                                            <div style={{maxHeight: windowHeight - 400, overflowY: 'auto'}}>
                                                <table className={'table table-hover'} style={styles.table}>
                                                    <tbody>
                                                    {filteredItems.map(i => <tr key={i._id} onClick={e => this.onItemAdd(e, i._id)}><td>{i.name}</td></tr>)}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type={'button'} className={'btn btn-primary'} onClick={this.onDone}>{Localize.text('Done', 'Universal')}</button>
                                    </div>
                                </div>

                                :

                                <div>
                                    <div className="modal-body">
                                        <div className={'form-group'}>
                                            <div className={'dropdown'}>
                                                <button className={'btn btn-secondary-outline dropdown-toggle w-100'} type={'button'} ref={node => $(node).attr('data-toggle', 'dropdown')}>{Localize.text('SelectItem', 'Universal')}</button>
                                                <div className={'dropdown-menu w-100'}>
                                                    {filteredItems.map( i => <a href={'#'} className={'dropdown-item'} key={i._id} onClick={e => this.onItemAdd(e, i._id)}>{i.name}</a>)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type={'button'} className={'btn btn-primary'} onClick={this.onDone}>{Localize.text('Done', 'Universal')}</button>
                                    </div>
                                </div>

                            }
                        </div>
                    </div>
                </div>

            </div>
        );
    }

}
MultiSelect.propTypes = {
    title: PropTypes.string,
    addTitle: PropTypes.string,
    filterable: PropTypes.bool,
    items: PropTypes.arrayOf(PropTypes.object),
    selected: PropTypes.arrayOf(PropTypes.string),
    windowHeight: PropTypes.number,
    onAdd: PropTypes.func,
    onDelete: PropTypes.func
};

export default connect(
    ({ appState }) => ({
        windowHeight: appState.windowHeight
    })
)(MultiSelect);
