import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import bindAll from 'lodash/bindAll';
import { connect } from 'react-redux';
import swal from 'sweetalert';
import { setAppDisableNav } from '../../../../actions/app-actions';
import { modalStatuses } from '../../../../constants';
import omit from 'lodash/omit';

const RadioRow = ({ value, description, onChange }) => {

    const styles = {
        radioBox: {
            width: 40
        }
    };

    return (
        <tr>
            <td style={styles.radioBox}><input type={'radio'} checked={value === 0 ? true : false} onChange={e => onChange(e, 0)} /></td>
            <td style={styles.radioBox}><input type={'radio'} checked={value === 1 ? true : false} onChange={e => onChange(e, 1)} /></td>
            <td style={styles.radioBox}><input type={'radio'} checked={value === 2 ? true : false} onChange={e => onChange(e, 2)} /></td>
            <td style={styles.radioBox}><input type={'radio'} checked={value === 3 ? true : false} onChange={e => onChange(e, 3)} /></td>
            <td style={styles.radioBox}><input type={'radio'} checked={value === 4 ? true : false} onChange={e => onChange(e, 4)} /></td>
            <td style={styles.radioBox}><input type={'radio'} checked={value === 5 ? true : false} onChange={e => onChange(e, 5)} /></td>
            <td style={styles.radioBox}><input type={'radio'} checked={value === 6 ? true : false} onChange={e => onChange(e, 6)} /></td>
            <td>{description}</td>
        </tr>
    );
};
RadioRow.propTypes = {
    value: PropTypes.number,
    description: PropTypes.string,
    onChange: PropTypes.func
};

const omitForOrig = ['orig', 'status', 'editingIndex'];

class MaturingChurchForm extends React.Component {

    constructor(props) {
        super(props);
        const { maturingChurchStatuses = [], maturingChurchNote } = props.location;
        const orig = {
            ...this.generateDefaultData(maturingChurchStatuses),
            newNote: maturingChurchNote || ''
        };
        this.state = {
            ...orig,
            orig: omit(orig, omitForOrig),
        };
        bindAll(this, [
            'onMonthChange',
            'onYearChange',
            'noteChanged',
            'valueChanged',
            'onSave',
            'onCancel',
            'onDelete',
            'changeStatus',
            'onNewClick',
            'onEditClick',
            'onSelectedChange',
            'closeMaturityModal'
        ]);
    }

    componentWillReceiveProps(newProps) {
        if(newProps.location._id !== this.props.location._id) {
            const orig = {
                ...this.generateDefaultData(newProps.location.maturingChurchStatuses),
                newNote: newProps.location.maturingChurchNote
            };
            this.setState({ ...orig, orig: omit(orig, omitForOrig) });
        }
    }

    generateDefaultData(statuses = []) {
        const date = moment();
        return {
            statuses: statuses.map(this.convertToArr),
            status: 'view', // 'view', 'edit', 'new'
            month: date.format('M'),
            year: date.format('YYYY'),
            newValues: [[0, 0, 0, 0, 0], [0, 0, 0, 0, 0], [0, 0, 0, 0, 0], [0, 0, 0, 0, 0]],
            newNote: '',
            editingIndex: -1
        };
    }

    componentDidUpdate() {
        if (this.props.modalStatus === modalStatuses.CLOSE_MODAL) {
            this.onCancel();
        }
        // enableNav and disableNav here causes a re-render loop somehow, so for now just don't call them here.
        //  Manage nav in locations.js instead.
        // } else if (isEqual(this.state.orig, omit(this.state, omitForOrig))) {
        //     this.props.enableNav();
        // } else {
        //     this.props.disableNav();
        // }
    }

    onMonthChange(e) {
        e.preventDefault();
        console.log(typeof e.target.value);
        this.setState({
            ...this.state,
            month: e.target.value
        });
    }

    onYearChange(e) {
        e.preventDefault();
        console.log(typeof e.target.value);
        this.setState({
            ...this.state,
            year: e.target.value
        });
    }

    noteChanged(e) {
        e.preventDefault();
        this.setState({
            ...this.state,
            newNote: e.target.value
        });
    }

    valueChanged(e, val, idx1, idx2) {
        const { newValues } = this.state;
        this.setState({
            ...this.state,
            newValues: [
                ...newValues.slice(0, idx1),
                [
                    ...newValues[idx1].slice(0, idx2),
                    val,
                    ...newValues[idx1].slice(idx2 + 1)
                ],
                ...newValues.slice(idx1 + 1)
            ]
        });
    }

    async onSave(e, skipSetState = false) {
        try {
            if(e) e.preventDefault();
            const { statuses, newValues, newNote, editingIndex } = this.state;
            const month = parseInt(this.state.month, 10);
            const year = parseInt(this.state.year, 10);
            const valid = month > 0 && month < 13 ? true : false;
            if(!valid) {
                await swal({
                    text: Localize.text('MonthMustBeANumberFrom1To12', 'Location'),
                    icon: 'error',
                    button: Localize.text('OK', 'Universal')
                });
                return;
            }
            let newStatuses;
            const newStatus = [...newValues, month + '-' + year];
            if(editingIndex > -1) { // edited status
                newStatuses = [
                    ...statuses.slice(0, editingIndex),
                    newStatus,
                    ...statuses.slice(editingIndex + 1)
                ];
            } else { // new status
                newStatuses = [
                    ...statuses,
                    newStatus
                ];
            }
            newStatuses.sort((a, b) => {
                let [ aMonth, aYear ] = a[4].split('-');
                aMonth = Number(aMonth);
                aYear = Number(aYear);
                let [ bMonth, bYear ] = b[4].split('-');
                bMonth = Number(bMonth);
                bYear = Number(bYear);
                if(aYear === bYear) {
                    return aMonth === bMonth ? 0 : aMonth > bMonth ? 1 : -1;
                } else {
                    return aYear > bYear ? 1 : -1;
                }
            });
            if(!skipSetState) {
                const newState = {
                    ...this.generateDefaultData(),
                    statuses: newStatuses,
                    newNote
                };
                this.setState(newState);
            }
            this.props.onSave(newStatuses.map(this.convertToObj), newNote.trim());
        } catch(err) {
            handleError(err);
        }
    }

    onCancel(e) {
        if (e) e.preventDefault();
        const newState = {
            ...this.generateDefaultData(),
            statuses: this.state.statuses,
            newNote: this.props.location.maturingChurchNote
        };
        this.setState(newState);
    }

    async onDelete(e) {
        try {
            e.preventDefault();
            const { statuses, editingIndex } = this.state;
            const status = statuses[editingIndex];
            console.log('status', status);
            const confirmed = await swal({
                title: Localize.text('DeleteMaturityEntry', 'Location'),
                text: Localize.text('AreYouSureThatYouWantToDeleteTheMaturityEntryFor', 'Location') + ' ' + status[4],
                icon: 'warning',
                buttons: [
                    Localize.text('Cancel', 'Universal'),
                    Localize.text('YesDeleteEntry', 'Location')
                ]
            });
            if(!confirmed) return;
            const newStatuses = [
                ...statuses.slice(0, editingIndex),
                ...statuses.slice(editingIndex + 1)
            ];
            const newNote = this.props.location.maturingChurchNote;
            const newState = {
                ...this.generateDefaultData(),
                statuses: newStatuses,
                newNote
            };
            this.setState(newState);
            this.props.onSave(newStatuses.map(this.convertToObj), newNote);
        } catch(err) {
            handleError(err);
        }
    }

    changeStatus(status) {
        this.setState({
            ...this.state,
            status
        });
    }

    onNewClick(e) {
        e.preventDefault();
        this.changeStatus('new');
    }

    onEditClick(e) {
        e.preventDefault();
        this.changeStatus('edit');
    }

    convertToObj(s) {
        return {
            mChurchDate: s[4],
            mChurchGodsWord0: s[0][0],
            mChurchGodsWord1: s[0][1],
            mChurchGodsWord2: s[0][2],
            mChurchGodsWord3: s[0][3],
            mChurchGodsWord4: s[0][4],
            mChurchIdentity0: s[1][0],
            mChurchIdentity1: s[1][1],
            mChurchIdentity2: s[1][2],
            mChurchIdentity3: s[1][3],
            mChurchIdentity4: s[1][4],
            mChurchLife0: s[2][0],
            mChurchLife1: s[2][1],
            mChurchLife2: s[2][2],
            mChurchLife3: s[2][3],
            mChurchLife4: s[2][4],
            mChurchDiscipleship0: s[3][0],
            mChurchDiscipleship1: s[3][1],
            mChurchDiscipleship2: s[3][2],
            mChurchDiscipleship3: s[3][3],
            mChurchDiscipleship4: s[3][4]
        };
    }

    convertToArr(s) {
        return [
            [s.mChurchGodsWord0, s.mChurchGodsWord1, s.mChurchGodsWord2, s.mChurchGodsWord3, s.mChurchGodsWord4],
            [s.mChurchIdentity0, s.mChurchIdentity1, s.mChurchIdentity2, s.mChurchIdentity3, s.mChurchIdentity4],
            [s.mChurchLife0, s.mChurchLife1, s.mChurchLife2, s.mChurchLife3, s.mChurchLife4],
            [s.mChurchDiscipleship0, s.mChurchDiscipleship1, s.mChurchDiscipleship2, s.mChurchDiscipleship3, s.mChurchDiscipleship4],
            s.mChurchDate
        ];
    }

    onSelectedChange(e) {
        e.preventDefault();
        const idx = e.target.value;
        const s = this.state.statuses[idx];
        const splitDate = s[4].split('-');
        this.setState({
            ...this.state,
            month: splitDate[0],
            year: splitDate[1],
            newValues: s.slice(0, 4),
            editingIndex: Number(idx)
        });
    }

    async closeMaturityModal() {
        try {
            const { status, editingIndex } = this.state;
            if(status === 'new' || (status === 'edit' && editingIndex > -1)) {
                const confirmed = await swal({
                    text: Localize.text('WouldYouLikeToSaveAnyChanges', 'UniversalForms'),
                    buttons: {
                        cancel: Localize.text('Cancel', 'Universal'),
                        confirm: {
                            value: 'no',
                            text: Localize.text('No', 'Universal')
                        },
                        yes: {
                            value: 'yes',
                            text: Localize.text('Yes', 'Universal')
                        }
                    },
                    icon: 'warning',
                    closeOnClickOutside: false,
                    closeOnEsc: false
                });
                if(!confirmed) return;
                if(confirmed === 'yes') {
                    this.onSave(null, true);
                }
            }
            this.props.onClose();
        } catch(err) {
            handleError(err);
        }
    }

    render() {

        const { windowHeight, peopleGroup } = this.props;

        const { month, year, newValues, newNote, status, statuses, editingIndex } = this.state;

        const styles = {
            radioBox: {
                width: 40
            },
            valueBox: {
                width: 30
            },
            valueHeaderBox: {
                width: 30,
                position: 'relative'
            },
            valueHeaderInnerBox: {
                position: 'absolute',
                left: -17,
                bottom: 30,
                width: 70,
                transform: 'rotate(-90deg)'
                // transformOrigin: 'left top 0'
            },
            saveButton: {
                minWidth: 150
            },
            cancelButton: {
                minWidth: 150,
                marginRight: 10
            },
            deleteButton: {
                minWidth: 150,
                marginRight: 10,
                display: status === 'edit' && editingIndex > -1 ? 'block' : 'none'
            },
            modalDialog: {
                maxWidth: 1200
            },
            modalBody: {
                height: windowHeight - 250,
                maxHeight: windowHeight - 250,
                overflowY: 'auto'
            }
        };

        // console.log('maturing church form state', this.state);

        const { level } = peopleGroup;

        return (
            <div className={'modal show'} style={{display: 'block'}}>
                <div className={'modal-dialog modal-lg'} style={styles.modalDialog}>
                    <div className={'modal-content'}>
                        <div className={'modal-header'}>
                            <h5 className="modal-title">{Localize.text('MaturingChurch', 'PeopleGroupDetail')}</h5>
                            <button type="button" className="close" onClick={this.closeMaturityModal}>
                                <span>&times;</span>
                            </button>
                        </div>
                        <div className="modal-body" style={styles.modalBody}>

                            <div>

                                <div style={level > 1 ? {display: 'block'} : {display: 'none'}} className={'row'}>
                                    <div className={'col'}>
                                        <div className={'form-group'}>
                                            <button className={`btn btn-outline-dark ${status === 'edit' ? 'd-none' : ''}`} onClick={this.onNewClick}><i className={'fa fa-plus'} /> {Localize.text('CreateNewEntry', 'PeopleGroupDetail')}</button>
                                            <button className={`btn btn-outline-dark ${status === 'new' ? 'd-none' : ''}`} style={{marginLeft: 10}} onClick={this.onEditClick}><i className={'fa fa-pencil'} /> {Localize.text('EditExistingEntries', 'PeopleGroupDetail')}</button>
                                        </div>
                                    </div>
                                </div>

                                {status === 'view' ?
                                    <div>

                                        <div className={'row'}>
                                            <div className={'col'}>
                                                <div className={'form-group'}>
                                                    <table className={'table table-bordered'}>
                                                        <thead>
                                                        <tr style={{height: 80}}>
                                                            {statuses.map((s, i) => <th style={styles.valueHeaderBox} key={`th-0-${i}`}><span style={styles.valueHeaderInnerBox}>{s[4]}</span></th>)}
                                                            <th><h3>{Localize.text('GodSWordForTheChurch', 'PeopleGroupDetail')}</h3></th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr>
                                                            {statuses.map((s, i) => <td style={styles.valueBox} key={`s-0-${0}-${i}`}>{s[0][0]}</td>)}
                                                            <td>{Localize.text('TheAccessTheChurchAndCommunityHasToScripture', 'Universal')}</td>
                                                        </tr>
                                                        <tr>
                                                            {statuses.map((s, i) => <td style={styles.valueBox} key={`s-0-${1}-${i}`}>{s[0][1]}</td>)}
                                                            <td>{Localize.text('TheWayGodSWordEntersAndEngagesWithTheLivesOfPeople', 'Universal')}</td>
                                                        </tr>
                                                        <tr>
                                                            {statuses.map((s, i) => <td style={styles.valueBox} key={`s-0-${2}-${i}`}>{s[0][2]}</td>)}
                                                            <td>{Localize.text('TheLevelOfAuthorityTheChurchAscribesToGodSWord', 'Universal')}</td>
                                                        </tr>
                                                        <tr>
                                                            {statuses.map((s, i) => <td style={styles.valueBox} key={`s-0-${3}-${i}`}>{s[0][3]}</td>)}
                                                            <td>{Localize.text('TheWayTheChurchGoesAboutInterpretingGodSWord', 'Universal')}</td>
                                                        </tr>
                                                        <tr>
                                                            {statuses.map((s, i) => <td style={styles.valueBox} key={`s-0-${4}-${i}`}>{s[0][4]}</td>)}
                                                            <td>{Localize.text('TheChurchSCapacityToMakeUseOfGodSWord', 'Universal')}</td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>

                                        <div className={'row'}>
                                            <div className={'col'}>
                                                <div className={'form-group'}>
                                                    <table className={'table table-bordered'}>
                                                        <thead>
                                                        <tr>
                                                            {statuses.map((s, i) => <th style={styles.valueBox} key={`th-1-${i}`}></th>)}
                                                            <th><h3>{Localize.text('IdentityOfTheChurch', 'PeopleGroupDetail')}</h3></th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr>
                                                            {statuses.map((s, i) => <td style={styles.valueBox} key={`s-1-${0}-${i}`}>{s[1][0]}</td>)}
                                                            <td>{Localize.text('TheChurchSUnderstandingOfWhoItIs', 'Universal')}</td>
                                                        </tr>
                                                        <tr>
                                                            {statuses.map((s, i) => <td style={styles.valueBox} key={`s-1-${1}-${i}`}>{s[1][1]}</td>)}
                                                            <td>{Localize.text('TheBondsWhichUniteTheChurchAsABodyUnderChristTheHead', 'Universal')}</td>
                                                        </tr>
                                                        <tr>
                                                            {statuses.map((s, i) => <td style={styles.valueBox} key={`s-1-${2}-${i}`}>{s[1][2]}</td>)}
                                                            <td>{Localize.text('TheCompositionOfTheChurchMembership', 'Universal')}</td>
                                                        </tr>
                                                        <tr>
                                                            {statuses.map((s, i) => <td style={styles.valueBox} key={`s-1-${3}-${i}`}>{s[1][3]}</td>)}
                                                            <td>{Localize.text('TheOriginsAndContinuityOfTheChurch', 'Universal')}</td>
                                                        </tr>
                                                        <tr>
                                                            {statuses.map((s, i) => <td style={styles.valueBox} key={`s-1-${4}-${i}`}>{s[1][4]}</td>)}
                                                            <td>{Localize.text('TheWayTheChurchRelatesToOthers', 'Universal')}</td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>

                                        <div className={'row'}>
                                            <div className={'col'}>
                                                <div className={'form-group'}>
                                                    <table className={'table table-bordered'}>
                                                        <thead>
                                                        <tr>
                                                            {statuses.map((s, i) => <th style={styles.valueBox} key={`th-2-${i}`}></th>)}
                                                            <th><h3>{Localize.text('LifeOfTheChurch', 'PeopleGroupDetail')}</h3></th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr>
                                                            {statuses.map((s, i) => <td style={styles.valueBox} key={`s-2-${0}-${i}`}>{s[2][0]}</td>)}
                                                            <td>{Localize.text('TheNatureAndQualityOfTheChurchSSpiritualLife', 'Universal')}</td>
                                                        </tr>
                                                        <tr>
                                                            {statuses.map((s, i) => <td style={styles.valueBox} key={`s-2-${1}-${i}`}>{s[2][1]}</td>)}
                                                            <td>{Localize.text('TheChurchSAwarenessOfTheOverallPurposeForItsExistence', 'Universal')}</td>
                                                        </tr>
                                                        <tr>
                                                            {statuses.map((s, i) => <td style={styles.valueBox} key={`s-2-${2}-${i}`}>{s[2][2]}</td>)}
                                                            <td>{Localize.text('TheSpecificFunctionsTheChurchTakesOn', 'Universal')}</td>
                                                        </tr>
                                                        <tr>
                                                            {statuses.map((s, i) => <td style={styles.valueBox} key={`s-2-${3}-${i}`}>{s[2][3]}</td>)}
                                                            <td>{Localize.text('TheWayTheChurchGoesAboutEstablishingFormsForItself', 'Universal')}</td>
                                                        </tr>
                                                        <tr>
                                                            {statuses.map((s, i) => <td style={styles.valueBox} key={`s-2-${4}-${i}`}>{s[2][4]}</td>)}
                                                            <td>{Localize.text('TheFruitThatResultsFromTheLifeOfTheChurch', 'Universal')}</td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>

                                        <div className={'row'}>
                                            <div className={'col'}>
                                                <div className={'form-group'}>
                                                    <table className={'table table-bordered'}>
                                                        <thead>
                                                        <tr>
                                                            {statuses.map((s, i) => <th style={styles.valueBox} key={`th-3-${i}`}></th>)}
                                                            <th><h3>{Localize.text('DiscipleshipInTheChurch', 'PeopleGroupDetail')}</h3></th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr>
                                                            {statuses.map((s, i) => <td style={styles.valueBox} key={`s-3-${0}-${i}`}>{s[3][0]}</td>)}
                                                            <td>{Localize.text('TheChurchSViewOfItsRelationshipToChrist', 'Universal')}</td>
                                                        </tr>
                                                        <tr>
                                                            {statuses.map((s, i) => <td style={styles.valueBox} key={`s-3-${1}-${i}`}>{s[3][1]}</td>)}
                                                            <td>{Localize.text('TheAbilityToHelpPeopleMakePersonalAndPracticalApplicationsOfTruth', 'Universal')}</td>
                                                        </tr>
                                                        <tr>
                                                            {statuses.map((s, i) => <td style={styles.valueBox} key={`s-3-${2}-${i}`}>{s[3][2]}</td>)}
                                                            <td>{Localize.text('TheValueGivenToPurposefulChristAimedRelationshipsInTheChurch', 'Universal')}</td>
                                                        </tr>
                                                        <tr>
                                                            {statuses.map((s, i) => <td style={styles.valueBox} key={`s-3-${3}-${i}`}>{s[3][3]}</td>)}
                                                            <td>{Localize.text('TheEncouragementOfMembersFoFunctionInAreasOfEngiftmentIncludingShepherdsOfTheTruth', 'Universal')}</td>
                                                        </tr>
                                                        <tr>
                                                            {statuses.map((s, i) => <td style={styles.valueBox} key={`s-3-${4}-${i}`}>{s[3][4]}</td>)}
                                                            <td>{Localize.text('TheExistenceOfStrategiesForEquippingThoseInvolvedInTheOutreachOfTheChurch', 'Universal')}</td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>

                                        <div className={'row'}>
                                            <div className={'col'}>
                                                <div className={'form-group'}>
                                                    <h3>{Localize.text('MaturingChurchNote', 'PeopleGroupDetail')}</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={'row'}>
                                            <div className={'col'}>
                                                <div className={'form-group'}>
                                                    {newNote}
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    :
                                    <div>

                                        {status === 'edit' && editingIndex === -1 ?
                                            <div className={'row'}>
                                                <div className={'col-4'}>
                                                    <div className={'form-group'}>
                                                        <label>{Localize.text('DateBeingEdited', 'Universal')}</label>
                                                        <select className={'form-control'} onChange={this.onSelectedChange}>
                                                            <option value={-1}>{Localize.text('NoneSelected', 'Universal')}</option>
                                                            {statuses.map((s, i) => {
                                                                return <option key={`status=${i}`} value={i}>{s[4]}</option>;
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div>
                                                <div className={'row'}>
                                                    <div className={'col'}>
                                                        <div className={'form-group'}>
                                                            <label>{Localize.text('DateEvaluated', 'PeopleGroupDetail')}</label>
                                                            <div className={'row'}>
                                                                <div className={'col-2'}>
                                                                    <input type={'number'} className={'form-control text-center'} value={month} onChange={this.onMonthChange} step={1} min={1} max={12}></input>
                                                                </div>
                                                                <div className={'col-2'}>
                                                                    <input type={'number'} className={'form-control text-center'} value={year} onChange={this.onYearChange} step={1} min={1900} max={2100}></input>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        <div style={{visibility: status === 'edit' && editingIndex === -1 ? 'hidden' : 'visible'}}>
                                            <div className={'row'}>
                                                <div className={'col'}>
                                                    <div className={'form-group'}>
                                                        <h3>{Localize.text('GodSWordForTheChurch', 'PeopleGroupDetail')}</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={'row'}>
                                                <div className={'col'}>
                                                    <div className={'form-group'}>
                                                        <table className={'table'}>
                                                            <thead>
                                                            <tr>
                                                                <th style={styles.radioBox}>{0}</th>
                                                                <th style={styles.radioBox}>{1}</th>
                                                                <th style={styles.radioBox}>{2}</th>
                                                                <th style={styles.radioBox}>{3}</th>
                                                                <th style={styles.radioBox}>{4}</th>
                                                                <th style={styles.radioBox}>{5}</th>
                                                                <th style={styles.radioBox}>{6}</th>
                                                                <th>{Localize.text('Description', 'Universal')}</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            <RadioRow value={newValues[0][0]} description={Localize.text('TheAccessTheChurchAndCommunityHasToScripture', 'Universal')} onChange={(e, val) => this.valueChanged(e, val, 0, 0)} />
                                                            <RadioRow value={newValues[0][1]} description={Localize.text('TheWayGodSWordEntersAndEngagesWithTheLivesOfPeople', 'Universal')} onChange={(e, val) => this.valueChanged(e, val, 0, 1)} />
                                                            <RadioRow value={newValues[0][2]} description={Localize.text('TheLevelOfAuthorityTheChurchAscribesToGodSWord', 'Universal')} onChange={(e, val) => this.valueChanged(e, val, 0, 2)} />
                                                            <RadioRow value={newValues[0][3]} description={Localize.text('TheWayTheChurchGoesAboutInterpretingGodSWord', 'Universal')} onChange={(e, val) => this.valueChanged(e, val, 0, 3)} />
                                                            <RadioRow value={newValues[0][4]} description={Localize.text('TheChurchSCapacityToMakeUseOfGodSWord', 'Universal')} onChange={(e, val) => this.valueChanged(e, val, 0, 4)} />
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={'row'}>
                                                <div className={'col'}>
                                                    <div className={'form-group'}>
                                                        <h3>{Localize.text('IdentityOfTheChurch', 'PeopleGroupDetail')}</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={'row'}>
                                                <div className={'col'}>
                                                    <div className={'form-group'}>
                                                        <table className={'table'}>
                                                            <thead>
                                                            <tr>
                                                                <th style={styles.radioBox}>{0}</th>
                                                                <th style={styles.radioBox}>{1}</th>
                                                                <th style={styles.radioBox}>{2}</th>
                                                                <th style={styles.radioBox}>{3}</th>
                                                                <th style={styles.radioBox}>{4}</th>
                                                                <th style={styles.radioBox}>{5}</th>
                                                                <th style={styles.radioBox}>{6}</th>
                                                                <th>{Localize.text('Description', 'Universal')}</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            <RadioRow value={newValues[1][0]} description={Localize.text('TheChurchSUnderstandingOfWhoItIs', 'Universal')} onChange={(e, val) => this.valueChanged(e, val, 1, 0)} />
                                                            <RadioRow value={newValues[1][1]} description={Localize.text('TheBondsWhichUniteTheChurchAsABodyUnderChristTheHead', 'Universal')} onChange={(e, val) => this.valueChanged(e, val, 1, 1)} />
                                                            <RadioRow value={newValues[1][2]} description={Localize.text('TheCompositionOfTheChurchMembership', 'Universal')} onChange={(e, val) => this.valueChanged(e, val, 1, 2)} />
                                                            <RadioRow value={newValues[1][3]} description={Localize.text('TheOriginsAndContinuityOfTheChurch', 'Universal')} onChange={(e, val) => this.valueChanged(e, val, 1, 3)} />
                                                            <RadioRow value={newValues[1][4]} description={Localize.text('TheWayTheChurchRelatesToOthers', 'Universal')} onChange={(e, val) => this.valueChanged(e, val, 1, 4)} />
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={'row'}>
                                                <div className={'col'}>
                                                    <div className={'form-group'}>
                                                        <h3>{Localize.text('LifeOfTheChurch', 'PeopleGroupDetail')}</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={'row'}>
                                                <div className={'col'}>
                                                    <div className={'form-group'}>
                                                        <table className={'table'}>
                                                            <thead>
                                                            <tr>
                                                                <th style={styles.radioBox}>{0}</th>
                                                                <th style={styles.radioBox}>{1}</th>
                                                                <th style={styles.radioBox}>{2}</th>
                                                                <th style={styles.radioBox}>{3}</th>
                                                                <th style={styles.radioBox}>{4}</th>
                                                                <th style={styles.radioBox}>{5}</th>
                                                                <th style={styles.radioBox}>{6}</th>
                                                                <th>{Localize.text('Description', 'Universal')}</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            <RadioRow value={newValues[2][0]} description={Localize.text('TheNatureAndQualityOfTheChurchSSpiritualLife', 'Universal')} onChange={(e, val) => this.valueChanged(e, val, 2, 0)} />
                                                            <RadioRow value={newValues[2][1]} description={Localize.text('TheChurchSAwarenessOfTheOverallPurposeForItsExistence', 'Universal')} onChange={(e, val) => this.valueChanged(e, val, 2, 1)} />
                                                            <RadioRow value={newValues[2][2]} description={Localize.text('TheSpecificFunctionsTheChurchTakesOn', 'Universal')} onChange={(e, val) => this.valueChanged(e, val, 2, 2)} />
                                                            <RadioRow value={newValues[2][3]} description={Localize.text('TheWayTheChurchGoesAboutEstablishingFormsForItself', 'Universal')} onChange={(e, val) => this.valueChanged(e, val, 2, 3)} />
                                                            <RadioRow value={newValues[2][4]} description={Localize.text('TheFruitThatResultsFromTheLifeOfTheChurch', 'Universal')} onChange={(e, val) => this.valueChanged(e, val, 2, 4)} />
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={'row'}>
                                                <div className={'col'}>
                                                    <div className={'form-group'}>
                                                        <h3>{Localize.text('DiscipleshipInTheChurch', 'PeopleGroupDetail')}</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={'row'}>
                                                <div className={'col'}>
                                                    <div className={'form-group'}>
                                                        <table className={'table'}>
                                                            <thead>
                                                            <tr>
                                                                <th style={styles.radioBox}>{0}</th>
                                                                <th style={styles.radioBox}>{1}</th>
                                                                <th style={styles.radioBox}>{2}</th>
                                                                <th style={styles.radioBox}>{3}</th>
                                                                <th style={styles.radioBox}>{4}</th>
                                                                <th style={styles.radioBox}>{5}</th>
                                                                <th style={styles.radioBox}>{6}</th>
                                                                <th>{Localize.text('Description', 'Universal')}</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            <RadioRow value={newValues[3][0]} description={Localize.text('TheChurchSViewOfItsRelationshipToChrist', 'Universal')} onChange={(e, val) => this.valueChanged(e, val, 3, 0)} />
                                                            <RadioRow value={newValues[3][1]} description={Localize.text('TheAbilityToHelpPeopleMakePersonalAndPracticalApplicationsOfTruth', 'Universal')} onChange={(e, val) => this.valueChanged(e, val, 3, 1)} />
                                                            <RadioRow value={newValues[3][2]} description={Localize.text('TheValueGivenToPurposefulChristAimedRelationshipsInTheChurch', 'Universal')} onChange={(e, val) => this.valueChanged(e, val, 3, 2)} />
                                                            <RadioRow value={newValues[3][3]} description={Localize.text('TheEncouragementOfMembersFoFunctionInAreasOfEngiftmentIncludingShepherdsOfTheTruth', 'Universal')} onChange={(e, val) => this.valueChanged(e, val, 3, 3)} />
                                                            <RadioRow value={newValues[3][4]} description={Localize.text('TheExistenceOfStrategiesForEquippingThoseInvolvedInTheOutreachOfTheChurch', 'Universal')} onChange={(e, val) => this.valueChanged(e, val, 3, 4)} />
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={'row'}>
                                                <div className={'col'}>
                                                    <div className={'form-group'}>
                                                        <h3>{Localize.text('MaturingChurchNote', 'PeopleGroupDetail')}</h3>
                                                    </div>
                                                    <div className={'form-group'}>
                                                        <textarea className={'form-control'} style={{resize: 'vertical'}} rows={5} value={newNote} onChange={this.noteChanged} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={'row'} style={{marginBottom: 10}}>
                                                <div className={'col'}>
                                                    <div className={'form-group'}>
                                                        <button type={'button'} style={styles.saveButton} className={'btn btn-primary float-right'} onClick={this.onSave}>{Localize.text('Save', 'Universal')}</button>
                                                        <button type={'button'} style={styles.cancelButton} className={'btn btn-outline-dark float-right'} onClick={this.onCancel}>{Localize.text('Cancel', 'Universal')}</button>
                                                        <button type={'button'} style={styles.deleteButton} className={'btn btn-danger float-right'} onClick={this.onDelete}>{Localize.text('Delete', 'Universal')}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>

                        </div>
                        <div className="modal-footer">
                            <button type={'button'} className={'btn btn-outline-dark'} onClick={this.closeMaturityModal}>{Localize.text('Done', 'Universal')}</button>
                        </div>
                    </div>
                </div>
            </div>
        );

    }

}
MaturingChurchForm.propTypes = {
    peopleGroup: PropTypes.object,
    windowHeight: PropTypes.number,
    location: PropTypes.object,
    onSave: PropTypes.func,
    onClose: PropTypes.func,
    modalStatus: PropTypes.string,
    disableNav: PropTypes.func,
    enableNav: PropTypes.func,
};

export default connect(
    ({ appState }) => ({
        windowHeight: appState.windowHeight,
        modalStatus: appState.modalStatus,
    }),
    dispatch => ({
        disableNav: () => dispatch(setAppDisableNav(true)),
        enableNav: () => dispatch(setAppDisableNav(false)),
    }),
)(MaturingChurchForm);
