import React from 'react';
import PropTypes from 'prop-types';
import * as _ from 'lodash';
import swal from 'sweetalert';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import moment from 'moment';

import bibleData from '../../../shared/bible';
import ModalTextarea from '../../../shared/modal-textarea';
import FilterSelect from '../../../shared/filter-select';
import * as dateUtils from '../../../../util/date-utils';
import { setAppDisableNav, setAppModalStatus } from '../../../../actions/app-actions';
import { modalStatuses, suppressErrorEmail } from '../../../../constants';
import callAttributes from '../../../../util/gql-call-attributes';

// console.log('bibleData', bibleData);

const oldTestamentBookAbbrs = new Set(bibleData.slice(0, 39).map(b => b.abbr));
// const newTestamentBookAbbrs = new Set(bibleData.slice(39).map(b => b.abbr));

const totalOldTestamentVerses = bibleData
    .slice(0, oldTestamentBookAbbrs.size)
    .reduce((totalVerses, b) => {
        const verses = b.chapters
            .reduce((chapterVerses, c) => {
                return chapterVerses + Number(c.verses);
            }, 0);
        return totalVerses + verses;
    }, 0);
const totalNewTestamentVerses = bibleData
    .slice(oldTestamentBookAbbrs.size)
    .reduce((totalVerses, b) => {
        const verses = b.chapters
            .reduce((chapterVerses, c) => {
                return chapterVerses + Number(c.verses);
            }, 0);
        return totalVerses + verses;
    }, 0);

// console.log('oldTestamentBooks', oldTestamentBooks);
// console.log('totalOldTestamentVerses', totalOldTestamentVerses);
// console.log('newTestamentBooks', newTestamentBooks);
// console.log('totalNewTestamentVerses', totalNewTestamentVerses);

const getMaxVerses = (book, chapter) => {
    if(!chapter) return '';
    chapter = Number(chapter);
    return book.chapters[chapter - 1] ? book.chapters[chapter - 1].verses : '';
};

const singleVersePatt = /^\w\w\w\s([0123456789]+):([0123456789]+)$/;
const singleChapterPatt = /^\w\w\w\s([0123456789]+)$/;
const verseRangePatt = /^\w\w\w\s([0123456789]+):([0123456789]+)-([0123456789]+)$/;
const chapterRangePatt = /^\w\w\w\s([0123456789]+)-([0123456789]+)$/;
const chapterVerseRange = /^\w\w\w\s([0123456789]+):([0123456789]+)-([0123456789]+):([0123456789]+)$/;

const addVerse = (allChapters, book, chapter, verse, maxVerse) => {
    if (chapter < 1 || chapter > Object.keys(allChapters).length) {
        throw new Error(`${suppressErrorEmail}${Localize.text('InvalidChapter', 'Translation')} ${book.book} ${chapter}`);
    }

    if (verse < 0 || verse > book.chapters[chapter - 1].verses) {
        throw new Error(`${suppressErrorEmail}${Localize.text('InvalidVerse', 'Translation')} ${book.book} ${chapter}:${maxVerse}`);
    }

    // valid to add
    allChapters[chapter].add(verse);

    return allChapters;
};

const addVerses = (allChapters, startVerse, endVerse, book, chapter) => {
    if (endVerse === 0) {
        throw new Error(`${suppressErrorEmail}${Localize.text('InvalidChapter', 'Translation')} ${book.book} ${chapter}`);
    }

    if (startVerse > endVerse) {
        throw new Error(`${suppressErrorEmail}${Localize.text('InvalidVerse', 'Translation')} ${book.book} ${chapter}:${startVerse}`);
    }

    for(let v = startVerse; v < endVerse + 1; v++) {
        allChapters = addVerse(allChapters, book, chapter, v, endVerse);
    }

    return allChapters;
};

const expandPassage = (book, allChapters, currentChapter, p) => {
    if(singleVersePatt.test(p)) {
        const matches = p.match(singleVersePatt);
        const chapter = matches[1];
        const verse = Number(matches[2]);
        allChapters = addVerse(allChapters, book, chapter, verse, verse);
    } else if(singleChapterPatt.test(p)) {
        const matches = p.match(singleChapterPatt);
        const chapter = matches[1];
        const total = Number(getMaxVerses(book, chapter));
        allChapters = addVerses(allChapters, 1, total, book, chapter);
    } else if(chapterRangePatt.test(p)) {
        const matches = p.match(chapterRangePatt);
        const fromChapter = matches[1];
        const toChapter = matches[2];
        for(let chapter = Number(fromChapter); chapter < Number(toChapter) + 1; chapter++) {
            const total = Number(getMaxVerses(book, String(chapter)));
            allChapters = addVerses(allChapters, 1, total, book, chapter);
        }
    } else if(chapterVerseRange.test(p)) {
        const matches = p.match(chapterVerseRange);
        const fromChapter = Number(matches[1]);
        const fromVerse = Number(matches[2]);
        const toChapter = Number(matches[3]);
        const toVerse = Number(matches[4]);
        for(let chapter = fromChapter; chapter < toChapter + 1; chapter++) {
            const total = Number(getMaxVerses(book, String(chapter)));
            if(chapter === fromChapter) {
                allChapters = addVerses(allChapters, fromVerse, total, book, chapter);
            } else if(chapter < toChapter) {
                allChapters = addVerses(allChapters, 1, total, book, chapter);
            } else if(chapter === toChapter) {
                allChapters = addVerses(allChapters, 1, toVerse, book, chapter);
            }
        }
    } else if(verseRangePatt.test(p)) {
        const matches = p.match(verseRangePatt);
        const fromChapter = Number(matches[1]);
        const fromVerse = Number(matches[2]);
        const toVerse = Number(matches[3]);
        allChapters = addVerses(allChapters, fromVerse, toVerse, book, fromChapter);
    } else {
        // split on comma and check for separate verses, separate chapters, and ranges across chapter breaks
        // some tests:
        //  1,3,5
        //  1, 3
        //  1:3,5,7
        //  1:3, 5, 7
        //  1:3,5,7-2:12
        //  1:3-5,7,9-4:2,4-10:15,17;14
        const commaSplits = p
            .split(',')
            .map(s => s.trim().replace(/\s+/g, ' '))
            .filter(s => !!s);

        // get chapter and whether we are inside the chapter
        if (commaSplits.length > 1) {
            const hasAbbrPatt = new RegExp('^' + book.abbr);
            for (const cs of commaSplits) {
                // remove book abbreviation if it exists
                const cleanCs = hasAbbrPatt.test(cs) ? cs.slice(4) : cs;

                allChapters = expandPassage(book, allChapters, currentChapter,  book.abbr + ' ' + (currentChapter === 0 ? cleanCs : `${currentChapter}:${cleanCs}`));

                if (cleanCs.includes(':')) {
                    const dashSplits = cleanCs.split('-');
                    for (let i = dashSplits.length - 1; i >= 0; --i) {
                        const ds = dashSplits[i];
                        if (ds.includes(':')) {
                            currentChapter = parseInt(ds, 10);
                        }
                    }
                }
            }
        } else {
            throw new Error(`${suppressErrorEmail}${Localize.text('InvalidRangePeriod', 'Translation')} ${p}`);
        }
    }

    return allChapters;
};

const expandPassages = (book, passages) => {
    let allChapters = book.chapters.reduce((obj, c, i) => {
        return {
            ...obj,
            [i + 1]: new Set()
        };
    }, {});

    let currentChapter = 0;

    for(const p of passages) {
        allChapters = expandPassage(book, allChapters, currentChapter, p);
    }

    return allChapters;
};

const consolidatePassages = (book, passages) => {
    if (passages.length === 0 || passages.filter(p => p.trim().length > 0).length === 0) {
        throw new Error(`${suppressErrorEmail}${Localize.text('NothingToAdd', 'Translation')}`);
    }
    const consolidated = [];
    const allChapters = expandPassages(book, passages);
    const allChaptersLength = Object.keys(allChapters).length;
    for(let i = 0; i < allChaptersLength; i++) {
        const c = i + 1;
        const chapterLength = Number(getMaxVerses(book, c));
        const translatedVerses = allChapters[c].size;
        if(translatedVerses === 0) {
            continue;
        } else if(translatedVerses === chapterLength) {
            const wholeChapters = [c];
            for(let j = i + 1; j < allChaptersLength; j++) {
                const cc = j + 1;
                const ccLength = Number(getMaxVerses(book, cc));
                const translated = allChapters[cc].size;
                if(ccLength === translated) {
                    wholeChapters.push(cc);
                } else {
                    break;
                }
            }
            i = i + wholeChapters.length - 1;
            consolidated.push(wholeChapters.length === 1 ? `${book.abbr} ${wholeChapters[0]}` : `${book.abbr} ${wholeChapters[0]}-${wholeChapters[wholeChapters.length - 1]}`);
        } else {
            let startChapter = c;
            let startVerse = 0;
            let endChapter = 0;
            let endVerse = 0;
            for(let j = 0; j < allChaptersLength - startChapter + 1; j++) {
                const cc = c + j;
                const max = Number(getMaxVerses(book, cc));
                let breakChapterLoop = false;
                for(let v = 1; v < max + 1; v++) {
                    if(v === max) {
                        if(!startVerse || !allChapters[cc + 1] || (allChapters[cc + 1] && !allChapters[cc + 1].has(1))) {
                            i = cc - 1;
                            breakChapterLoop = true;
                        }
                    }
                    if(!allChapters[cc].has(v)) {
                        if(startVerse) {
                            consolidated.push((startChapter === endChapter && startVerse === endVerse) ? `${book.abbr} ${endChapter}:${endVerse}` : startChapter === endChapter ? `${book.abbr} ${startChapter}:${startVerse}-${endVerse}` : `${book.abbr} ${startChapter}:${startVerse}-${endChapter}:${endVerse}`);
                            startChapter = 0;
                            startVerse = 0;
                            endChapter = 0;
                            endVerse = 0;
                        } else {
                            continue;
                        }
                    } else if(!startVerse) {
                        startChapter = cc;
                        startVerse = v;
                        endChapter = cc;
                        endVerse = v;
                    } else {
                        endChapter = cc;
                        endVerse = v;
                    }
                }
                if(breakChapterLoop) {
                    if(startVerse) consolidated.push((startChapter === endChapter && startVerse === endVerse) ? `${book.abbr} ${endChapter}:${endVerse}` : startChapter === endChapter ? `${book.abbr} ${startChapter}:${startVerse}-${endVerse}` : `${book.abbr} ${startChapter}:${startVerse}-${endChapter}:${endVerse}`);
                    break;
                }
            }

        }
    }
    return consolidated;
};

const generateCounts = (passages) => {
    const passagesByBook = passages
        .reduce((obj, p) => {
            const book = p.slice(0, 3);
            if(obj[book]) {
                return {
                    ...obj,
                    [book]: [...obj[book], p]
                };
            } else {
                return {
                    ...obj,
                    [book]: [p]
                };
            }
        }, {});
    // console.log('Passages by book.', passagesByBook);
};

const sortByCompletedDate = direction => (a, b) => {
    const dateA = a.completedDate;
    const dateB = b.completedDate;
    let [ monthA, yearA ] = dateA.split('-');
    let [ monthB, yearB ] = dateB.split('-');
    monthA = Number(monthA);
    monthB = Number(monthB);
    yearA = Number(yearA);
    yearB = Number(yearB);
    if(yearA === yearB) {
        if(monthA === monthB) {
            const timeA = moment(a.createdAt).toDate().getTime();
            const timeB = moment(b.createdAt).toDate().getTime();
            return timeA === timeB ? 0 : timeA > timeB ? direction : direction * -1;
        } else {
            return monthA > monthB ? direction : direction * -1;
        }
    } else {
        return yearA > yearB ? direction : direction * -1;
    }
};

class Translation extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedBook: 'GEN',
            showNonTranslated: false,
            fromChapter: '1',
            fromVerse: '1',
            toChapter: '1',
            toVerse: bibleData.find(b => b.abbr === 'GEN').chapters[0].verses,
            validatedList: '',
            translationText: '',
            activeTab: 'assisted', // assisted, text
            translator: '',
            consultant: '',
            completedDate: '',
            note: '',
            translationDetails: [],
            detailsSortBy: 'completedDate',
            detailsSortDirection: -1
        };
        _.bindAll(this, [
            'onShowNonTranslated',
            'activeTabChange',
            'onChangeBook',
            'onValidateClick',
            'onClearValidatedList',
            'onFinishedClick',
            'updateTestamentPercentages',
            'onLastChapter',
            'onLastVerse',
            'onEditTranslatedSave',
            'onTranslationTextChange',
            'onInputChange',
            'onShowDetails',
            'onDetailsModalClose',
            'setDetailsSortBy'
        ]);
    }

    async componentDidMount() {
        try {
            const { _id } = this.props.peopleGroup;
            const res = await gql.transaction(
                'query',
                'getTranslationDetails',
                { peopleGroup: _id },
                [
                    '_id',
                    'createdAt',
                    'translator',
                    'consultant',
                    'completedDate',
                    'sectionBeingTranslated',
                    'note'
                ]
            );
            if(res.errors) throw res.errors[0];
            const { getTranslationDetails: translationDetails } = res.data;
            translationDetails
                .sort((a, b) => b.createdAt === a.createdAt ? 0 : a.createdAt > b.createdAt ? -1 :1);
            if(translationDetails.length > 0) {
                const { translator, consultant, completedDate } = translationDetails[0];
                this.setState({
                    ...this.state,
                    translationDetails,
                    translator,
                    consultant,
                    completedDate
                });
            }
        } catch(err) {
            handleError(err);
        }
    }

    onTranslationTextChange(e) {
        e.preventDefault();
        this.setState({
            ...this.state,
            translationText: e.target.value
        });
    }

    onShowNonTranslated(e) {
        this.setState({
            ...this.state,
            showNonTranslated: !this.state.showNonTranslated
        });
    }

    activeTabChange(e, tab) {
        e.preventDefault();
        this.setState({
            ...this.state,
            activeTab: tab
        });
    }

    async onChangeBook(e, abbr) {
        try {
            e.preventDefault();

            const { validatedList } = this.state;
            if(validatedList) {
                const confirmed = await swal({
                    text: Localize.text('WouldYouLikeToSaveTheEnteredTranslationsBeforeGoingToANewBook', 'Translation'),
                    buttons: [
                        Localize.text('No', 'Universal'),
                        Localize.text('Yes', 'Universal')
                    ],
                    icon: 'warning'
                });
                if(confirmed) {
                    const success = await this.onFinishedClick();
                    if(!success) return;
                }
            }

            const book = bibleData.find(b => b.abbr === abbr);
            const fromChapter = '1';
            const fromVerse = '1';
            const toChapter = '1';
            const toVerse = book.chapters[0].verses;
            this.setState({
                ...this.state,
                selectedBook: abbr,
                fromChapter,
                fromVerse,
                toChapter,
                toVerse,
                validatedList: '',
                translationText: ''
            });

        } catch(err) {
            handleError(err);
        }
    }

    onChapterVerseChange(e, key) { // fromChapter, fromVerse, toChapter, toVerse
        e.preventDefault();
        let { value } = e.target;
        let { selectedBook, fromChapter, fromVerse, toChapter, toVerse } = this.state;
        const book = bibleData.find(b => b.abbr === selectedBook);
        if(key === 'fromChapter') {
            value = !value ? '' : Number(value) > 0 && Number(value) <= book.chapters.length ? value : fromChapter;
            fromChapter = value;
            fromVerse = '1';
            toChapter = value ? value : toChapter;
            toVerse = value ? book.chapters[Number(value) - 1].verses : '';
        } else if(key === 'fromVerse') {
            const maxVerses = getMaxVerses(book, fromChapter);
            fromVerse = !value ? '' : Number(value) > 0 && Number(value) <= maxVerses ? value : fromVerse;
        } else if(key === 'toChapter') {
            value = !value ? '' : Number(value) > 0 && Number(value) <= book.chapters.length ? value : toChapter;
            toChapter = value;
            const maxVerses = getMaxVerses(book, toChapter);
            toVerse = value ? maxVerses : '';
        } else if(key === 'toVerse') {
            const maxVerses = getMaxVerses(book, toChapter);
            toVerse = !value ? '' : Number(value) > 0 && Number(value) <= maxVerses ? value : toVerse;
        }
        this.setState({
            ...this.state,
            fromChapter: fromChapter,
            fromVerse: fromVerse,
            toChapter: toChapter,
            toVerse: toVerse
        });
    }

    onClearValidatedList(e) {
        e.preventDefault();
        this.setState({
            ...this.state,
            validatedList: ''
        });
        this.props.enableNav();
    }

    onValidateClick(e) {
        e.preventDefault();
        const { activeTab, selectedBook, fromChapter, fromVerse, toChapter, toVerse, translationText } = this.state;
        const book = bibleData.find(b => b.abbr === selectedBook);
        if(activeTab === 'assisted') {
            if(!fromChapter || !fromVerse || !toChapter || !toVerse) {
                swal({
                    icon: 'warning',
                    title: Localize.text('MissingInformation', 'Translation'),
                    text: Localize.text('YouMustEnterAStartingChapterAndVerseAndAnEndingChapterAndVerse', 'Translation')
                });
                return;
            }
            let range;
            if(fromChapter === toChapter) {
                const toTotalVerses = getMaxVerses(book, toChapter);
                if(fromVerse === toVerse) {
                    range = `${selectedBook} ${fromChapter}:${fromVerse}`;
                } else if(fromVerse === '1' && toVerse === toTotalVerses) {
                    range = `${selectedBook} ${fromChapter}`;
                } else if(Number(fromVerse) < Number(toVerse)) {
                    range = `${selectedBook} ${fromChapter}:${fromVerse}-${toVerse}`;
                } else { // fromVerse is more than toVerse
                    swal({
                        icon: 'warning',
                        title: Localize.text('InvalidRange', 'Translation'),
                        text: Localize.text('TheFromVerseCannotBeHigherThanTheToVerse', 'Translation')
                    });
                    return;
                }
            } else if(Number(fromChapter) < Number(toChapter)) {
                const toTotalVerses = getMaxVerses(book, toChapter);
                if(fromVerse === '1' && toVerse === toTotalVerses) {
                    range = `${selectedBook} ${fromChapter}-${toChapter}`;
                } else {
                    range = `${selectedBook} ${fromChapter}:${fromVerse}-${toChapter}:${toVerse}`;
                }
            } else { // fromChapter is more than toChapter
                swal({
                    icon: 'warning',
                    title: Localize.text('InvalidRange', 'Translation'),
                    text: Localize.text('TheFromChapterCannotBeHigherThanTheToChapter', 'Translation')
                });
                return;
            }
            const consolidated = consolidatePassages(book, [...this.state.validatedList.split('\n').filter(v => v), range]);
            this.setState({
                ...this.state,
                translationText: '',
                validatedList: consolidated.join('\n')
            });
        } else { // text
            console.log(translationText);
            const bookNamePatt = new RegExp(book.book, 'i');
            const bookAbbrPatt = new RegExp(book.abbr, 'i');
            const hasAbbrPatt = new RegExp('^' + book.abbr);
            const splitText = translationText
                .trim()
                .split(/[\n;]/g)
                .map(s => s.trim().replace(/\s+/g, ' '))
                .filter(s => !!s)
                .map(s => s.replace(bookNamePatt, book.abbr))
                .map(s => s.replace(bookAbbrPatt, book.abbr))
                .map(s => !hasAbbrPatt.test(s) ? book.abbr + ' ' + s : s);
                // .filter(s => {
                //     try {
                //         console.log('s to consolidate', s)
                //         consolidatePassages(book, [s]);
                //         return true;
                //     } catch(err) {
                //         return false;
                //     }
                // });
            const consolidated = consolidatePassages(book, splitText);
            this.setState({
                ...this.state,
                translationText: '',
                validatedList: consolidated.join('\n')
            });
        }

        this.props.disableNav();
    }

    async onFinishedClick(e) {
        try {
            if(e) e.preventDefault();
            const { selectedBook, validatedList, translator, consultant } = this.state;
            if (!validatedList) {
                await swal({
                    icon: 'warning',
                    text: Localize.text('NothingToAdd', 'Translation'),
                    button: Localize.text('OK', 'Universal'),
                });
                return;
            }

            let { completedDate, note } = this.state;
            const { peopleGroup } = this.props;
            const translated = peopleGroup.translationTranslated
                .filter(t => t.slice(0, 3) === selectedBook);
            const book = bibleData.find(b => b.abbr === selectedBook);
            const consolidated = consolidatePassages(book, [...validatedList.split('\n'), ...translated]);

            completedDate = completedDate.trim();
            note = note.trim();

            if(!completedDate) {
                await swal({
                    icon: 'warning',
                    text: Localize.text('PleaseEnterATranslationCompletedDate', 'Translation'),
                    button: Localize.text('OK', 'Universal')
                });
                return;
            }
            const valid = await dateUtils.isValidDate(completedDate, true);
            if(!valid) return;
            completedDate = dateUtils.formatDate(completedDate);

            if(!translator) {
                await swal({
                    icon: 'warning',
                    text: Localize.text('YouMustSelectATranslator', 'Translation'),
                    button: Localize.text('OK', 'Universal')
                });
                return;
            } else if(!consultant) {
                await swal({
                    icon: 'warning',
                    text: Localize.text('YouMustSelectAConsultant', 'Translation'),
                    button: Localize.text('OK', 'Universal')
                });
                return;
            }

            this.setState({
                ...this.state,
                validatedList: ''
            });
            const totalVerses = bibleData
                .find(b => b.abbr === selectedBook)
                .chapters
                .reduce((total, { verses }) => {
                    return total + Number(verses);
                }, 0);
            const allChapters = expandPassages(book, consolidated);
            const allTranslatedVerses = Object.keys(allChapters).reduce((total, k) => total + allChapters[k].size, 0);
            let percentage = Math.floor((allTranslatedVerses / totalVerses) * 100);
            if(percentage === 0 && allTranslatedVerses > 0) percentage = 1;
            await this.props.onTranslationChange(selectedBook, consolidated, percentage);
            const translatorData = {
                peopleGroup: peopleGroup._id,
                translator,
                consultant,
                completedDate,
                note,
                sectionBeingTranslated: validatedList.split('\n').join(',')
            };
            console.log('translatorData', translatorData);
            const res = await gql.transaction(
                'mutation',
                'createTranslationDetails',
                { input: translatorData },
                [
                    '_id',
                    'peopleGroup',
                    'translator',
                    'consultant',
                    'completedDate',
                    'note',
                    'sectionBeingTranslated'
                ]
            );
            if(res.errors) throw res.errors[0];
            const { createTranslationDetails: translationDetails } = res.data;
            this.setState({
                ...this.state,
                translationDetails: [translationDetails, ...this.state.translationDetails]
            });
            await new Promise(resolve => setTimeout(resolve, 0));
            this.updateTestamentPercentages();
            this.props.enableNav();
            return true;
        } catch(err) {
            handleError(err);
            return false;
        }
    }

    updateTestamentPercentages() {
        const { peopleGroup } = this.props;
        const otVersesTranslated = bibleData
            .slice(0, oldTestamentBookAbbrs.size)
            .map(book => {
                const translated = peopleGroup.translationTranslated
                    .filter(t => t.slice(0, 3) === book.abbr);
                const allChapters = expandPassages(book, translated);
                const allVerses = Object.keys(allChapters)
                    .map(key => allChapters[key].size)
                    .reduce((total, t) => total + t, 0);
                return allVerses;
            })
            .reduce((total, t) => total + t, 0);
        const ntVersesTranslated = bibleData
            .slice(oldTestamentBookAbbrs.size)
            .map(book => {
                const translated = peopleGroup.translationTranslated
                    .filter(t => t.slice(0, 3) === book.abbr);
                const allChapters = expandPassages(book, translated);
                const allVerses = Object.keys(allChapters)
                    .map(key => allChapters[key].size)
                    .reduce((total, t) => total + t, 0);
                return allVerses;
            })
            .reduce((total, t) => total + t, 0);
        const oldTestamentPercentage = ((otVersesTranslated / totalOldTestamentVerses) * 100).toFixed(2);
        const newTestamentPercentage = ((ntVersesTranslated / totalNewTestamentVerses) * 100).toFixed(2);
        this.props.onTotalPercentageChange(Number(oldTestamentPercentage), Number(newTestamentPercentage));
    }

    onLastChapter(e) {
        e.preventDefault();
        const { selectedBook: abbr } = this.state;
        const book = bibleData.find(b => b.abbr === abbr);
        const lastChapter = book.chapters[book.chapters.length - 1];
        this.setState({
            ...this.state,
            toChapter: lastChapter.chapter,
            toVerse: lastChapter.verses
        });
    }

    onLastVerse(e) {
        e.preventDefault();
        const { selectedBook: abbr, toChapter } = this.state;
        const book = bibleData.find(b => b.abbr === abbr);
        const chapter = book.chapters.find(c => c.chapter === toChapter);
        if (chapter) {
            this.setState({
                ...this.state,
                toVerse: chapter.verses
            });
        } else {
            swal(`${Localize.text('InvalidChapter', 'Translation')} ${abbr} ${toChapter}`).then();
        }
    }

    async onEditTranslatedSave(value = '') {
        try {
            let confirmed;
            let percentage = 0;
            let consolidated = [];
            const { selectedBook } = this.state;

            const deleteAll = value.trim().length === 0;

            if (!deleteAll) {
                value = value
                    .trim()
                    .split('\n')
                    .map(s => s.trim())
                    .filter(s => !!s);
                const book = bibleData.find(b => b.abbr === selectedBook);
                consolidated = consolidatePassages(book, value);
                const totalVerses = bibleData
                    .find(b => b.abbr === selectedBook)
                    .chapters
                    .reduce((total, {verses}) => {
                        return total + Number(verses);
                    }, 0);
                const allChapters = expandPassages(book, consolidated);
                const allTranslatedVerses = Object.keys(allChapters).reduce((total, k) => total + allChapters[k].size, 0);
                percentage = Math.floor((allTranslatedVerses / totalVerses) * 100);
                if (percentage === 0 && allTranslatedVerses > 0) percentage = 1;
                confirmed = await swal({
                    text: Localize.text('YourChangesHaveBeenEvaluatedIsThisTheCorrectNewTranslatedList', 'Translation') + '\n\n' + consolidated.join('\n'),
                    icon: 'warning',
                    buttons: [
                        Localize.text('Cancel', 'Universal'),
                        Localize.text('OK', 'Universal')
                    ]
                });
            } else {
                confirmed = await swal({
                    text: Localize.text('AreYouSureYouWantToDeleteAllTranslationForThisBook', 'Translation') + '\n\n' + consolidated.join('\n'),
                    icon: 'warning',
                    buttons: [
                        Localize.text('Cancel', 'Universal'),
                        Localize.text('OK', 'Universal')
                    ]
                });
            }
            if(confirmed) {
                const { onTranslationChange, peopleGroup } = this.props;
                await onTranslationChange(selectedBook, consolidated, percentage);
                await new Promise(resolve => setTimeout(resolve, 0));
                this.updateTestamentPercentages();

                // check to see if user deleted all translation for the people group
                const { pgErrors, data } = await gql.transaction(
                    'query',
                    'getPeopleGroup',
                    {
                        _id: peopleGroup._id,
                    },
                    callAttributes.peopleGroupDetail(),
                );
                if(pgErrors) throw new Error(pgErrors[0].message);
                const { getPeopleGroup: newPgInfo } = data;

                if (newPgInfo.translationPercentages.findIndex(t => t.percentage > 0) < 0 &&
                    !newPgInfo.translationTranslated.length) {
                    // User has deleted all translation, so they want to delete all TranslationDetails for this people group as well.
                    const { errors: tdErrors } = await gql.transaction(
                        'mutation',
                        'deleteTranslationDetails',
                        { peopleGroup: peopleGroup._id }
                    );
                    if(tdErrors) throw new Error(tdErrors[0].message);

                    this.setState({ translationDetails: [] });
                }
            }
        } catch(err) {
            console.error(err);
            swal({
                text: Localize.text('ThereWasAProblemParsingYourChangesPleaseTryAgainButMakeSureThatEachPassageRangeIsOnItsOwnLineAndThatEachRangeIsValid', 'Translation'),
                icon: 'error',
                button: Localize.text('OK', 'Universal')
            });
        }
    }

    async showEditTranslatedWarning() {
        await swal({
            title: Localize.text('BeCareful', 'Translation'),
            text: Localize.text('DoNotEnterThisAreaUnlessYouAbsolutelyHaveToIfYouWantToAddNewTranslatedRangesPleaseUseTheAssistedOrTextEntryMethodIfYouAbsolutelyHaveToEditTheFinishedTranslationRangesThenDoSoHereButBeExtremelyCareful', 'Translation'),
            icon: 'warning',
            button: Localize.text('OK', 'Universal')
        });
    }

    onInputChange(e, key) {
        e.preventDefault();
        this.setState({
            ...this.state,
            [key]: e.target.value
        });
    }

    onShowDetails(e) {
        e.preventDefault();
        this.props.setModalStatus(modalStatuses.SHOWING_SWAL);

        const $modal = $(this.detailsModalNode);
        $modal.modal({
            show: true,
            backdrop: 'static'
        });
        $modal
            .off('hidden.bs.modal')
            .on('hidden.bs.modal', () => {
                this.onDetailsModalClose();
            });
    }

    onDetailsModalClose(e) {
        if (e) e.preventDefault();
        // $(this.detailsModalNode).modal({
        //     show: false
        // });
        this.props.setModalStatus(modalStatuses.NO_MODAL);
    }

    setDetailsSortBy(e, key) {
        e.preventDefault();
        if(key === this.state.detailsSortBy) {
            this.setState({
                ...this.state,
                detailsSortBy: key,
                detailsSortDirection: -1 * this.state.detailsSortDirection
            });
        } else {
            if( key === 'completedDate') {
                this.setState({
                    ...this.state,
                    detailsSortBy: key,
                    detailsSortDirection: -1
                });
            } else {
                this.setState({
                    ...this.state,
                    detailsSortBy: key,
                    detailsSortDirection: 1
                });
            }
        }
    }

    render() {

        // console.log('translation state', this.state);

        const { showNonTranslated, activeTab, selectedBook, fromChapter, fromVerse, toChapter, toVerse, validatedList, confirmedList, translationText, translator, consultant, completedDate, note, translationDetails, detailsSortBy, detailsSortDirection } = this.state;

        const { windowWidth, windowHeight, peopleGroup, people } = this.props;

        generateCounts(peopleGroup.translationTranslated);

        const book = bibleData.find(b => b.abbr === selectedBook);

        const translated = peopleGroup.translationTranslated
            .filter(t => t.slice(0, 3) === selectedBook);

        const { translationPercentages } = peopleGroup;

        const peopleItems = [...people.values()];

        let sortedTranslationDetails;
        const intCol = new Intl.Collator(Localize.locale());
        if(detailsSortBy === 'completedDate') {
            sortedTranslationDetails = translationDetails.sort(sortByCompletedDate(detailsSortDirection));
        } else if(detailsSortBy === 'translator' || detailsSortBy === 'consultant') {
            if(people.size > 0) {
                sortedTranslationDetails = [...translationDetails]
                    .sort((a, b) => {
                        const { firstName: firstNameA = '', lastName: lastNameA = '' } = people.get(a[detailsSortBy]) || {};
                        const { firstName: firstNameB = '', lastName: lastNameB = '' } = people.get(b[detailsSortBy]) || {};
                        const nameA = firstNameA + ' ' + lastNameA;
                        const nameB = firstNameB + ' ' + lastNameB;
                        return detailsSortDirection * intCol.compare(nameA, nameB);
                    });
            } else {
                sortedTranslationDetails = translationDetails;
            }
        } else if(detailsSortBy === 'sectionBeingTranslated') {
            sortedTranslationDetails = [...translationDetails].sort((a, b) => detailsSortDirection * intCol.compare(a.sectionBeingTranslated, b.sectionBeingTranslated));
        } else {
            sortedTranslationDetails = translationDetails;
        }

        const editable = peopleGroup.level > 1;

        return (
            <div className={'container-fluid'}>
                <div className={'row'} style={{height: windowHeight - 300}}>

                    <div className={'col-md-3'} style={{height: '100%'}}>
                        <div style={{display: 'flex', flexDirection: 'column', flexWrap: 'nowrap', justifyContent: 'flex-start', height: '100%'}}>
                            <div className={'form-check'} style={{marginBottom: 10}}>
                                <input id="js-showNonTranslated" className="form-check-input" type="checkbox" checked={showNonTranslated} onChange={this.onShowNonTranslated} />
                                <label htmlFor="js-showNonTranslated" className="form-check-label">{Localize.text('ShowNonTranslatedBooks', 'Translation')}</label>
                            </div>
                            <div className={'card'} style={{flexGrow: 1, display: 'flex'}}>
                                <div className={'card-body'} style={{flexGrow: 1, overflowY: 'auto'}}>
                                    {
                                        bibleData
                                            .filter(b => {
                                                if(showNonTranslated) return true;
                                                const p = translationPercentages.find(p => p.book === b.abbr);
                                                const progress = p ? p.percentage : 0;
                                                return progress;
                                            })
                                            .map(b => {
                                                const p = translationPercentages.find(p => p.book === b.abbr);
                                                const progress = p ? p.percentage : 0;
                                                return (
                                                    <button key={b.abbr} type={'button'} className={'btn btn-sm ' + (selectedBook === b.abbr ? 'btn-info' : 'btn-outline-secondary')} style={{display:'block', width: '100%', marginBottom: 10, cursor: 'pointer'}} onClick={e => this.onChangeBook(e, b.abbr)}>{`${b.book} : ${progress}%`}</button>
                                                );
                                            })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={'col'}>
                        <div className={'row'}>
                            <div className={'col'} style={{position: 'relative', marginBottom: 5}}>
                                <button className={'btn btn-outline-dark'} style={{position: 'absolute', left: 15, top: -5}} onClick={this.onShowDetails}><i className="fa fa-list" /> {Localize.text('ShowDetails', 'Translation')}</button>
                                <h4 className={'text-center'} style={{marginTop: 0}}>{Localize.text('TranslationStatus', 'Translation')}</h4>
                            </div>
                        </div>
                        <div className={'row'}>
                            <div className={'col'}>
                                <div className={'card'}>
                                    <div className={'card-header'}>{Localize.text('AddTranslationRange', 'Translation')}</div>
                                    <div className={'card-body'}>

                                        <ul className="nav nav-tabs">
                                            <li className="nav-item">
                                                <a className={`nav-link ${activeTab === 'assisted' ? 'active' : ''}`} href="#" onClick={e => this.activeTabChange(e, 'assisted')}>{Localize.text('AssistedEntryMode', 'Translation')}</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className={`nav-link ${activeTab === 'text' ? 'active' : ''}`} href="#" onClick={e => this.activeTabChange(e, 'text')}>{Localize.text('TextEntryMode', 'Translation')}</a>
                                            </li>
                                        </ul>

                                        {activeTab === 'assisted' ?
                                            <div>
                                                <div className={'row'}>
                                                    <div className={'col'}>
                                                        <div className={'card'} style={{marginTop: 10}}>
                                                            <div className={'card-header'}>{Localize.text('From', 'Universal')}</div>
                                                            <div className={'card-body'} style={{minHeight: 126}}>
                                                                <div className={'row'}>
                                                                    <div className={'col form-group'}>
                                                                        <label>{Localize.text('Book', 'Universal')}</label>
                                                                        <select className={'form-control form-control-sm'} value={selectedBook} onChange={e => this.onChangeBook(e, e.target.value)}>
                                                                            {
                                                                                bibleData.map(b => {
                                                                                    return (
                                                                                        <option key={b.abbr} value={b.abbr}>{b.book} ({b.abbr})</option>
                                                                                    );
                                                                                })
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                    <div className={'col form-group'}>
                                                                        <label>{Localize.text('Chapter', 'Universal')}</label>
                                                                        <input type={'number'} className={'form-control form-control-sm'} min={1} max={book.chapters.length} value={fromChapter} onChange={e => this.onChapterVerseChange(e, 'fromChapter')} />
                                                                    </div>
                                                                    <div className={'col form-group'}>
                                                                        <label>{Localize.text('Verse', 'Universal')}</label>
                                                                        <input type={'number'} className={'form-control form-control-sm'} min={1} max={getMaxVerses(book, fromChapter)} value={fromVerse} onChange={e => this.onChapterVerseChange(e, 'fromVerse')} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={'col'}>
                                                        <div className={'card'} style={{marginTop: 10}}>
                                                            <div className={'card-header'}>{Localize.text('To', 'Universal')}</div>
                                                            <div className={'card-body'} style={{minHeight: 126}}>
                                                                <div className={'row'}>
                                                                    <div className={'col form-group'}>
                                                                        <label>{Localize.text('Book', 'Universal')}</label>
                                                                        <input type={'text'} className={'form-control form-control-sm'} value={`${book.book} (${book.abbr})`} readOnly={true} />
                                                                    </div>
                                                                    <div className={'col'}>
                                                                        <div className={'form-group'}>
                                                                            <label>{Localize.text('Chapter', 'Universal')}</label>
                                                                            <input type={'number'} className={'form-control form-control-sm'} value={toChapter} min={1} max={book.chapters.length} onChange={e => this.onChapterVerseChange(e, 'toChapter')} />
                                                                        </div>
                                                                        <div>

                                                                            <button type={'button'} className={'btn btn-outline-secondary btn-sm'} style={{display: 'block', width: '100%'}} onClick={this.onLastChapter}>{Localize.text('LastChapter', 'Translation')}</button>

                                                                        </div>
                                                                    </div>
                                                                    <div className={'col'}>
                                                                        <div className={'form-group'}>
                                                                            <label>{Localize.text('Verse', 'Universal')}</label>
                                                                            <input type={'number'} className={'form-control form-control-sm'} value={toVerse} min={1} max={getMaxVerses(book, toChapter)} onChange={e => this.onChapterVerseChange(e, 'toVerse')} />
                                                                        </div>
                                                                        <div>

                                                                            <button type={'button'} className={'btn btn-outline-secondary btn-sm'} style={{display: 'block', width: '100%'}} onClick={this.onLastVerse}>{Localize.text('LastVerse', 'Translation')}</button>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div>
                                                <div className={'row'}>
                                                    <div className={'col-4'}></div>
                                                    <div className={'col'}>{Localize.text('Example1151610', 'Translation')}</div>
                                                </div>
                                                <div className={'form-group row'}>
                                                    <div className={'form-group col-2'}>
                                                        <label>{Localize.text('Book', 'Universal')}</label>
                                                        <select className={'form-control form-control-sm'} value={selectedBook} onChange={e => this.onChangeBook(e, e.target.value)}>
                                                            {
                                                                bibleData.map(b => {
                                                                    return (
                                                                        <option key={b.abbr} value={b.abbr}>{b.book} ({b.abbr})</option>
                                                                    );
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                    <label className={'col-2 col-form-label'}>{Localize.text('EnterVerseAddressesHere', 'Translation')}:</label>
                                                    <textarea className={'col-6 form-control'} style={{height: 100}} value={translationText} onChange={this.onTranslationTextChange} placeholder={Localize.text('EnterRangesSeparatedBySemicolonsOrNewLines', 'Translation')}></textarea>
                                                </div>
                                            </div>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={'row'} style={{marginTop: 10}}>
                            <button type={'button'} className={'btn btn-primary btn-sm'} style={{minWidth: 280, display: 'block', margin: 'auto'}} onClick={this.onValidateClick} disabled={editable ? false : true}><i className={'fa fa-arrow-down'} /> {Localize.text('ValidateAndAddTranslation', 'Translation')}</button>
                        </div>

                        <div className={'row'} style={{marginTop: 10}}>
                            <div className={'col'}>
                                <div className={'card'}>
                                    <div className={'card-header'}>{Localize.text('ThisIsWhatWillBeAdded', 'Translation')}</div>
                                    <div className={'card-body'}>
                                        <div className={'form-group row'} style={{marginBottom: 0}}>
                                            <div className={'col'}>
                                                <textarea className={'form-control'} style={{height: 75, fontSize: 13}} readOnly={true} value={validatedList}></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={'col-2'}>
                                <button type={'button'} className={'btn btn-sm btn-outline-secondary'} style={{width: '100%', overflow: 'hidden'}} onClick={this.onClearValidatedList} disabled={editable ? false : true}><i className={'fa fa-remove'} /> {Localize.text('ClearAddList', 'Translation')}</button>
                            </div>
                        </div>

                        <div className={'row'} style={{marginTop: 10}}>
                            <div className={'col-10'}>
                                <div className={'form-group'} style={{marginBottom: 0, display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', justifyContent: 'flex-start'}}>
                                    <label style={{minWidth: 260}}>{Localize.text('CompletedDate', 'UniversalForms')}: <small className={'text-muted'}>({Localize.text('FormatMMYYYY', 'Training')})</small></label>
                                    <input style={{minWidth: 100, maxWidth: 100, width: 100, marginRight: 10}} type={'text'} className={'form-control form-control-sm'} value={completedDate} onChange={e => this.onInputChange(e, 'completedDate')} />
                                    <label style={{marginRight: 5}}>{Localize.text('Note', 'Universal')}:</label>
                                    <input style={{flexGrow: 1}} type={'text'} className={'form-control form-control-sm'} value={note} onChange={e => this.onInputChange(e, 'note')} />
                                </div>
                            </div>
                        </div>
                        <div className={'row'} style={{marginTop: 10}}>
                            <div className={'col-10'}>
                                <div className={'form-group'} style={{marginBottom: 0, display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', justifyContent: 'flex-start'}}>
                                    <label style={{marginRight: 5}}>{Localize.text('Translator', 'Universal')}:</label>
                                    <FilterSelect key={`translator-${translator}`} initialValue={translator} setValueOnBlur={true} small={true} retainValueOnSelect={true} items={peopleItems} onSelect={_id => this.setState({...this.state, translator: _id})} />
                                    <label style={{marginLeft: 10, marginRight: 5}}>{Localize.text('Consultant', 'Universal')}:</label>
                                    <FilterSelect key={`consultant-${consultant}`} initialValue={consultant} setValueOnBlur={true} small={true} retainValueOnSelect={true} items={peopleItems} onSelect={_id => this.setState({...this.state, consultant: _id})} />
                                </div>
                            </div>
                        </div>
                        <div className={'row'} style={{marginTop: 10, marginBottom: 10}}>
                            <div className={'col-12'}>
                                <div className={'form-group'} style={{marginBottom: 0, display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', justifyContent: 'space-around'}}>
                                    <button type={'button'} style={{minWidth: 280, display: 'block'}} className={'btn btn-primary btn-sm'} onClick={this.onFinishedClick} disabled={!editable}><i className={'fa fa-arrow-down'} /> {Localize.text('AddToFinishedTranslationsAndSave', 'Translation')}</button>
                                </div>
                            </div>
                        </div>

                        <div className={'row'} style={{marginTop: 0, marginBottom: 10}}>
                            <div className={'col'}>
                                <div className={'card'}>
                                    <div className={'card-header'}>
                                        <ModalTextarea
                                            onBeforeOpen={this.showEditTranslatedWarning}
                                            readonly={!editable}
                                            value={translated.join('\n')}
                                            onSave={this.onEditTranslatedSave}
                                            title={Localize.text('EditTranslatedVerses', 'Translation')} />
                                        <span>{Localize.text('VersesTranslatedForThisBook', 'Translation')}</span>
                                    </div>
                                    <div className={'card-body'} style={{fontSize: 13}}>
                                        <div className={'form-group row'} style={{marginBottom: 0}}>
                                            <div className={'col'}>
                                                <textarea className={'form-control'} style={{height: 75, fontSize: 13}} readOnly={true} value={translated.join('\n')}></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

                <div className={'modal'} ref={node => this.detailsModalNode = node}>
                    <div className={'modal-dialog modal-lg'} style={{width: windowWidth - 200, maxWidth: windowWidth - 200, minWidth: windowWidth - 200}}>
                        <div className={'modal-content'}>
                            <div className={'modal-header'}>
                                <h5 className="modal-title">{Localize.text('TranslationDetails', 'Translation')}</h5>
                                <button type="button" onClick={this.onDetailsModalClose} className="close" data-dismiss="modal">
                                    <span>&times;</span>
                                </button>
                            </div>
                            <div className="modal-body" style={{maxHeight: windowHeight - 250, overflowY: 'auto', overflowX: 'hidden'}}>
                                <div className={'row'}>
                                    <div className={'col'}>
                                        <table className={'table table-bordered'} style={{width: '100%', maxWidth: '100%'}}>
                                            <thead>
                                            <tr>
                                                <th style={{minWidth: 110, maxWidth: 110, width: 110}}><a href="#" onClick={e => this.setDetailsSortBy(e, 'completedDate')}>{Localize.text('Completed', 'Universal')}</a></th>
                                                <th><a href="#" onClick={e => this.setDetailsSortBy(e, 'translator')}>{Localize.text('Translator', 'Universal')}</a></th>
                                                <th><a href="#" onClick={e => this.setDetailsSortBy(e, 'consultant')}>{Localize.text('Consultant', 'Universal')}</a></th>
                                                <th><a href="#" onClick={e => this.setDetailsSortBy(e, 'sectionBeingTranslated')}>{Localize.text('Sections', 'Universal')}</a></th>
                                                <th>{Localize.text('Note', 'Universal')}</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {sortedTranslationDetails.map((t, i) => {
                                                const translatorPersonObj = people.has(t.translator) ? people.get(t.translator) : {firstName: '', lastName: ''};
                                                const constultantPersonObj = people.has(t.consultant) ? people.get(t.consultant) : {firstName: '', lastName: ''};

                                                return (
                                                    <tr key={t._id + i}>
                                                        <td>{t.completedDate}</td>
                                                        <td>{translatorPersonObj.firstName + ' ' + translatorPersonObj.lastName}</td>
                                                        <td>{constultantPersonObj.firstName + ' ' + constultantPersonObj.lastName}</td>
                                                        <td>{t.sectionBeingTranslated.replace(/,/g, ', ')}</td>
                                                        <td className={'text-break'}>{t.note}</td>
                                                    </tr>
                                                );
                                            })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button style={{minWidth: 150}} type={'button'} className={'btn btn-primary'} onClick={this.onDetailsModalClose} data-dismiss="modal">{Localize.text('Close', 'Universal')}</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }

}
Translation.propTypes = {
    routeParams: PropTypes.object,
    peopleGroup: PropTypes.object,
    windowHeight: PropTypes.number,
    windowWidth: PropTypes.number,
    people: PropTypes.instanceOf(Map),
    onTranslationChange: PropTypes.func,
    onTotalPercentageChange: PropTypes.func,
    modalStatus: PropTypes.string,
    disableNav: PropTypes.func,
    enableNav: PropTypes.func,
    setModalStatus: PropTypes.func,
};

const TranslationContainer = connect(
    ({ appState }) => ({
        people: appState.people,
        modalStatus: appState.modalStatus,
    }),
    dispatch => ({
        disableNav: () => dispatch(setAppDisableNav(true)),
        enableNav: () => dispatch(setAppDisableNav(false)),
        setModalStatus: status => dispatch(setAppModalStatus(status)),
    }),
)(Translation);

export default TranslationContainer;
