import omit from 'lodash/omit';

export default class FileType {

    constructor(data) {
        this._id = data._id || '';
        this.name = data.name || '';
        this.filename = data.filename || '';
        this.size = data.size || 0;
        this.type = data.type || '';
        this.folder = data.folder || '';
        this.uploadedDate = data.uploadedDate || '';
        this.uploadedBy = data.uploadedBy || '';
        this.aboutPerson = data.aboutPerson || '';
        this.aboutField = data.aboutField || '';
        this.aboutPeopleGroup = data.aboutPeopleGroup || '';
        this.children = data.children || [];
        this.depth = data.depth || 0;
    }

    set(newData) {
        return new FileType({
            ...this,
            ...newData
        });
    }

    async updateRemote(input) {
        const { _id } = this;
        const { errors } = await gql.transaction(
            'mutation',
            'updateFile',
            {
                _id,
                input
            },
            ['_id']
        );
        if(errors) throw errors[0];
    }

    async deleteRemote() {
        const { children = [] } = this;
        const res = await Promise.all(
            [this._id, ...children.map(c => c._id)]
                .map(_id => gql.transaction(
            'mutation',
            'deleteFile',
            { _id }
            ))
        );
        for(const { errors } of res) {
            if(errors) throw errors[0];
        }
    }

}
