import { connect } from 'react-redux';

import { setAppLocations, setAppFields, setAppDisableNav } from '../../actions/app-actions';
import EditPeopleGroups from './edit-people-groups';

export default connect(
    ({ appState }) => ({
        windowHeight: appState.windowHeight,
        user: appState.user,
        fields: appState.fields,
        locations: appState.locations,
        personGroupStatuses: appState.personGroupStatuses,
        people: appState.people,
    }),
    dispatch => ({
        setAppLocations: locations => dispatch(setAppLocations(locations)),
        setAppFields: fields => dispatch(setAppFields(fields)),
        disableNav: () => dispatch(setAppDisableNav(true)),
        enableNav: () => dispatch(setAppDisableNav(false)),
    })
)(EditPeopleGroups);
