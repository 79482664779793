export default {

    peopleGroupDetail() {
        return [
            '_id',
            'name',
            'alternateName',
            'publicationName',
            'ethnologueCode',
            'languageFamily',
            'population',
            'translationOrganization',
            'otherMaterialsNote',
            'dateTranslationStarted',
            'targetNTCompleteDate',
            'dateNTCompleted',
            'translationInProgress',
            'literacyProgramDeveloped',
            'literacyRateVernacular',
            'literacyRateLWC',
            'note',
            'personnelNeeds { number, comment }',
            'phase1CurriculumStatus',
            'phase2CurriculumStatus',
            'phase3CurriculumStatus',
            'phase4CurriculumStatus',
            'joshuaProjectLink',
            'peopleGroupsOrgLink',
            'hidden',
            'dateStartedPartnering',
            'workStatus',
            'translationTranslated',
            'translationPercentages { book, percentage }',
            'level',
            'oldTestamentPercentage',
            'newTestamentPercentage'
        ];
    },

    locationDetail() {
        return [
            '_id',
            'name',
            'publicationName',
            'peopleGroup {_id}',
            'country {_id name}',
            'field',
            'area',
            'population',
            'numberOfBelievers',
            'literacyOngoingVernacularProgram',
            'literacyOngoingLWCProgram',
            'literacyRateVernacular',
            'literacyRateLWC',
            'literacyGovernmentProgram',
            'literacyNote',
            'initialEvangelism',
            'workersNote',
            'note',
            'workStatus',
            'churchStage',
            'numberOfElders',
            'dateEntered',
            'dateGospelPresented',
            'targetDateEntry',
            'targetDateGospelPresented',
            'dateTeachingBegin',
            'maturingChurchStatuses { mChurchDate mChurchGodsWord0 mChurchGodsWord1 mChurchGodsWord2 mChurchGodsWord3 mChurchGodsWord4 mChurchIdentity0 mChurchIdentity1 mChurchIdentity2 mChurchIdentity3 mChurchIdentity4 mChurchLife0 mChurchLife1 mChurchLife2 mChurchLife3 mChurchLife4 mChurchDiscipleship0 mChurchDiscipleship1 mChurchDiscipleship2 mChurchDiscipleship3 mChurchDiscipleship4 }',
            'maturingChurchNote',
            'primaryCare',
            'level'
        ];
    },

    locationLimited() {
        return [
            '_id',
            'name',
            'peopleGroup { _id, name, alternateName, publicationName, ethnologueCode }',
            'country { _id, name }',
            'field',
            'level'
        ];
    },

    people() {
        return [
            '_id',
            'firstName',
            'lastName',
            'emails',
            'memberStatus',
            'organization',
            'activePeopleGroup',
        ];
    },

    training() {
        return [
            '_id',
            'trainingName',
            'date',
            'location',
            'note',
            'instructors { _id firstName lastName }',
            'attendees { _id }',
            'creator',
        ];
    },

    region() {
        return [
            '_id',
            'name',
            'fields { _id, name, level, countries { _id, name, hidden } }'
        ];
    },

    file() {
        return [
            '_id',
            'name',
            'filename',
            'size',
            'type',
            'folder',
            'uploadedDate',
            'uploadedBy',
            'aboutPerson',
            'aboutField',
            'aboutPeopleGroup'
        ];
    }

};
