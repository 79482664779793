import actions from '../enums/actions';

export const setAppLocale = locale => {
    return {
        type: actions.APP_SET_LOCALE,
        payload: {
            locale
        }
    };
};

export const setAppLocaleData = data => {
    return {
        type: actions.APP_SET_LOCALE_DATA,
        payload: {
            data
        }
    };
};

export const setAppWindowSize = ({ width, height }) => {
    return {
        type: actions.APP_SET_WINDOW_SIZE,
        payload: {
            width,
            height
        }
    };
};

export const setAppHeaderHeight = height => {
    return {
        type: actions.APP_SET_HEADER_HEIGHT,
        payload: {
            height,
        },
    };
};

export const setAppUserData = user => {
    return {
        type: actions.APP_SET_USER_DATA,
        payload: {
            user
        }
    };
};

export const setCanCreateUpdateTrainings = value => {
    return {
        type: actions.APP_SET_CAN_CREATE_UPDATE_TRAININGS,
        payload: {
            value,
        },
    };
};

export const setAppDisableNav = (disableNav, message = '') => {
    return {
        type: actions.APP_SET_DISABLE_NAV,
        payload: {
            disableNav,
            message
        }
    };
};

export const setAppCountries = countries => {
    return {
        type: actions.APP_SET_COUNTRIES,
        payload: {
            countries
        }
    };
};
export const setAppFields = fields => {
    return {
        type: actions.APP_SET_FIELDS,
        payload: {
            fields
        }
    };
};
export const setAppRegions = regions => {
    return {
        type: actions.APP_SET_REGIONS,
        payload: {
            regions
        }
    };
};

export const setAppLocations = locations => {
    return {
        type: actions.APP_SET_LOCATIONS,
        payload: {
            locations
        }
    };
};

export const setAppPeople = people => {
    return {
        type: actions.APP_SET_PEOPLE,
        payload: {
            people
        }
    };
};

export const setAppPersonGroupStatuses = personGroupStatuses => {
    return {
        type: actions.APP_SET_PERSON_GROUP_STATUSES,
        payload: {
            personGroupStatuses
        }
    };
};

export const setAppLocalizedLists = localizedLists => {
    return {
        type: actions.APP_SET_LOCALIZED_LISTS,
        payload: {
            localizedLists
        }
    };
};

export const setAppTrainings = trainings => {
    return {
        type: actions.APP_SET_TRAININGS,
        payload: {
            trainings
        }
    };
};

export const setAppPersonToFieldPeopleGroup = personToFieldPeopleGroup => {
    return {
        type: actions.APP_SET_PERSON_TO_FIELD_PEOPLE_GROUP,
        payload: {
            personToFieldPeopleGroup
        }
    };
};

export const setAppModalStatus = modalStatus => {
    return {
        type: actions.APP_SET_MODAL_STATUS,
        payload: {
            modalStatus
        }
    };
};

export const setAppInitialServerLoadDone = () => {
    return {
        type: actions.APP_SET_INITIAL_SERVER_LOAD_DONE,
        payload: {},
    };
};
