import React from 'react';
import PropTypes from 'prop-types';

const lineColor = '#555';
// const labelColor = '#000';

const makeStyles = (width, divisions) => {

    const styles = {
        chartContainer: {
            // fontFamily: 'sans-serif',
            margin: 24,
            width: width,
            height: width,
            borderRadius: '50%',
            position: 'relative'
        },
        chartCrosshairVertical: {
            position: 'absolute',
            width: 1,
            backgroundColor: lineColor,
            left: '50%',
            height: '100%'
        },
        chartCrosshairHorizontal: {
            position: 'absolute',
            height: 1,
            backgroundColor: lineColor,
            top: '50%',
            width: '100%'
        },
        chartDivisionLabel: {
            fontSize: 14,
            lineHeight: '14px',
            marginLeft: '50%',
            paddingLeft: 2,
            marginTop: -16
        },
        chartTopLeft: {
            position: 'absolute',
            right: '50%',
            bottom: '50%',
            borderTopLeftRadius: '100%'
        },
        chartTopRight: {
            position: 'absolute',
            left: '50%',
            bottom: '50%',
            borderTopRightRadius: '100%'
        },
        chartBottomLeft: {
            position: 'absolute',
            right: '50%',
            top: '50%',
            borderBottomLeftRadius: '100%'
        },
        chartBottomRight: {
            position: 'absolute',
            left: '50%',
            top: '50%',
            borderBottomRightRadius: '100%'
        },
        topLeftLabel: {
            position: 'absolute',
            left: -12,
            top: -12,
            width: width / 4,
            fontSize: 16,
            lineHeight: '24px'
        },
        topRightLabel: {
            position: 'absolute',
            top: -12,
            right: -12,
            width: width / 4,
            fontSize: 16,
            lineHeight: '24px',
            textAlign: 'right'
        },
        bottomLeftLabel: {
            position: 'absolute',
            bottom: -12,
            left: -12,
            width: width / 4,
            fontSize: 16,
            lineHeight: '24px'
        },
        bottomRightLabel: {
            position: 'absolute',
            bottom: -12,
            right: -12,
            width: width / 4,
            fontSize: 16,
            lineHeight: '24px',
            textAlign: 'right'
        }
    };
    return styles;
};

const RadarGraph = ({ width, divisions, data }) => { // data is an array of {label: String, color: string, value: number}

    const [ data1, data2, data3, data4 ] = data;

    console.log(data1, data2, data3, data4);

    const styles = makeStyles(width, divisions);

    const divisionRings = [];
    for(let n = 1; n < divisions + 1; n++) {

        const negativeMargin = -n * (width / divisions) / 2;

        const style = {
            position: 'absolute',
            left: '50%',
            top: '50%',
            marginLeft: negativeMargin,
            marginTop: negativeMargin,
            borderColor: lineColor,
            borderWidth: 1,
            borderStyle: 'solid',
            width: n * (width / divisions),
            height: n * (width / divisions),
            borderRadius: '50%'
        };

        divisionRings.push(
            <div key={`ring${n}`} style={style}>
                <div style={styles.chartDivisionLabel}>{n}</div>
            </div>
        );

    }

    const graph1Size = (data1.value * width / divisions) / 2;
    const graph2Size = (data2.value * width / divisions) / 2;
    const graph3Size = (data3.value * width / divisions) / 2;
    const graph4Size = (data4.value * width / divisions) / 2;

    return (
        <div style={styles.chartContainer}>

            <div style={{...styles.chartTopLeft, width: graph1Size, height: graph1Size, backgroundColor: data1.color}}>

            </div>
            <div style={{...styles.chartTopRight, width: graph2Size, height: graph2Size, backgroundColor: data2.color}}></div>
            <div style={{...styles.chartBottomLeft, width: graph3Size, height: graph3Size, backgroundColor: data3.color}}></div>
            <div style={{...styles.chartBottomRight, width: graph4Size, height: graph4Size, backgroundColor: data4.color}}></div>

            <div style={styles.chartCrosshairVertical}></div>
            <div style={styles.chartCrosshairHorizontal}></div>
            {divisionRings}
            <div style={styles.topLeftLabel}>{data1.label}</div>
            <div style={styles.topRightLabel}>{data2.label}</div>
            <div style={styles.bottomLeftLabel}>{data3.label}</div>
            <div style={styles.bottomRightLabel}>{data4.label}</div>
        </div>
    );
};
RadarGraph.propTypes = {
    width: PropTypes.number,
    divisions: PropTypes.number,
    data: PropTypes.arrayOf(PropTypes.object)
};

export default RadarGraph;
