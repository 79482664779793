import React from 'react';
import PropTypes from 'prop-types';
import gqlCallAttributes from '../../util/gql-call-attributes';
import * as _ from 'lodash';
import { Link } from 'react-router-dom';

class PeopleGroupSearch extends React.Component {

    async componentWillMount() {
        try {

            const { errors, data } = await gql.transaction(
                'query',
                'getLocations',
                {},
                gqlCallAttributes.locationLimited()
            );
            if(errors) throw new Error(errors[0].message);
            this.props.setLocations(data.getLocations);
        } catch(err) {
            handleError(err);
        }
    }

    render() {
        const { history, location, locations } = this.props;

        const peopleGroups = locations
            .reduce((map, l) => {
                const { field } = l;
                const { _id = '', name = '', alternateName = '', publicationName = '', ethnologueCode = '' } = l.peopleGroup;
                if(field && _id && name) {
                    if(!map.has(_id)) {
                        return map.set(_id, {
                            _id,
                            name,
                            alternateName,
                            publicationName,
                            ethnologueCode,
                            field
                        });
                    }
                }
                return map;
            }, new Map());

        const generateQueryParams = obj => {
            const newQuery = {
                ...location.query,
                ...obj
            };
            return Object
                .keys(newQuery)
                .map(key => `${key}=${encodeURI(newQuery[key])}`)
                .join('&');
        };

        const { search = '' } = location.query;

        const trimmedSearch = search.trim();
        const terms = trimmedSearch ? trimmedSearch.split(/\s+/) : [];
        const patterns = terms
            .map(s => new RegExp(`(^|\\W)${_.escapeRegExp(s)}`, 'i'));

        const onInputChange = e => {
            e.preventDefault();
            history.push(`/people-group-search?${generateQueryParams({search: e.target.value})}`);
        };

        return (
            <div className={'container-fluid'}>

                <div className={'row'}>
                    <div className={'col-12'}>
                        <h1>{Localize.text('PeopleGroupSearch', 'PeopleGroupSearch')}</h1>
                    </div>
                </div>

                <div className={'row'} style={{marginTop: 15}}>
                    <div className={'col-5'}>
                        <div className={'form-group'}>
                            <input ref={node => node ? node.focus() : null} type={'text'} className={'form-control form-control-lg'} placeholder={Localize.text('EnterSearchTerms', 'PeopleGroupSearch')} value={search} onChange={onInputChange} />
                        </div>
                    </div>
                </div>

                <div className={'row'} style={{marginBottom: 15}}>
                    <div className={'col-5'}>
                        <div className={'list-group'}>
                            {[...peopleGroups.values()]
                                .filter(({ name, alternateName, publicationName, ethnologueCode }) => {
                                    if(patterns.length > 0) {
                                        const names = [name, alternateName, publicationName, ethnologueCode].filter(s => s);
                                        return names.some(n => patterns.every(p => p.test(n)));
                                    } else {
                                        return false;
                                    }
                                })
                                .map(p => {
                                    return (
                                        <Link to={`/field/${p.field}/people/${p._id}`} key={p._id} className={'list-group-item'}>{p.name}</Link>
                                    );
                                })
                            }
                        </div>
                    </div>
                </div>

            </div>
        );
    }

}
PeopleGroupSearch.propTypes = {
    location: PropTypes.object,
    history: PropTypes.object,
    locations: PropTypes.arrayOf(PropTypes.object),
    setLocations: PropTypes.func
};

export default PeopleGroupSearch;
