import React  from 'react';
import PropTypes from 'prop-types';

import MyBootstrapModal from './my-bootstrap-modal';

const PencilEditModal = ({ children, modalTitle, hidden, setModalIsShowing = () => {}, done }) => {

    return (
        <MyBootstrapModal
            modalTitle={modalTitle}
            buttonContents={<i className={'fa fa-pencil'} />}
            buttonClassName="pull-right"
            hidden={hidden}
            setModalIsShowing={setModalIsShowing}
            done={done}
            >
            { children }
        </MyBootstrapModal>

    );
};
PencilEditModal.propTypes = {
    children: PropTypes.element,
    modalTitle: PropTypes.string,
    hidden: PropTypes.bool,
    setModalIsShowing: PropTypes.func,
    done: PropTypes.func,
};

export default PencilEditModal;
