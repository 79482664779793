import React from 'react';
import PropTypes from 'prop-types';
import bindAll from 'lodash/bindAll';
import omit from 'lodash/omit';
import swal from 'sweetalert';
import $ from 'jquery';
import filesize from 'file-size';
import uuid from 'uuid';
import { saveAs } from 'file-saver';
import FileType from '../../types/file';
import { fileTypes, loggedOutInternalErrorMessage } from '../../constants';
import { partialResponseDelimiter } from '../../constants/share-with-server';

// we show the warning once per session
let showLargeUploadWarning = true;

const uploadFiles = async function(newFiles, files, invalidFilenames) {
    try {
        // t375 Don't allow uploading duplicate filenames
        const badFiles = newFiles.filter(f => invalidFilenames.includes(f.name) || invalidFilenames.includes(f.filename));

        if (badFiles.length) {
            const badList = badFiles.map(f => f.filename).join('\n');

            await swal({
                icon: 'error',
                title: Localize.text('InvalidFiles', 'FileBrowser'),
                text: `${Localize.text('TheFilesBelowCannotBeUploadedBecauseThereIsAlreadyAnUploadedFileWithTheSameName', 'FileBrowser')}\n\n${badList}`,
                button: Localize.text('OK', 'Universal'),
            });

            return false;
        }

        // t373 show warning message if user is uploading more than 30 MB.
        const totalSizeToUpload = newFiles.reduce((total, f) => {
            return total + f.size;
        }, 0);

        const okToProceed = async () => {
            if (totalSizeToUpload / (1024 * 1024) > 30 && showLargeUploadWarning) {
                const proceed = await swal({
                    title: Localize.text('LargeAmountOfData', 'FileBrowser'),
                    text: Localize.text('TheTotalAmountOfDataYouHaveSelectedToUploadIsDataSizeCRLFIfYourInternetConnectionIsVerySlowItMayTakeQuiteAwhileDoYouWantToProceedWithThisUpload', 'FileBrowser', {
                        dataSize: filesize(totalSizeToUpload).human('jdec'),
                        CRLF: '\n',
                    }),
                    buttons: [
                        Localize.text('Cancel', 'Universal'),
                        Localize.text('Yes', 'Universal'),
                    ]
                });

                showLargeUploadWarning = false;

                return proceed;
            } else {
                return true;
            }
        };

        const proceed = await okToProceed();

        if (!proceed) return false;

        swal({
            title: Localize.text('Uploading', 'FileBrowser'),
            content: {
                element: 'div',
                attributes: {
                    id: 'js-uploadProgressBox',
                },
            },
            buttons: false,
            closeOnClickOutside: false,
            closeOnEsc: false,
        }).then();
        await new Promise(resolve1 => setTimeout(resolve1, 502)); // change all 500ms timeouts to track down t223
        const xhr = new XMLHttpRequest();
        const formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            formData.append(newFiles[i]._id, files[i]);
        }

        let responseTextLastPosition = 0;

        return await new Promise((resolve, reject) => {
            xhr.upload.addEventListener('progress', e => {
                if (e.lengthComputable) {
                    const percentage = Math.round((e.loaded * 100) / e.total);
                    $('#js-uploadProgressBox').text(`${Localize.text('NumberUploaded', 'FileBrowser', { number: percentage })}`);
                }
            }, false);
            xhr.upload.addEventListener('error', err => {
                reject(err);
            }, false);
            xhr.upload.addEventListener('load', () => {
                // t373 show processing progress so user knows something is happening
                swal({
                    title: Localize.text('UploadingAndProcessing', 'FileBrowser'),
                    content: {
                        element: 'div',
                        attributes: {
                            id: 'js-processingProgressBox',
                        },
                    },
                    buttons: false,
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                }).then();
            }, false);
            xhr.onreadystatechange = () => {
                if (xhr.status === 401) {
                    handleError(new Error(loggedOutInternalErrorMessage));
                    return;
                }

                // check for partial responses (readyState 3).  See https://stackoverflow.com/a/7953033/385655
                if (xhr.readyState === 3) {
                    const data = xhr.responseText.substring(responseTextLastPosition);
                    responseTextLastPosition = xhr.responseText.length;

                    // Update the swal with the current percentage.  Note that sometimes we receive multiple percentages at once.
                    //  So, we use a delimiter and just grab the last one, since that is most recent.
                    const percentDone = data.split(partialResponseDelimiter).slice(-1)[0];
                    $('#js-processingProgressBox').text(`${Localize.text('NumberComplete', 'FileBrowser', { number: percentDone })}`);
                } else if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        console.log('Done!');

                        // t372 possibly some users have readyState change to 4 before the "Processing..." swal is shown.  So give swal time to show before closing it.
                        setTimeout(() => {
                            swal.close();
                            resolve(true);
                        }, 750);
                    } else {
                        reject(new Error(Localize.text('ErrorUploadingFile', 'FileBrowser')));
                    }
                }
            };
            // t372 set up listeners before sending the data.  Otherwise, users were sometimes hanging with the "Processing..." swal.
            xhr.open('post', `${location.origin}/api/upload-file`);
            xhr.send(formData);
        });
    } catch (err) {
        swal.close();
        handleError(err);
    }
};

const sortByTypeName = items => {
    const intCol = new Intl.Collator(Localize.locale());
    return [...items]
        .sort((a, b) => {
            if(a.type === b.type) {
                return intCol.compare(a.name, b.name);
            } else {
                return a.type === fileTypes.DIR ? -1 : 1;
            }
        });
};

const folderIconStyles = {
    fontSize: 18,
    verticalAlign: 'middle',
    color: '#f5c538',
};
const openFolderIconStyles = {
    fontSize: 18,
    verticalAlign: 'middle',
    color: '#f5c538',
    marginRight: -2,
};

const ExpandBox = () => (<i style={folderIconStyles} className={'fa fa-folder'} />);
const UnexpandBox = () => (<i style={openFolderIconStyles} className={'fa fa-folder-open'} />);

const imageFilePatterns = ['jpg', 'jpeg', 'gif', 'bmp', 'png'].map(ext => new RegExp(`\\.${ext}$`, 'i'));
const audioFilePatterns = ['wav', 'mp3', 'pcm', ' aiff', 'aac', 'off', 'wma', 'flac', 'alac'].map(ext => new RegExp(`\\.${ext}$`, 'i'));
const videoFilePatterns = ['mp4', 'mov', 'avi', 'flv', 'wmv'].map(ext => new RegExp(`\\.${ext}$`, 'i'));
const textFilePatterns = ['txt', 'rtf'].map(ext => new RegExp(`\\.${ext}$`, 'i'));

const getFileIcon = filename => {

    const defaultStyles = {
        color: '#333',
    };

    if(/\.xl[st]x*$/i.test(filename)) {
        return <i style={{color: '#236f46'}} className={'fa fa-file-excel-o'} />;
    } else if(/\.docx*$/i.test(filename)) {
        return <i style={{color: '#2e5795'}} className={'fa fa-file-word-o'} />;
    } else if(/\.pptx*$/i.test(filename)) {
        return <i style={{color: '#cf4629'}} className={'fa fa-file-powerpoint-o'} />;
    } else if(/\.pdf$/i.test(filename)) {
        return <i style={{color: '#e2574c'}} className={'fa fa-file-pdf-o'} />;
    } else if(/\.zip$/i.test(filename)) {
        return <i style={defaultStyles} className={'fa fa-file-archive-o'} />;
    } else if(textFilePatterns.some(p => p.test(filename))) {
        return <i style={defaultStyles} className={'fa fa-file-text-o'} />;
    } else if(audioFilePatterns.some(p => p.test(filename))) {
        return <i style={defaultStyles} className={'fa fa-file-audio-o'} />;
    } else if(videoFilePatterns.some(p => p.test(filename))) {
        return <i style={defaultStyles} className={'fa fa-file-video-o'} />;
    } else if(imageFilePatterns.some(p => p.test(filename))) {
        return <i style={defaultStyles} className={'fa fa-file-image-o'} />;
    } else {
        return <i style={defaultStyles} className={'fa fa-file-o'} />;
    }
};

class FileItem extends React.Component {

    constructor(props) {
        super(props);
        bindAll(this, [
            'onClick',
            'onEditClick',
            'onDeleteClick',
            'onFileInputChange',
            'onDragStart',
        ]);
    }

    async onClick(e) {
        e.preventDefault();
        const { item } = this.props;
        const res = await swal({
            title: item.name,
            text: item.filename,
            buttons: {
                cancel: true,
                confirm: {
                    className: 'swal-delete-btn',
                    text: Localize.text('Delete', 'FileBrowser'),
                    value: 'delete',
                    closeModal: false,
                },
                replace: {
                    className: 'swal-replace-btn',
                    text: Localize.text('ReplaceFile', 'FileBrowser'),
                    value: 'replace',
                },
                download: {
                    className: 'swal-download-btn',
                    text: Localize.text('DownloadFile', 'FileBrowser'),
                    value: 'download',
                },
            },
        });
        if(!res) return;
        if(res === 'delete') {
            const confirmed = await swal({
                icon: 'warning',
                text: Localize.text('AreYouSureThatYouWantToDeleteItemnameItemfilename', 'FileBrowser', { itemname: item.name, itemfilename: item.filename}),
                buttons: {
                    cancel: true,
                    confirm: true,
                },
            });
            if(confirmed) this.props.onDelete(item._id);
        } else if(res === 'replace') {
            this.fileInputNode.click();
        } else { // res === 'download'
            const fileLocation = `${location.origin}/api/files/${item._id}/${item.filename}`;
            // t359 instead of opening the file in a window in the browser and the user having to know how to save it
            //  from there, now we actually download the file to the default downloads folder.
            saveAs(fileLocation, item.filename);
        }
    }

    async onEditClick(e) {
        e.preventDefault();
        const { item } = this.props;
        const confirmed = await swal({
            text: Localize.text('PleaseEnterTheNewDisplayNameForItemfilename', 'FileBrowser', { itemfilename: item.filename }),
            content: {
                element: 'input',
                attributes: {
                    type: 'text',
                    id: 'js-newFolderNameInput',
                    value: item.name,
                },
            },
            buttons: [
                Localize.text('Cancel', 'Universal'),
                {
                    text: Localize.text('Save', 'Universal'),
                    closeModal: false,
                },
            ],
        });
        if(!confirmed) return swal.close();
        const newFileName = $('#js-newFolderNameInput').val().trim();
        if(!newFileName) return swal.close();
        const changeData = {
            name: newFileName,
        };
        await item.updateRemote(changeData);
        this.props.onChange(item.set(changeData));
        swal.close();
    }

    async onDeleteClick(e) {
        try {
            e.preventDefault();
            const { item } = this.props;
            const confirmed = await swal({
                icon: 'warning',
                text: Localize.text('AreYouSureThatYouWantToDeleteItemnameItemfilename', 'FileBrowser', { itemname: item.name, itemfilename: item.filename}),
                buttons: {
                    cancel: true,
                    confirm: true,
                },
            });
            if(confirmed) {
                swal({
                    title: Localize.text('Processing', 'FileBrowser'),
                    text: Localize.text('ThisCouldTakeALittleWhileDependingOnHowBigTheFileIsThatYouDeleted', 'FileBrowser'),
                    buttons: false,
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                }).then();

                await new Promise(resolve1 => setTimeout(resolve1, 300));   // make sure the swal gets shown
                await item.deleteRemote();
                this.props.onDelete(item._id);
            }
        } catch(err) {
            handleError(err);
        }
    }

    async onFileInputChange(e) {
        e.preventDefault();
        const { item, getOtherFilenames } = this.props;
        const [ file ] = e.target.files;
        const { name: filename, size } = file;
        const changeObj = {
            filename,
            size,
        };
        const newFile = item.set(changeObj);

        const invalidFilenames = getOtherFilenames(item.folder, item._id);
        const result = await uploadFiles([newFile], [file], invalidFilenames);

        // reset onFileInputChange so it gets triggered if user immediately tries to upload the same file(s) again
        this.fileInputNode.value = '';

        if (result) {
            await newFile.updateRemote(changeObj);
            this.props.onChange(newFile);
        }
    }

    onDragStart(e) {
        const { item } = this.props;
        e.dataTransfer.setData('Text/json', JSON.stringify(item));
        e.dataTransfer.effectAllowed = 'move';
    }

    render() {

        const styles = {
            deleteButton: {
                padding: '0px 6px 0px 6px',
                fontSize: 14,
                lineHeight: '14px',
                verticalAlign: 'middle',
                // backgroundColor: '#dc3545',
                marginRight: 4,
            },
            editButton: {
                padding: '0px 6px 0px 6px',
                fontSize: 14,
                lineHeight: '14px',
                verticalAlign: 'middle',
                // backgroundColor: '#6c757d',
                marginRight: 4,
            },
        };

        const { item } = this.props;
        const { filename, depth } = item;
        const marginLeft = depth * 20;
        const fileIcon = getFileIcon(filename);

        return (
            <div style={{marginLeft: marginLeft}}>
                <div className={'faint-hover-background'} onDragStart={this.onDragStart} draggable={true}><a onClick={this.onClick} href={'#'} style={{textDecoration: 'none'}}>{fileIcon} <strong>{item.name}</strong></a> ({`${item.filename}, ${filesize(item.size).human('jdec')}`}) <a onClick={this.onEditClick} style={styles.editButton} className={'btn btn-secondary btn-sm'} href={'#'} title={Localize.text('EditFileDisplayName', 'FileBrowser')}><i className={'fa fa-pencil'} /></a><a onClick={this.onDeleteClick} style={styles.deleteButton} className={'btn btn-danger btn-sm'} href={'#'} title={Localize.text('DeleteFile', 'FileBrowser')}><i className={'fa fa-times'} /></a></div>
                <form style={{display: 'none'}} ref={node => this.formNode = node}>
                    <input type={'file'} ref={node => this.fileInputNode = node} onChange={this.onFileInputChange} />
                </form>
            </div>
        );
    }

}
FileItem.propTypes = {
    item: PropTypes.instanceOf(FileType),
    onDelete: PropTypes.func,
    onChange: PropTypes.func,
    getOtherFilenames: PropTypes.func,
};

class DirItem extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            expanded: false,
            readyToDrop: false,
            dragCounter: 0,
        };
        bindAll(this, [
            'toggleExpand',
            'onAddClick',
            'onDeleteClick',
            'onEditClick',
            'onFileInputChange',
            'processNewFiles',
            'onDrop',
            'onDragEnter',
            'onDragOver',
            'onDragLeave',
            'onDragStart',
        ]);
    }

    toggleExpand(e) {
        e.preventDefault();
        this.setState({
            ...this.state,
            expanded: !this.state.expanded,
        });
    }

    async onAddClick(e) {
        e.preventDefault();
        const { item } = this.props;
        let res;
        if(item.depth > 0) {
            res = fileTypes.FILE;
        } else {
            res = await swal({
                text: Localize.text('WhatWouldYouLikeToAddToItemname', 'FileBrowser', { itemname: item.name}),
                buttons: {
                    cancel: true,
                    confirm: {
                        text: Localize.text('AddNewFolder', 'FileBrowser'),
                        value: fileTypes.DIR,
                        closeModal: false,
                    },
                    file: {
                        text: Localize.text('UploadNewFileS', 'FileBrowser'),
                        value: fileTypes.FILE,
                    },
                },
            });
        }
        if(res === fileTypes.DIR) {
            this.props.onAddDir(item);
            setTimeout(() => {
                this.setState({
                    ...this.state,
                    expanded: true,
                });
            }, 0);
        } else if(res === fileTypes.FILE) { // res === fileTypes.FILE
            console.log('browse for file');
            this.fileInputNode.click();
        }
    }

    async onFileInputChange(e) {
        e.preventDefault();
        const { files } = e.target;
        await this.processNewFiles(files);

        // reset onFileInputChange so it gets triggered if user immediately tries to upload the same file(s) again
        this.fileInputNode.value = '';
    }

    async processNewFiles(files) {
        const { item } = this.props;
        const newFiles = [];
        for(const file of files) {
            const { name: filename, size } = file;
            const newFile = new FileType({
                _id: uuid.v4(),
                name: filename,
                folder: item._id,
                type: fileTypes.FILE,
                aboutPerson: '',
                aboutField: item.aboutField || '',
                aboutPeopleGroup: item.aboutPeopleGroup || '',
                filename,
                size,
            });
            if(item.aboutField) newFile.aboutField = item.aboutField;
            if(item.aboutPeopleGroup) newFile.aboutPeopleGroup = item.aboutPeopleGroup;
            if(item.aboutPerson) newFile.aboutPerson = item.aboutPerson;
            newFiles.push(newFile);
        }

        const invalidFilenames = item.children.reduce((acc, f) => [...acc, f.name, f.filename], []);

        const result = await uploadFiles(newFiles, files, invalidFilenames);

        if (result) {
            for(const file of newFiles) {
                this.props.onAdd(file);
                await new Promise(resolve => setTimeout(resolve, 0));
            }
            setTimeout(() => {
                this.setState({
                    ...this.state,
                    expanded: true,
                });
            }, 0);
        }
    }

    async onDeleteClick(e) {
        e.preventDefault();
        const { item } = this.props;
        const confirmed = await swal({
            icon: 'warning',
            text: Localize.text('AreYouSureThatYouWantToDeleteItemnameAndEverythingInsideOfIt', 'FileBrowser', { itemname: item.name }),
            buttons: {
                cancel: true,
                confirm: true,
            },
        });
        if(confirmed) {
            swal({
                title: Localize.text('Processing', 'FileBrowser'),
                text: Localize.text('ThisCouldTakeALittleWhileDependingOnHowMuchYouDeleted', 'FileBrowser'),
                buttons: false,
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then();

            await new Promise(resolve1 => setTimeout(resolve1, 300));   // make sure the swal gets shown
            await item.deleteRemote();
            this.props.onDelete(item._id);
        }
    }

    async onEditClick(e) {
        e.preventDefault();
        const { item } = this.props;
        const confirmed = await swal({
            text: Localize.text('PleaseEnterTheNewNameOfItemname', 'FileBrowser', { itemname: item.name }),
            content: {
                element: 'input',
                attributes: {
                    type: 'text',
                    id: 'js-newFolderNameInput',
                    value: item.name,
                },
            },
            buttons: [
                Localize.text('Cancel', 'Universal'),
                {
                    text: Localize.text('Save', 'Universal'),
                    closeModal: false,
                },
            ],
        });
        if(!confirmed) return;
        const newFolderName = $('#js-newFolderNameInput').val().trim();
        if(!newFolderName) return swal.close();
        const changeData = {
            name: newFolderName,
        };
        await item.updateRemote(changeData);
        this.props.onChange(item.set(changeData));
        swal.close();
    }

    async onDrop(e) {
        e.preventDefault();
        const { item } = this.props;
        if(!e.dataTransfer.types.includes('text/json') && !e.dataTransfer.types.includes('Files')) return;
        const { files = [] } = e.dataTransfer;
        const dataStr = e.dataTransfer.getData('text/json');
        if(files.length > 0 && !dataStr) {
            this.processNewFiles(files).then();
        } else {
            try {
                if(dataStr) {
                    const data = JSON.parse(dataStr);
                    if(item._id === data.folder) return;
                    const newFile = new FileType({
                        ...data,
                        folder: item._id,
                        depth: item.depth + 1,
                    });
                    await newFile.updateRemote({
                        folder: newFile.folder,
                    });
                    this.props.onMove(newFile);
                }
            } catch(err) {
                handleError(err);
            }
        }
        this.setState({
            ...this.state,
            readyToDrop: false,
            dragCounter: 0,
        });
    }

    onDragEnter(e) {
        // if(!e.dataTransfer.types.includes('text/json') && !e.dataTransfer.types.includes('Files')) return;
        e.preventDefault();
        this.setState({
            ...this.state,
            dragCounter: this.state.dragCounter + 1,
            readyToDrop: true,
        });
    }

    onDragOver(e) {
        // if(!e.dataTransfer.types.includes('text/json') && !e.dataTransfer.types.includes('Files')) return;
        e.preventDefault();
    }

    onDragLeave() {
        let { dragCounter } = this.state;
        dragCounter = dragCounter - 1;
        if(dragCounter === 0) {
            this.setState({
                ...this.state,
                dragCounter,
                readyToDrop: false,
            });
        } else {
            this.setState({
                ...this.state,
                dragCounter,
            });
        }
    }

    onDragStart(e) {
        const { item } = this.props;
        e.dataTransfer.setData('Text/json', JSON.stringify(item));
        e.dataTransfer.effectAllowed = 'move';
    }

    render() {

        const { item, onAdd, onChange, onDelete, onMove } = this.props;
        const { expanded, readyToDrop } = this.state;

        const { depth } = item;

        const expandIcon = !expanded ? <ExpandBox /> : <UnexpandBox />;

        const marginLeft = depth * 20;

        const styles = {
            addButton: {
                padding: '0px 6px 0px 6px',
                fontSize: 14,
                lineHeight: '14px',
                verticalAlign: 'middle',
                marginRight: 4,
            },
            deleteButton: {
                padding: '0px 6px 0px 6px',
                fontSize: 14,
                lineHeight: '14px',
                verticalAlign: 'middle',
                marginRight: 4,
            },
            editButton: {
                padding: '0px 6px 0px 6px',
                fontSize: 14,
                lineHeight: '14px',
                verticalAlign: 'middle',
                marginRight: 4,
            },
            folderItem: {
            },
            folderLink: {
                outlineWidth: 1,
                outlineStyle: 'dashed',
                textDecoration: 'none',
            },
        };

        if(readyToDrop) styles.folderLink.outlineColor = '#aaa';

        // find all filenames for the specified folder, other than for the specified file.
        const getOtherFilenames = (folderId, fileId) => {
            if (folderId !== item._id) return [];

            return item.children.reduce((acc, f) => {
                if (f._id === fileId) return [...acc];

                return f.type === fileTypes.FILE ? [...acc, f.name, f.filename] : [...acc, f.name];
            }, []);
        };

        return (
            <div style={{marginLeft: marginLeft}}>
                <div style={styles.folderItem} draggable={true} onDragStart={this.onDragStart} className={'faint-hover-background'} onDragEnter={this.onDragEnter} onDragOver={this.onDragOver} onDragLeave={this.onDragLeave} onDrop={this.onDrop}><a onClick={this.toggleExpand} href={'#'} style={styles.folderLink}>{expandIcon} <strong>{item.name}</strong></a> ({Localize.text('NumberItems', 'FileBrowser', { number: item.children.length })}) <a onClick={this.onAddClick} style={styles.addButton} className={'btn btn-primary btn-sm'} href={'#'} title={Localize.text('AddFileOrFolder', 'FileBrowser')}><i className={'fa fa-plus'} /></a><a onClick={this.onEditClick} style={styles.editButton} className={'btn btn-secondary btn-sm'} href={'#'} title={Localize.text('EditFolderName', 'FileBrowser')}><i className={'fa fa-pencil'} /></a><a onClick={this.onDeleteClick} style={styles.deleteButton} className={'btn btn-danger btn-sm'} href={'#'} title={Localize.text('DeleteFolder', 'FileBrowser')}><i className={'fa fa-times'} /></a></div>
                {!expanded ? <div /> : sortByTypeName(item.children).map(i => {
                    if(i.type === fileTypes.FILE) {
                        return (
                            <FileItem key={i._id} item={i} onAdd={onAdd} onDelete={onDelete} onChange={onChange} getOtherFilenames={getOtherFilenames} />
                        );
                    } else {
                        return (
                            <DirItem key={i._id} item={i} onAdd={onAdd} onDelete={onDelete} onChange={onChange} onMove={onMove} />
                        );
                    }
                })}
                <form style={{display: 'none'}} ref={node => this.formNode = node}>
                    <input type={'file'} ref={node => this.fileInputNode = node} onChange={this.onFileInputChange} multiple={true} />
                </form>
            </div>
        );
    }

}
DirItem.propTypes = {
    item: PropTypes.instanceOf(FileType),
    onAdd: PropTypes.func,
    onAddDir: PropTypes.func,
    onDelete: PropTypes.func,
    onChange: PropTypes.func,
    onMove: PropTypes.func,
};

class FileBrowser extends React.Component {

    constructor(props) {
        super(props);
        bindAll(this, [
            'onAdd',
            'onDelete',
            'onChange',
            'onMove',
            'onAddDir',
            'onAddDirClick',
        ]);
    }

    onAddDirClick(e) {
        e.preventDefault();
        this.onAddDir().then();
    }

    async onAddDir(item = {}) {
        const { _id = '' } = item;
        let { aboutPeopleGroup = '', aboutField = '' } = item;
        if(!aboutPeopleGroup) aboutPeopleGroup = this.props.aboutPeopleGroup || '';
        if(!aboutField) aboutField = this.props.aboutField || '';
        const confirmed = await swal({
            text: Localize.text('PleaseEnterTheNameOfTheNewFolder', 'FileBrowser'),
            content: {
                element: 'input',
                attributes: {
                    type: 'text',
                    id: 'js-newFolderNameInput',
                },
            },
            buttons: [
                Localize.text('Cancel', 'Universal'),
                {
                    text: Localize.text('CreateFolder', 'FileBrowser'),
                    closeModal: false,
                },
            ],
        });
        if(!confirmed) return;
        const newFolderName = $('#js-newFolderNameInput').val().trim();
        if(!newFolderName) return;
        const newFile = new FileType({
            _id: uuid.v4(),
            name: newFolderName,
            folder: _id,
            type: fileTypes.DIR,
            aboutPeopleGroup,
            aboutField,
        });
        this.onAdd(newFile);
        setTimeout(() => {
            swal.close();
        });
        return true;
    }

    onAdd(newFile) {
        try {

            const input = omit(newFile, ['depth', 'children']);
            const { errors } = gql.transaction(
                'mutation',
                'createFile',
                {
                    input,
                },
                ['_id']
            );
            if(errors) throw errors[0];

            const { items } = this.props;
            const { folder } = newFile;
            if(folder) {
                for(let i = 0; i < items.length; i++) {
                    const item1 = items[i];
                    if(folder === item1._id) {
                        this.props.onChange([
                            ...items.slice(0, i),
                            item1.set({
                                children: [
                                    newFile.set({
                                        depth: 1,
                                    }),
                                    ...item1.children,
                                ],
                            }),
                            ...items.slice(i + 1),
                        ]);
                        break;
                    } else {
                        for(let j = 0; j < item1.children.length; j++) {
                            const item2 = item1.children[j];
                            if(folder === item2._id) {
                                this.props.onChange([
                                    ...items.slice(0, i),
                                    item1.set({
                                        children: [
                                            ...item1.children.slice(0, j),
                                            item2.set({
                                                children: [
                                                    newFile.set({
                                                        depth: 2,
                                                    }),
                                                    ...item2.children,
                                                ],
                                            }),
                                            ...item1.children.slice(j + 1),
                                        ],
                                    }),
                                    ...items.slice(i + 1),
                                ]);
                                break;
                            }
                        }
                    }
                }
            } else {
                this.props.onChange([
                    newFile.set({
                        depth: 0,
                    }),
                    ...items,
                ]);
            }
        } catch(err) {
            handleError(err);
        }
    }

    onDelete(_id) {
        try {
            const { items } = this.props;
            let found;
            let newItems;

            // check first level
            for(let i = 0; i < items.length; i++) {
                if(items[i]._id === _id) {
                    found = items[i];
                    newItems = [
                        ...items.slice(0, i),
                        ...items.slice(i + 1),
                    ];
                    break;
                }
            }

            // check second level
            if(!found) {
                for(let i = 0; i < items.length; i++) {
                    for(let ii = 0; ii < items[i].children.length; ii++) {
                        if(items[i].children[ii]._id === _id) {
                            found = items[i].children[ii];
                            newItems = [
                                ...items.slice(0, i),
                                items[i].set({
                                    children: [
                                        ...items[i].children.slice(0, ii),
                                        ...items[i].children.slice(ii + 1),
                                    ],
                                }),
                                ...items.slice(i + 1),
                            ];
                            break;
                        }
                    }
                    if(found) break;
                }
            }

            // check third level
            if(!found) {
                for(let i = 0; i < items.length; i++) {
                    for(let ii = 0; ii < items[i].children.length; ii++) {
                        for(let iii = 0; iii < items[i].children[ii].children.length; iii++) {
                            if(items[i].children[ii].children[iii]._id === _id) {
                                found = items[i].children[ii].children[iii];
                                newItems = [
                                    ...items.slice(0, i),
                                    items[i].set({
                                        children: [
                                            ...items[i].children.slice(0, ii),
                                            items[i].children[ii].set({
                                                children: [
                                                    ...items[i].children[ii].children.slice(0, iii),
                                                    ...items[i].children[ii].children.slice(iii + 1),
                                                ],
                                            }),
                                            ...items[i].children.slice(ii + 1),
                                        ],
                                    }),
                                    ...items.slice(i + 1),
                                ];
                                break;
                            }
                        }
                        if(found) break;
                    }
                    if(found) break;
                }
            }
            this.props.onChange(newItems);
            swal.close();   // close the "This could take a little while..." swal
        } catch(err) {
            handleError(err);
        }
    }

    onChange(newFile) {
        try {
            const { _id } = newFile;
            const { items } = this.props;
            let newItems;
            for(let i = 0; i < items.length; i++) {

                if(items[i]._id === _id) {
                    newItems = [
                        ...items.slice(0, i),
                        newFile,
                        ...items.slice(i + 1),
                    ];
                    break;
                }

                for(let ii = 0; ii < items[i].children.length; ii++) {

                    if(items[i].children[ii]._id === _id) {
                        newItems = [
                            ...items.slice(0, i),
                            items[i].set({
                                children: [
                                    ...items[i].children.slice(0, ii),
                                    newFile,
                                    ...items[i].children.slice(ii + 1),
                                ],
                            }),
                            ...items.slice(i + 1),
                        ];
                        break;
                    }

                    for(let iii = 0; iii < items[i].children[ii].children.length; iii++) {
                        if(items[i].children[ii].children[iii]._id === _id) {
                            newItems = [
                                ...items.slice(0, i),
                                items[i].set({
                                    children: [
                                        ...items[i].children.slice(0, ii),
                                        items[i].children[ii].set({
                                            children: [
                                                ...items[i].children[ii].children.slice(0, iii),
                                                newFile,
                                                ...items[i].children[ii].children.slice(iii + 1),
                                            ],
                                        }),
                                        ...items[i].children.slice(ii + 1),
                                    ],
                                }),
                                ...items.slice(i + 1),
                            ];
                            break;
                        }
                    }
                    if(newItems) break;
                }
                if(newItems) break;
            }
            this.props.onChange(newItems);
        } catch(err) {
            handleError(err);
        }
    }

    onMove(newFile) {
        try{
            const { _id, folder } = newFile;
            let items = [...this.props.items];
            let filteredItems;
            for(let i = 0; i < items.length; i++) {
                const item = items[i];
                if(item._id === _id) {
                    filteredItems = [
                        ...items.slice(0, i),
                        ...items.slice(i + 1),
                    ];
                    break;
                }
                for(let j = 0; j < item.children.length; j++) {
                    const child = item.children[j];
                    if(child._id === _id) {
                        filteredItems = [
                            ...items.slice(0, i),
                            item.set({
                                children: [
                                    ...item.children.slice(0, j),
                                    ...item.children.slice(j + 1),
                                ],
                            }),
                            ...items.slice(i + 1),
                        ];
                        break;
                    }
                    for(let k = 0; k < child.children.length; k++) {
                        const grandchild = child.children[k];
                        if(grandchild._id === _id) {
                            filteredItems = [
                                ...items.slice(0, i),
                                item.set({
                                    children: [
                                        ...item.children.slice(0, j),
                                        child.set({
                                            children: [
                                                ...child.children.slice(0, k),
                                                ...child.children.slice(k + 1),
                                            ],
                                        }),
                                        ...item.children.slice(j + 1),
                                    ],
                                }),
                                ...items.slice(i + 1),
                            ];
                            break;
                        }
                    }
                    if(filteredItems) break;
                }
                if(filteredItems) break;
            }
            items = filteredItems;
            let newItems;
            for(let i = 0; i < items.length; i++) {
                const item = items[i];
                if(item._id === folder) {
                    newItems = [
                        ...items.slice(0, i),
                        item.set({
                            children: [newFile, ...item.children],
                        }),
                        ...items.slice(i + 1),
                    ];
                    break;
                }
                for(let j = 0; j < item.children.length; j++) {
                    const child = item.children[j];
                    if(child._id === folder) {
                        newItems = [
                            ...items.slice(0, i),
                            item.set({
                                children: [
                                    ...item.children.slice(0, j),
                                    child.set({
                                        children: [newFile, ...child.children],
                                    }),
                                    ...item.children.slice(j + 1),
                                ],
                            }),
                            ...items.slice(i + 1),
                        ];
                        break;
                    }
                    if(newItems) break;
                }
                if(newItems) break;
            }
            this.props.onChange(newItems);
        } catch(err) {
            handleError(err);
        }
    }

    render() {

        const { items, disabled } = this.props;

        const styles = {
            container: {
                fontFamily: 'monospace',
                fontSize: 18,
                lineHeight: '24px',
                paddingLeft: 15,
                paddingRight: 15,
                paddingBottom: 15,
            },
            addButton: {
                marginBottom: 10,
            },
        };

        const sortedItems = sortByTypeName(items);

        // find all filenames for the specified folder, other than for the specified file.
        const getOtherFilenames = (folderId, fileId) => {
            const folder = sortedItems.find(f => f._id === folderId);

            if (!folder) return [];

            return folder.children.reduce((acc, f) => {
                if (f._id === fileId) return [...acc];

                return f.type === fileTypes.FILE ? [...acc, f.name, f.filename] : [...acc, f.name];
            }, []);
        };

        const files = [];

        for(let i = 0; i < sortedItems.length; i++) {
            const item = sortedItems[i];
            if(item.type === fileTypes.FILE) {
                files.push(
                    <FileItem item={item} key={item._id} onAdd={this.onAdd} onDelete={this.onDelete} onChange={this.onChange} getOtherFilenames={getOtherFilenames} />
                );
            } else { // type is fileTypes.DIR
                files.push(
                    <DirItem item={item} key={item._id} onAdd={this.onAdd} onAddDir={this.onAddDir} onDelete={this.onDelete} onChange={this.onChange} onMove={this.onMove} />
                );
            }
        }

        return (
            <div style={styles.container}>
                <div>
                    <button disabled={disabled} style={styles.addButton} type={'button'} className={'btn btn-primary btn-sm'} onClick={this.onAddDirClick}><i className="fa fa-plus" /> {Localize.text('AddTopLevelFolder', 'FileBrowser')}</button>
                </div>
                {files}
            </div>
        );
    }

}
FileBrowser.propTypes = {
    items: PropTypes.arrayOf(PropTypes.instanceOf(FileType)),
    disabled: PropTypes.bool,
    aboutField: PropTypes.string,
    aboutPeopleGroup: PropTypes.string,
    onChange: PropTypes.func,
};

export default FileBrowser;
