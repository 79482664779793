import actions from '../enums/actions';

export const setPeopleGroup = peopleGroup => ({
    type: actions.PEOPLE_GROUP_SET_PEOPLE_GROUP,
    payload: {
        peopleGroup
    }
});

export const setLocations = locations => ({
    type: actions.PEOPLE_GROUP_SET_LOCATIONS,
    payload: {
        locations
    }
});

export const setStatuses = (peopleGroupId, statuses) => ({
    type: actions.PEOPLE_GROUP_SET_STATUSES,
    payload: {
        peopleGroupId,
        statuses
    }
});

export const setConsultants = (peopleGroupId, consultants) => ({
    type: actions.PEOPLE_GROUP_SET_CONSULTANTS,
    payload: {
        peopleGroupId,
        consultants
    }
});
