import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const styles = {
    table: {
        cursor: 'pointer',
        overflowX: 'hidden'
    },
    adjustHeader: {
        borderBottom: 0,
    },
    btnDiv: {
        paddingTop: 0,
    },
    textbox: {
        background: 'white',
    },
};

const MyModal = ({ title, hideAllButton, showAllButton, itemStates, setItemStates, done, windowHeight }) => {
    const toggleShowItem = idx => {
        setItemStates([
            ...itemStates.slice(0, idx),
            { ...itemStates[idx], show: !itemStates[idx].show },
            ...itemStates.slice(idx + 1)
        ]);
    };

    const hideAll = e => {
        e.preventDefault();
        setItemStates(itemStates.map(item => ({ ...item, show: false })));
    };

    const showAll = e => {
        e.preventDefault();
        setItemStates(itemStates.map(item => ({ ...item, show: true })));
    };

    return (
        <div className="modal" id="myModal">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header" style={(hideAllButton || showAllButton) ? styles.adjustHeader : {}}>
                        <h5 className="modal-title">{title}</h5>
                        <button type="button" className="close" onClick={done} data-dismiss="modal">
                            <span>&times;</span>
                        </button>
                    </div>
                    {
                        (hideAllButton || showAllButton) ?
                            <div className="modal-header" style={styles.btnDiv}>
                                <button hidden={!showAllButton} type="button" className="btn btn-sm btn-info" onClick={showAll}>{Localize.text('CheckAll', 'UniversalForms')}</button>
                                <button hidden={!hideAllButton} type="button" className="btn btn-sm btn-info" onClick={hideAll}>{Localize.text('UncheckAll', 'UniversalForms')}</button>
                            </div> :
                            null
                    }
                    <div className="modal-body">
                        <div className={'form-group'}>
                            <div style={{maxHeight: windowHeight - 400, overflowY: 'auto'}}>
                                <table className={'table table-hover'} style={styles.table}>
                                    <tbody>
                                    {itemStates.map((i, idx) =>
                                        <tr key={i.text} onClick={() => toggleShowItem(idx)}>
                                            <td>{i.show ? <i className="fa fa-check" /> : ''} {i.text}</td>
                                        </tr>)}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary" onClick={done} data-dismiss="modal">{Localize.text('Done', 'Universal')}</button>
                    </div>
                </div>
            </div>
        </div>
    );
};
MyModal.propTypes = {
    title: PropTypes.string,
    hideAllButton: PropTypes.bool,
    showAllButton: PropTypes.bool,
    itemStates: PropTypes.arrayOf(PropTypes.object),
    setItemStates: PropTypes.func,
    done: PropTypes.func,
    windowHeight: PropTypes.number,
};

const MultiCheckbox = ({ buttonAsTextbox, buttonTitle, editorTitle, hideAllButton, showAllButton, filterable, items, onDone, windowHeight, disabled }) => {
    const [itemStates, setItemStates] = useState(items);

    useEffect(() => setItemStates(items), [items]);

    const done = () => onDone(itemStates);

    const myButton = buttonAsTextbox ?
        <input readOnly={true} disabled={disabled} type={'text'} className={'form-control form-control-sm'} style={disabled ? {} : styles.textbox} value={buttonTitle} data-toggle="modal" data-target="#myModal" />
        :
        <button type="button" className="btn btn-sm btn-outline-info" data-toggle="modal" data-target="#myModal" disabled={disabled}>{buttonTitle}</button>

    return (
        <div>
            {myButton}
            <MyModal
                title={editorTitle}
                hideAllButton={hideAllButton}
                showAllButton={showAllButton}
                itemStates={itemStates}
                setItemStates={setItemStates}
                done={done}
                windowHeight={windowHeight}
            />
        </div>
    );
};
MultiCheckbox.propTypes = {
    buttonAsTextbox: PropTypes.bool,
    buttonTitle: PropTypes.string,
    editorTitle: PropTypes.string,
    hideAllButton: PropTypes.bool,
    showAllButton: PropTypes.bool,
    title: PropTypes.string,
    filterable: PropTypes.bool,
    items: PropTypes.arrayOf(PropTypes.object),
    onDone: PropTypes.func,
    windowHeight: PropTypes.number,
    disabled: PropTypes.bool,
};

export default connect(
    ({ appState }) => ({
        windowHeight: appState.windowHeight
    })
)(MultiCheckbox);
