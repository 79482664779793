import { bindAll } from 'lodash';
import { setAppLocalizedLists } from '../actions/app-actions';
import * as immutable from 'immutable';
import request from 'superagent';
import swal from 'sweetalert';
import { loggedOutInternalErrorMessage } from '../constants';

require('superagent-jsonapify')(request);

export default class LocalizedList {

    constructor({ store }) {
        this._store = store;
        bindAll(this, [
            '_updateData',
            'get',
            'addItem'
        ]);
    }

    async initialize(locale) {
        this._locale = locale;
        await this._updateData();
    }

    async _updateData() {
        const { errors, data } = await gql.transaction(
            'query',
            'getLocalizedLists',
            {},
            ['_id', 'listName', 'num', 'values { en { key, val } es { key, val } pt { key, val } id { key, val } fr { key, val }}']
        );
        if(errors) throw new Error(errors[0].message);
        this._store.dispatch(setAppLocalizedLists(data.getLocalizedLists.sort((a, b) => a.num === b.num ? 0 : a.num > b.num ? 1 : -1)));
    }

    get(data, listName) {
        return data
            .filter(l => l.listName === listName)
            .map(l => ({
                _id: l._id,
                num: l.num,
                values: l.values[this._locale].length > 0 ?
                    l.values[this._locale]
                    :
                    l.values.en.length > 0 ?
                        l.values.en
                        :
                        l.values.find(locale => l.values[locale].length > 0)
            }))
            .map(l => ({
                ...l,
                values: l.values.reduce((map, { key, val }) => map.set(key, val), new Map())
            }));
    }

    getItems(data) {
        return data
            .reduce((map, i) => {
                const values = i.values[this._locale].length > 0 ?
                    i.values[this._locale]
                    :
                    i.values.en.length > 0 ?
                        i.values.en
                        :
                        i.values.find(locale => i.values[locale].length > 0);
                return map.set(i._id, values.reduce((obj, v) => ({...obj, [v.key]: v.val}), {}));
            }, immutable.Map());
    }

    async addItem(listName, values) {
        const locale = this._locale;
        const locales = ['en', 'es', 'pt', 'id', 'fr'];
        const item = {
            listName,
            num: 0,
            values: locales
                .reduce((obj, l) => {
                    return {
                        ...obj,
                        [l]: l !== locale ? [] : [...values.entries()].map(([ key, val ]) => ({ key, val }))
                    };
                }, {})
        };
        const { errors, data } = await gql.transaction(
            'mutation',
            'createLocalizedListItem',
            {
                input: item
            },
            ['_id', 'listName', 'num', 'values { en { key, val } es { key, val } pt { key, val } id { key, val } fr { key, val }}']
        );
        if(errors) throw new Error(errors[0].message);
        const { localizedLists } = this._store.getState().appState;
        const newLocalizedLists = [...localizedLists, data.createLocalizedListItem];
        this._store.dispatch(setAppLocalizedLists(newLocalizedLists));
    }

    async updateItem(item) {
        const { errors, data } = await gql.transaction(
            'mutation',
            'updateLocalizedListItem',
            {
                _id: item._id,
                input: item
            },
            ['_id', 'listName', 'num', 'values { en { key, val } es { key, val } pt { key, val } id { key, val } fr { key, val }}']
        );
        if(errors) throw new Error(errors[0].message);
        const { localizedLists } = this._store.getState().appState;
        const idx = localizedLists.findIndex(l => l._id === item._id);
        const newLocalizedLists = [...localizedLists.slice(0, idx), data.updateLocalizedListItem, ...localizedLists.slice(idx + 1)];
        this._store.dispatch(setAppLocalizedLists(newLocalizedLists));
    }

    async deleteItem({ oldKey, newKey }) {
        // t191 replace the old key everywhere in the db with the new key
        return await new Promise((resolve, reject) => {
            const showUpdatingMessage = () => {
                // show a new alert with no buttons, so the user can't do anything until the db finishes updating.
                swal({
                    text: Localize.text('UpdatingTheDatabaseThisMayTakeAMinute', 'Admin'),
                    icon: 'info',
                    button: { visible: false },
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                });
            };

            let ignoreIds = [];

            const doDeleteItem = async dryRun => {
                showUpdatingMessage();

                // t364 check whether the id exists in any other tables.
                if (dryRun) {
                    const { errors, data } = await gql.transaction(
                        'mutation',
                        'deleteLocalizedListItem',
                        { _id: oldKey, locale: this._locale, dryRun: true }
                    );
                    if(errors) reject(Error(errors[0].message));
                    const dryRunResult = JSON.parse(data.deleteLocalizedListItem);

                    if (dryRunResult.display.length) {
                        const msg = `${Localize.text('ThisItemIsReferencedElsewhereInTheDatabaseDoYouWantToReplaceTheseReferencesWithTheDefaultValue', 'LocalizedList')}\n\n${dryRunResult.display.join('\n')}`;
                        const ok = await swal({
                            icon: 'warning',
                            title: Localize.text('ChangeAllReferencedItems', 'LocalizedList'),
                            text: msg,
                            buttons: [
                                Localize.text('Cancel', 'Universal'),
                                Localize.text('Yes', 'Universal')
                            ],
                        });

                        if (!ok) {
                            return false;
                        }

                        showUpdatingMessage();
                    }

                    ignoreIds = dryRunResult.idList;
                }

                return await new Promise((resolve1, reject1) => {
                    request
                        .post('/api/replace-list-item')
                        .send({ oldKey, newKey, dryRun, ignoreIds })
                        .then(async (response) => {
                            if (response.statusCode !== 200) throw new Error(response.text);

                            const responseObj = JSON.parse(response.text);

                            if (dryRun && responseObj.length > 0) {
                                // if we get here, that means we are not checking everywhere we need to check in deleteLocalizedListItem.
                                const ok = await swal({
                                    icon: 'warning',
                                    title: Localize.text('ChangeAllReferencedItems', 'LocalizedList'),
                                    text: `${Localize.text('ThisItemIsReferencedElsewhereInTheDatabaseDoYouWantToReplaceTheseReferencesWithTheDefaultValue', 'LocalizedList')}\n${JSON.stringify(responseObj, null, '  ')}`,
                                    buttons: [
                                        Localize.text('Cancel', 'Universal'),
                                        Localize.text('Yes', 'Universal')
                                    ],
                                });

                                if (!ok) {
                                    resolve1(false);
                                    return;
                                }
                            }

                            if (!dryRun) {
                                const { errors: errors2, data: data2 } = await gql.transaction(
                                    'mutation',
                                    'deleteLocalizedListItem',
                                    { _id: oldKey, locale: this._locale }
                                );
                                if(errors2) reject(Error(errors2[0].message));
                                const result = JSON.parse(data2.deleteLocalizedListItem);

                                if (result.display.length) {
                                    // should never be able to get here.  api/replace-list-item should have already cleaned the data.
                                    const msg = `${Localize.text('UnableToDeleteThisItemBecauseItIsReferencedElsewhereInTheDatabase', 'LocalizedList')}\n\n${result.display.join('\n')}`;
                                    await swal({
                                        icon: 'error',
                                        title: Localize.text('CannotDelete', 'Universal'),
                                        text: msg,
                                    });

                                    resolve1(false);
                                    return;
                                }

                                // remove the item we deleted from our local store
                                const { localizedLists } = this._store.getState().appState;
                                const newLocalizedLists = localizedLists
                                    .filter(l => l._id !== oldKey);
                                this._store.dispatch(setAppLocalizedLists(newLocalizedLists));
                            }

                            resolve1(response.text);
                        })
                        .catch(err => {
                            if (err.status === 403) {   // I have not tested this scenario
                                handleError(new Error(loggedOutInternalErrorMessage));
                                reject1();
                            }

                            handleError(err);
                        });
                });
            };

            doDeleteItem(true)
                .then(async (ok) => {
                    if (ok) {
                        const result = await doDeleteItem(false);
                        resolve(result);
                    } else {
                        resolve();
                    }
                })
                .catch(err => {
                    if (err.status === 403) {   // I have not tested this scenario
                        handleError(new Error(loggedOutInternalErrorMessage));
                    } else {
                        handleError(err);
                    }
                });
        });
    }

}
