import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import bindAll from 'lodash/bindAll';
import isEqual from 'lodash/isEqual';
import swal from 'sweetalert';
import { Map } from 'immutable';
import FilterSelect from './filter-select';
import { setAppDisableNav, setAppModalStatus } from '../../actions/app-actions';
import { modalStatuses } from '../../constants';

let Selector = ({ variableRole, data, allPeople, allRoles =[], localizedLists, onChange, onDelete }) => {

    const items = [...allPeople.values()];

    const showPersonDetail = (e, _id) => {
        e.preventDefault();

        const memberStatusList = listManager.get(localizedLists, 'Member Status');
        const organizationList = listManager.get(localizedLists, 'Organization');

        const person = allPeople.get(_id);

        swal({
            title: Localize.text('PersonDetail', 'UniversalForms'),
            text: [
                Localize.text('NameColon', 'PersonDetail'),
                person.firstName + ' ' + person.lastName,
                Localize.text('MemberStatusColon', 'PersonDetail'),
                person.memberStatus ? memberStatusList.find(s => s._id === person.memberStatus).values.get('name') : '',
                Localize.text('OrganizationColon', 'PersonDetail'),
                person.organization ? organizationList.find(o => o._id === person.organization).values.get('name') : '',
                Localize.text('EmailAddressesColon', 'PersonDetail'),
                person.emails ? person.emails.join('<br>') : ''
            ].join('\n'),
            button: Localize.text('OK', 'Universal')
        });
    };

    return (
        <div className={'form-group'}>
            <div className={'card'}>
                <div className={'card-body'}>
                    <div className={'row'}>
                        <div className={'col'}>
                            <div className={'form-group input-group-sm'}>
                                <label>{Localize.text('Role', 'Universal')}</label>
                                {variableRole ?
                                    <input className={'form-control'} value={data.role} onChange={e => {
                                        e.preventDefault();
                                        onChange({
                                            ...data,
                                            role: e.target.value
                                        });
                                    }} />
                                    :
                                    <select className={'form-control'} value={data.role} onChange={e => {
                                        e.preventDefault();
                                        onChange({
                                            ...data,
                                            role: e.target.value
                                        });
                                    }}>
                                        <option value={''}>{Localize.text('SelectOne', 'UniversalForms')}</option>
                                        {allRoles.map(r => (
                                            <option key={r._id} value={r._id}>{r.name}</option>
                                        ))}
                                    </select>
                                }
                            </div>
                        </div>
                        <div className={'col'}>
                            <div className={'form-group input-group-sm'}>
                                <label>{Localize.text('Details', 'Universal')}</label>
                                <input className={'form-control'} value={data.category} onChange={e => {
                                    e.preventDefault();
                                    onChange({
                                        ...data,
                                        category: e.target.value
                                    });
                                }} />
                            </div>
                        </div>
                        <div className={'col'}>
                            <div className={'form-group input-group-sm'}>
                                <label>{Localize.text('People', 'Universal')}</label>
                                <div className={'list-group'}>
                                    <div className={'list-group-item'}>
                                        {/*<input className={'form-control'} type={'text'} value={''} />*/}
                                        <FilterSelect items={items.filter(({_id}) => !data.people.includes(_id))} onSelect={_id => {
                                            onChange({
                                                ...data,
                                                people: [...data.people, _id]
                                            });
                                        }} />
                                    </div>
                                    {data.people.map((p, i) => {
                                        return (
                                            <div key={data.role + 'person' + i} className={'list-group-item list-group-item-action'} style={{cursor: 'pointer'}} onClick={e => showPersonDetail(e, p)}>
                                                <a href={'#'} className={'pull-right text-danger'} onClick={async function(e) {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    const person = allPeople.get(p);
                                                    const confirmed = await swal({
                                                        text: Localize.text('AreYouSureThatYouWantToRemovePersonFromThisRole', 'UniversalForms', {person: person.firstName + ' ' + person.lastName}),
                                                        icon: 'warning',
                                                        buttons: [
                                                            Localize.text('Cancel', 'Universal'),
                                                            Localize.text('OK', 'Universal')
                                                        ]
                                                    });
                                                    if(!confirmed) return;
                                                    onChange({
                                                        ...data,
                                                        people: data.people.filter(pp => pp !== p)
                                                    });
                                                }}><i className={'fa fa-remove'} /></a>
                                                {allPeople.get(p).firstName + ' ' + allPeople.get(p).lastName}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className={'col-1'}>
                            <a href={'#'} className={'pull-right text-danger'} onClick={async function(e) {
                                e.preventDefault();
                                e.stopPropagation();
                                if(data.people.length > 0) {
                                    const confirmed = await swal({
                                        text: Localize.text('AreYouSureThatYouWantToRemoveThisRole', 'UniversalForms'),
                                        icon: 'warning',
                                        buttons: [
                                            Localize.text('Cancel', 'Universal'),
                                            Localize.text('OK', 'Universal')
                                        ]
                                    });
                                    if(!confirmed) return;
                                }
                                onDelete();
                            }}><i className={'fa fa-remove'} /></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
Selector.propTypes = {
    variableRole: PropTypes.bool,
    data: PropTypes.object,
    allPeople: PropTypes.instanceOf(Map),
    allRoles: PropTypes.arrayOf(PropTypes.object),
    localizedLists: PropTypes.arrayOf(PropTypes.object),
    onChange: PropTypes.func,
    onDelete: PropTypes.func
};
Selector = connect(
    ({ appState }) => ({
        localizedLists: appState.localizedLists
    })
)(Selector);

class RoleSelector extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            roles: props.roles.length > 0 ? props.roles : [
                this.defaultRoleItem()
            ],
            orig: props.roles,
        };
        bindAll(this, [
            'openModal',
            'closeModal',
            'onDone',
            'onChange',
            'onDeleteRole',
            'addRole'
        ]);
    }

    defaultRoleItem() {
        return {role: '', category: '', people: []};
    }

    componentWillReceiveProps(newProps) {
        if (!isEqual(newProps.roles, this.props.roles) && !isEqual(newProps.roles, this.state.roles)) {
            this.setState({
                ...this.state,
                roles: newProps.roles
            });
        }
    }

    componentDidUpdate() {
        // make sure this update is for the modal we are showing
        if (!($(this.modalNode).data('bs.modal') || {})._isShown) return;

        if (this.props.modalStatus === modalStatuses.CLOSE_MODAL) {
            // user wants to lose changes
            if (!isEqual(this.state.orig, this.state.roles)) {
                this.setState({ roles: this.state.orig }, () => this.closeModal());
            } else {
                this.closeModal();
            }
        } else if (!isEqual(this.state.orig, this.state.roles)) {
            this.props.disableNav();
        } else {
            this.props.enableNav();
        }
    }

    openModal(e) {
        e.preventDefault();
        const $modal = $(this.modalNode);
        $modal.modal({
            show: true,
            backdrop: 'static'
        });
        $modal
            .off('hidden.bs.modal')
            .on('hidden.bs.modal', () => {
                this.onDone();
            });
        this.props.setModalStatus(modalStatuses.SHOWING_MODAL);
    }

    closeModal(e) {
        if (e) e.preventDefault();
        $(this.modalNode).modal('hide');
        const { roles } = this.state;
        this.setState({
            ...this.state,
            roles: roles
                .filter(({ role }) => !!role),
        });
        this.props.setModalStatus(modalStatuses.NO_MODAL);
        this.props.enableNav();
    }

    onDone() {
        if (!isEqual(this.state.orig, this.state.roles)) {
            this.props.onDone(this.state.roles.filter(r => r.people && r.people.length));   // t238 only include roles that have at least one person assigned.
        }
    }

    onChange(idx, data) {
        const { state } = this;
        this.setState({
            ...state,
            roles: [
                ...state.roles.slice(0, idx),
                data,
                ...state.roles.slice(idx + 1)
            ]
        });
    }

    onDeleteRole(idx) {
        const { state } = this;
        this.setState({
            ...state,
            roles: [
                ...state.roles.slice(0, idx),
                ...state.roles.slice(idx + 1)
            ]
        });
    }

    addRole(e) {
        e.preventDefault();
        this.setState({
            ...this.state,
            roles: [
                ...this.state.roles,
                this.defaultRoleItem()
            ]
        });
    }

    render() {

        const { title, allRoles, people, variableRole, readonly } = this.props;
        const { roles } = this.state;

        return (
            <div className={'pull-right'}>

                <a href={'#'} onClick={this.openModal} style={readonly ? {display: 'none'} : {}}><i className={'fa fa-pencil'} /></a>

                <div className={'modal'} ref={node => this.modalNode = node}>
                    <div className={'modal-dialog modal-lg'}>
                        <div className={'modal-content'}>
                            <div className={'modal-header'}>
                                <h5 className="modal-title">{title}</h5>
                                <button type="button" className="close" onClick={this.closeModal}>
                                    <span>&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                {
                                    roles.map((r, i) => (
                                        <Selector key={'selector' + i} variableRole={variableRole} data={r} allRoles={allRoles} allPeople={people} onChange={data => this.onChange(i, data)} onDelete={() => this.onDeleteRole(i)} />
                                    ))
                                }
                                <div>
                                    <button type={'button'} className={'btn btn-outline-dark'} onClick={this.addRole}><i className={'fa fa-plus'} /> {Localize.text('AddRoleAssignment', 'UniversalForms')}</button>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type={'button'} className={'btn btn-primary'} onClick={this.closeModal}>{Localize.text('Done', 'Universal')}</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }

}
RoleSelector.propTypes = {
    title: PropTypes.string,
    variableRole: PropTypes.bool,
    allRoles: PropTypes.arrayOf(PropTypes.object),
    people: PropTypes.instanceOf(Map),
    roles: PropTypes.arrayOf(PropTypes.object),
    readonly: PropTypes.bool,
    onDone: PropTypes.func,
    modalStatus: PropTypes.string,
    disableNav: PropTypes.func,
    enableNav: PropTypes.func,
    setModalStatus: PropTypes.func,
};

const WrappedRoleSelector = connect(
    ({ appState }) => ({
        people: appState.people,
        modalStatus: appState.modalStatus,
    }),
    dispatch => ({
        disableNav: () => dispatch(setAppDisableNav(true)),
        enableNav: () => dispatch(setAppDisableNav(false)),
        setModalStatus: status => dispatch(setAppModalStatus(status)),
    }),
)(RoleSelector);

export default WrappedRoleSelector;
