import { loggedOutInternalErrorMessage } from '../constants';
import StackTrace from 'stacktrace-js';

const { bindAll, isPlainObject, isArray, isString, isNumber, isBoolean } = require('lodash');
const request = require('superagent');
require('superagent-jsonapify')(request);

const escapeQuotes = s => JSON
    .stringify(s)
    .replace(/^"/, '')
    .replace(/"$/, '');

const escapeArray = arr => JSON
    .stringify(arr);

const recEnc = item => {
    if(isPlainObject(item)) {
        return `{\n${Object.keys(item).map(k => `${k}: ${recEnc(item[k])}`).join(',\n')}\n}`;
    } else if(isArray(item)) {
        return `[\n${item.map(i => recEnc(i)).join(',\n')}\n]`;
    } else if(isNumber(item) || isBoolean(item)) {
        return String(item);
    } else if(isString(item)) {
        return `"${escapeQuotes(item)}"`;
    } else {
        throw new Error('You can only encode type array, object, string, number, or boolean for transport');
    }
};

// console.log(recEnc({
//     some: 'thing',
//     nested: [
//         {str: 'something', num: 2},
//         {str: 'something else', num: 3},
//         {str: 'another something', num: 4}
//     ],
//     obj: {
//         name: 'me',
//         age: 31,
//         bool1: true,
//         bool2: false
//     }
// }));

class GQL {

    constructor({ endpoint }) {
        this._endpoint = endpoint;
        bindAll(this, [
            'query',
            'mutation',
            'transaction'
        ]);
    }

    query(query) {
        return new Promise((resolve, reject) => {
            const queryStr = `query { ${query} }`;
            request
                .post(this._endpoint)
                .send({query: queryStr})
                .then(res => {
                    if (res.body === null) {
                        reject(new Error(loggedOutInternalErrorMessage));
                    }
                    resolve(res.body);
                })
                .catch(console.error);
        });
    }

    async mutation(query) {
        const { body } = await request
            .post(this._endpoint)
            .send({query: `mutation { ${query} }`});

        if (body === null) {
            throw new Error(loggedOutInternalErrorMessage);
        }

        return body;
    }

    async transaction(type, name, data = {}, res = []) {
        try {
            if (type === 'query') {
                const keys = Object.keys(data);
                const queryStr = keys.length > 0 ? `
                    ${name}(${Object
                        .keys(data)
                        .map(key => isArray(data[key]) ? `${key}: ${escapeArray(data[key])}` : `${key}: "${escapeQuotes(data[key])}"`)
                        // .map(key => `${key}: "${recEnc(data[key])}"`)
                        .join(', ')
                    }) ${res.length === 0 ? '' : `{
                        ${res.join('\n')}
                    }`}
                ` : `
                    ${name} {\n${res.join('\n')}\n}
                `;
                return this.query(queryStr);
            } else if (type === 'mutation') {
                const mutationStr = `
                    ${name}(${Object
                        .keys(data)
                        .map(key => `${key}: ${recEnc(data[key])}`)
                        .join(', ')
                    }) ${res.length === 0 ? '' : `{
                        ${res.join('\n')}
                    }`}
                `;
                return this.mutation(mutationStr);
            }
        } catch (err) {
            StackTrace.fromError(err).then((stackFrames) => {
                throw new Error(`${err}\nGQL transaction:\ntype: ${type}\nname: ${name}\ndata: ${JSON.stringify(data, null, '  ')}\nrec: ${JSON.stringify(res, null, '  ')}\n\nStack Frames:\n${JSON.stringify(stackFrames, null, '  ')}`);
            }); // NB we cannot at a .catch() here b/c it will catch the error we throw in .then()
        }
    }
}

module.exports = GQL;
