import React from 'react';
import PropTypes from 'prop-types';
import { bindAll, omit } from 'lodash';
import swal from 'sweetalert';
import { Link } from 'react-router-dom';
import isEqual from 'lodash/isEqual';
import MultiSelect from './multi-select';

const emptyRegion = {
    _id: '',
    name: '',
    fields: []
};

class RegionForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            ...emptyRegion,
            allFields: [],
            orig: { ...emptyRegion },
        };

        bindAll(this, [
            'onNameChange',
            'onFieldAdd',
            'onFieldDelete',
            'onSubmit',
            'onDelete'
        ]);
    }

    async componentWillMount() {
        try {
            const { regionId } = this.props;
            let region, fields;
            if(regionId) {
                const [ res1, res2 ] = await Promise.all([
                    gql.transaction(
                        'query',
                        'getRegion',
                        {_id: regionId},
                        ['_id', 'name', 'fields { _id }']
                    ),
                    gql.transaction(
                        'query',
                        'getFields',
                        {},
                        ['_id', 'name']
                    )
                ]);
                if(res1.errors) throw new Error(res1.errors[0].message + ' - ' + res1.errors[0].path.join('/'));
                if(res2.errors) throw new Error(res2.errors[0].message + ' - ' + res2.errors[0].path.join('/'));
                region = res1.data.getRegion;
                region.fields = region.fields.map(f => f._id);
                fields = res2.data.getFields;
            } else {
                const { errors, data } = await gql.transaction(
                    'query',
                    'getFields',
                    {},
                    ['_id', 'name']
                );
                if(errors) throw errors[0];
                fields = data.getFields;
                region = { ...emptyRegion };
            }
            const intCol = new Intl.Collator(Localize.locale());
            this.setState({
                ...this.state,
                ...region,
                allFields: fields
                    .sort((a, b) => intCol.compare(a.name, b.name)),
                orig: { ...region },
            });
        } catch(err) {
            handleError(err);
        }
    }

    componentDidUpdate() {
        const { disableNav, enableNav } = this.props;

        const current = Object
            .keys(emptyRegion)
            .reduce((acc, k) => ({
                ...acc,
                [k]: this.state[k],
            }), {});

        if (isEqual(this.state.orig, current)) {
            enableNav();
        } else {
            disableNav();
        }
    }

    onNameChange(e) {
        e.preventDefault();
        this.setState({
            ...this.state,
            name: e.target.value
        });
    }

    onFieldAdd(_id) {
        const { fields } = this.state;
        this.setState({
            ...this.state,
            fields: [...fields, _id]
        });
    }

    async onFieldDelete(idToDelete) {
        try {
            const { fields } = this.state;
            const confirmed = await swal({
                icon: 'warning',
                title: Localize.text('RemoveField', 'RegionForm'),
                text: Localize.text('AreYouSureThatYouWantToRemoveThisField', 'RegionForm'),
                buttons: [Localize.text('Cancel', 'Universal'), Localize.text('YesRemoveField', 'RegionForm')]
            });
            if(!confirmed) return;
            this.setState({
                ...this.state,
                fields: fields.filter(_id => _id !== idToDelete)
            });
        } catch(err) {
            handleError(err);
        }
    }

    onSubmit(e) {
        e.preventDefault();
        const { state } = this;
        const name = state.name.trim();
        if(!name) {
            swal(Localize.text('Oops', 'Universal'), Localize.text('YouMustEnterAName', 'UniversalForms'), 'warning');
            return;
        }
        this.props.onSubmit(omit(state, ['allFields', 'orig']));
    }

    async onDelete(e) {
        try {
            e.preventDefault();
            const confirmed = await swal({
                title: Localize.text('DeleteRegion', 'RegionForm'),
                text: Localize.text('AreYouSureThatYouWantToDeleteThisRegion', 'RegionForm'),
                icon: 'warning',
                buttons: [Localize.text('Cancel', 'Universal'), Localize.text('YesDeleteRegion', 'RegionForm')]
            });
            if (!confirmed) return;
            this.props.onDelete();
        } catch(err) {
            handleError(err);
        }

    }

    render() {

        const { name, fields, allFields } = this.state;
        const { regionId, disabled, location } = this.props;

        return (
            <div>
                <div className={'row border-bottom'}>
                    {regionId ?
                        <h1 className={'col-12'}>{Localize.text('EditRegion', 'RegionForm')}</h1>
                        :
                        <h1 className={'col-12'}>{Localize.text('CreateNewRegion', 'RegionForm')}</h1>
                    }
                </div>
                <div className={'row'}>
                    <div className={'col-sm-9 col-md-7 col-lg-5'}>
                        <div className={'form-group'}>
                            <label>{Localize.text('RegionName', 'RegionForm')}</label>
                            <input type={'text'} className={'form-control'} value={name} onChange={this.onNameChange} />
                        </div>
                    </div>
                </div>
                <div className={'row'}>
                    <div className={'col-sm-9 col-md-7 col-lg-5'}>
                        <MultiSelect title={Localize.text('Fields', 'RegionForm')} addTitle={Localize.text('AddField', 'RegionForm')} filterable={true} items={allFields} selected={fields} onAdd={this.onFieldAdd} onDelete={this.onFieldDelete} />
                    </div>
                </div>
                {regionId ?
                    <div className={'row'}>
                        <div className={'col-sm-9 col-md-7 col-lg-5'}>
                            <div className={'form-group'}>
                                <Link type={'button'} to={`/add-field?after=${encodeURIComponent(location.pathname)}`} className={'btn btn-outline-secondary d-block w-100'} title={Localize.text('CreateNewField', 'RegionForm')}><i className={'fa fa-plus'} /> {Localize.text('CreateNewField', 'RegionForm')}</Link>
                            </div>
                        </div>
                    </div>
                    :
                    <div></div>
                }
                <div className={'row'}>
                    <div className={'col-12'}>
                        <div className={'form-group'}>
                            <button type={'button'} className={'btn btn-primary'} onClick={this.onSubmit} disabled={disabled}>{Localize.text('SaveRegion', 'RegionForm')}</button>
                        </div>
                    </div>
                </div>
                {regionId ?
                    <div className={'row'}>
                        <div className={'col-12'}>
                            <div className={'form-group'}>
                                <button type={'button'} className={'btn btn-danger'} onClick={this.onDelete} disabled={disabled}>{Localize.text('DeleteRegion', 'RegionForm')}</button>
                            </div>
                        </div>
                    </div>
                    :
                    <div></div>
                }
            </div>
        );
    }

}
RegionForm.propTypes = {
    location: PropTypes.object,
    regionId: PropTypes.string,
    disabled: PropTypes.bool,
    onSubmit: PropTypes.func,
    onDelete: PropTypes.func,
    disableNav: PropTypes.func,
    enableNav: PropTypes.func,
};

export default RegionForm;
