import { connect } from 'react-redux';
import { setAppFields, setAppModalStatus } from '../../../actions/app-actions';
import { setActive, setFieldToConsultants } from '../../../actions/field-actions';

import FieldDetail from './field-detail';

export default connect(
    ({ appState, fieldState }) => ({
        fields: appState.fields,
        user: appState.user,
        windowWidth: appState.windowWidth,
        people: appState.people,
        localizedLists: appState.localizedLists,
        locations: appState.locations,
        fieldToConsultants: fieldState.fieldToConsultants
    }),
    dispatch => ({
        setFields: fields => dispatch(setAppFields(fields)),
        setActive: active => dispatch(setActive(active)),
        setFieldToConsultants: fieldToConsultants => dispatch(setFieldToConsultants(fieldToConsultants)),
        setModalStatus: status => dispatch(setAppModalStatus(status)),
    })
)(FieldDetail);
