import gqlCallAttributes from './gql-call-attributes';
import { setAppRegions, setAppCountries, setAppFields } from '../actions/app-actions';

export const updateAppRegions = async function(dispatch) {
    try {
        const { errors, data } = await gql.transaction(
            'query',
            'getRegions',
            {},
            gqlCallAttributes.region()
        );
        if(errors) throw errors[0];
        const regions = data.getRegions;
        dispatch(setAppRegions(regions));
    } catch(err) {
        handleError(err);
    }
};

export const updateAppCountries = async function(dispatch) {
    try {
        const { errors, data } = await gql.transaction(
            'query',
            'getCountries',
            {},
            [
                '_id',
                'name'
            ]
        );
        if(errors) throw errors[0];
        const { getCountries: countries } = data;
        dispatch(setAppCountries(countries));
    } catch(err) {
        handleError(err);
    }
};

export const updateAppFields = async function(dispatch) {
    try {
        const { errors, data } = await gql.transaction(
            'query',
            'getFields',
            {},
            [
                '_id',
                'hidden',
                'name',
                'publicationName',
                'countries { _id, name }',
                'IMTRequestedFieldRoles { title, category, people }',
                'fieldAppointedRoles { title, category, people }',
                'note',
                'contacts',
                'personnelNeeds { number, comment }',
                'level'
            ]
        );
        if(errors) throw errors[0];
        const { getFields: fields } = data;
        dispatch(setAppFields(fields));
    } catch(err) {
        handleError(err);
    }
};
