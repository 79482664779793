import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import bindAll from 'lodash/bindAll';
import { Map } from 'immutable';
import swal from 'sweetalert';
import RadarGraph from '../../../shared/radar-graph';
import LineChart from '../../../shared/line-chart';
import ModalTextarea from '../../../shared/modal-textarea';
import MaturingChurchForm from './maturing-church-form';
import { setLocations } from '../../../../actions/people-group-actions';
import { setAppDisableNav, setAppLocations, setAppModalStatus } from '../../../../actions/app-actions';
import callAttributes from '../../../../util/gql-call-attributes';
import { TwoColumnLayout, TwoColumnRow } from '../../../shared/two-column-layout';
import * as dateUtils from '../../../../util/date-utils';
import { dbGuids, modalStatuses } from '../../../../constants';

const yesNo = bool => {
    if(bool) {
        return <span>{Localize.text('Yes', 'Universal')}</span>;
    } else {
        return <span>{Localize.text('No', 'Universal')}</span>;
    }
};

class Locations extends React.Component {

    constructor(props) {
        super(props);
        const location = props.locations.find(l => l._id === props.locationId);
        this.state = this.getInitialState(location);
        bindAll(this, [
            'openGeneralModal',
            'onGeneralModalSave',
            'onGeneralModalCancel',
            'openChurchStatusModal',
            'onChurchStatusModalSave',
            'onChurchStatusModalCancel',
            'openLiteracyModal',
            'onLiteracyModalSave',
            'onLiteracyModalCancel',
            'openTimelineModal',
            'onTimelineModalSave',
            'onTimelineModalCancel',
            'openNewLocationModal',
            'onNewLocationModalSave',
            'onNewLocationModalCancel',
            'onInputChange',
            'onBooleanInputChange',
            'save',
            'getInitialState',
            'openMaturityModal',
            'closeMaturityModal',
            'saveNote',
            'saveWorkersNote'
        ]);
    }

    getInitialState(location) {
        return {
            newLocationName: '',
            newLocationCountry: location.country._id,
            name: location.name,
            publicationName: location.publicationName,
            country: location.country._id,
            area: location.area,
            population: location.population,
            note: location.note,
            workersNote: location.workersNote,
            workStatus: location.workStatus,
            churchStage: location.churchStage,
            numberOfBelievers: location.numberOfBelievers,
            numberOfElders: location.numberOfElders,
            literacyOngoingVernacularProgram: location.literacyOngoingVernacularProgram,
            literacyOngoingLWCProgram: location.literacyOngoingLWCProgram,
            literacyRateVernacular: location.literacyRateVernacular,
            literacyRateLWC: location.literacyRateLWC,
            literacyGovernmentProgram: location.literacyGovernmentProgram,
            literacyNote: location.literacyNote,
            targetDateEntry: location.targetDateEntry,
            dateEntered: location.dateEntered,
            targetDateGospelPresented: location.targetDateGospelPresented,
            dateGospelPresented: location.dateGospelPresented,
            dateTeachingBegin: location.dateTeachingBegin,
            initialEvangelism: location.initialEvangelism,
            primaryCare: location.primaryCare,
            showMaturityModal: false
        };
    }

    componentWillReceiveProps(newProps) {
        if(newProps.locationId !== this.props.locationId) {
            const location = newProps.locations.find(l => l._id === newProps.locationId);
            this.setState(this.getInitialState(location));
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.modalStatus === modalStatuses.CLOSE_MODAL) {
            if (($(this.newLocationModalNode).data('bs.modal') || {})._isShown) this.onNewLocationModalCancel();
            if (($(this.generalModalNode).data('bs.modal') || {})._isShown) this.onGeneralModalCancel();
            if (($(this.churchStatusModalNode).data('bs.modal') || {})._isShown) this.onChurchStatusModalCancel();
            if (($(this.literacyModalNode).data('bs.modal') || {})._isShown) this.onLiteracyModalCancel();
            if (($(this.timelineModalNode).data('bs.modal') || {})._isShown) this.onTimelineModalCancel();
            if ((this.state.showMaturityModal)) this.closeMaturityModal();
        }
    }

    openGeneralModal(e) {
        e.preventDefault();
        $(this.generalModalNode).modal({
            backdrop: 'static',
            keyboard: false,
            show: true
        });
        this.props.disableNav();
        this.props.setModalStatus(modalStatuses.SHOWING_MODAL);
    }

    onGeneralModalSave(e) {
        e.preventDefault();
        let {
            name,
            publicationName,
            country,
            area,
            population,
            initialEvangelism,
            primaryCare,
            note,
            workStatus
        } = this.state;

        name = name.trim();
        publicationName = publicationName.trim();
        area = area.trim();
        note = note.trim();

        if(!name) {
            return swal({
                icon: 'warning',
                title: Localize.text('InvalidName', 'Location'),
                text: Localize.text('YouMustEnterALocationName', 'Location'),
                button: Localize.text('OK', 'Universal')
            });
        }

        this.save({
            name,
            publicationName,
            country,
            area,
            population,
            initialEvangelism,
            primaryCare,
            note,
            workStatus
        });
        $(this.generalModalNode).modal('hide');
        this.props.setModalStatus(modalStatuses.NO_MODAL);
        this.props.enableNav();
    }

    async save(locationData) {
        try {
            const { locationId, countries } = this.props;
            const { errors, data } = await gql.transaction(
                'mutation',
                'updateLocation',
                {
                    _id: locationId,
                    input: locationData
                },
                ['_id']
            );
            if(errors) throw new Error(errors[0].message);
            const location = data.updateLocation;
            if(locationData.country) {
                const country = countries.find(c => c._id === locationData.country);
                locationData = {
                    ...locationData,
                    country: {
                        _id: country._id,
                        name: country.name
                    }
                };
            }
            this.props.updateLocation(locationData);
        } catch(err) {
            handleError(err);
        }
    }

    onGeneralModalCancel(e) {
        if (e) e.preventDefault();
        const { locationId, locations } = this.props;
        const location = locations.find(l => l._id === locationId);
        const {
            name,
            publicationName,
            country,
            area,
            population,
            note,
            workStatus
        } = location;
        this.setState({
            ...this.state,
            name,
            publicationName,
            country: country._id,
            area,
            population,
            note,
            workStatus
        });
        $(this.generalModalNode).modal('hide');
        this.props.setModalStatus(modalStatuses.NO_MODAL);
        this.props.enableNav();
    }

    onInputChange(e, key) {
        e.preventDefault();
        const { value } = e.target;
        const inputType = e.target.getAttribute('type');
        this.setState({
            ...this.state,
            [key]: inputType === 'number' ? Number(value) : value
        });
    }

    onBooleanInputChange(e, key) {
        e.preventDefault();
        const { value } = e.target;
        this.setState({
            ...this.state,
            [key]: value === 'true' ? true : false
        });
    }

    openChurchStatusModal(e) {
        e.preventDefault();
        $(this.churchStatusModalNode).modal({
            backdrop: 'static',
            keyboard: false,
            show: true
        });
        this.props.disableNav();
        this.props.setModalStatus(modalStatuses.SHOWING_MODAL);
    }

    onChurchStatusModalSave(e) {
        e.preventDefault();
        const {
            churchStage,
            numberOfBelievers,
            numberOfElders
        } = this.state;

        this.save({
            churchStage,
            numberOfBelievers,
            numberOfElders
        });
        $(this.churchStatusModalNode).modal('hide');
        this.props.setModalStatus(modalStatuses.NO_MODAL);
        this.props.enableNav();
    }

    onChurchStatusModalCancel(e) {
        if (e) e.preventDefault();
        const { locationId, locations } = this.props;
        const location = locations.find(l => l._id === locationId);
        const {
            churchStage,
            numberOfBelievers,
            numberOfElders
        } = location;
        this.setState({
            ...this.state,
            churchStage,
            numberOfBelievers,
            numberOfElders
        });
        $(this.churchStatusModalNode).modal('hide');
        this.props.setModalStatus(modalStatuses.NO_MODAL);
        this.props.enableNav();
    }

    openLiteracyModal(e) {
        e.preventDefault();
        $(this.literacyModalNode).modal({
            backdrop: 'static',
            keyboard: false,
            show: true
        });
        this.props.disableNav();
        this.props.setModalStatus(modalStatuses.SHOWING_MODAL);
    }

    async onLiteracyModalSave(e) {
        e.preventDefault();
        const {
            literacyOngoingVernacularProgram,
            literacyOngoingLWCProgram,
            literacyRateVernacular,
            literacyRateLWC,
            literacyGovernmentProgram,
            literacyNote
        } = this.state;

        if(literacyRateVernacular < 0 || literacyRateVernacular > 100) {
            await swal({
                text: Localize.text('LiteracyRateVernacularMustBeANumberBetween0And100', 'Location'),
                button: Localize.text('OK', 'Universal'),
                icon: 'warning'
            });
            return;
        }
        if(literacyRateLWC < 0 || literacyRateLWC > 100) {
            await swal({
                text: Localize.text('LiteracyRateLWCMustBeANumberBetween0And100', 'Location'),
                button: Localize.text('OK', 'Universal'),
                icon: 'warning'
            });
            return;
        }

        this.save({
            literacyOngoingVernacularProgram,
            literacyOngoingLWCProgram,
            literacyRateVernacular,
            literacyRateLWC,
            literacyGovernmentProgram,
            literacyNote
        });
        $(this.literacyModalNode).modal('hide');
        this.props.setModalStatus(modalStatuses.NO_MODAL);
        this.props.enableNav();
    }

    onLiteracyModalCancel(e) {
        if (e) e.preventDefault();
        const { locationId, locations } = this.props;
        const location = locations.find(l => l._id === locationId);
        const {
            literacyOngoingVernacularProgram,
            literacyOngoingLWCProgram,
            literacyRateVernacular,
            literacyRateLWC,
            literacyGovernmentProgram,
            literacyNote
        } = location;
        this.setState({
            ...this.state,
            literacyOngoingVernacularProgram,
            literacyOngoingLWCProgram,
            literacyRateVernacular,
            literacyRateLWC,
            literacyGovernmentProgram,
            literacyNote
        });
        $(this.literacyModalNode).modal('hide');
        this.props.setModalStatus(modalStatuses.NO_MODAL);
        this.props.enableNav();
    }

    openTimelineModal(e) {
        e.preventDefault();
        $(this.timelineModalNode).modal({
            backdrop: 'static',
            keyboard: false,
            show: true
        });
        this.props.disableNav();
        this.props.setModalStatus(modalStatuses.SHOWING_MODAL);
    }

    async onTimelineModalSave(e) {
        e.preventDefault();
        let {
            targetDateEntry,
            dateEntered,
            targetDateGospelPresented,
            dateGospelPresented,
            dateTeachingBegin
        } = this.state;

        targetDateEntry = targetDateEntry.trim();
        dateEntered = dateEntered.trim();
        targetDateGospelPresented = targetDateGospelPresented.trim();
        dateGospelPresented = dateGospelPresented.trim();
        dateTeachingBegin = dateTeachingBegin.trim();

        for(const date of [targetDateEntry, dateEntered, targetDateGospelPresented, dateGospelPresented, dateTeachingBegin]) {
            if(!date) continue;
            const valid = await dateUtils.isValidDate(date, true);
            if(!valid) return;
        }

        targetDateEntry = targetDateEntry ? dateUtils.formatDate(targetDateEntry) : '';
        dateEntered = dateEntered ? dateUtils.formatDate(dateEntered) : '';
        targetDateGospelPresented = targetDateGospelPresented ? dateUtils.formatDate(targetDateGospelPresented) : '';
        dateGospelPresented = dateGospelPresented ? dateUtils.formatDate(dateGospelPresented) : '';
        dateTeachingBegin = dateTeachingBegin ? dateUtils.formatDate(dateTeachingBegin) : '';

        this.save({
            targetDateEntry,
            dateEntered,
            targetDateGospelPresented,
            dateGospelPresented,
            dateTeachingBegin
        });
        $(this.timelineModalNode).modal('hide');
        this.props.setModalStatus(modalStatuses.NO_MODAL);
        this.props.enableNav();
    }

    onTimelineModalCancel(e) {
        if (e) e.preventDefault();
        const { locationId, locations } = this.props;
        const location = locations.find(l => l._id === locationId);
        const {
            targetDateEntry,
            dateEntered,
            targetDateGospelPresented,
            dateGospelPresented,
            dateTeachingBegin
        } = location;
        this.setState({
            ...this.state,
            targetDateEntry,
            dateEntered,
            targetDateGospelPresented,
            dateGospelPresented,
            dateTeachingBegin
        });
        $(this.timelineModalNode).modal('hide');
        this.props.setModalStatus(modalStatuses.NO_MODAL);
        this.props.enableNav();
    }

    openNewLocationModal(e) {
        e.preventDefault();
        $(this.newLocationModalNode).modal({
            backdrop: 'static',
            keyboard: false,
            show: true
        });
        this.props.disableNav();
        this.props.setModalStatus(modalStatuses.SHOWING_MODAL);
    }

    onNewLocationModalSave(e) {
        e.preventDefault();
        let { newLocationName, newLocationCountry } = this.state;

        newLocationName = newLocationName.trim();

        if(!newLocationName) {
            swal({
                icon: 'warning',
                title: Localize.text('Oops', 'Universal'),
                text: Localize.text('YouMustEnterALocationName', 'PeopleGroupDetail')
            });
            return;
        }

        const { field, peopleGroup } = this.props;
        this.props.createLocation({
            name: newLocationName,
            country: newLocationCountry,
            field: field._id,
            peopleGroup: peopleGroup._id,
            literacyRateVernacular: 0,
            literacyRateLWC: 0,
        });
        $(this.newLocationModalNode).modal('hide');
        setTimeout(() => {
            this.setState({
                ...this.state,
                newLocationName: '',
            });
        }, 0);
        this.props.setModalStatus(modalStatuses.NO_MODAL);
        this.props.enableNav();
    }

    onNewLocationModalCancel(e) {
        if (e) e.preventDefault();
        this.setState({
            ...this.state,
            newLocationName: '',
            newLocationCountry: '',
        });
        $(this.newLocationModalNode).modal('hide');
        this.props.setModalStatus(modalStatuses.NO_MODAL);
        this.props.enableNav();
    }

    openMaturityModal(e) {
        e.preventDefault();
        this.setState({
            ...this.state,
            showMaturityModal: true
        });
        this.props.setModalStatus(modalStatuses.SHOWING_MODAL);
        this.props.disableNav();
    }

    closeMaturityModal() {
        this.setState({
            ...this.state,
            showMaturityModal: false
        });
        this.props.setModalStatus(modalStatuses.NO_MODAL);
        this.props.enableNav();
    }

    saveNote(note) {
        note = note.trim();
        this.setState({
            ...this.state,
            note
        });
        this.save({
            note
        });
    }

    saveWorkersNote(workersNote) {
        workersNote = workersNote.trim();
        this.setState({
            ...this.state,
            workersNote
        });
        this.save({
            workersNote
        });
    }

    render() {

        const { state } = this;

        let { locations, locationId, peopleGroup, params, localizedLists, localizedListItems, updateMaturity, windowHeight, countries, peopleGroupStateStatuses, appStatePeople, readonly } = this.props;
        // console.log('locations state', this.state);

        const styles = {
            leftPadding: {
            //     paddingLeft: 10,
            //     minHeight: 24,
            //     minWidth: 50
            },
        };

        const intCol = new Intl.Collator(Localize.locale());

        locations = [...locations]
            .sort((a, b) => intCol.compare(a.name, b.name));

        const { fieldId, peopleId } = params;

        const location = locations.find(l => l._id === locationId);

        const createNewLocationModal = () => {
            return (
                <div className={'modal'} ref={node => this.newLocationModalNode = node}>
                    <div className={'modal-dialog modal-lg'}>
                        <div className={'modal-content'}>
                            <div className={'modal-header'}>
                                <h5 className="modal-title">{Localize.text('AddNew', 'Universal')}</h5>
                                <button type="button" className="close" onClick={this.onNewLocationModalCancel}>
                                    <span>&times;</span>
                                </button>
                            </div>
                            <div className="modal-body" style={{maxHeight: windowHeight - 300, overflowY: 'auto'}}>

                                <div className={'form-group row'}>
                                    <label className={'col-lg-3'}>{Localize.text('LocationName', 'PeopleGroupDetail') + ':'}</label>
                                    <div className={'col-lg-6'}>
                                        <input type={'text'} className={'form-control form-control-sm'} value={state.newLocationName} onChange={e => this.onInputChange(e, 'newLocationName')} />
                                    </div>
                                </div>

                                <div className={'form-group row'}>
                                    <label className={'col-lg-3'}>{Localize.text('Country', 'PeopleGroupDetail') + ':'}</label>
                                    <div className={'col-lg-6'}>
                                        <select className={'form-control form-control-sm'} value={state.newLocationCountry} onChange={e => this.onInputChange(e, 'newLocationCountry')}>
                                            {
                                                countries
                                                    .sort((a, b) => intCol.compare(a.name, b.name))
                                                    .map(c => <option key={c._id} value={c._id}>{c.name}</option>)
                                            }
                                        </select>
                                    </div>
                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type={'button'} className={'btn btn-secondary'} onClick={this.onNewLocationModalCancel}>{Localize.text('Cancel', 'Universal')}</button>
                                <button type={'button'} className={'btn btn-primary'} onClick={this.onNewLocationModalSave}>{Localize.text('Save', 'Universal')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            );
        };

        if(!location) {
            return (
                <div className={'row'}>
                    <div className={'col-12'}>
                        <div className={'card'}>
                            <div className={'card-header'}>
                                <ul className="nav nav-pills">
                                    <li className="nav-item">
                                        <a href={'#'} className={'nav-link text-secondary'} style={readonly ? {display: 'none'} : {}} onClick={this.openNewLocationModal}><strong><i className="fa fa-plus" /> {Localize.text('AddNew', 'Universal')}</strong></a>
                                    </li>
                                </ul>
                            </div>
                            {createNewLocationModal()}
                        </div>
                    </div>
                </div>
            );
        }

        console.log('location', location);

        const statuses = location.maturingChurchStatuses.length > 0 ? location.maturingChurchStatuses[location.maturingChurchStatuses.length - 1] : {};

        const round = (val = 0) => {
            if(val > 5 && val < 6) return 5;
            const rounded = Math.round(val);
            if(val > 0 && rounded === 0) return 1;
            return rounded;
        };

        const discipleshipValue = statuses.mChurchDiscipleship0 ? round((statuses.mChurchDiscipleship0 + statuses.mChurchDiscipleship1 + statuses.mChurchDiscipleship2 + statuses.mChurchDiscipleship3 + statuses.mChurchDiscipleship4) / 5) : 0;
        const wordValue = statuses.mChurchGodsWord0 ? round((statuses.mChurchGodsWord0 + statuses.mChurchGodsWord1 + statuses.mChurchGodsWord2 + statuses.mChurchGodsWord3 + statuses.mChurchGodsWord4) / 5) : 0;
        const lifeValue = statuses.mChurchLife0 ? round((statuses.mChurchLife0 + statuses.mChurchLife1 + statuses.mChurchLife2 + statuses.mChurchLife3 + statuses.mChurchLife4) / 5) : 0;
        const identityValue = statuses.mChurchIdentity0 ? round((statuses.mChurchIdentity0 + statuses.mChurchIdentity1 + statuses.mChurchIdentity2 + statuses.mChurchIdentity3 + statuses.mChurchIdentity4) / 5) : 0;

        const saveMaturingChurchStatuses = (statusArr, maturityNote) => {
            // console.log('statusArr', statusArr);
            setTimeout(() => {
                updateMaturity({
                    ...location,
                    maturingChurchStatuses: statusArr,
                    maturingChurchNote: maturityNote
                });
            }, 0);
        };

        const locationPeople = peopleGroupStateStatuses
            .get(peopleGroup._id)
            .map(s => {
                const person = appStatePeople.get(s.person) || {};
                return {
                    ...person,
                    location: s.location,
                    serviceStatus: s.serviceStatus,
                };
            });

        return (
            <div className={'container-fluid'}>

                <div className={'row'}>
                    <div className={'col-12'}>
                        <div className={'card'}>
                            <div className={'card-header'}>
                                <ul className="nav nav-pills">
                                    {locations
                                        .sort((a, b) => intCol.compare(a.name, b.name))
                                        .map((l, i) => {
                                            return (
                                                <li key={l._id} className="nav-item">
                                                    <Link to={`/field/${fieldId}/people/${peopleId}?tab=locations&location=${l._id}`} className={'nav-link ' + (!locationId && i === 0 ? 'active' : locationId === l._id ? 'active' : '')}><strong>{l.name}</strong></Link>
                                                </li>
                                            );
                                        })
                                    }
                                    <li className="nav-item">
                                        <a href={'#'} className={'nav-link text-secondary'} style={readonly ? {display: 'none'} : {}} onClick={this.openNewLocationModal}><strong><i className="fa fa-plus" /> {Localize.text('AddNew', 'Universal')}</strong></a>
                                    </li>
                                </ul>
                            </div>

                            {createNewLocationModal()}

                            <div className={'card-body'}>
                                <div className={'container-fluid'}>
                                    <div className={'row'}>
                                        <div className={'col-6'}>

                                            <div className={'row'}>
                                                <div className={'col-12 form-group'}>
                                                    <div className={'card'}>
                                                        <div className={'card-header'}>
                                                            <div className={'pull-right'}>
                                                                <a href={'#'} onClick={this.openGeneralModal} style={readonly ? {display: 'none'} : {}}><i className={'fa fa-pencil'} /></a>
                                                            </div>
                                                            <h4 className={'no-bottom-margin'}>{Localize.text('GeneralDetails', 'PeopleGroupDetail')}</h4>
                                                        </div>
                                                        <div className={'card-body'}>
                                                            <h5>{Localize.text('Location', 'PeopleGroupDetail') + ':'}</h5>
                                                            <h2>{location.name}{location.country.name ? ` (${location.country.name})` : ''}</h2>
                                                            <TwoColumnLayout>
                                                                <TwoColumnRow>
                                                                    <div>{Localize.text('PublicationName', 'PeopleGroupDetail')}:</div>
                                                                    <div style={styles.leftPadding}>{location.publicationName}</div>
                                                                </TwoColumnRow>
                                                                <TwoColumnRow>
                                                                    <div>{Localize.text('Country', 'PeopleGroupDetail')}:</div>
                                                                    <div style={styles.leftPadding}>{location.country.name}</div>
                                                                </TwoColumnRow>
                                                                <TwoColumnRow>
                                                                    <div>{Localize.text('Area', 'PeopleGroupDetail')}:</div>
                                                                    <div style={styles.leftPadding}>{location.area}</div>
                                                                </TwoColumnRow>
                                                                <TwoColumnRow>
                                                                    <div>{Localize.text('WorkStatus', 'PeopleGroupDetail')}:</div>
                                                                    <div style={styles.leftPadding}>{localizedListItems.has(location.workStatus) ? localizedListItems.get(location.workStatus).name : ''}</div>
                                                                </TwoColumnRow>
                                                                <TwoColumnRow>
                                                                    <div>{Localize.text('Population', 'PeopleGroupDetail')}:</div>
                                                                    <div style={styles.leftPadding}>{location.population}</div>
                                                                </TwoColumnRow>
                                                                <TwoColumnRow>
                                                                    <div>{Localize.text('InitialEvangelism', 'Location')}:</div>
                                                                    <div style={styles.leftPadding}>{localizedListItems.has(location.initialEvangelism) ? localizedListItems.get(location.initialEvangelism).name : ''}</div>
                                                                </TwoColumnRow>
                                                                <TwoColumnRow>
                                                                    <div>{Localize.text('PrimaryCare', 'Location')}:</div>
                                                                    <div style={styles.leftPadding}>{localizedListItems.has(location.primaryCare) ? localizedListItems.get(location.primaryCare).name : ''}</div>
                                                                </TwoColumnRow>
                                                                <TwoColumnRow>
                                                                    <div>{Localize.text('Note', 'PeopleGroupDetail')}:</div>
                                                                    <div style={styles.leftPadding}>{location.note}</div>
                                                                </TwoColumnRow>
                                                            </TwoColumnLayout>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={'modal'} ref={node => this.generalModalNode = node}>
                                                <div className={'modal-dialog modal-lg'}>
                                                    <div className={'modal-content'}>
                                                        <div className={'modal-header'}>
                                                            <h5 className="modal-title">{Localize.text('GeneralDetails', 'PeopleGroupDetail')}</h5>
                                                            <button type="button" className="close" onClick={this.onGeneralModalCancel}>
                                                                <span>&times;</span>
                                                            </button>
                                                        </div>
                                                        <div className="modal-body" style={{maxHeight: windowHeight - 300, overflowY: 'auto'}}>

                                                            <div className={'form-group row'}>
                                                                <label className={'col-lg-3'}>{Localize.text('LocationName', 'Location') + ':'}</label>
                                                                <div className={'col-lg-6'}>
                                                                    <input type={'text'} className={'form-control form-control-sm'} value={state.name} onChange={e => this.onInputChange(e, 'name')} />
                                                                </div>
                                                            </div>

                                                            <div className={'form-group row'}>
                                                                <label className={'col-lg-3'}>{Localize.text('PublicationName', 'PeopleGroupDetail') + ':'}</label>
                                                                <div className={'col-lg-6'}>
                                                                    <input type={'text'} className={'form-control form-control-sm'} value={state.publicationName} onChange={e => this.onInputChange(e, 'publicationName')} />
                                                                </div>
                                                            </div>

                                                            <div className={'form-group row'}>
                                                                <label className={'col-lg-3'}>{Localize.text('Country', 'PeopleGroupDetail') + ':'}</label>
                                                                <div className={'col-lg-6'}>
                                                                    <select className={'form-control form-control-sm'} value={state.country} onChange={e => this.onInputChange(e, 'country')}>
                                                                        {
                                                                            countries
                                                                                .sort((a, b) => intCol.compare(a.name, b.name))
                                                                                .map(c => <option key={c._id} value={c._id}>{c.name}</option>)
                                                                        }
                                                                    </select>
                                                                </div>
                                                            </div>

                                                            <div className={'form-group row'}>
                                                                <label className={'col-lg-3'}>{Localize.text('Area', 'PeopleGroupDetail') + ':'}</label>
                                                                <div className={'col-lg-6'}>
                                                                    <input type={'text'} className={'form-control form-control-sm'} value={state.area} onChange={e => this.onInputChange(e, 'area')} />
                                                                </div>
                                                            </div>

                                                            <div className={'form-group row'}>
                                                                <label className={'col-lg-3'}>{Localize.text('WorkStatus', 'PeopleGroupDetail') + ':'}</label>
                                                                <div className={'col-lg-6'}>
                                                                    <select className={'form-control form-control-sm'} value={state.workStatus} onChange={e => this.onInputChange(e, 'workStatus')}>
                                                                        <option value={''}>{Localize.text('SelectOne', 'UniversalForms')}</option>
                                                                        {
                                                                            listManager
                                                                                .get(localizedLists, 'Work Status')
                                                                                .map(d => <option key={d._id} value={d._id} title={d.values.get('description') || ''}>{d.values.get('name')}</option>)
                                                                        }
                                                                    </select>
                                                                </div>
                                                            </div>

                                                            <div className={'form-group row'}>
                                                                <label className={'col-lg-3'}>{Localize.text('Population', 'PeopleGroupDetail') + ':'}</label>
                                                                <div className={'col-lg-6'}>
                                                                    <input type={'number'} className={'form-control form-control-sm'} value={state.population} min={0} onChange={e => this.onInputChange(e, 'population')} />
                                                                </div>
                                                            </div>

                                                            <div className={'form-group row'}>
                                                                <label className={'col-lg-3'}>{Localize.text('InitialEvangelism', 'Location') + ':'}</label>
                                                                <div className={'col-lg-6'}>
                                                                    <select className={'form-control form-control-sm'} value={state.initialEvangelism} onChange={e => this.onInputChange(e, 'initialEvangelism')}>
                                                                        <option value={''}>{Localize.text('SelectOne', 'UniversalForms')}</option>
                                                                        {
                                                                            listManager
                                                                                .get(localizedLists, 'Primary Care')
                                                                                .map(d => <option key={d._id} value={d._id}>{d.values.get('name')}</option>)
                                                                        }
                                                                    </select>
                                                                </div>
                                                            </div>

                                                            <div className={'form-group row'}>
                                                                <label className={'col-lg-3'}>{Localize.text('PrimaryCare', 'Location') + ':'}</label>
                                                                <div className={'col-lg-6'}>
                                                                    <select className={'form-control form-control-sm'} value={state.primaryCare} onChange={e => this.onInputChange(e, 'primaryCare')}>
                                                                        <option value={''}>{Localize.text('SelectOne', 'UniversalForms')}</option>
                                                                        {
                                                                            listManager
                                                                                .get(localizedLists, 'Primary Care')
                                                                                .map(d => <option key={d._id} value={d._id}>{d.values.get('name')}</option>)
                                                                        }
                                                                    </select>
                                                                </div>
                                                            </div>

                                                            <div className={'form-group row'}>
                                                                <label className={'col-lg-3'}>{Localize.text('Note', 'PeopleGroupDetail') + ':'}</label>
                                                                <div className={'col-lg-9'}>


                                                                    <textarea rows={5} style={{resize: 'vertical'}} className={'form-control form-control-sm'} value={state.note} onChange={e => this.onInputChange(e, 'note')} />
                                                                </div>
                                                            </div>

                                                            {/*<div className={'form-group row'}>*/}
                                                                {/*<label className={'col-lg-3'}>{Localize.text('Phase2', 'PeopleGroupDetail') + ':'}</label>*/}
                                                                {/*<div className={'col-lg-6'}>*/}
                                                                    {/*<select className={'form-control form-control-sm'} value={state.phase2CurriculumStatus} onChange={e => this.onInputChange(e, 'phase2CurriculumStatus')}>*/}
                                                                        {/*<option value={''}>{Localize.text('SelectOne', 'UniversalForms')}</option>*/}
                                                                        {/*{*/}
                                                                            {/*listManager*/}
                                                                                {/*.get(localizedLists, 'Curriculum Status')*/}
                                                                                {/*.map(d => <option key={d._id} value={d._id}>{d.values.get('name')}</option>)*/}
                                                                        {/*}*/}
                                                                    {/*</select>*/}
                                                                {/*</div>*/}
                                                            {/*</div>*/}

                                                        </div>
                                                        <div className="modal-footer">
                                                            <button type={'button'} className={'btn btn-secondary'} onClick={this.onGeneralModalCancel}>{Localize.text('Cancel', 'Universal')}</button>
                                                            <button type={'button'} className={'btn btn-primary'} onClick={this.onGeneralModalSave}>{Localize.text('Save', 'Universal')}</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={'row'}>
                                                <div className={'col-12 form-group'}>
                                                    <div className={'card'}>
                                                        <div className={'card-header'}>
                                                            <div className={'pull-right'}>
                                                                <a href={'#'} onClick={this.openChurchStatusModal} style={readonly ? {display: 'none'} : {}}><i className={'fa fa-pencil'} /></a>
                                                            </div>
                                                            <h4 className={'no-bottom-margin'}>{Localize.text('ChurchStatus', 'PeopleGroupDetail')}</h4>
                                                        </div>
                                                        <div className={'card-body'}>
                                                            <TwoColumnLayout>
                                                                <TwoColumnRow>
                                                                    <div>{Localize.text('ChurchStage', 'PeopleGroupDetail')}:</div>
                                                                    <div style={styles.leftPadding}>{localizedListItems.has(location.churchStage) ? localizedListItems.get(location.churchStage).name : ''}</div>
                                                                </TwoColumnRow>
                                                                <TwoColumnRow>
                                                                    <div>{Localize.text('NumberOfBelievers', 'PeopleGroupDetail')}:</div>
                                                                    <div style={styles.leftPadding}>{location.numberOfBelievers}</div>
                                                                </TwoColumnRow>
                                                                <TwoColumnRow>
                                                                    <div>{Localize.text('NumberOfElders', 'PeopleGroupDetail')}:</div>
                                                                    <div style={styles.leftPadding}>{location.numberOfElders}</div>
                                                                </TwoColumnRow>
                                                            </TwoColumnLayout>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={'modal'} ref={node => this.churchStatusModalNode = node}>
                                                <div className={'modal-dialog modal-lg'}>
                                                    <div className={'modal-content'}>
                                                        <div className={'modal-header'}>
                                                            <h5 className="modal-title">{Localize.text('ChurchStatus', 'PeopleGroupDetail')}</h5>
                                                            <button type="button" className="close" onClick={this.onChurchStatusModalCancel}>
                                                                <span>&times;</span>
                                                            </button>
                                                        </div>
                                                        <div className="modal-body" style={{maxHeight: windowHeight - 300, overflowY: 'auto'}}>

                                                            <div className={'form-group row'}>
                                                                <label className={'col-lg-3'}>{Localize.text('ChurchStage', 'PeopleGroupDetail') + ':'}</label>
                                                                <div className={'col-lg-6'}>
                                                                    <select className={'form-control form-control-sm'} value={state.churchStage} onChange={e => this.onInputChange(e, 'churchStage')}>
                                                                        <option value={''}>{Localize.text('SelectOne', 'UniversalForms')}</option>
                                                                        {
                                                                            listManager
                                                                                .get(localizedLists, 'Church Stage')
                                                                                .map(d => <option key={d._id} value={d._id}>{d.values.get('name')}</option>)
                                                                        }
                                                                    </select>
                                                                </div>
                                                            </div>

                                                            <div className={'form-group row'}>
                                                                <label className={'col-lg-3'}>{Localize.text('NumberOfBelievers', 'PeopleGroupDetail') + ':'}</label>
                                                                <div className={'col-lg-6'}>
                                                                    <input type={'number'} className={'form-control form-control-sm'} value={state.numberOfBelievers} min={0} max={100} onChange={e => this.onInputChange(e, 'numberOfBelievers')} />
                                                                </div>
                                                            </div>

                                                            <div className={'form-group row'}>
                                                                <label className={'col-lg-3'}>{Localize.text('NumberOfElders', 'PeopleGroupDetail') + ':'}</label>
                                                                <div className={'col-lg-6'}>
                                                                    <input type={'number'} className={'form-control form-control-sm'} value={state.numberOfElders} min={0} max={100} onChange={e => this.onInputChange(e, 'numberOfElders')} />
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className="modal-footer">
                                                            <button type={'button'} className={'btn btn-secondary'} onClick={this.onChurchStatusModalCancel}>{Localize.text('Cancel', 'Universal')}</button>
                                                            <button type={'button'} className={'btn btn-primary'} onClick={this.onChurchStatusModalSave}>{Localize.text('Save', 'Universal')}</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* t186 add Personnel */}
                                            <div className={'row'}>
                                                <div className={'col-12 form-group'}>
                                                    <div className={'card'}>
                                                        <div className={'card-header'}>
                                                            <h4 className={'no-bottom-margin'}>{Localize.text('Personnel', 'PeopleGroupDetail')}</h4>
                                                        </div>
                                                        <div style={{ whiteSpace: 'pre-line' }} className={'card-body'}>
                                                            <TwoColumnLayout>
                                                                {locationPeople.filter(p => p.location === locationId).map(p => {
                                                                    const { firstName = '', lastName = '' } = p;
                                                                    return (
                                                                        <TwoColumnRow key={p._id}>
                                                                            <div><Link
                                                                                to={`/personnel/${p._id}`}>{firstName + ' ' + lastName}</Link>:
                                                                            </div>
                                                                            <div>{localizedListItems.get(p.serviceStatus).name}</div>
                                                                        </TwoColumnRow>
                                                                    );
                                                                })}
                                                            </TwoColumnLayout>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={'row'}>
                                                <div className={'col-12 form-group'}>
                                                    <div className={'card'}>
                                                        <div className={'card-header'}>
                                                            <ModalTextarea
                                                                readonly={readonly}
                                                                value={state.workersNote}
                                                                onSave={this.saveWorkersNote}
                                                                title={Localize.text('LocalWorkers', 'PeopleGroupDetail')} />
                                                            <h4 className={'no-bottom-margin'}>{Localize.text('LocalWorkers', 'PeopleGroupDetail')}</h4>
                                                        </div>
                                                        <div style={{whiteSpace: 'pre-line'}} className={'card-body'}>
                                                            {location.workersNote}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={'row'}>
                                                <div className={'col-12 form-group'}>
                                                    <div className={'card'}>
                                                        <div className={'card-header'}>
                                                            <div className={'pull-right'}>
                                                                <a href={'#'} onClick={this.openLiteracyModal} style={readonly ? {display: 'none'} : {}}><i className={'fa fa-pencil'} /></a>
                                                            </div>
                                                            <h4 className={'no-bottom-margin'}>{Localize.text('Literacy', 'PeopleGroupDetail')}</h4>
                                                        </div>
                                                        <div className={'card-body'}>
                                                            <TwoColumnLayout>
                                                                <TwoColumnRow>
                                                                    <div>{Localize.text('LiteracyOnGoingVernacularProgram', 'PeopleGroupDetail')}:</div>
                                                                    <div style={styles.leftPadding}>{yesNo(location.literacyOngoingVernacularProgram)}</div>
                                                                </TwoColumnRow>
                                                                <TwoColumnRow>
                                                                    <div>{Localize.text('LiteracyOnGoingLWCProgram', 'PeopleGroupDetail')}:</div>
                                                                    <div style={styles.leftPadding}>{yesNo(location.literacyOngoingLWCProgram)}</div>
                                                                </TwoColumnRow>
                                                                <TwoColumnRow>
                                                                    <div>{Localize.text('LiteracyRateVernacular', 'PeopleGroupDetail')}:</div>
                                                                    <div style={styles.leftPadding}>{location.literacyRateVernacular + '%'}</div>
                                                                </TwoColumnRow>
                                                                <TwoColumnRow>
                                                                    <div>{Localize.text('LiteracyRateLWC', 'PeopleGroupDetail')}:</div>
                                                                    <div style={styles.leftPadding}>{location.literacyRateLWC + '%'}</div>
                                                                </TwoColumnRow>
                                                                <TwoColumnRow>
                                                                    <div>{Localize.text('LiteracyGovernmentProgram', 'PeopleGroupDetail')}:</div>
                                                                    <div style={styles.leftPadding}>{yesNo(location.literacyGovernmentProgram)}</div>
                                                                </TwoColumnRow>
                                                                <TwoColumnRow>
                                                                    <div>{Localize.text('LiteracyNote', 'PeopleGroupDetail')}:</div>
                                                                    <div style={styles.leftPadding}>{location.literacyNote}</div>
                                                                </TwoColumnRow>
                                                            </TwoColumnLayout>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={'modal'} ref={node => this.literacyModalNode = node}>
                                                <div className={'modal-dialog modal-lg'}>
                                                    <div className={'modal-content'}>
                                                        <div className={'modal-header'}>
                                                            <h5 className="modal-title">{Localize.text('Literacy', 'PeopleGroupDetail')}</h5>
                                                            <button type="button" className="close" onClick={this.onLiteracyModalCancel}>
                                                                <span>&times;</span>
                                                            </button>
                                                        </div>
                                                        <div className="modal-body" style={{maxHeight: windowHeight - 300, overflowY: 'auto'}}>

                                                            <div className={'form-group row'}>
                                                                <label className={'col-lg-3'}>{Localize.text('LiteracyOnGoingVernacularProgram', 'PeopleGroupDetail') + ':'}</label>
                                                                <div className={'col-lg-6'}>
                                                                    <select className={'form-control form-control-sm'} value={state.literacyOngoingVernacularProgram} onChange={e => this.onBooleanInputChange(e, 'literacyOngoingVernacularProgram')}>
                                                                        <option value={true}>{Localize.text('Yes', 'Universal')}</option>
                                                                        <option value={false}>{Localize.text('No', 'Universal')}</option>
                                                                    </select>
                                                                </div>
                                                            </div>

                                                            <div className={'form-group row'}>
                                                                <label className={'col-lg-3'}>{Localize.text('LiteracyOnGoingLWCProgram', 'PeopleGroupDetail') + ':'}</label>
                                                                <div className={'col-lg-6'}>
                                                                    <select className={'form-control form-control-sm'} value={state.literacyOngoingLWCProgram} onChange={e => this.onBooleanInputChange(e, 'literacyOngoingLWCProgram')}>
                                                                        <option value={true}>{Localize.text('Yes', 'Universal')}</option>
                                                                        <option value={false}>{Localize.text('No', 'Universal')}</option>
                                                                    </select>
                                                                </div>
                                                            </div>

                                                            <div className={'form-group row'}>
                                                                <label className={'col-lg-3'}>{Localize.text('LiteracyRateVernacular', 'PeopleGroupDetail') + ':'}</label>
                                                                <div className={'col-lg-6'}>
                                                                    <input type={'number'} className={'form-control form-control-sm'} value={state.literacyRateVernacular} min={0} max={100} onChange={e => this.onInputChange(e, 'literacyRateVernacular')} />
                                                                </div>
                                                            </div>

                                                            <div className={'form-group row'}>
                                                                <label className={'col-lg-3'}>{Localize.text('LiteracyRateLWC', 'PeopleGroupDetail') + ':'}</label>
                                                                <div className={'col-lg-6'}>
                                                                    <input type={'number'} className={'form-control form-control-sm'} value={state.literacyRateLWC} min={0} max={100} onChange={e => this.onInputChange(e, 'literacyRateLWC')} />
                                                                </div>
                                                            </div>

                                                            <div className={'form-group row'}>
                                                                <label className={'col-lg-3'}>{Localize.text('LiteracyGovernmentProgram', 'PeopleGroupDetail') + ':'}</label>
                                                                <div className={'col-lg-6'}>
                                                                    <select className={'form-control form-control-sm'} value={state.literacyGovernmentProgram} onChange={e => this.onBooleanInputChange(e, 'literacyGovernmentProgram')}>
                                                                        <option value={true}>{Localize.text('Yes', 'Universal')}</option>
                                                                        <option value={false}>{Localize.text('No', 'Universal')}</option>
                                                                    </select>
                                                                </div>
                                                            </div>

                                                            <div className={'form-group row'}>
                                                                <label className={'col-lg-3'}>{Localize.text('LiteracyNote', 'PeopleGroupDetail') + ':'}</label>
                                                                <div className={'col-lg-9'}>
                                                                    <textarea rows={5} style={{resize: 'vertical'}} className={'form-control form-control-sm'} value={state.literacyNote} onChange={e => this.onInputChange(e, 'literacyNote')} />
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className="modal-footer">
                                                            <button type={'button'} className={'btn btn-secondary'} onClick={this.onLiteracyModalCancel}>{Localize.text('Cancel', 'Universal')}</button>
                                                            <button type={'button'} className={'btn btn-primary'} onClick={this.onLiteracyModalSave}>{Localize.text('Save', 'Universal')}</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={'row'}>
                                                <div className={'col-12 form-group'}>
                                                    <div className={'card'}>
                                                        <div className={'card-header'}>
                                                            <div className={'pull-right'}>
                                                                <a href={'#'} onClick={this.openTimelineModal} style={readonly ? {display: 'none'} : {}}><i className={'fa fa-pencil'} /></a>
                                                            </div>
                                                            <h4 className={'no-bottom-margin'}>{Localize.text('Timeline', 'PeopleGroupDetail')}</h4>
                                                        </div>
                                                        <div className={'card-body'}>
                                                            <TwoColumnLayout>
                                                                <TwoColumnRow>
                                                                    <div>{Localize.text('TargetDateEntry', 'PeopleGroupDetail')}:</div>
                                                                    <div style={styles.leftPadding}>{location.targetDateEntry}</div>
                                                                </TwoColumnRow>
                                                                <TwoColumnRow>
                                                                    <div>{Localize.text('DateEntered', 'PeopleGroupDetail')}:</div>
                                                                    <div style={styles.leftPadding}>{location.dateEntered}</div>
                                                                </TwoColumnRow>
                                                                <TwoColumnRow>
                                                                    <div>{Localize.text('DateTeachingBegan', 'Location')}:</div>
                                                                    <div style={styles.leftPadding}>{location.dateTeachingBegin}</div>
                                                                </TwoColumnRow>
                                                                <TwoColumnRow>
                                                                    <div>{Localize.text('TargetDateGospelPresented', 'PeopleGroupDetail')}:</div>
                                                                    <div style={styles.leftPadding}>{location.targetDateGospelPresented}</div>
                                                                </TwoColumnRow>
                                                                <TwoColumnRow>
                                                                    <div>{Localize.text('DateGospelPresented', 'PeopleGroupDetail')}:</div>
                                                                    <div style={styles.leftPadding}>{location.dateGospelPresented}</div>
                                                                </TwoColumnRow>
                                                            </TwoColumnLayout>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={'modal'} ref={node => this.timelineModalNode = node}>
                                                <div className={'modal-dialog modal-lg'}>
                                                    <div className={'modal-content'}>
                                                        <div className={'modal-header'}>
                                                            <h5 className="modal-title">{Localize.text('Timeline', 'PeopleGroupDetail')}</h5>
                                                            <button type="button" className="close" onClick={this.onTimelineModalCancel}>
                                                                <span>&times;</span>
                                                            </button>
                                                        </div>
                                                        <div className="modal-body" style={{maxHeight: windowHeight - 300, overflowY: 'auto'}}>

                                                            <div className={'form-group row'}>
                                                                <label className={'col-lg-3'}>{Localize.text('TargetDateEntry', 'PeopleGroupDetail') + ':'}</label>
                                                                <div className={'col-lg-6'}>
                                                                    <input type={'text'} className={'form-control form-control-sm'} value={state.targetDateEntry} onChange={e => this.onInputChange(e, 'targetDateEntry')} />
                                                                </div>
                                                            </div>

                                                            <div className={'form-group row'}>
                                                                <label className={'col-lg-3'}>{Localize.text('DateEntered', 'PeopleGroupDetail') + ':'}</label>
                                                                <div className={'col-lg-6'}>
                                                                    <input type={'text'} className={'form-control form-control-sm'} value={state.dateEntered} onChange={e => this.onInputChange(e, 'dateEntered')} />
                                                                </div>
                                                            </div>

                                                            <div className={'form-group row'}>
                                                                <label className={'col-lg-3'}>{Localize.text('DateTeachingBegan', 'Location') + ':'}</label>
                                                                <div className={'col-lg-6'}>
                                                                    <input type={'text'} className={'form-control form-control-sm'} value={state.dateTeachingBegin} onChange={e => this.onInputChange(e, 'dateTeachingBegin')} />
                                                                </div>
                                                            </div>

                                                            <div className={'form-group row'}>
                                                                <label className={'col-lg-3'}>{Localize.text('TargetDateGospelPresented', 'PeopleGroupDetail') + ':'}</label>
                                                                <div className={'col-lg-6'}>
                                                                    <input type={'text'} className={'form-control form-control-sm'} value={state.targetDateGospelPresented} onChange={e => this.onInputChange(e, 'targetDateGospelPresented')} />
                                                                </div>
                                                            </div>

                                                            <div className={'form-group row'}>
                                                                <label className={'col-lg-3'}>{Localize.text('DateGospelPresented', 'PeopleGroupDetail') + ':'}</label>
                                                                <div className={'col-lg-6'}>
                                                                    <input type={'text'} className={'form-control form-control-sm'} value={state.dateGospelPresented} onChange={e => this.onInputChange(e, 'dateGospelPresented')} />
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className="modal-footer">
                                                            <button type={'button'} className={'btn btn-secondary'} onClick={this.onTimelineModalCancel}>{Localize.text('Cancel', 'Universal')}</button>
                                                            <button type={'button'} className={'btn btn-primary'} onClick={this.onTimelineModalSave}>{Localize.text('Save', 'Universal')}</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className={'col-6'}>

                                            <div className={'row'}>
                                                <div className={'col-12'}>
                                                    <button className={'btn btn-primary'} style={{float: 'right'}} onClick={this.openMaturityModal}>{Localize.text('Details', 'Universal')}</button>
                                                    <h2 className={'text-center'}>{Localize.text('ChurchMaturity', 'PeopleGroupDetail')}</h2>
                                                    <div style={{marginLeft: 'auto', marginRight: 'auto', width: 420}}>
                                                        <RadarGraph width={420} divisions={6} data={[
                                                            {
                                                                label: Localize.text('DiscipleshipInTheChurch', 'PeopleGroupDetail'),
                                                                color: '#ffa',
                                                                value: discipleshipValue
                                                            },
                                                            {
                                                                label: Localize.text('GodSWordForTheChurch', 'PeopleGroupDetail'),
                                                                color: '#faa',
                                                                value: wordValue
                                                            },
                                                            {
                                                                label: Localize.text('LifeOfTheChurch', 'PeopleGroupDetail'),
                                                                color: '#afa',
                                                                value: lifeValue
                                                            },
                                                            {
                                                                label: Localize.text('IdentityOfTheChurch', 'PeopleGroupDetail'),
                                                                color: '#aaf',
                                                                value: identityValue,
                                                            },
                                                        ]} />
                                                    </div>
                                                </div>

                                                {state.showMaturityModal ?
                                                    <MaturingChurchForm peopleGroup={peopleGroup} location={location} onSave={saveMaturingChurchStatuses} onClose={this.closeMaturityModal} />
                                                    :
                                                    <div />
                                                }

                                            </div>

                                            <div style={{marginTop: 10}} className={'row'}>
                                                <div className={'col-12'}>
                                                    <h2 className={'text-center'}>{Localize.text('MaturingChurch', 'PeopleGroupDetail')}</h2>
                                                    <div>
                                                        {location.maturingChurchStatuses.length > 0 ?
                                                            <LineChart data={{
                                                                labels: location.maturingChurchStatuses
                                                                    .map(s => s.mChurchDate),
                                                                datasets: [
                                                                    {
                                                                        label: Localize.text('DiscipleshipInTheChurch', 'PeopleGroupDetail'),
                                                                        borderColor: '#cc0',
                                                                        backgroundColor: '#fff',
                                                                        fill: false,
                                                                        data: location.maturingChurchStatuses
                                                                            .map(s => {
                                                                                return (s.mChurchDiscipleship0 + s.mChurchDiscipleship1 + s.mChurchDiscipleship2 + s.mChurchDiscipleship3 + s.mChurchDiscipleship4) / 5;
                                                                            })
                                                                    },
                                                                    {
                                                                        label: Localize.text('GodSWordForTheChurch', 'PeopleGroupDetail'),
                                                                        borderColor: '#f00',
                                                                        backgroundColor: '#fff',
                                                                        fill: false,
                                                                        data: location.maturingChurchStatuses
                                                                            .map(s => {
                                                                                return (s.mChurchGodsWord0 + s.mChurchGodsWord1 + s.mChurchGodsWord2 + s.mChurchGodsWord3 + s.mChurchGodsWord4) / 5;
                                                                            })
                                                                    },
                                                                    {
                                                                        label: Localize.text('LifeOfTheChurch', 'PeopleGroupDetail'),
                                                                        borderColor: '#0b0',
                                                                        backgroundColor: '#fff',
                                                                        fill: false,
                                                                        data: location.maturingChurchStatuses
                                                                            .map(s => {
                                                                                return (s.mChurchLife0 + s.mChurchLife1 + s.mChurchLife2 + s.mChurchLife3 + s.mChurchLife4) / 5;
                                                                            })
                                                                    },
                                                                    {
                                                                        label: Localize.text('IdentityOfTheChurch', 'PeopleGroupDetail'),
                                                                        borderColor: '#00f',
                                                                        backgroundColor: '#fff',
                                                                        fill: false,
                                                                        data: location.maturingChurchStatuses
                                                                            .map(s => {
                                                                                return (s.mChurchIdentity0 + s.mChurchIdentity1 + s.mChurchIdentity2 + s.mChurchIdentity3 + s.mChurchIdentity4) / 5;
                                                                            }),
                                                                    },
                                                                ],
                                                            }} />
                                                            :
                                                            <div />
                                                        }
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }

}
Locations.propTypes = {
    locationId: PropTypes.string,
    params: PropTypes.object,
    queryParams: PropTypes.object,
    localizedLists: PropTypes.arrayOf(PropTypes.object),
    localizedListItems: PropTypes.instanceOf(Map),
    field: PropTypes.object,
    peopleGroup: PropTypes.object,
    locations: PropTypes.arrayOf(PropTypes.object),
    allLocations: PropTypes.arrayOf(PropTypes.object),
    countries: PropTypes.arrayOf(PropTypes.object),
    peopleGroupStateStatuses: PropTypes.instanceOf(Map),
    appStatePeople: PropTypes.instanceOf(Map),
    readonly: PropTypes.bool,
    windowHeight: PropTypes.number,
    updateMaturity: PropTypes.func,
    updateLocation: PropTypes.func,
    createLocation: PropTypes.func,
    modalStatus: PropTypes.string,
    disableNav: PropTypes.func,
    enableNav: PropTypes.func,
    setModalStatus: PropTypes.func,
};
const LocationsContainer = connect(
    ({ appState, peopleGroupState }) => ({
        countries: appState.countries,
        // t272  we need the peopleGroup id to compute locationPeople, so we can't do it here.
        //  Instead, include the props necessary to compute it in render().
        peopleGroupStateStatuses: peopleGroupState.statuses,
        appStatePeople: appState.people,
        modalStatus: appState.modalStatus,
    }),
    (dispatch, props) => ({
        async updateMaturity(location) {
            try {
                const { locations } = props;
                const locationIdx = locations
                    .findIndex(l => l._id === location._id);
                const newLocations = [
                    ...locations.slice(0, locationIdx),
                    location,
                    ...locations.slice(locationIdx + 1)
                ];
                dispatch(setLocations(newLocations));
                const { errors } = await gql.transaction(
                    'mutation',
                    'updateLocation',
                    {
                        _id: location._id,
                        input: {
                            maturingChurchStatuses: location.maturingChurchStatuses,
                            maturingChurchNote: location.maturingChurchNote
                        }
                    },
                    ['_id', 'name']
                );
                if(errors) throw new Error(errors[0].message);
            } catch(err) {
                handleError(err);
            }
        },
        updateLocation(locationData) {
            const { locationId, locations } = props;
            const locationIdx = locations
                .findIndex(l => l._id === locationId);
            const location = {
                ...locations[locationIdx],
                ...locationData
            };
            const newLocations = [
                ...locations.slice(0, locationIdx),
                location,
                ...locations.slice(locationIdx + 1)
            ];
            dispatch(setLocations(newLocations));
        },
        async createLocation(locationData) {
            try {
                const { allLocations } = props;
                const { errors, data } = await gql.transaction(
                    'mutation',
                    'createLocation',
                    {
                        input: {
                            name: locationData.name,
                            peopleGroup: locationData.peopleGroup,
                            country: locationData.country,
                            field: locationData.field,
                            churchStage: dbGuids.CHURCH_STAGE_UNKNOWN,
                            literacyRateVernacular: locationData.literacyRateVernacular,
                            literacyRateLWC: locationData.literacyRateLWC,
                        },
                    },
                    ['_id', 'name']
                );
                if(errors) throw new Error(errors[0].message);
                const { _id } = data.createLocation;
                const { error: errors1, data: data1 } = await gql.transaction(
                    'query',
                    'getLocation',
                    { _id },
                    callAttributes.locationDetail()
                );
                if(errors1) throw new Error(errors1[0].message);
                const newLocation = data1.getLocation;
                const newLocations = [
                    ...allLocations,
                    {
                        _id: newLocation._id,
                        name: newLocation.name,
                        level: newLocation.level,
                        country: newLocation.country,
                        field: newLocation.field,
                        peopleGroup: newLocation.peopleGroup,
                    },
                ];
                const { locations } = props;
                const newPeopleGroupLocations = [
                    ...locations,
                    newLocation,
                ];
                dispatch(setLocations(newPeopleGroupLocations));
                dispatch(setAppLocations(newLocations));
            } catch(err) {
                handleError(err);
            }
        },
        disableNav: () => dispatch(setAppDisableNav(true)),
        enableNav: () => dispatch(setAppDisableNav(false)),
        setModalStatus: status => dispatch(setAppModalStatus(status)),
    })
)(Locations);

export default LocationsContainer;
