import actions from '../enums/actions';
import { Map } from 'immutable';

const getInitialState = () => ({
    peopleGroups: Map(),
    locations: Map(),
    statuses: Map(),
    consultants: Map()
});

export default (state = getInitialState(), { type, payload }) => {
    switch(type) {
        case actions.PEOPLE_GROUP_SET_PEOPLE_GROUP:
            return {
                ...state,
                peopleGroups: state.peopleGroups.set(payload.peopleGroup._id, payload.peopleGroup)
            };
        case actions.PEOPLE_GROUP_SET_LOCATIONS:
            return {
                ...state,
                locations: payload.locations
                    .reduce((map, l) => map.set(l._id, l), state.locations)
            };
        case actions.PEOPLE_GROUP_SET_STATUSES:
            return {
                ...state,
                statuses: state.statuses.set(payload.peopleGroupId, payload.statuses)
            };
        case actions.PEOPLE_GROUP_SET_CONSULTANTS:
            return {
                ...state,
                consultants: state.consultants.set(payload.peopleGroupId, payload.consultants)
            };
        default:
            return state;
    }
};
